import theme from 'config/theme';
import type {MultiplayerMember} from 'interface/multiplayer/types';
import {AppMemberAvatar} from 'interface/stacks/app/AppMemberAvatar';
import {StyleSheet, TextInput, View} from 'react-native';
import {EditorActions} from '../components/top-toolbar/ActionButtons/EditorActions';
import BackButton from '../components/top-toolbar/BackButton';
import UserDropdown from '../components/top-toolbar/UserDropdown';
import {useEditorContext} from '../context/EditorContext';

export const HEIGHT_TOP_TOOLBAR = 52;

type Props = {
  members?: MultiplayerMember[];
};

const EditorTopToolbar = ({members}: Props) => {
  const {fileName, setFileName} = useEditorContext();

  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <BackButton />
        <TextInput
          style={styles.input}
          value={fileName}
          placeholder={'Insert file name here'}
          onChangeText={text => {
            setFileName(text);
          }}
        />
      </View>
      <View style={styles.right}>
        <UserDropdown kind="dark" />
        <View style={styles.divider} />
        <EditorActions />
        <View style={styles.divider} />
        {members?.map(member => (
          <AppMemberAvatar
            key={member.clientId}
            member={member}
            borderKind={'light'}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: HEIGHT_TOP_TOOLBAR,
    backgroundColor: theme.colors.neutral.$1,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 5,
    width: '100%',
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    flex: 1,
  },
  right: {
    flexDirection: 'row-reverse',
  },
  divider: {
    width: 0,
    height: HEIGHT_TOP_TOOLBAR,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$black,
    marginHorizontal: 8,
    marginTop: -8,
  },
  input: {
    flex: 1,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 22,
    color: theme.colors.neutral.$white,
  },
});

export default EditorTopToolbar;
