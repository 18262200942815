import {t} from '@lingui/macro';
import theme from 'config/theme';
import {format, isToday, isYesterday} from 'date-fns';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import Skeleton from 'interface/common/Skeleton';
import {useGetUserDetailsCached} from 'interface/common/hooks/useGetUserDetailsCached';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

type Props = {
  date: Date;
  userId: string;
  action: string;
  last: boolean;
  type: string;
};

const renderDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, "'Today,' h:mm a");
  }

  if (isYesterday(date)) {
    return format(date, "'Yesterday,' h:mm a");
  }

  return format(date, 'MMM. d, yyyy, h:mm a');
};

const PreviewSidePanelVersionChange = ({
  date,
  userId,
  action,
  last,
  type,
}: Props) => {
  const {user, initials, userName, isLoading} = useGetUserDetailsCached(userId);

  const message = user
    ? `${userName} ${action}d this item`
    : `The item was ${action}d`;

  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <View style={styles.line} />
        <View style={styles.circle} />
        {!last && <View style={styles.line} />}
      </View>
      <View style={styles.right}>
        <Text style={styles.date}>{renderDate(date)}</Text>
        <View style={styles.user}>
          {isLoading ? (
            <Skeleton width={30} height={30} />
          ) : user ? (
            <MemberAvatar initials={initials} memberId={userId} />
          ) : (
            <View style={styles.unknownUser}>
              <Icon
                name="lucide:user-round"
                size={20}
                color={theme.colors.neutral.$2Base}
              />
            </View>
          )}
          <Typography size="xs" color={theme.colors.neutral.$2Base}>
            {t`${message}`}
          </Typography>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: 80,
    flexDirection: 'row',
    gap: 10,
  },
  left: {
    width: 30,
    alignItems: 'center',
  },
  line: {
    width: 2,
    height: 32.5,
    backgroundColor: theme.colors.neutral.$10,
  },
  lineLast: {},
  circle: {
    width: 10,
    height: 10,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$10,
    borderStyle: 'solid',
    borderRadius: 50,
  },
  right: {
    justifyContent: 'center',
    gap: 5,
    flex: 1,
    marginTop: 24,
  },
  date: {
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
  },
  user: {
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
    marginTop: 4,
  },
  unknownUser: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.colors.neutral.$10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default PreviewSidePanelVersionChange;
