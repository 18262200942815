import {ROUTES} from 'constants/routes';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router';
import * as app from 'store/slices/app';
import type {Organization} from 'store/slices/user/types';
import {isSubdomainValid} from 'utils/common/platform';
import {GO_SUBDOMAIN} from 'utils/fast/constants';
import {useNavigateToGo} from './hooks/navigator/useNavigateToGo';

type Props = {
  organizationsList: Organization[];
  subdomain: string;
};

const DefaultRedirect = ({subdomain}: Props) => {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const {navigateToGo} = useNavigateToGo();

  useEffect(() => {
    if (isSubdomainValid(subdomain)) {
      if (isLoggedIn) {
        navigateToGo(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
      } else {
        navigateToGo(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`);
      }
    }
  }, [subdomain]);

  if (subdomain === GO_SUBDOMAIN) {
    if (isLoggedIn) {
      return <Navigate to={`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`} replace />;
    }
    return (
      <Navigate
        to={`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`}
        replace
      />
    );
  }
};

export default DefaultRedirect;
