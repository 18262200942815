import useHasAccessToWorkspace from 'extensions/session/hooks/useHasAccessToWorkspace';
import {api} from 'fast-sdk';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {useDispatch} from 'react-redux';
import store from 'store';
import * as shared from 'store/slices/shared';

const useFetchShareDetails = () => {
  const dispatch = useDispatch();

  const {checkWorkspaceAccess} = useHasAccessToWorkspace();

  const getWorkspaceFolderName = (
    share: ShareItem,
    workspaceFolderName?: string,
  ) => {
    const cachedWorkspace = store
      .getState()
      .workspace.workspaces?.find(ws => ws.id === share.parent_workspace);
    const workspaceAccess = checkWorkspaceAccess(share.parent_workspace);
    return (
      workspaceFolderName ??
      cachedWorkspace?.folder_name ??
      (workspaceAccess ? workspaceAccess.folderName : 'externals')
    );
  };

  const fetchShareDetails = async (
    workspaceFolderName: string | undefined,
    shareId: string,
  ) => {
    const {result, share: shareDetails} =
      await api.share.getShareDetails(shareId);

    if (result) {
      dispatch(
        shared.default.actions.updateShareDetails({
          workspaceFolderName: getWorkspaceFolderName(
            shareDetails,
            workspaceFolderName,
          ),
          shareDetails,
        }),
      );
      return shareDetails;
    }
  };

  const fetchShareDetailsByCustomName = async (sharedCustomName: string) => {
    const {result, share} = await api.share.getShareDetails(sharedCustomName);
    if (result) {
      dispatch(
        shared.default.actions.updateShareDetails({
          workspaceFolderName: getWorkspaceFolderName(share),
          shareDetails: share,
        }),
      );
      return share;
    }
  };

  const fetchPublicShareDetails = async (shareName: string) => {
    const {result, ...rest} = await api.share.getPublicShareDetails(shareName);
    return result ? rest : null;
  };

  return {
    fetchShareDetails,
    fetchShareDetailsByCustomName,
    fetchPublicShareDetails,
  };
};

export default useFetchShareDetails;
