import commonEmails from 'email-providers/common.json';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {api} from 'fast-sdk';
import {MemberManagePerm} from 'fast-sdk/src/api/organization/consts';
import useBillingDetails from 'interface/common/hooks/useBillingDetails';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import type {BillingPlan} from '../lib/types';

let hasCreatedOrgCalled = false;

export default function useCreateOrg() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [intentClientSecret, setIntentClientSecret] = useState<string>('');
  const [stripeKey, setStripeKey] = useState<string>('');
  const [currentPlan, setCurrentPlan] = useState<BillingPlan | null>(null);

  const clientSecret = useSelector(onboarding.selectors.getIntentClientSecret);
  const orgName = useSelector(onboarding.selectors.getOrganizationName);
  const currentSubdomain = useSelector(onboarding.selectors.getSubdomain);
  const subdomain = useSelector(
    onboarding.selectors.getCreatedOrganizationDomain,
  );
  const userDetails = useSelector(user.selectors.getUserDetails);
  const createdOrgId = useSelector(
    onboarding.selectors.getCreatedOrganizationId,
  );
  const industry = useSelector(onboarding.selectors.getSelectedIndustry);

  async function handleCreateOrg() {
    hasCreatedOrgCalled = true;
    const emailProvider = userDetails.email_address.split('@')[1];
    const authDomain = commonEmails.includes(emailProvider)
      ? null
      : emailProvider;

    const {result, plans} = await api.billing.getPlanList();
    if (!result) {
      navigate(-1);
      return;
    }
    const defaultPlan = plans.at(0);

    try {
      const {result, org} = await api.organization.createOrganization({
        name: orgName,
        domain: currentSubdomain,
        billing_plan: defaultPlan.name,
        billing_email: userDetails.email_address,
        perm_auth_domains: authDomain,
        perm_join: MemberManagePerm['Member or above'],
        industry,
      });

      if (!result) {
        handleAppError({
          appError: AppErrors.CreateOrgError,
          dialogOptions: {
            message: 'Error creating organization',
            title: 'proceeding to checkout',
          },
        });
      } else {
        setCurrentPlan(defaultPlan);
        dispatch(
          onboarding.default.actions.setCreatedOrganization({
            id: org.id,
            name: orgName,
            domain: org.domain,
          }),
        );
      }
    } catch (error) {
      navigate(-1);
    } finally {
      hasCreatedOrgCalled = false;
    }
  }

  const handleCreateSubscriptionError = () => {
    handleAppError({
      appError: AppErrors.CreateOrUpdateSubError,
      dialogOptions: {
        message: 'Error creating subscription',
        title: 'proceeding to checkout',
      },
    });
    return navigate(-1);
  };

  const handleInvalidPublicOrIntent = () => {
    handleAppError({
      appError: AppErrors.CreateOrUpdateSubError,
      dialogOptions: {
        message: 'Error getting public key or intent',
        title: 'proceeding to checkout',
      },
    });
    return navigate(-1);
  };

  const {billingStatus} = useBillingDetails(subdomain, {
    generatePaymentIntent: true,
    disable: !createdOrgId,
    onError: handleCreateSubscriptionError,
    plan: currentPlan,
    redirectWhenSubscriptionActive: true,
  });

  useEffect(() => {
    if (!createdOrgId && !hasCreatedOrgCalled) {
      handleCreateOrg();
    }
  }, []);

  useEffect(() => {
    if (billingStatus) {
      try {
        const {
          setup_intent: {client_secret} = {},
          public_key,
          current_plan,
        } = billingStatus;

        if (!public_key || !client_secret) {
          handleInvalidPublicOrIntent();
        }

        setIntentClientSecret(client_secret);
        setStripeKey(public_key);
        setCurrentPlan(current_plan);

        dispatch(
          onboarding.default.actions.setIntentClientSecret({
            intentClientSecret: client_secret,
          }),
        );
        dispatch(
          onboarding.default.actions.setPublicKey({
            key: public_key,
          }),
        );
      } finally {
        setLoading(false);
      }
    }
  }, [billingStatus]);

  return {
    loading,
    clientSecret: intentClientSecret || clientSecret,
    stripeKey,
    plan: currentPlan,
  };
}
