import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {BREAKPOINTS} from 'interface/common/hooks/useBreakpoints';
import {Fragment, useState} from 'react';
import {StyleSheet} from 'react-native';

import type {MetadataState} from '../types';

interface Props {
  metadata: MetadataState;
  containerWidth?: number;
}

export function TemplatesPopup({metadata, containerWidth}: Props) {
  const [hasMenu, setHasMenu] = useState(false);
  const {templates, templateId, getTemplateData} = metadata;
  const isMinimal = containerWidth && containerWidth < BREAKPOINTS.sm;
  const activeTpl = getTemplateData(templateId);
  const list = templates.map(template => getTemplateData(template.template_id));

  if (!activeTpl || list.length === 0) {
    return null;
  }

  const trigger = (
    <Button
      onPress={() => setHasMenu(true)}
      variant="primary"
      startEnhancer={
        <Icon name="lucide:files" color={theme.colors.neutral.$0} />
      }
      endEnhancer={
        <Icon name="lucide:chevron-down" color={theme.colors.neutral.$0} />
      }>
      {!isMinimal && (
        <Typography size="xs" color={theme.colors.neutral.$0}>
          {activeTpl?.name}
        </Typography>
      )}
    </Button>
  );

  const update = (value: string) => {
    metadata.selectTemplate(value);
    setHasMenu(false);
  };

  return (
    <Popup
      isOpen={hasMenu}
      showBorder={false}
      triggerElement={trigger}
      customContainerStyle={styles.popupContainer}
      customPopupStyle={styles.customPopupStyle}
      close={() => setHasMenu(false)}>
      <Fragment>
        <Typography
          size="sm"
          variant="regular"
          color={theme.colors.neutral.$500}
          overrides={styles.title}>
          Metadata
        </Typography>
        {list.map(tpl =>
          tpl.id ? (
            <MenuItem
              key={tpl.id}
              icon={tpl.icon}
              title={tpl.name}
              active={activeTpl?.id === tpl.id}
              description={tpl.description}
              onPress={() => update(tpl.id)}
            />
          ) : null,
        )}
      </Fragment>
    </Popup>
  );
}

function MenuItem({
  title,
  description,
  active,
  icon,
  onPress,
}: {
  title: string;
  description: string;
  active: boolean;
  icon: string;
  onPress: () => void;
}) {
  return (
    <HoveredView
      onPress={onPress}
      style={[styles.item, active && styles.active]}>
      <Icon name={icon} color={theme.colors.neutral.$700} />
      <Typography
        aria-label={description}
        size="sm"
        variant="regular"
        color={theme.colors.neutral.$700}>
        {title}
      </Typography>
    </HoveredView>
  );
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 16,
  },
  item: {
    paddingHorizontal: 8,
    height: 28,
    paddingVertical: 2,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  active: {
    backgroundColor: theme.colors.neutral.$100,
  },
  popupContainer: {
    borderRadius: 16,
    padding: 16,
    width: 250,
    shadowColor: 'rgb(28, 27, 34)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
  },
  customPopupStyle: {
    backgroundColor: 'transparent',
    paddingBottom: 8,
    paddingTop: 8,
    paddingHorizontal: 8,
  },
});
