import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import Skeleton from 'interface/common/Skeleton';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import {useGetWorkspaceLogo} from 'interface/common/hooks/useGetWorkspaceLogo';
import useWorkspaceMenuSettings from 'interface/stacks/app/hooks/useWorkspaceMenuSettings';
import {MemberAvatarGroup} from 'interface/stacks/auth/components/MemberAvatarGroup';
import {useWorkspaceJoinMember} from 'interface/stacks/workspace/hooks/useWorkspaceJoinMember';
import {TimeLabel} from 'interface/stacks/workspace/layout/WorkspaceAiChat/components/AiChatTimeLabel';
import {useEffect, useRef, useState} from 'react';
import {type GestureResponderEvent, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import * as workspaceStore from 'store/slices/workspace';
import {WorkspaceLayoutTabs} from 'store/slices/workspace/types';
import {parseServerDate} from 'utils/common/dates';
import Card from '../common/Card';
interface WorkspaceCardProps {
  workspaceId: string;
  onWorkspacePress: (workspace: WorkspaceListDetail) => void;
  layoutTab: WorkspaceLayoutTabs;
}

const WorkspaceCard = ({
  workspaceId,
  onWorkspacePress,
  layoutTab,
}: WorkspaceCardProps) => {
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const {fetchWorkspaceLogo} = useGetWorkspaceLogo();
  const workspace = useSelector(
    workspaceStore.selectors.getWorkspaceById(workspaceId),
  );
  const workspaceMenuRef = useRef<any>(null);

  const currentUser = useSelector(user.selectors.getUserDetails);

  const {
    handleOpenMenu,
    loading: membersLoading,
    members,
  } = useWorkspaceMenuSettings(workspace, currentUser.id);
  const {onJoinButtonPress} = useWorkspaceJoinMember({navigateOnJoin: false});

  const handleMenuPress = (event: GestureResponderEvent) => {
    event.preventDefault();
    handleOpenMenu(event, workspaceMenuRef);
  };

  const handleJoinWorkspacePress = async () => {
    try {
      setIsJoinLoading(true);
      await onJoinButtonPress(workspace);
      onWorkspacePress(workspace);
    } finally {
      setIsJoinLoading(false);
    }
  };

  const handleWorkspacePress = () => {
    onWorkspacePress(workspace);
  };

  const isUserJoinedWorkspace =
    workspace.user_status === WorkspaceUserStatus.Joined;

  const cardStyle =
    layoutTab === WorkspaceLayoutTabs.Grid
      ? styles.gridStyle
      : styles.listStyle;

  const isPermissionsLoading = membersLoading && !members?.length;

  useEffect(() => {
    const fetchLogo = async () => {
      await fetchWorkspaceLogo(workspace.id);
    };
    if (workspace.logo !== null) {
      fetchLogo();
    }
  }, [workspace.id, workspace.logoImage]);

  return (
    <Card
      style={[styles.card, cardStyle]}
      onPress={isUserJoinedWorkspace ? handleWorkspacePress : undefined}>
      <View style={styles.content}>
        <View style={styles.line}>
          <View style={styles.leftContent}>
            <WorkspaceBadge
              id={workspace.id}
              size={40}
              style={{borderRadius: 8}}
            />
            <View style={styles.nameContainer}>
              <Typography
                variant={'medium'}
                color={theme.colors.neutral.$2Base}
                overrides={styles.name}>
                {workspace.name}
              </Typography>
              <Typography
                size={'sm'}
                color={theme.colors.neutral.$500}
                overrides={styles.name}>
                {workspace.description}
              </Typography>
            </View>
          </View>
          {isUserJoinedWorkspace ? (
            isPermissionsLoading ? (
              <Skeleton width={40} height={10} />
            ) : (
              <Button
                ref={workspaceMenuRef}
                overrides={{
                  Button: {
                    style: {
                      width: 40,
                      height: 40,
                    },
                  },
                }}
                aria-label="Open Workspace Menu"
                variant="text"
                onPress={handleMenuPress}
                endEnhancer={
                  <Icon
                    name={'lucide:ellipsis'}
                    color={theme.colors.neutral.$2Base}
                  />
                }
              />
            )
          ) : (
            <Button
              aria-label="Join Workspace"
              variant="text"
              loading={isJoinLoading}
              overrides={{
                Button: {
                  style: {
                    borderRadius: 8,
                    backgroundColor: theme.colors.neutral.$100,
                  },
                },
              }}
              onPress={handleJoinWorkspacePress}>
              <Trans>Join</Trans>
            </Button>
          )}
        </View>
        {isUserJoinedWorkspace && (
          <View style={styles.footer}>
            <View>
              <MemberAvatarGroup
                members={members}
                loading={isPermissionsLoading}
                size={-2}
              />
            </View>
            <TimeLabel date={parseServerDate(workspace.updated)} />
          </View>
        )}
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    padding: 16,
  },
  content: {
    flexDirection: 'column',
    gap: 12,
    width: '100%',
  },
  line: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footer: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
  },
  leftContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    flex: 1,
  },
  nameContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  gridStyle: {
    minWidth: 'auto',
    maxWidth: 'auto',
  },
  listStyle: {
    minWidth: '100%',
    maxWidth: '100%',
  },
});

export default WorkspaceCard;
