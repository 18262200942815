import {t} from '@lingui/macro';
import type {ModalContextInterface} from 'extensions/viewport/ModalContext';
import {Button} from 'interface/base/Button';
import {useState} from 'react';
import ReactCrop, {type PercentCrop} from 'react-image-crop';
import {Image, StyleSheet, View} from 'react-native';
import {getCroppedImg} from 'utils/common/image';
import Modal, {ModalSize} from './Modal';

interface Props {
  image: string;
  setCompletedImg: (completedImg: string) => void;
  setImage?: (image: File) => void;
  imageType?: string;
  imageName?: string;
  modal: ModalContextInterface;
}

export function CropImage({modal, ...props}: Props) {
  const [crop, setCrop] = useState<PercentCrop>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const base64ToBlob = (base64: string) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = Array.from(byteCharacters).map(char =>
      char.charCodeAt(0),
    );
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: 'image/jpeg'});
  };

  const submit = async () => {
    const img = await getCroppedImg(props.image, crop);
    const blob = new Blob([base64ToBlob(img)], {type: props.imageType});
    const file = new File([blob], props.imageName, {type: props.imageType});
    if (props.setImage) {
      props.setImage(file);
    }
    props.setCompletedImg(img);
  };

  return (
    <Modal size={ModalSize.AUTO}>
      <ReactCrop
        crop={crop}
        onChange={(pixelCrop, percentCrop) => {
          setCrop(percentCrop);
        }}
        circularCrop
        style={styles.cropContainer}
        onComplete={(pixelCrop, percentCrop) => {
          setCrop(percentCrop);
        }}
        aspect={1}>
        <Image
          source={{uri: props.image}}
          style={styles.image}
          resizeMode="contain"
        />
      </ReactCrop>
      <View style={styles.buttons}>
        <Button
          variant="secondary"
          onPress={() => {
            modal.close();
          }}>
          {t`Cancel`}
        </Button>
        <Button
          variant="primary"
          onPress={() => {
            submit();
            modal.close();
          }}>
          {t`Confirm`}
        </Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  cropContainer: {
    marginBottom: 24,
    width: '100%',
    aspectRatio: 1,
  },
  image: {
    width: 320,
    height: 320,
    // aspectRatio: 1,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
  },
});
