// @ts-ignore
import LogoLight from 'assets/fastio_logo_assets/mark_color_48px.svg';
// @ts-ignore
import FacebookLogo from 'assets/svgs/logos/Facebook.svg';
// @ts-ignore
import InstagramLogo from 'assets/svgs/logos/Instragram.svg';
// @ts-ignore
import TwitterLogo from 'assets/svgs/logos/Twitter.svg';
// @ts-ignore
import YouTubeLogo from 'assets/svgs/logos/YouTube.svg';
import theme from 'config/theme';
import {Link} from 'extensions/navigation/components/Link';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Avatar} from 'interface/common/Avatar';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as contentViewer from 'store/slices/content-viewer';
import * as settings from 'store/slices/settings';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';

export function PromoDialog() {
  const dispatch = useDispatch();

  const org = useSelector(user.selectors.getSelectedOrganization);
  const logo = useSelector(settings.selectors.getOrganizationLogo);

  const initials = createInitials(org?.name);
  const color = org?.accent_color?.color || getHashColor(org?.id);

  const showTwitter = Boolean(org?.twitter);
  const showYouTube = Boolean(org?.youtube);
  const showFacebook = Boolean(org?.facebook);
  const showInstagram = Boolean(org?.instagram);
  const showSocials =
    showTwitter || showYouTube || showFacebook || showInstagram;
  const showDescription = Boolean(org?.description);

  const handleClose = () => {
    dispatch(contentViewer.default.actions.setShowPromoDialog({show: false}));
  };

  if (!org) {
    return null;
  }

  return (
    <View style={styles.root}>
      <Pressable
        accessibilityRole="button"
        style={styles.closeIcon}
        onPress={handleClose}>
        <Icon name="lucide:x" size={18} color={theme.colors.neutral.$700} />
      </Pressable>
      <View style={styles.top}>
        <View style={styles.header}>
          <Avatar
            color={color}
            initials={initials}
            photo={logo}
            hideBorder
            size={5}
            shapeKind="rounded-square"
          />
          <View style={styles.headerContent}>
            <Typography
              size="lg"
              variant="semi-bold"
              color={theme.colors.neutral.$700}>
              {org?.name}
            </Typography>
            {showSocials && (
              <View style={styles.logoContainer}>
                {showTwitter && (
                  <Image
                    source={TwitterLogo}
                    style={styles.socialLogo}
                    alt="Twitter logo"
                  />
                )}
                {showYouTube && (
                  <Image
                    source={YouTubeLogo}
                    style={styles.socialLogo}
                    alt="YouTube logo"
                  />
                )}
                {showFacebook && (
                  <Image
                    source={FacebookLogo}
                    style={styles.socialLogo}
                    alt="Facebook logo"
                  />
                )}
                {showInstagram && (
                  <Image
                    source={InstagramLogo}
                    style={styles.socialLogo}
                    alt="Instagram logo"
                  />
                )}
              </View>
            )}
          </View>
        </View>
        {showDescription && (
          <Typography
            size="sm"
            variant="regular"
            color={theme.colors.neutral.$700}>
            {org?.description}
          </Typography>
        )}
      </View>
      <View style={styles.bottom}>
        <Image source={LogoLight} style={styles.logo} alt="Fast.io logo" />
        <Typography
          size="xs"
          variant="regular"
          color={theme.colors.neutral.$700}>
          This Quick-Share is powered by{' '}
          <Link
            onClick={() => window.open('https://fast.io', '_blank')}
            role="link">
            Fast.io
          </Link>
        </Typography>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    margin: 2,
    borderRadius: 16,
    maxWidth: 400,
    boxShadow: '0px 8px 32px 0px #1C1B221A, 0px 4px 12px 0px #1C1B221A',
  },
  top: {
    gap: 16,
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
  },
  headerContent: {
    gap: 8,
  },
  bottom: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    paddingVertical: 11,
    backgroundColor: theme.colors.neutral.$50,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$100,
  },
  logoContainer: {
    flexDirection: 'row',
    gap: 10,
  },
  logo: {
    width: 32,
    height: 32,
  },
  socialLogo: {
    width: 20,
    height: 20,
  },
  closeIcon: {
    position: 'absolute',
    top: 19,
    right: 19,
    zIndex: 100,
  },
});
