import theme from 'config/theme';
import {formatCurrency} from 'fast-sdk/src/utils';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

interface Props {
  title: string;
  free: boolean;
  freeDays: number;
  priceBase: number;
  billed: string;
  discount: number;
}

export default function BillingPlanDetails(props: Props) {
  const isFree = props.free || props.freeDays > 0;
  const totalPrice = props.priceBase;

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View style={styles.headerTextContainer}>
          <Typography variant="bold" color={theme.colors.neutral.$2Base}>
            {props.title}
          </Typography>
        </View>
        <View style={styles.headerPriceContainer}>
          {isFree && (
            <Typography size="md" color={theme.colors.neutral.$2Base}>
              {props.freeDays} day free trial
            </Typography>
          )}
          <Typography size="md" color={theme.colors.neutral.$5}>
            {`$${props.priceBase} per user / month ${isFree ? 'after' : ''}`}
          </Typography>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Typography
          size="md"
          color={theme.colors.neutral.$5}
          overrides={styles.contentHeaderText}>
          {`Recurring ${props.billed} cost`}
        </Typography>
        <View style={styles.contentItem}>
          <Typography size="md" color={theme.colors.neutral.$2Base}>
            Subtotal
          </Typography>
          <Typography size="md" color={theme.colors.neutral.$2Base}>
            {formatCurrency(props.priceBase)}
          </Typography>
        </View>
        {props.discount && (
          <View style={styles.contentItem}>
            <Typography size="md" color={theme.colors.neutral.$2Base}>
              Discount (annual billing)
            </Typography>
            <Typography size="md" color={theme.colors.neutral.$2Base}>
              {formatCurrency(props.discount)}
            </Typography>
          </View>
        )}
      </View>
      <View style={styles.footer}>
        {isFree && (
          <View style={styles.contentItem}>
            <Typography size="md" color={theme.colors.neutral.$4}>
              Total after trial
            </Typography>
            <Typography size="md" color={theme.colors.neutral.$4}>
              {formatCurrency(totalPrice)}
            </Typography>
          </View>
        )}
        <View style={styles.contentItem}>
          <Typography
            size="md"
            variant="bold"
            color={theme.colors.neutral.$2Base}>
            Total due today
          </Typography>
          <Typography
            size="md"
            variant="bold"
            color={theme.colors.neutral.$2Base}>
            {formatCurrency(isFree ? 0 : totalPrice)}
          </Typography>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBottom: '3rem',
    borderRadius: 14,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: theme.colors.neutral.$11,
    borderBottomWidth: 1,
    padding: '1.5rem',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 14,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.colors.neutral.$15,
    borderTopColor: theme.colors.neutral.$11,
    borderTopWidth: 1,
    paddingVertical: '1.5rem',
    paddingHorizontal: '2rem',
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  discountBadge: {
    display: 'flex',
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderRadius: 50,
    backgroundColor: theme.colors.success.$7,
    color: theme.colors.success.$2,
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
  },
  headerPriceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginVertical: '1.5rem',
    paddingHorizontal: '2rem',
  },
  contentHeaderText: {
    marginBottom: '1.5rem',
  },
  contentItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
});
