import Clipboard from '@react-native-clipboard/clipboard';
import theme from 'config/theme';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
  type NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  type TextInputKeyPressEventData,
  View,
} from 'react-native';
import {hexToRGBA} from 'utils/common/color';

const OTP_LENGTH = 6;

type Props = {
  onStartTyping?: () => void;
  validate: (code: string) => void;
  disabled?: boolean;
};

const FOCUS_INITIAL_STATE = new Array(OTP_LENGTH).fill(false);

export default function OTPInput({validate, disabled, onStartTyping}: Props) {
  const [focus, setFocus] = useState(FOCUS_INITIAL_STATE);
  const [otp, setOtp] = useState(() => new Array(OTP_LENGTH).fill(''));
  const boxRef = useRef([]);

  useEffect(() => {
    const isFilled =
      otp.every(char => char !== '') && otp.length === OTP_LENGTH;

    if (isFilled) {
      validate(otp.join(''));
    }
  }, [otp]);

  useEffect(() => {
    async function handleOnPaste() {
      if (disabled) {
        return;
      }

      const rawContent = await Clipboard.getString();
      if (!rawContent) {
        return;
      }

      const content = rawContent.trim();

      if (content.includes('-')) {
        const [left, right] = content.split('-');
        return setOtp([...left, ...right]);
      }

      setOtp(content.split(''));
    }

    document.addEventListener('paste', handleOnPaste);

    return () => document.removeEventListener('paste', handleOnPaste);
  }, [disabled]);

  const handleChangeOTP = (index: number, value: string) => {
    onStartTyping?.();

    if (value === ' ') {
      return;
    }

    setOtp(prev => {
      const copy = [...prev];
      copy[index] = value.toUpperCase();
      return copy;
    });

    if (value && index < OTP_LENGTH - 1) {
      boxRef.current[index + 1].focus();
    }
  };

  function handleBackspace(
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
    index: number,
  ) {
    if (e.nativeEvent.key === 'Backspace' && !otp[index] && index > 0) {
      boxRef.current[index - 1].focus();
    }
  }

  const handleIndexBlurFocus = (index: number, value: boolean) => {
    setFocus(prev => {
      const copy = [...prev];
      copy[index] = value;
      return copy;
    });
  };

  return (
    <View style={styles.root}>
      {new Array(OTP_LENGTH).fill('').map((_, index) => {
        const isLeft = index === 0 || index === 3;
        const isRight = index === 2 || index === 5;
        const isMiddle = index === OTP_LENGTH / 2 - 1;

        const input = (
          <TextInput
            key={index}
            maxLength={1}
            style={[
              styles.box,
              isLeft && styles.leftBox,
              isRight && styles.rightBox,
              disabled && styles.disabled,
              focus[index] && styles.focused,
            ]}
            onFocus={() => handleIndexBlurFocus(index, true)}
            onBlur={() => handleIndexBlurFocus(index, false)}
            editable={!disabled}
            onChangeText={text => handleChangeOTP(index, text)}
            onKeyPress={e => handleBackspace(e, index)}
            value={otp[index]}
            ref={reference => (boxRef.current[index] = reference)}
          />
        );

        return isMiddle ? (
          <Fragment key={`mid-${index}`}>
            {input}
            <View style={styles.dash} />
          </Fragment>
        ) : (
          input
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftBox: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderRightWidth: 0,
  },
  rightBox: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderLeftWidth: 0,
  },
  box: {
    paddingHorizontal: 20,
    width: 72,
    height: 72,
    backgroundColor: theme.colors.neutral.$14,
    fontSize: 32,
    color: theme.colors.neutral.$2Base,
    lineHeight: 48,
    fontWeight: '400',
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
    borderStyle: 'solid',
    textAlign: 'center',
  },
  dash: {
    width: 10,
    height: 2,
    backgroundColor: theme.colors.neutral.$8,
    marginHorizontal: 10,
  },
  disabled: {
    color: hexToRGBA(theme.colors.neutral.$2Base, 0.5),
  },
  focused: {
    borderColor: theme.colors.brand.$4Base,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderTopWidth: 2,
    borderLeftWidth: 2,
  },
});
