import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {REDIRECT_TO_COOKIE} from 'constants/sessionRoutes';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/common/Button';
import {CustomLink} from 'interface/common/CustomLink';
import useLegalNavigation from 'interface/stacks/app/hooks/useLegalNavigation';
import {Fragment, useEffect} from 'react';
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {clearCookie} from 'utils/common/cookies';

export enum AuthPageTypes {
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
}

const windowWidth = Dimensions.get('screen').width;

interface Props {
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  subContent?: string | JSX.Element;
  headerLogo?: JSX.Element;
  button?: {
    text: string;
    onPress: () => void;
    disabled?: boolean;
    loading?: boolean;
    style?: ViewStyle;
  };
  children: JSX.Element | Array<JSX.Element>;
  type?: AuthPageTypes;
  customTitleContainerStyle?: ViewStyle;
  customRootMaxWidth?: string | number;
  customContentContainerStyle?: ViewStyle | Array<ViewStyle>;
  removeSubContentBottomBorder?: boolean;
  disableAutoMargin?: boolean;
  onSignupOrSignin?: () => void;
  onForgotPassword?: () => void;
}

export function RouteLayout(props: Props) {
  const dimension = useWindowDimensions();
  const navigate = useNavigate();
  const {navigateToTerms, navigateToPrivacy} = useLegalNavigation();

  const textColor = theme.colors.neutral.$2Base;

  useEffect(() => {
    clearCookie(REDIRECT_TO_COOKIE);
  }, []);

  return (
    <View
      style={[
        styles.root,
        {
          width:
            dimension.width > 790 ? props.customRootMaxWidth || 420 : '100%',
        },
        props.disableAutoMargin ? {} : {marginHorizontal: 'auto'},
      ]}>
      {/* {isSubdomainValid(subdomain) ? (
        <Image
          source={{uri: ''}}
          alt={`${subdomain} Organization`}
          style={styles.logo}
        />
      ) : null} */}
      <View style={styles.container}>
        {(props.title || props.subTitle) && (
          <View
            style={[
              styles.contentContainer,
              props.customContentContainerStyle,
            ]}>
            {props.headerLogo && props.headerLogo}
            {props.title && (
              <Text
                style={[
                  styles.title,
                  {color: textColor},
                  props.customTitleContainerStyle,
                ]}>
                <Trans>{props.title}</Trans>
              </Text>
            )}
            {typeof props.subTitle === 'string' ? (
              <Text style={[styles.content, {color: textColor}]}>
                <Trans>{props.subTitle}</Trans>
              </Text>
            ) : (
              props.subTitle && (
                <Text style={{color: textColor}}>{props.subTitle}</Text>
              )
            )}
          </View>
        )}
        <View style={styles.children}>{props.children}</View>
        {props.subContent ? (
          <Fragment>
            <Text
              style={[
                styles.subContent,
                {borderBottomWidth: props.removeSubContentBottomBorder ? 0 : 1},
              ]}>
              {props.subContent}
            </Text>
          </Fragment>
        ) : null}
        {props.type === AuthPageTypes.SIGNUP ? (
          <Text
            style={[styles.privacyContent, {color: theme.colors.neutral.$5}]}>
            By continuing, you are setting up a Fast.io account and agree to the
            <CustomLink
              onPress={navigateToPrivacy}
              customTextStyle={{paddingVertical: 0, paddingHorizontal: 2}}>
              Privacy Policy
            </CustomLink>
            and
            <CustomLink
              onPress={navigateToTerms}
              customTextStyle={{paddingVertical: 0, paddingHorizontal: 2}}>
              Terms of Service
            </CustomLink>
            .
          </Text>
        ) : null}
        {props.type === AuthPageTypes.SIGNUP ? (
          <View
            style={[
              styles.hl,
              {
                borderBottomColor: theme.colors.neutral.$11,
              },
            ]}
          />
        ) : null}
        <View
          style={[
            styles.bottomLinks,
            {
              justifyContent:
                props.type === AuthPageTypes.SIGNIN
                  ? 'space-between'
                  : 'center',
            },
          ]}>
          {props.type ? (
            <View style={styles.signupLinkContainer} key={props.type}>
              <Text style={[styles.content, {color: textColor}]}>
                <Trans>
                  {props.type === AuthPageTypes.SIGNIN
                    ? t`Need an account?`
                    : t`Already have an account?`}
                </Trans>
              </Text>
              <Button
                type="Text"
                onPress={() =>
                  props.onSignupOrSignin
                    ? props.onSignupOrSignin()
                    : navigate(
                        props.type === AuthPageTypes.SIGNIN
                          ? '/signup'
                          : '/signin',
                      )
                }
                label={
                  props.type === AuthPageTypes.SIGNIN ? t`Sign Up` : 'Sign In'
                }
                customRootStyle={{paddingVertical: '1rem'}}
              />
            </View>
          ) : null}
          {props.type === AuthPageTypes.SIGNIN ? (
            <Button
              type="Text"
              onPress={() =>
                props.onForgotPassword
                  ? props.onForgotPassword()
                  : navigate('/forgot-password')
              }
              label={t`Forgot password?`}
              customRootStyle={{paddingVertical: '1rem'}}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3rem',
  },
  title: {
    marginBottom: 7,
    marginTop: 12,
    lineHeight: 40,
    fontSize: 26,
    fontWeight: '700',
    textAlign: 'center',
  },
  content: {
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
  },
  signupLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  linkText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.brand.$4Base,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  children: {
    width: '100%',
    marginBottom: '1.5rem',
  },
  button: {
    width: '100%',
    height: 48,
    marginBottom: '1.5rem',
  },
  bottomLinks: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subContent: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    paddingBottom: '1.5rem',
    textAlign: 'center',
    borderBottomColor: theme.colors.neutral.$10,
    color: theme.colors.neutral.$4,
    marginBottom: '1.5rem',
  },
  logo: {
    width: '5rem',
    height: '5rem',
    borderRadius: 50,
  },
  rightArrowIcon: {
    marginLeft: 0,
  },
  privacyContent: {
    maxWidth: windowWidth > 790 ? 380 : windowWidth - 40,
    marginBottom: '1.5rem',
    padding: 7,
    lineHeight: 21,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: '#71757F',
  },
  hl: {
    width: '90%',
    marginBottom: '1.5rem',
    borderBottomWidth: 1,
  },
});
