import type {ToastOptions} from 'react-native-toast-notifications';

export enum ErrorFlowActions {
  PageError = 'PageError',
  Dialog = 'Dialog',
  Toast = 'Toast',
  NoAction = 'NoAction',
}

export enum ErrorCategories {
  Network = 'Network',
  Authentication = 'Authentication',
  Data = 'Data',
  Resource = 'Resource',
  Unknown = 'Unknown',
}

export enum ErrorServices {
  Segment = 'segment',
  Bugsnag = 'bugsnag',
  Intercom = 'intercom',
}

export interface RetryOptions {
  automatic?: boolean;
  waitTimeBeforeRetry?: number;
  maxRetries?: number;
  retryInterval?: number;
  maxRetryInterval?: number;
  backoffFactor?: number;
}

export interface ErrorConfiguration {
  key: string;
  friendlyMessage?: string;
  flowAction?: ErrorFlowActions;
  disabledServices?: Array<ErrorServices>;
  allowRetry?: boolean;
  allowReset?: boolean;
  toastOptions?: Omit<ToastOptions, 'style' | 'textStyle'>;
  retryOptions?: RetryOptions;
  category?: ErrorCategories;
}

export type ErrorConfigurations<T extends string | number | symbol> = {
  [code in T]: ErrorConfiguration;
};

export type ErrorCategoryConfiguration = {
  [code in ErrorCategories]: Omit<ErrorConfiguration, 'key' | 'category'>;
};

export interface ErrorBoundaryDetails {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  title: string;
  message: string;
}
