import {t} from '@lingui/macro';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {Input} from 'interface/base/Input';
import {FormError} from 'interface/common/FormError';
import {useEffect, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import {validateEmail} from 'utils/common/validation';
import {RouteLayout} from '../_layout/RouteLayout';
import {CheckEmail} from '../components/CheckEmail';
import Auth from '../consts';

enum PageStatus {
  ForgotPassword = 'ForgotPassword',
  CheckEmail = 'CheckEmail',
}

interface ForgotPasswordProps {
  isModal?: boolean;
  onSignin?: () => void;
}

export const ForgotPassword = (props: ForgotPasswordProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [pageStatus, setPageStatus] = useState<PageStatus>(
    PageStatus.ForgotPassword,
  );
  const [email, setEmail] = useState<string>('');
  const [sendEmailLoading, setSendEmailLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: 'Forgot Password',
      }),
    );
  }, []);

  const sendEmail = () => {
    if (!email) {
      setErrorMessage(t`Please add your email`);
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage(t`Please add a valid email`);
      return;
    }
    setErrorMessage('');
    setSendEmailLoading(true);
    api.user
      .lostPassword({email})
      .then(res => {
        if (res?.result) {
          Auth.setResetPasswordEmail(email);
          setPageStatus(PageStatus.CheckEmail);
          toast.show(t`Password reset link sent to your email`, {
            type: 'success',
          });
        } else {
          setErrorMessage(res.error?.text);
        }
      })
      .catch(err => {
        analytics.notify(err);
        setErrorMessage(err.message);
      })
      .finally(() => setSendEmailLoading(false));
  };

  return pageStatus === PageStatus.ForgotPassword ? (
    <RouteLayout
      title={t`Reset your password`}
      subTitle={t`Enter the email address you used to create your account and we’ll send a password reset link.`}
      subContent={
        <Button
          variant="text"
          size="lg"
          onPress={() =>
            props.onSignin ? props.onSignin() : navigate('/signin')
          }>
          {t`Back to sign in`}
        </Button>
      }
      removeSubContentBottomBorder
      customRootMaxWidth={props.isModal ? '100%' : undefined}
      disableAutoMargin={props.isModal}>
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <Input
        overrides={{
          Root: {
            style: {
              marginBottom: '1.5rem',
              height: 40,
            },
          },
          Input: {
            style: {
              height: 40,
            },
          },
        }}
        value={email}
        onChangeValue={setEmail}
        placeholder={t`Enter your email address`}
        aria-label="Your Email Address"
      />
      <Button
        variant="primary"
        size="lg"
        onPress={sendEmail}
        loading={sendEmailLoading}
        disabled={sendEmailLoading}>
        {t`Send password reset link`}
      </Button>
    </RouteLayout>
  ) : (
    <CheckEmail
      email={email}
      resendEmail={sendEmail}
      tryDifferentEmail={() => setPageStatus(PageStatus.ForgotPassword)}
      sendEmailLoading={sendEmailLoading}
    />
  );
};
