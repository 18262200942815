import {useFlag} from '@unleash/proxy-client-react';
import {ROUTES, WORKSPACE_TRASH} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {useModalCreateFolder} from 'interface/stacks/workspace/storage/hooks/useModalCreateFolder';
import {useModalPurge} from 'interface/stacks/workspace/storage/hooks/useModalPurge';
import {useModalTrash} from 'interface/stacks/workspace/storage/hooks/useModalTrash';
import {StyleSheet, View} from 'react-native';
import {ShareViewStatus} from 'store/slices/shared/types';
import type {FilesData} from '../../hooks/useFetchShareFiles';
import {FileItems} from './FileItems';
import {FilesHeader} from './FilesHeader';

import {usePopup} from 'extensions/viewport/usePopup';
import {Mouse} from 'interface/common/Mouse';
import {useContextMenu} from 'interface/stacks/workspace/storage/hooks/useContextMenu';
import {createRef, useCallback, useEffect} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import files, {selectors} from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import {download, restore} from 'utils/fast/storage';
import type {ShareFilesNavigation} from '../../hooks/useShareFilesNavigation';

interface Props {
  share: Share;
  shareFilesNavigation: ShareFilesNavigation;
  filesData: FilesData;
}

export default function ShareFiles({
  share,
  shareFilesNavigation,
  filesData,
}: Props) {
  const navigate = useNavigate();
  const popup = usePopup();
  const toast = useToast();

  const {breadcrumbs, setBreadcrumbs, setTrashView, selectedFolder, folderKey} =
    shareFilesNavigation ?? {};

  const dispatch = useDispatch();
  const selection = useSelector(selectors.getSelection);

  useEffect(() => {
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.ShareExternal,
        shareName: share.name,
        shareId: share.id,
        folderId: selectedFolder?.id,
        workspace: share.parent_workspace_folder,
      }),
    );
  }, [share.parent_workspace_folder, selectedFolder?.name]);

  const purgeTrashModal = useModalPurge(null, share.id, 'share');
  const isTrashView = folderKey === WORKSPACE_TRASH;

  const handleFileOpen = (file: FilesItem) => {
    const fileId = file.id;
    if (file.type === StorageItemType.Folder) {
      setBreadcrumbs(prev => [...prev, file]);
    } else if (
      file.type === StorageItemType.File ||
      file.type === StorageItemType.Note
    ) {
      navigate(
        `/share/${share.id}/${ROUTES.LOGGED_IN_WITH_ORG.PREVIEW}/${fileId}`,
      );
    }
  };

  const handleTrashOpen = () => {
    setTrashView(true);
  };

  const handleTrashEmpty = () => {
    purgeTrashModal.open();
  };

  const handleGoBack = () => {
    if (isTrashView) {
      setTrashView(false);
    } else {
      setBreadcrumbs(breadcrumbs.slice(0, -1));
    }
  };

  const clearSelection = () => {
    dispatch(files.actions.select({ids: []}));
  };

  const canManageFiles =
    (share.filesystem.file_creation && useFlag('share-external-uploads')) ||
    share.shareViewStatus === ShareViewStatus.Owner ||
    share.shareViewStatus === ShareViewStatus.Editor;

  const handleDownload = useCallback(async () => {
    const targets = selection.length > 0 ? selection : [];
    if (targets.length === 0) return;

    for (const target of targets) {
      await download(target, share.id, 'share');
      await new Promise(resolve => setTimeout(resolve, 1500));
    }
  }, [selection, share.id]);

  const trashModal = useModalTrash(
    selection.length > 0 ? selection : [],
    share.id,
    'share',
  );

  const handleMoveToTrash = useCallback(() => {
    if (selection.length === 0) return;
    trashModal.open();
  }, [selection, trashModal]);

  const handleRestoreFromTrash = useCallback(async () => {
    if (selection.length === 0) return;

    const {completes, errors} = await restore(
      dispatch,
      selection,
      'root',
      share.id,
      'share',
    );

    if (completes.length === selection.length) {
      dispatch(files.actions.archiveFiles({ids: selection}));
      toast.show('Items restored from trash');
    } else {
      toast.show(`Failed to restore ${errors.length} items from trash`, {
        type: 'danger',
      });
    }
  }, [selection, share.id, dispatch, toast]);

  const modalCreateFolder = useModalCreateFolder(
    selectedFolder?.id || 'root',
    share.id,
    'share',
  );

  const menuRef = createRef<any>();
  const menuFiles = useContextMenu({
    noDividers: true,
    ignoreMultiSelect: true,
    headerText: selectedFolder?.name ?? share.name,
    downloadOnPress:
      canManageFiles && selection.length > 0 ? handleDownload : undefined,
    moveToTrashOnPress:
      canManageFiles && selection.length > 0 && !isTrashView
        ? handleMoveToTrash
        : undefined,
    restoreFromTrashOnPress:
      isTrashView && selection.length > 0 ? handleRestoreFromTrash : undefined,
    purgeAllFromTrashOnPress: isTrashView ? handleTrashEmpty : undefined,
    createFolderOnPress:
      canManageFiles && !isTrashView ? modalCreateFolder.open : undefined,
  });

  return (
    <Mouse
      onContextMenu={e => {
        e?.preventDefault();
        e?.stopPropagation();
        if (popup) {
          if ('nativeEvent' in e)
            popup.setPosition({
              x: e.nativeEvent.pageX,
              y: e.nativeEvent.pageY,
            });
          popup.setRef(menuRef);
        }
        menuFiles.open();
      }}>
      <View onClick={clearSelection} style={styles.filesContainer}>
        <FilesHeader
          share={share}
          filesData={filesData}
          selectedFolder={selectedFolder}
          folderKey={folderKey}
          onGoBack={handleGoBack}
          onTrashOpen={handleTrashOpen}
          onTrashEmpty={handleTrashEmpty}
          canManageFiles={canManageFiles}
        />
        <FileItems
          share={share}
          filesData={filesData}
          handleFileOpen={handleFileOpen}
          canManageFiles={canManageFiles}
          isTrashView={isTrashView}
        />
      </View>
    </Mouse>
  );
}

const styles = StyleSheet.create({
  filesContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
  },
});
