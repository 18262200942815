import {useNavigate} from 'extensions/navigation';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as workspace from 'store/slices/workspace';

const REDIRECT_TO_TIMEOUT = 300;

export default function useRedirectTo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectTo = useSelector(app.selectors.getRedirectTo);
  const workspacesLoaded = useSelector(workspace.selectors.getWorkspacesLoaded);
  const hasActiveSession = useSelector(app.selectors.getHasActiveSession);

  useEffect(() => {
    if (redirectTo && workspacesLoaded && hasActiveSession) {
      setTimeout(() => {
        dispatch(app.default.actions.setRedirectTo(undefined));
        if (redirectTo.startsWith('http')) {
          requestAnimationFrame(() => {
            window.location.href = redirectTo;
          });
        } else {
          navigate(redirectTo);
        }
      }, REDIRECT_TO_TIMEOUT);
    }
  }, [redirectTo, workspacesLoaded, hasActiveSession]);

  return {redirectTo, hasActiveSession};
}
