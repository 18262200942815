import theme from 'config/theme';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {StyleSheet, View} from 'react-native';
import EditorTopToolbar from './base/EditorTopToolbar';
import MarkdownViewer from './base/markdown/MarkdownViewer';
import {EditorProvider} from './context/EditorContext';

interface MarkdownEditorProps {
  text: string;
  fileName: string;
  instanceId: string;
  instanceNs: StorageNamespace;
  parentFolder?: string;
}

const MarkdownCreator = ({
  text,
  fileName,
  instanceId,
  instanceNs,
  parentFolder,
}: MarkdownEditorProps) => {
  const {multiplayer} = useWorkspaceContext();

  return (
    <EditorProvider
      initialFileName={fileName}
      instanceId={instanceId}
      instanceNs={instanceNs}
      parentFolder={parentFolder}>
      <View style={styles.root}>
        <EditorTopToolbar members={multiplayer?.members} />
        <MarkdownViewer markdownText={text} theme="light" />
      </View>
    </EditorProvider>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.colors.neutral.$white,
  },
  content: {
    marginTop: 64,
    maxWidth: 900,
    width: '100%',
    alignSelf: 'center',
  },
});

export default MarkdownCreator;
