import type React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {
  Animated,
  type LayoutChangeEvent,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';

interface SideBarLayoutProps {
  isSidebarOpen: boolean;
  listScreen: React.ReactElement;
  detailScreen: React.ReactElement;
}

const MOBILE_MAX_WIDTH = 900;
const SIDEBAR_WIDTH = 300;

export function SideBarLayout({
  isSidebarOpen,
  listScreen,
  detailScreen,
}: SideBarLayoutProps): React.ReactElement {
  const slideAnim = useRef(new Animated.Value(isSidebarOpen ? 1 : 0)).current;
  const firstTime = useRef(true);
  const [containerWidth, setContainerWidth] = useState(0);
  const {width: windowWidth} = useWindowDimensions();
  const isMobile = windowWidth < MOBILE_MAX_WIDTH;

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  useEffect(() => {
    Animated.timing(slideAnim, {
      toValue: isSidebarOpen ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [isSidebarOpen, slideAnim]);

  useEffect(() => {
    setTimeout(() => {
      firstTime.current = false;
    });
  }, []);

  return (
    <View style={styles.container} onLayout={onLayout}>
      <Animated.View
        style={[
          styles.sidebar,
          isMobile &&
            !firstTime.current && {
              zIndex: 11,
              width: slideAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, SIDEBAR_WIDTH],
              }),
            },
        ]}>
        {listScreen}
      </Animated.View>
      <Animated.View
        style={[
          styles.detail,
          !isMobile &&
            !firstTime.current && {
              marginStart: slideAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, SIDEBAR_WIDTH],
              }),
              width: slideAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [containerWidth, containerWidth - SIDEBAR_WIDTH],
              }),
            },
        ]}>
        {detailScreen}
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  sidebar: {
    width: SIDEBAR_WIDTH,
    backgroundColor: 'white',
    display: 'flex',
    position: 'absolute',
    height: '100%',
    zIndex: 5,
  },
  detail: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
});
