import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {StyleSheet, Text, View} from 'react-native';

export function NoResults() {
  return (
    <View style={styles.root}>
      <View style={styles.content}>
        <Icon
          name="lucide:file-search-2"
          color={theme.colors.neutral.$700}
          size={24}
        />
        <Text style={styles.title}>No results were found</Text>
        <Text style={styles.message}>
          Try adjusting your filters settings or clearing them to see your files
        </Text>
      </View>
      <Button aria-label="Clear Filters" variant="secondary" onPress={() => {}}>
        Clear Filters
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    backgroundColor: theme.colors.neutral.$0,
  },
  content: {
    gap: 8,
    maxWidth: 300,
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
  },
  title: {
    alignSelf: 'stretch',
    color: theme.colors.neutral.$700,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 28,
  },
  message: {
    alignSelf: 'stretch',
    color: theme.colors.neutral.$700,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
  },
});
