import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {PopupMenu} from 'extensions/viewport/PopupMenu';
import {Icon} from 'interface/base/Icon';
import {useCopyLink} from 'interface/stacks/app/hooks/useCopyLink';
import {useState} from 'react';
import {Pressable} from 'react-native';

interface Props {
  chatId: string;
  messageId: string;
  showRegenerate?: boolean;
  onRegenerate: () => void;
}

export function AiChatMessageItemPopup({
  chatId,
  messageId,
  showRegenerate = false,
  onRegenerate,
}: Props) {
  const [hasMenu, setHasMenu] = useState(false);
  const {copyLink} = useCopyLink();

  const triggerElement = (
    <Pressable onPress={() => setHasMenu(true)}>
      <Icon name="lucide:more-horizontal" color={theme.colors.neutral.$700} />
    </Pressable>
  );

  const handleProfileAction = (command: string) => {
    const baseUrl = window.location.href.split('?')[0];
    switch (command) {
      case 'copy':
        copyLink(
          `${baseUrl}?chatId=${chatId}&messageId=${messageId}`,
          'Link copied',
        );
        break;
      case 'regenerate':
        onRegenerate();
        break;
    }
    setHasMenu(false);
  };

  return (
    <Popup
      triggerElement={triggerElement}
      isOpen={hasMenu}
      close={() => setHasMenu(false)}>
      <PopupMenu
        autoClose
        close={() => setHasMenu(false)}
        onSelectItem={handleProfileAction}
        menuItems={[
          {
            command: 'copy',
            text: 'Copy Link',
          },
          showRegenerate && {
            command: 'regenerate',
            text: 'Regenerate',
          },
        ].filter(e => !!e)}
      />
    </Popup>
  );
}
