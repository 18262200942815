import {userProfilePhotoService} from 'extensions/images';
import {
  Avatar,
  type AvatarBorder,
  type AvatarSize,
} from 'interface/common/Avatar';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';

interface Props {
  memberId: string;
  initials: string;
  isStacked?: boolean;
  borderKind?: AvatarBorder;
  profilePicture?: string;
  size?: AvatarSize;
}

export function MemberAvatar({
  memberId,
  initials,
  isStacked,
  borderKind,
  profilePicture,
  size = 0,
}: Props) {
  const currentUser = useSelector(user.selectors.getUserDetails);
  const currentUserProfilePic = useSelector(user.selectors.getUserProfilePic);
  const [photoFromCache, setPhotoFromCache] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const isCurrentUser = currentUser.id === memberId;

  const loadPhoto = useCallback(async () => {
    if (isCurrentUser || isLoading || photoFromCache) {
      return;
    }

    setIsLoading(true);

    try {
      const photoContent = await userProfilePhotoService.getContent(memberId);
      setPhotoFromCache(photoContent);
    } catch (err) {
      setPhotoFromCache('');
    } finally {
      setIsLoading(false);
    }
  }, [memberId, isCurrentUser, isLoading, photoFromCache]);

  useEffect(() => {
    loadPhoto();
  }, [loadPhoto]);

  useEffect(() => {
    setPhotoFromCache('');
    setIsLoading(false);
  }, [memberId]);

  const photo =
    profilePicture ?? (isCurrentUser ? currentUserProfilePic : photoFromCache);

  return (
    <Avatar
      key={memberId}
      color={getHashColor(memberId)}
      initials={initials}
      isStacked={isStacked}
      borderKind={borderKind}
      photo={photo}
      size={size}
      hideBorder={!photo}
    />
  );
}
