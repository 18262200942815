import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useWorkspaceContext} from '../../storage/hooks/useWorkspaceContext';
import {useMetadata} from './hooks/useMetadata';
import {DataGrid} from './views/DataGrid';
import {Toolbar} from './views/Toolbar';

import type {LayoutChangeEvent} from 'react-native';

export default function WorkspaceMetadata() {
  const folderId = 'root';
  const [width, setWidth] = useState(0);
  const {workspace} = useWorkspaceContext();
  const metadata = useMetadata(folderId);

  const onLayout = (event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  };

  return (
    <View onLayout={onLayout} style={styles.content}>
      <Toolbar metadata={metadata} containerWidth={width} />
      <View style={styles.files}>
        <DataGrid
          metadata={metadata}
          folderId={folderId}
          workspaceId={workspace.id}
        />
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  content: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  files: {
    flex: 1,
    flexDirection: 'row',
  },
});
