import {t} from '@lingui/macro';
import {PaymentElement, useElements} from '@stripe/react-stripe-js';
import type {Stripe, StripeError} from '@stripe/stripe-js';
import theme from 'config/theme';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {formatCurrency} from 'fast-sdk/src/utils';
import {Button} from 'interface/base/Button';
import Typography from 'interface/base/Typography';
import useCreateDefaultWorkspace from 'interface/stacks/onboard/hooks/useCreateDefaultWorkspace';
import usePayment from 'interface/stacks/onboard/hooks/usePayment';
import type {BillingPlan} from 'interface/stacks/onboard/lib/types';
import {Fragment, useEffect, useState} from 'react';
import {StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';

interface AddCardProps {
  stripe: Stripe;
  orgDomain: string;
  orgId: string;
  onSubscriptionSuccess: () => void;
  plan: BillingPlan;
}

const MAX_RETRIES = 6;
const SUBSCRIPTION_WAIT_TIME = 3000;

const SUBSCRIPTION_NOT_ACTIVATED_ERROR_MESSAGE =
  'We ran into a problem activating your subscription. Please try again. If it doesn’t work after a few minutes, contact our support team for help.';

const SUBSCRIPTION_NOT_ACTIVATED_ERROR = 'Subscription Activation Pending';

const SUBSCRIPTION_NOT_ACTIVATED_ERROR_TITLE =
  'when activating your subscription';

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function AddCard({
  orgDomain,
  orgId,
  onSubscriptionSuccess,
  plan,
}: AddCardProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [errorLoading, setErrorLoading] = useState<string | null>(null);

  const {handlePayment} = usePayment();
  const {isWorkspaceCreated} = useCreateDefaultWorkspace(
    isSubscribed,
    orgDomain,
    orgId,
  );
  const elements = useElements();

  const userDetails = useSelector(user.selectors.getUserDetails);
  const onboardingEmail = useSelector(
    onboarding.selectors.getStartOnboardingEmail,
  );
  const updatedOrgName = useSelector(onboarding.selectors.getOrganizationName);

  const originalOrgName = useSelector(
    onboarding.selectors.getCreatedOrganizationName,
  );
  const originalOrgDomain = useSelector(
    onboarding.selectors.getCreatedOrganizationDomain,
  );

  useEffect(() => {
    if (isWorkspaceCreated) {
      onSubscriptionSuccess();
    }
  }, [isWorkspaceCreated]);

  const showRetryModal = () => {
    handleAppError({
      appError: AppErrors.SubscriptionNotActivatedError,
      exception: new Error(
        `${SUBSCRIPTION_NOT_ACTIVATED_ERROR}, organization: ${orgId} orgDomain: ${orgDomain}`,
      ),
      retryFn: async () => {
        const isSubscribed = await validateSubscription();
        isSubscribed && setIsSubscribed(isSubscribed);
        return {result: isSubscribed};
      },
      dialogOptions: {
        title: SUBSCRIPTION_NOT_ACTIVATED_ERROR_TITLE,
        message: SUBSCRIPTION_NOT_ACTIVATED_ERROR_MESSAGE,
      },
    });
  };

  const validateSubscription = async () => {
    const shouldUpdateOrg =
      orgId &&
      orgDomain &&
      updatedOrgName &&
      (originalOrgName !== updatedOrgName || originalOrgDomain !== orgDomain);

    let orgWasUpdated = false;

    if (shouldUpdateOrg) {
      for (let i = 0; i < MAX_RETRIES; i++) {
        await timeout(SUBSCRIPTION_WAIT_TIME);
        const {result} = await api.organization.updateOrganization(orgId, {
          domain: orgDomain,
          name: updatedOrgName,
        });

        if (result) {
          orgWasUpdated = true;
          break;
        }
      }
    } else {
      orgWasUpdated = true;
      await timeout(SUBSCRIPTION_WAIT_TIME);
    }

    if (!orgWasUpdated) {
      return false;
    }

    for (let i = 0; i < MAX_RETRIES; i++) {
      const response = await api.billing.getSubscriptionDetails(orgDomain);

      if (response.result && response.billing_status.active) {
        return true;
      }
      await timeout(SUBSCRIPTION_WAIT_TIME);
    }

    return false;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const elementsResult = await elements?.submit();

    if (elementsResult?.error) {
      return;
    }

    setLoading(true);

    const payment = await handlePayment();

    if (payment.error) {
      const isPaymentDone = payment.error.setup_intent?.status === 'succeeded';

      if (!isPaymentDone) {
        analytics.notify(payment.error.message);
        setLoading(false);
        return;
      }
    }

    const isSubscribed = await validateSubscription();

    if (isSubscribed) {
      setIsSubscribed(true);
    } else {
      showRetryModal();
    }

    setLoading(false);
  };

  const handleError = (error: StripeError) => {
    setErrorLoading(error.message);
    setLoading(false);
  };

  const isFree = plan.pricing.free || plan.pricing.free_days > 0;

  const buttonText = isFree
    ? t`Complete trial activation - Pay ${formatCurrency(0)}`
    : t`Continue`;

  return (
    <Fragment>
      <PaymentElement
        onReady={() => setLoading(false)}
        onLoaderStart={() => setLoading(true)}
        onLoadError={event => handleError(event.error)}
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
          defaultValues: {
            billingDetails: {
              email: userDetails?.email_address || onboardingEmail,
            },
          },
        }}
      />
      {errorLoading && (
        <Typography
          variant="regular"
          size="sm"
          color={theme.colors.neutral.$2Base}>
          {errorLoading}
        </Typography>
      )}
      <Button
        variant="primary"
        onPress={handleSubmit}
        size="lg"
        loading={loading}
        disabled={loading}
        overrides={{Button: {style: styles.button}}}>
        {t`${buttonText}`}
      </Button>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: 26,
  },
});
