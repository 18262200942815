import theme from 'config/theme';
// import {Button} from 'interface/base/Button';
// import {Icon} from 'interface/base/Icon';
import {StyleSheet, View} from 'react-native';

import {ColumnsPopup} from '../popups/ColumnsPopup';
import {FiltersPopup} from '../popups/FiltersPopup';
import {TemplatesPopup} from '../popups/TemplatesPopup';

import type {MetadataState} from '../types';

export interface ToolbarProps {
  metadata?: MetadataState;
  containerWidth?: number;
}

export function Toolbar({metadata, containerWidth}: ToolbarProps) {
  return (
    <View style={styles.root}>
      <View style={styles.group}>
        <ColumnsPopup
          containerWidth={containerWidth}
          onColumnToggle={metadata?.toggleColumn}
          columns={metadata?.allColumns ?? []}
          onColumnReorder={metadata?.rearrangeColumn}
        />
        {/* <FiltersPopup
          containerWidth={containerWidth}
          onFilterChange={console.log}
          filters={[]}
        />
        <Button
          onPress={console.log}
          variant="secondary"
          startEnhancer={
            <Icon name="lucide:save" color={theme.colors.neutral.$700} />
          }
        /> */}
      </View>
      <View style={styles.group}>
        <TemplatesPopup containerWidth={containerWidth} metadata={metadata} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
});
