import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {useModal} from 'extensions/viewport/useModal';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {useOrgLogo} from 'interface/base/OrgLogo';
import {OrgLogoSize} from 'interface/base/OrgLogo/types';
import Typography from 'interface/base/Typography';
import Chip from 'interface/common/Chip';
import {Divider} from 'interface/common/Divider';
import {DropdownButton} from 'interface/common/DropdownButton';
import {MemberAvatarGroup} from 'interface/stacks/auth/components/MemberAvatarGroup';
import {InviteMembersModal} from 'interface/stacks/settings/components/members/InviteMembersModal';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {
  ORG_ADMIN_PERMISSIONS,
  ORG_ARCHIVED_WORKSPACES_PERMISSIONS,
} from 'store/slices/app/types';
import type {Member} from 'store/slices/settings/types';
import * as user from 'store/slices/user';
import type {Organization} from 'store/slices/user/types';

interface OrganizationHeaderProps {
  organization: Organization;
  workspacesCount: number;
  sharesCount: number;
  isWorkspaces: boolean;
  members?: Member[];
  isExternal?: boolean;
  isMembersLoading?: boolean;
  hideControls?: boolean;
  onNewWorkspacePress?: () => void;
  onSettingsPress?: () => void;
  onViewArchivedWorkspacesPress?: () => void;
  onRenewSubscriptionPress?: () => void;
  hasInvalidSubscription?: boolean;
}

const OrganizationHeader = ({
  organization,
  workspacesCount,
  sharesCount,
  isWorkspaces,
  members = [],
  isExternal = false,
  isMembersLoading = false,
  hideControls = false,
  onNewWorkspacePress,
  onSettingsPress,
  onViewArchivedWorkspacesPress,
  onRenewSubscriptionPress,
  hasInvalidSubscription,
}: OrganizationHeaderProps) => {
  const modal = useModal();

  const [isOrgMenuOpen, setIsOrgMenuOpen] = useState(false);

  const {logo} = useOrgLogo({
    orgId: organization.id,
    orgDetails: organization,
    size: OrgLogoSize.ExtraLarge,
    showBorder: false,
  });

  const userDetails = useSelector(user.selectors.getUserDetails);

  const onInviteMemberPress = () => {
    modal.open(
      <InviteMembersModal organization={organization} onSubmit={() => {}} />,
    );
  };

  const openOrgMenu = () => setIsOrgMenuOpen(true);
  const closeOrgMenu = () => setIsOrgMenuOpen(false);

  const isOwner = `${organization.parent}` === userDetails.id;
  const permissions = members?.find(m => m.id === userDetails.id)?.permissions;
  const canManageOrg = isOwner || ORG_ADMIN_PERMISSIONS.includes(permissions);
  const canViewArchivedWorkspaces =
    isOwner || ORG_ARCHIVED_WORKSPACES_PERMISSIONS.includes(permissions);
  const showSettings = isWorkspaces && !isExternal && !hasInvalidSubscription;

  return (
    <View
      style={[
        styles.root,
        hasInvalidSubscription && styles.invalidSubscriptionRoot,
      ]}>
      <View style={styles.contentLeft}>
        {logo}
        <View style={styles.headerInfo}>
          <View style={styles.headerName}>
            <Typography
              variant={'medium'}
              size={'xl'}
              color={theme.colors.neutral.$2Base}>
              {organization.name}
            </Typography>
            {isOwner && (
              <Chip label="Owner" textColor={theme.colors.neutral.$2Base} />
            )}
            {isExternal && (
              <Chip label="External" textColor={theme.colors.neutral.$2Base} />
            )}
          </View>
          {!hasInvalidSubscription && (
            <View style={styles.headerStats}>
              {!isExternal && (
                <>
                  <View style={styles.membersContainer}>
                    <MemberAvatarGroup
                      members={members}
                      loading={isMembersLoading}
                      size={-1}
                    />
                  </View>
                  <Divider orientation={'vertical'} />
                </>
              )}
              <Typography size={'sm'} color={theme.colors.neutral.$2Base}>
                <Trans>
                  {isWorkspaces
                    ? `${workspacesCount} workspaces`
                    : `${sharesCount} shares`}
                </Trans>
              </Typography>
            </View>
          )}
        </View>
      </View>

      {!hideControls && (
        <View style={styles.contentRight}>
          {showSettings && (
            <>
              <Button
                onPress={onNewWorkspacePress}
                variant="primary"
                startEnhancer={
                  <Icon
                    name="lucide:plus"
                    color={theme.colors.neutral.$white}
                  />
                }>
                New Workspace
              </Button>
              <Button
                onPress={onInviteMemberPress}
                variant="secondary"
                startEnhancer={
                  <Icon
                    name="lucide:user-plus"
                    color={theme.colors.neutral.$black}
                  />
                }>
                <Trans>Invite</Trans>
              </Button>
              {(canManageOrg || canViewArchivedWorkspaces) && (
                <Popup
                  isOpen={isOrgMenuOpen}
                  close={closeOrgMenu}
                  placement="bottom-start"
                  offset={4}
                  triggerElement={
                    <Button
                      onPress={openOrgMenu}
                      variant="secondary"
                      startEnhancer={
                        <Icon
                          name="lucide:settings"
                          color={theme.colors.neutral.$black}
                        />
                      }
                    />
                  }>
                  {canManageOrg && (
                    <DropdownButton
                      iconName="lucide:settings"
                      label="Organization Settings"
                      onPress={onSettingsPress}
                      key="Settings"
                    />
                  )}
                  {canViewArchivedWorkspaces && (
                    <DropdownButton
                      iconName="lucide:archive"
                      label="View Archived Workspaces"
                      onPress={onViewArchivedWorkspacesPress}
                      key="View Archived Workspaces"
                    />
                  )}
                </Popup>
              )}
            </>
          )}
          {hasInvalidSubscription && (
            <Button
              onPress={onRenewSubscriptionPress}
              variant="text"
              startEnhancer={
                <Icon
                  name="lucide:shopping-cart"
                  color={theme.colors.brand.$4Base}
                />
              }>
              <Typography
                variant="regular"
                size="sm"
                color={theme.colors.brand.$4Base}>
                <Trans>Renew Subscription</Trans>
              </Typography>
            </Button>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  contentRight: {
    flexDirection: 'row',
    gap: 8,
  },
  statsContainer: {
    marginTop: 4,
  },
  headerInfo: {
    gap: 2,
  },
  headerStats: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    height: 24,
  },
  headerName: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  membersContainer: {
    flexDirection: 'row',
    gap: 4,
  },
  invalidSubscriptionRoot: {
    justifyContent: 'flex-start',
    gap: 8,
  },
});

export default OrganizationHeader;
