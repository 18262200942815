/**
 * AI Chat Creation Module
 *
 * This hook manages the lifecycle of creating new AI chats in the application.
 * It provides a seamless chat creation experience with optimized state handling.
 *
 * Key responsibilities:
 * - Creating new chats with immediate user feedback
 * - Managing file attachments and content scoping
 * - Orchestrating the API interactions for chat creation
 * - Handling state transitions between temporary and permanent resources
 * - Maintaining UI continuity throughout asynchronous operations
 */

import {api} from 'fast-sdk';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {Personality} from 'store/slices/aichat/types';
import * as user from 'store/slices/user';
import {
  CHAT_FOLDER_SCOPE_DEPTH,
  NEW_CHAT,
  NEW_PRIVATE_CHAT,
  TEMP_MESSAGE_ID,
} from '../consts';
import {type FilterInfo, buildTempMessage, toBool} from '../helpers';

export const useCreateNewChat = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {namespace, instanceId, instanceName, intelligence} =
    useGetNamespaceContext();
  const dispatch = useDispatch();
  const userId = useSelector(user.selectors.getUserDetails)?.id;
  const type = toBool(intelligence) ? 'chat_with_files' : 'chat';

  /**
   * Creates a new chat with the provided message and settings.
   *
   * This function handles the complete chat creation workflow:
   * - Creates a temporary chat for immediate user feedback
   * - Configures file scoping and attachments for context-aware AI responses
   * - Makes the API request to create the permanent chat
   * - Updates state with the permanent identifiers from the API
   * - Handles error conditions gracefully
   *
   * @param message - The initial user message content
   * @param personality - The AI personality setting for this chat
   * @param isPrivate - Whether the chat should be private
   * @param scopeFiles - Files to include in the AI's context scope
   * @param attachFiles - Files to attach to the chat
   */
  const createChat = async (
    message: string,
    personality: Personality,
    isPrivate: boolean,
    scopeFiles: FilterInfo[],
    attachFiles: FilterInfo[],
  ) => {
    if (!instanceName) return;
    setIsLoading(true);

    const tempChatId = isPrivate ? NEW_PRIVATE_CHAT : NEW_CHAT;

    try {
      const filesScope = scopeFiles
        .filter(file => file.type === 'file' || file.type === 'note')
        .map(file => `${file.id}:${file.version}`)
        .join(',');
      const foldersScope = scopeFiles
        .filter(file => file.type === 'folder')
        .map(file => `${file.id}:${CHAT_FOLDER_SCOPE_DEPTH}`)
        .join(',');
      const filesAttach = attachFiles
        .filter(file => file.type === 'file' || file.type === 'note')
        .map(file => `${file.id}:${file.version}`)
        .join(',');

      dispatch(aichat.default.actions.setTriggerStreaming(null));

      // Create temporary message with placeholder ID
      const newMessage = buildTempMessage(
        tempChatId,
        TEMP_MESSAGE_ID,
        message,
        personality,
        userId,
        filesScope,
        foldersScope,
        filesAttach,
      );

      // STEP 1: Create temporary chat in Redux (appears in UI)
      dispatch(
        aichat.default.actions.addChat({
          namespace,
          instanceId,
          chatId: tempChatId,
          isPrivate,
        }),
      );

      // Add temporary message to the temporary chat
      dispatch(
        aichat.default.actions.addChatMessage({
          namespace,
          instanceId,
          message: newMessage,
        }),
      );

      // STEP 2: Make API call to create the actual chat
      const createChatResponse = await api.workspaceAichat.createNewChat(
        namespace,
        instanceName,
        type,
        message,
        isPrivate,
        personality,
        filesScope,
        foldersScope,
        filesAttach,
      );
      if (!createChatResponse.result) {
        throw new Error('Failed to create chat');
      }
      const newChatId = createChatResponse.chat?.id;

      // Update the temporary chat ID to the permanent ID from the API
      // This maintains a continuous presence of the chat in the UI
      dispatch(
        aichat.default.actions.updateChatId({
          namespace,
          instanceId,
          oldChatId: tempChatId,
          newChatId: newChatId,
        }),
      );

      // Set the current chat to the new real chat
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: newChatId,
        }),
      );
    } catch (error) {
      // Clean up temporary resources in case of error
      dispatch(
        aichat.default.actions.deleteChat({
          namespace,
          instanceId,
          chatId: tempChatId,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading: isLoading, createChat};
};
