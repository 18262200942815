import type {PayloadAction} from '@reduxjs/toolkit';
import type {ActivityType} from 'fast-sdk/src/websockets/types';
import type {FilesItem} from '../files/types';

export type Store = {
  users: EntitiesActivity;
  workspaces: EntitiesActivity;
  organizations: EntitiesActivity;
  shares: EntitiesActivity;
};

export type EntitiesActivity = {
  [entitiyId in string]: EntityActivities;
};

export type EntityActivities = {
  lastActivity?: string;
  activities: ActivityTypeWithTimestamp;
};

export enum EntityType {
  User = 'user',
  Workspace = 'workspace',
  Organization = 'organization',
  Share = 'share',
}

export type ActivityTypeWithTimestamp = {
  [activityType in ActivityType]: {
    lastServerUpdate: string;
    lastClientUpdate?: string | null;
  };
};

export type Actions = {
  setEntityActivities: PayloadAction<{
    entityId: string;
    entityType: EntityType;
    lastActivity: string;
    activities: ActivityTypeWithTimestamp;
    items: {[id: string]: FilesItem};
  }>;
  updateAiChatActivity: PayloadAction<{
    namespace: string;
    instanceId: string;
    chatId: string;
  }>;
  updateAllAiChatActivity: PayloadAction<{
    namespace: string;
    instanceId: string;
  }>;
  setEntitiesLastClientUpdate: PayloadAction<{
    entityType: EntityType;
    entities: string[];
    activities: ActivityType[];
    lastClientUpdate: string;
  }>;
  removeEntityActivity: PayloadAction<{
    entityId: string;
    activityType: ActivityType;
    entityType: EntityType;
  }>;
  removeEntity: PayloadAction<{
    entityId: string;
    entityType: EntityType;
  }>;
};

export const entityTypeToStoreKey = {
  [EntityType.User]: 'users',
  [EntityType.Workspace]: 'workspaces',
  [EntityType.Organization]: 'organizations',
  [EntityType.Share]: 'shares',
};
