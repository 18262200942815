import {ROUTES, STORAGE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {api} from 'fast-sdk';
import {InvitationTypes} from 'fast-sdk/src/api/share/consts';
import type {PendingInvitationDetails} from 'interface/stacks/auth/consts';
import {AppTabs} from 'interface/stacks/profile-membership/types';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import {useSubDomain} from './useSubDomain';

interface HandleInvitationAcceptanceOptions {
  invitation: PendingInvitationDetails;
  showAppLoader?: boolean;
  disableLoading?: boolean;
  onSuccess?: () => void;
}

export const useJoinInvitationHandler = (shouldGoToOnboarding = false) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {generateShareUrlWithSubdomain} = useGenerateShareUrl();
  const {subdomain} = useSubDomain();

  const [isLoading, setIsLoading] = useState(false);

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const acceptAllInvitations = async (invitationKey: string) => {
    const {result, error} = await api.user.acceptAllInvitations(invitationKey);
    return {result, error};
  };

  const redirectOnSuccess = async (
    showAppLoader: boolean,
    invitation: PendingInvitationDetails,
  ) => {
    if (showAppLoader) {
      dispatch(app.default.actions.load({loaded: false}));
    }

    if (shouldGoToOnboarding) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
    } else {
      switch (invitation.entity_type) {
        case InvitationTypes.Shared:
          {
            const shareUrl = generateShareUrlWithSubdomain(
              invitation.share,
              invitation.org,
            );
            dispatch(app.default.actions.setRedirectTo(shareUrl));
          }
          break;
        case InvitationTypes.Workspace:
          {
            const origin = location.origin.replace(
              subdomain,
              invitation.workspace.org_domain,
            );
            const workspaceUrl = `${origin}/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${invitation.workspace.folder_name}/${WORKSPACE_STORAGE}/${STORAGE_ROOT}`;
            dispatch(app.default.actions.setRedirectTo(workspaceUrl));
          }
          break;
      }
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${AppTabs.Workspaces}`);
    }

    if (showAppLoader) {
      dispatch(app.default.actions.load({loaded: true}));
    }
  };

  const redirectOnFailure = () => {
    if (isLoggedIn) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${AppTabs.Workspaces}`);
    } else {
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }
  };

  const handleInvitationAcceptance = useCallback(
    async ({
      invitation,
      onSuccess,
      showAppLoader = false,
      disableLoading = false,
    }: HandleInvitationAcceptanceOptions) => {
      if (!disableLoading) {
        setIsLoading(true);
      }
      try {
        const {result, error} = await acceptAllInvitations(
          invitation.invitationToken,
        );

        if (result) {
          if (onSuccess) {
            onSuccess();
          } else {
            await redirectOnSuccess(showAppLoader, invitation);
          }
        } else {
          handleAppError({
            appError: AppErrors.JoinInvitationProcessError,
            text: error?.text,
          });
          redirectOnFailure();
        }
      } catch (error) {
        handleAppError({
          appError: AppErrors.JoinInvitationProcessError,
          exception: error,
        });
        redirectOnFailure();
      } finally {
        if (!disableLoading) {
          setIsLoading(false);
        }
      }
    },
    [isLoggedIn],
  );

  return {handleInvitationAcceptance, isLoading};
};
