import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import OrganizationHeader from '../profile-membership/components/content/organization/OrganizationHeader';
import {SettingsType} from './base/consts';
import {Authentication} from './components/Authentication';
import {EmailDomains} from './components/EmailDomains';
import {AccountSettings} from './components/account-settings';
import {Billing} from './components/billing';
import {Members} from './components/members';
import {Organization} from './components/organization';
import {Profile} from './components/profile';
import type {Params} from './types';

export const PUBLIC_SETTINGS_SECTIONS: Array<string> = [
  SettingsType.profile,
  SettingsType['account-settings'],
];

const WITH_ORG_HEADER = [SettingsType.billing];

const COMPONENT_MAP = {
  [SettingsType.organization]: <Organization />,
  [SettingsType.members]: <Members />,
  [SettingsType.authentication]: <Authentication />,
  [SettingsType.billing]: <Billing />,
  [SettingsType['email-domains']]: <EmailDomains />,
  [SettingsType.profile]: <Profile />,
  [SettingsType['account-settings']]: <AccountSettings />,
};

export interface SettingsContentType {
  type: SettingsType;
}

export default function SettingsContent({type}: SettingsContentType) {
  const {subdomain} = useParams<Params>();
  const isMinimal = screen.width < 600;
  const classes = {
    content: [styles.content, isMinimal && styles.minimal],
  };

  const orgs = useSelector(user.selectors.getUserAllowedSettingsOrgs);
  const currentOrg = orgs.find(org => org.domain === subdomain);
  const workspaces = useSelector(
    workspace.selectors.getOrganizationWorkspaces(subdomain),
  );
  const members = useSelector(
    user.selectors.getMembersByOrganization(currentOrg?.id),
  );

  const isWithOrgHeader = WITH_ORG_HEADER.includes(type);

  return (
    <View style={styles.root}>
      {isWithOrgHeader && (
        <View style={styles.header}>
          <OrganizationHeader
            organization={currentOrg}
            workspacesCount={workspaces.length}
            sharesCount={0}
            isWorkspaces
            members={members}
            hideControls
          />
        </View>
      )}
      <View style={classes.content}>{COMPONENT_MAP[type]}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.colors.neutral.$0,
    borderRadius: 6,
    marginTop: 2,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  content: {
    flex: 1,
    width: '100%',
  },
  minimal: {
    marginHorizontal: 0,
  },
});
