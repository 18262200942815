import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Requests, api} from 'fast-sdk';
import {Input} from 'interface/base/Input';
import Typography from 'interface/base/Typography';
import {Button} from 'interface/common/Button';
import {FormError} from 'interface/common/FormError';
import {useFormSubmit} from 'interface/common/hooks/useFormSubmit';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import Auth from 'interface/stacks/auth/consts';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';
import {validateEmail} from 'utils/common/validation';
import {AuthPageTypes, RouteLayout} from '../_layout/RouteLayout';
import {PasswordInput} from '../components/PasswordInput';
import {useGetAuthHeaderLogo} from '../hooks/useGetAuthHeaderLogo';

const prepareEmail = (email: string) => {
  return email;
};

const MIN_NAME_LENGTH = 2;

interface SignUpWithEmailProps {
  isModal?: boolean;
  onSignupOrSignin?: () => void;
  onSuccessSignup?: () => void;
}

export function SignUpWithEmail(props: SignUpWithEmailProps) {
  const {subdomain} = useSubDomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentJoinInvitation = useSelector(
    app.selectors.getCurrentJoinInvitation,
  );

  const organization = useSelector(user.selectors.getSelectedOrganization);

  const [email, setEmail] = useState<string>(
    currentJoinInvitation?.invitee_email || '',
  );
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const {headerLogo} = useGetAuthHeaderLogo(
    currentJoinInvitation?.org ?? organization,
  );

  const anyFilled = (...args: any[]) => {
    return args.some(arg => !!arg);
  };

  const renderInformation = useCallback(() => {
    if (currentJoinInvitation) {
      const {org} = currentJoinInvitation;
      if (org)
        return {
          title: (
            <Text>
              Create an account to join
              {'\n'}
              <Text style={{color: theme.colors.brand.$3}}>{org.name}</Text>
            </Text>
          ),
          subTitle: 'Use your work email to create an account',
        };
    }
    if (isSubdomainValid(subdomain) && organization) {
      return {
        title: (
          <Text>
            Create an account to join{' '}
            <Text style={{color: theme.colors.brand.$3}}>
              {organization.name}
            </Text>
          </Text>
        ),
        subTitle: 'Use your work email to create an account',
      };
    }

    return {
      title: 'Create your account',
      subTitle: 'We need just a few details before we continue',
    };
  }, [subdomain, currentJoinInvitation, organization]);

  useEffect(() => {
    if (currentJoinInvitation?.invitee_email) {
      setEmail(currentJoinInvitation.invitee_email);
    }
  }, [currentJoinInvitation?.invitee_email]);

  useEffect(() => {
    if (!email) {
      return setEmailIsValid(false);
    }

    const preparedEmail = prepareEmail(email);
    const isValid = validateEmail(preparedEmail);
    if (!isValid) {
      return setEmailIsValid(false);
    }

    setEmailIsValid(true);
  }, [email]);

  const submit = async () => {
    setErrorMessage(undefined);
    setLoading(true);
    const currentEmail = prepareEmail(email);

    try {
      const {result, error} = await api.user.createUser({
        first_name: firstName,
        last_name: lastName,
        email_address: currentEmail,
        password: password,
        tos_agree: true,
        country_code: 'US',
        // phone_number: '1-361-555-0000',
      });

      if (!result) {
        throw Error(error?.text);
      }

      const signInRes = await api.auth.signIn({
        email: currentEmail,
        password: password,
      });

      if (!signInRes.result) {
        throw Error(signInRes.error?.text);
      }

      Requests.setAuthToken(signInRes.auth_token);
      await Auth.setAuthToken(signInRes.auth_token);
      await Auth.setUserEmail(currentEmail);
      dispatch(user.default.actions.setUserToken(signInRes.auth_token));
      dispatch(onboarding.actions.restartOnboarding());
      if (props.onSuccessSignup) {
        props.onSuccessSignup();
      } else {
        navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`);
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  const {title, subTitle} = useMemo(
    () => renderInformation(),
    [renderInformation],
  );

  const isSubmitDisabled =
    !emailIsValid ||
    !firstName ||
    !lastName ||
    !passwordIsValid ||
    firstName.length < 3 ||
    lastName.length < 3;

  useFormSubmit(submit, isSubmitDisabled);

  return (
    <RouteLayout
      title={title}
      subTitle={subTitle}
      headerLogo={headerLogo}
      type={AuthPageTypes.SIGNUP}
      customRootMaxWidth={props.isModal ? 500 : undefined}
      onSignupOrSignin={props.onSignupOrSignin}>
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <View style={styles.content}>
        <View style={styles.inputWithLabel}>
          <Typography
            variant="bold"
            size="xs"
            color={theme.colors.neutral.$700}>
            {t`Email`}
          </Typography>
          <Input
            value={email}
            onChangeValue={setEmail}
            disabled={loading}
            error={
              (email || anyFilled(firstName, lastName, password)) &&
              !emailIsValid
            }
            placeholder="bruce.wayne@wayneenterprises.com"
            errorMessage={'Invalid email address'}
            aria-label="Email Address"
          />
        </View>
        <View style={styles.inputsContainer}>
          <View style={[styles.inputWithLabel, {width: '48%'}]}>
            <Typography
              variant="bold"
              size="xs"
              color={theme.colors.neutral.$700}>
              {t`First Name`}
            </Typography>
            <Input
              placeholder="Bruce"
              value={firstName}
              onChangeValue={setFirstName}
              disabled={loading}
              error={
                (firstName || anyFilled(lastName, password)) &&
                firstName.length < MIN_NAME_LENGTH
              }
              aria-label="First Name"
              errorMessage={`${MIN_NAME_LENGTH} characters minimum`}
            />
          </View>
          <View style={[styles.inputWithLabel, {width: '48%'}]}>
            <Typography
              variant="bold"
              size="xs"
              color={theme.colors.neutral.$700}>
              {t`Last Name`}
            </Typography>
            <Input
              placeholder="Wayne"
              value={lastName}
              onChangeValue={setLastName}
              disabled={loading}
              error={
                (lastName || anyFilled(password)) &&
                lastName.length < MIN_NAME_LENGTH
              }
              aria-label="Last Name"
              errorMessage={`${MIN_NAME_LENGTH} characters minimum`}
            />
          </View>
        </View>
        <View style={styles.passwordContainer}>
          <PasswordInput
            password={password}
            setPassword={setPassword}
            withValidation={true}
            setPasswordIsValid={setPasswordIsValid}
            disabled={loading}
            label={t`Password`}
          />
        </View>
      </View>
      <Button
        label={t`Continue`}
        onPress={submit}
        disabled={
          !emailIsValid ||
          !firstName ||
          !lastName ||
          !passwordIsValid ||
          firstName.length < MIN_NAME_LENGTH ||
          lastName.length < MIN_NAME_LENGTH
        }
        type={'Primary'}
        customRootStyle={{
          width: '100%',
          borderRadius: 5,
          height: 48,
        }}
        customTextStyle={styles.buttonText}
        loading={loading}
        customLoadingColor={theme.colors.neutral.$white}
      />
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  input: {
    padding: 10,
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
    marginBottom: '1.5rem',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
  label: {
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 20,
  },
  passwordContainer: {
    marginBottom: '3rem',
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: theme.colors.danger.$8,
    borderWidth: 2,
    borderColor: theme.colors.danger.$4Base,
    borderRadius: 5,
    marginBottom: '2rem',
  },
  errorMessage: {
    color: theme.colors.danger.$e2,
    textAlign: 'center',
    width: '100%',
  },
  inputWithLabel: {
    gap: 6,
  },
});
