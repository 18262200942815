import {Fragment, useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {WorkspaceCardList} from '../base/WorkspaceCardList';
import {useLayoutWorkspaces} from '../hooks/useLayoutWorkspaces';
import {WorkspacesToolbar} from './WorkspacesToolbar';

export function ArchivedWorkspaces() {
  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();

  const workspaces = useSelector(
    workspace.selectors.getArchivedWorkspacesInOrg,
  );

  useEffect(() => {
    fetchAndUpdateWorkspaces();
  }, []);

  return (
    <Fragment>
      <WorkspacesToolbar title="Archived Workspaces" />
      <WorkspaceCardList workspaces={workspaces} />
    </Fragment>
  );
}
