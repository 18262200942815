import {format} from 'date-fns';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import {bytesize} from 'utils/common/data';
import {parseServerDate} from 'utils/common/dates';
import {FileType, getFileTypeFromMime, typeToIcon} from 'utils/fast/files';
import {DEFAULT_COLUMNS} from './consts';

import type {GridColumn} from '@glideapps/glide-data-grid';
import type {MetadataItem} from 'fast-sdk/src/api/storage/consts';
import type {MetadataRow, SortState} from './types';

// Helper function to estimate text width based on content
function estimateTextWidth(text: string): number {
  // Base character width in pixels (approximate)
  const charWidth = 8;
  // Minimum width in pixels
  const minWidth = 100;
  // Add some padding
  const padding = 24;

  if (!text) return minWidth;

  // Calculate width based on text length
  const textWidth = text.length * charWidth + padding;

  // Return the calculated width, but not less than the minimum
  return Math.max(textWidth, minWidth);
}

// Function to get the maximum content length for a column
function getMaxContentWidth(rows: MetadataRow[], columnId: string): number {
  // Start with column title width
  const displayName = columnId
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const maxWidth = estimateTextWidth(displayName);

  // Special case for 'name' column with complex structure
  if (columnId === 'name') {
    const nameWidths = rows
      .filter(row => row[columnId] && Array.isArray(row[columnId]))
      .map(row => {
        const nameData = row[columnId][0];
        return estimateTextWidth(nameData?.text || '');
      });

    if (nameWidths.length > 0) {
      const maxNameWidth = Math.max(...nameWidths);
      // Add extra space for icon
      return Math.max(maxNameWidth + 24, maxWidth);
    }
    return maxWidth;
  }

  // For other columns
  const contentWidths = rows
    .filter(row => row[columnId] !== undefined)
    .map(row => {
      const content =
        typeof row[columnId] === 'object'
          ? JSON.stringify(row[columnId])
          : String(row[columnId]);
      return estimateTextWidth(content);
    });

  if (contentWidths.length > 0) {
    return Math.max(Math.max(...contentWidths), maxWidth);
  }

  return maxWidth;
}

export function getColumns(
  metadataColumns: string[] = [],
  columnWidths: Record<string, number> = {},
  sortState: SortState = {columnId: null, direction: 'desc'},
  hiddenColumns: string[] = [],
  rows: MetadataRow[] = [],
): Array<GridColumn> {
  return metadataColumns
    .filter(column => !!column)
    .map(columnId => {
      // Format column name for display (e.g., "file_name" -> "File Name")
      const displayName = columnId
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      const isSorted = sortState.columnId === columnId;

      // Calculate default width based on content if not specified
      const defaultWidth =
        rows.length > 0 ? getMaxContentWidth(rows, columnId) : 150; // Fallback default width

      return {
        id: columnId,
        grow: columnId === 'name' && !columnWidths[columnId] ? 1 : 0,
        width: columnWidths[columnId] || defaultWidth,
        title: displayName,
        hasMenu: false,
        menuIcon: 'dots',
        icon: isSorted
          ? sortState.direction === 'asc'
            ? 'up'
            : 'down'
          : undefined,
      } satisfies GridColumn;
    })
    .filter(column => !hiddenColumns.includes(column.id));
}

export function getRow(
  item: MetadataItem,
  metadataColumns: string[],
  hiddenColumns: string[] = [],
  thumbnails: Record<string, string> = {},
): MetadataRow {
  const storageItem = item.node_id;
  const fileType = storageItem && getFileTypeFromMime(storageItem);
  const icon = typeToIcon(fileType ?? FileType.Unknown);
  const id = storageItem?.id ?? item.object_id;

  // Create the base row props
  const row: MetadataRow = {file: storageItem};

  // Only add visible columns to the row
  if (!hiddenColumns.includes('name')) {
    // Get the thumbnail from the thumbnails dictionary if available
    const thumbnailKey = `${id}-${FileThumbnailSizes.Small}`;
    const thumbnailUrl = thumbnails[thumbnailKey];
    row.name = [
      {
        img: thumbnailUrl || undefined,
        icon,
        text: storageItem?.name ?? '',
      },
    ];
  }

  // Default columns
  if (!hiddenColumns.includes('size')) {
    row.size = bytesize(storageItem?.size ?? 0, 0);
  }

  if (!hiddenColumns.includes('created')) {
    row.created = storageItem?.created
      ? format(parseServerDate(storageItem.created), 'dd/MM/yyyy HH:mm aa')
      : '';
  }

  // Custom metadata columns - use exact column ID match
  metadataColumns
    .filter(column => !!column)
    .filter(column => !DEFAULT_COLUMNS.includes(column))
    .forEach(columnId => {
      if (!hiddenColumns.includes(columnId)) {
        const metadataEntry = item.metadata.find(meta => meta.key === columnId);
        row[columnId] = metadataEntry?.value || '';
      }
    });

  return row;
}

export function getTemplateIcon(category: string) {
  switch (category) {
    case 'general':
      return 'lucide:file';
    case 'technical':
      return 'lucide:circuit-board';
    case 'engineering':
      return 'lucide:blocks';
    case 'financial':
      return 'lucide:circle-dollar-sign';
    case 'insurance':
      return 'lucide:scroll-text';
    case 'medical':
      return 'lucide:stethoscope';
    case 'educational':
      return 'lucide:graduation-cap';
    case 'multimedia':
      return 'lucide:image-play';
    case 'hr':
      return 'lucide:users';
    default:
      return 'lucide:file';
  }
}
