import theme from 'config/theme';
import {KeyboardListener} from 'interface/KeyboardListener';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {
  type NativeSyntheticEvent,
  Pressable,
  type StyleProp,
  StyleSheet,
  TextInput,
  type TextInputContentSizeChangeEventData,
  View,
  type ViewStyle,
} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {NEW_CHAT, NEW_PRIVATE_CHAT} from '../../consts';
import {useGetLastMessageFiles} from '../../hooks/useGetLastMessageFiles';
import {AiChatInputButton} from './AiChatInputButton';

const MIN_MESSAGE_LENGTH = 3;
const LINE_HEIGHT = 22;
const MIN_INPUT_LINES = 3;
const MAX_INPUT_LINES = 8;

interface Props {
  chatId: string;
  onSendMessage: (
    message: string,
    webSearch: boolean,
    withRag: boolean,
  ) => void;
  isEnabled: boolean;
  enableSettings: boolean;
  style?: StyleProp<ViewStyle>;
}

function isFolder(id: string) {
  return id?.startsWith('2') ?? false;
}

const isShare = (folderId: string) => {
  return folderId.startsWith('5');
};

export function AiChatInput({
  chatId,
  onSendMessage,
  isEnabled,
  enableSettings,
  style,
}: Props) {
  const {namespace, instanceId, intelligence} = useGetNamespaceContext();
  const dispatch = useDispatch();
  const [manualHideEfficency, setManualHideEfficency] = useState(false);

  const [message, setMessage] = useState('');
  const toast = useToast();
  const [withRag, setWithRag] = useState(false);
  const [textInputHeight, setTextInputHeight] = useState(
    LINE_HEIGHT * MIN_INPUT_LINES,
  );

  const {chat} = useGetLastMessageFiles(chatId);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitMessage();
    }
  };

  const submitMessage = () => {
    if (message.length < MIN_MESSAGE_LENGTH) {
      const error = `This value is too short. It should have ${MIN_MESSAGE_LENGTH} characters or more.`;
      toast.show(error);
      return;
    }
    if (message.trim() && isEnabled) {
      onSendMessage(message, false, withRag);
      setMessage('');
    }
  };

  const startNewChat = () => {
    dispatch(
      aichat.default.actions.setCurrentChat({
        namespace,
        instanceId,
        chatId:
          chat?.privacy?.visibility === 'private' ? NEW_PRIVATE_CHAT : NEW_CHAT,
      }),
    );
    setMessage('');
  };

  const handleContentSizeChange = (
    e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>,
  ) => {
    const newHeight = e.nativeEvent.contentSize.height;
    const boundedHeight = Math.max(
      LINE_HEIGHT * MIN_INPUT_LINES,
      Math.min(newHeight, LINE_HEIGHT * MAX_INPUT_LINES),
    );
    const fixedHeight = Math.floor(boundedHeight / LINE_HEIGHT) * LINE_HEIGHT;
    setTextInputHeight(fixedHeight);
  };

  const showScrollbar = textInputHeight >= LINE_HEIGHT * MAX_INPUT_LINES;

  const showEfficency =
    !manualHideEfficency &&
    ['low', 'critical'].includes(chat?.efficiency ?? '-');

  const hideEfficency = () => {
    setManualHideEfficency(true);
  };

  return (
    <View style={[styles.container, style]}>
      {showEfficency && (
        <View style={styles.efficency}>
          <Typography
            size="xs"
            variant="medium"
            color={theme.colors.neutral.$700}>
            Tip:
          </Typography>
          <Typography
            size="xs"
            color={theme.colors.neutral.$700}
            overrides={{flex: 1}}>
            Long chats are worse and more costly
          </Typography>
          <Pressable onPress={startNewChat}>
            <Typography
              size="xs"
              color={theme.colors.neutral.$700}
              overrides={{paddingHorizontal: 8, paddingVertical: 2}}>
              Start New Chat
            </Typography>
          </Pressable>
          <Pressable onPress={hideEfficency}>
            <Icon name="lucide:x" size={18} color={theme.colors.neutral.$700} />
          </Pressable>
        </View>
      )}
      <View style={[styles.inputWrapper]}>
        <KeyboardListener onKeyPress={handleKeyPress} customStyle={{flex: 1}}>
          <TextInput
            placeholder="What do you want to know?"
            placeholderTextColor={theme.colors.neutral.$400}
            multiline={true}
            scrollEnabled={false}
            onChangeText={setMessage}
            value={message}
            onContentSizeChange={handleContentSizeChange}
            style={[
              styles.input,
              {
                // @ts-ignore
                outline: 'none',
                lineHeight: LINE_HEIGHT,
                height: textInputHeight,
                scrollbarWidth: showScrollbar ? 'thin' : 'none',
              },
            ]}
          />
        </KeyboardListener>
        <AiChatInputButton isEnabled={isEnabled} onPress={submitMessage} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  inputWrapper: {
    flex: 1,
    overflow: 'visible',
    borderRadius: 12,
    backgroundColor: theme.colors.neutral.$0,
    boxShadow: '0px 2px 6px 0px rgba(28, 27, 34, 0.05)',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: 12,
    paddingVertical: 12,
    gap: 12,
  },
  aiDisclaimer: {
    marginVertical: 4,
    textAlign: 'center',
  },
  customizeSection: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopColor: theme.colors.neutral.$100,
    borderTopWidth: 1,
    paddingTop: 8,
    gap: 8,
    paddingHorizontal: 16,
  },
  spacer: {
    flex: 1,
  },
  selectFilesSection: {
    marginHorizontal: 16,
  },
  input: {
    width: '100%',
    padding: 0,
    paddingHorizontal: 4,
    borderWidth: 0.5,
    borderColor: 'transparent',
    scrollbarColor: `${theme.colors.neutral.$200} transparent`,
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    height: 'fit-content',
    paddingEnd: 16,
  },
  help: {
    width: 'auto',
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
  efficency: {
    height: 32,
    borderRadius: 8,
    flexDirection: 'row',
    width: 'calc(100% - 32px)',
    marginHorizontal: 16,
    gap: 4,
    marginTop: 12,
    paddingHorizontal: 8,
    paddingEnd: 4,
    alignItems: 'center',
    backgroundColor: theme.colors.brand.$1_50,
  },
});
