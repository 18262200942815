import theme from 'config/theme';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {EditorActions} from 'interface/stacks/content-viewer/components/top-toolbar/ActionButtons/EditorActions';
import {useEditorContext} from 'interface/stacks/content-viewer/context/EditorContext';
import {StyleSheet, View} from 'react-native';
import {AiChatMenuButton} from '../../WorkspaceAiChat/components/AiChatMenuButton';
import {NOTE_LIST_HEADER_HEIGHT} from '../consts';

interface NoteTopToolbarProps {
  showMenu: boolean;
  onOpenSidebar: () => void;
}

const NoteTopToolbar = ({showMenu, onOpenSidebar}: NoteTopToolbarProps) => {
  const {fileName, setFileName, handleCancel} = useEditorContext();

  const handleCancelPress = async () => {
    await handleCancel();
  };

  return (
    <View style={styles.root}>
      <View style={styles.left}>
        {showMenu && (
          <AiChatMenuButton
            iconName="lucide:sidebar"
            onPress={onOpenSidebar}
            tooltipText="Open sidebar"
          />
        )}
        <CustomTextInput
          value={fileName}
          placeholder={'Insert note title here'}
          setValue={setFileName}
          ariaLabel="Note title"
          customInputStyle={styles.input}
        />
      </View>

      <View style={styles.right}>
        <EditorActions
          showFileMenu={false}
          onCancelPress={handleCancelPress}
          showCancelButton={false}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: NOTE_LIST_HEADER_HEIGHT,
    backgroundColor: theme.colors.neutral.$white,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 5,
    width: '100%',
    borderBottomWidth: 1,
    borderColor: theme.colors.neutral.$75,
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  center: {},
  right: {},
  divider: {
    width: 0,
    height: NOTE_LIST_HEADER_HEIGHT,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$black,
    marginHorizontal: 8,
    marginTop: -8,
  },
  input: {
    flex: 1,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 22,
    backgroundColor: theme.colors.neutral.$white,
    minWidth: 360,
  },
});

export default NoteTopToolbar;
