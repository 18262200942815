import {createSelector} from '@reduxjs/toolkit';
import {WorkspaceUserStatus} from 'fast-sdk/src/api/workspace/consts';
import type {Store} from 'store';
import {ORG_ADMIN_PERMISSIONS} from '../app/types';

const x = (store: Store) => store;

export const getOrganizationsList = createSelector(
  x,
  store => store.user.organizationsList,
);

export const getExternalOrgDomains = createSelector(x, store => {
  const orgs = store.user.organizationsList;
  const workspaces = store.workspace.workspaces.filter(workspace =>
    [WorkspaceUserStatus.Joined].includes(workspace.user_status),
  );
  const shares = Object.values(store.shared.shares);

  const externalWorkspaceDomains = new Set<string>();
  const externalWorkspaceDomainsIds = new Set<string>();

  for (const w of workspaces) {
    if (orgs.length === 0 || !orgs.some(org => org.domain === w.org_domain)) {
      const orgId = w.folder_name.split('-')[0];
      externalWorkspaceDomains.add(w.org_domain);
      externalWorkspaceDomainsIds.add(orgId);
    }
  }

  const externalShareDomains = new Set<string>();

  for (const share of shares) {
    if (
      (orgs.length === 0 || !orgs.some(org => org.id === share.parent_org)) &&
      !externalWorkspaceDomainsIds.has(share.parent_org)
    ) {
      externalShareDomains.add(share.parent_org);
    }
  }

  return {
    externalWorkspaceDomains: Array.from(externalWorkspaceDomains),
    externalShareDomains: Array.from(externalShareDomains),
  };
});

export const getSelectedOrganization = createSelector(
  x,
  store => store.user.selectedOrganization,
);
export const getUserFirstName = createSelector(
  x,
  store => store.user.user.first_name,
);
export const getUserLastName = createSelector(
  x,
  store => store.user.user.last_name,
);
export const getUserEmail = createSelector(
  x,
  store => store.user.user.email_address,
);
export const getUserProfilePic = createSelector(
  x,
  store => store.user.profilePic,
);
export const getToken = createSelector(x, store => store.user.token);
export const getUserDetails = createSelector(x, store => store.user.user);
// TODO we need to get all the organizations that the user has completed onboarded
// We need to change the organizationsList to have completed org info
export const getOnboardedOrganizations = createSelector(
  x,
  store => store.user.organizationsList,
);

export const getAvailableProfiles = createSelector(x, store => ({
  hasOrgs: store.user.hasOrgs,
  hasWorkspaces: store.user.hasWorkspaces,
  hasShares: store.user.hasShares,
}));

export const getMembersByOrganizationMap = createSelector(
  x,
  store => store.user.membersByOrganization,
);

export const getMembersByOrganizationString = createSelector(x, store =>
  JSON.stringify(store.user.membersByOrganization || {}),
);

export const getMembersByOrganization = (orgId: string) => {
  return createSelector(x, store => {
    if (!orgId) {
      return [];
    }

    const members = store.user.membersByOrganization[orgId];

    return members || [];
  });
};

export const getSSOData = createSelector(x, store => store.user.ssoData);

export const getUserPermissionByOrg = (userId: string, orgId: string) => {
  return createSelector(x, store => {
    const members = store.user.membersByOrganization[orgId];
    const member = members?.find(member => member.id === userId);
    return member?.permissions;
  });
};

export const getUserAllowedSettingsOrgs = createSelector(x, store => {
  const orgs = store.user.organizationsList;
  const membersByOrgMap = store.user.membersByOrganization;
  const userDetails = store.user.user;
  return orgs.filter(org => {
    const members = membersByOrgMap?.[org.id];
    const currentMember = members?.find(
      member => member.id === userDetails?.id,
    );
    return ORG_ADMIN_PERMISSIONS.includes(currentMember?.permissions);
  });
});
