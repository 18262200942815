import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import type {Citation} from 'fast-sdk/src/api/workspace/aichat/consts';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import Skeleton from 'interface/common/Skeleton';
import {useGetFileDetailsCached} from 'interface/common/hooks/useGetFileDetailsCached';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {FILE_ROW_THUMBNAIL_SIZE} from 'interface/stacks/workspace/storage/FileListItemRow';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {useMemo} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import AiChatCitationModal from './AiChatCitationModal';

const formatPages = (citation: Citation) => {
  const pages = Array.from(new Set(citation.entries.map(entry => entry.page)));
  if (pages.length === 1) {
    return `Page ${pages[0]}`;
  }

  if (pages.length <= 5) {
    return `Pages ${pages.join(', ')}`;
  }

  // For more than 5 items, show first 2, last 2, and ellipsis
  const firstTwo = pages.slice(0, 2);
  const lastTwo = pages.slice(-2);
  return `Pages ${firstTwo.join(', ')}, ... ${lastTwo.join(', ')}`;
};

export function CitationItem({citation}: {citation: Citation}) {
  const {namespace, instanceId} = useGetNamespaceContext();
  const {items} = useGetFileDetailsCached(
    [citation.nodeId],
    instanceId,
    namespace,
  );
  const item = items[0];

  const modal = useModal();
  const pages = formatPages(citation);

  const renderThumbnail = useMemo(() => {
    if (!item) {
      return null;
    }
    return (
      <FileThumbnail
        item={item}
        uploading={false}
        options={{
          previewResizeMode: 'contain',
          imageResizeMode: 'contain',
          imageWidth: FILE_ROW_THUMBNAIL_SIZE.width,
          imageHeight: FILE_ROW_THUMBNAIL_SIZE.height,
          containerStyle: FILE_ROW_THUMBNAIL_SIZE,
          imageContainerStyle: {
            backgroundColor: theme.colors.neutral.$50,
          },
        }}
        size={FileThumbnailSizes.Small}
      />
    );
  }, [item?.id]);

  const openCitationModal = () => {
    modal.open(
      <AiChatCitationModal
        item={item}
        citation={citation}
        onClose={modal.close}
      />,
    );
  };

  return (
    <View style={styles.citationItem}>
      {renderThumbnail}
      {item && (
        <Typography
          variant="medium"
          size="sm"
          color={theme.colors.neutral.$700}
          numberOfLines={1}
          overrides={styles.citationItemText}>
          {item.name}
        </Typography>
      )}
      {!item && (
        <Skeleton height={22} width={100} style={styles.citationItemText} />
      )}
      <Pressable style={styles.citationReference} onPress={openCitationModal}>
        <Typography
          variant="regular"
          size="xs"
          color={theme.colors.neutral.$700}>
          {pages}
        </Typography>
      </Pressable>
      <View style={styles.menuIcon}>
        <Icon
          name="lucide:ellipsis"
          size={16}
          color={theme.colors.neutral.$700}
          opacity={0.5}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  citationItem: {
    height: 40,
    paddingHorizontal: 8,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  citationItemText: {
    paddingHorizontal: 12,
    flex: 1,
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  citationReference: {
    height: 20,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 999,
    borderColor: 'rgba(34, 40, 53, 0.12)',
    borderWidth: 1,
    marginStart: 6,
    paddingHorizontal: 14,
    flexShrink: 0,
  },
  menuIcon: {
    padding: 4,
    marginStart: 6,
  },
  spacer: {
    flex: 1,
  },
  iconContainer: {
    padding: 4,
  },
});
