import theme from 'config/theme';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

type Props = {
  title?: string;
  onToggle: () => void;
  onExpand: () => void;
};

export function ColumnHeader({title, onToggle, onExpand}: Props) {
  const headerStyle = [
    styles.header,
    {height: 30, paddingHorizontal: title ? 12 : 8},
    // {paddingHorizontal: title ? 12 : 8, height: title ? 30 : 'auto'},
  ];

  const actionsStyle = {
    flexDirection: title ? 'row-reverse' : 'column',
    gap: title ? 8 : 12,
  } as const;

  return (
    <View style={headerStyle}>
      {title && (
        <Typography
          size="xs"
          color={theme.colors.neutral.$500}
          numberOfLines={1}>
          {title}
        </Typography>
      )}
      <View style={actionsStyle}>
        {/* <HoveredView onPress={onExpand}>
          <Icon
            name="lucide:maximize-2"
            size={16}
            color={theme.colors.neutral.$700}
          />
        </HoveredView> */}
        <HoveredView onPress={onToggle}>
          <Icon
            name={`lucide:panel-${title ? 'right' : 'left'}-close`}
            size={16}
            color={theme.colors.neutral.$700}
          />
        </HoveredView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    padding: 10,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 4,
    margin: 4,
    paddingHorizontal: 12,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
