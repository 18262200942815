import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import WorkspaceMetadata from '../layout/WorkspaceMetadata';

import * as files from 'store/slices/files';

export function RouteMetadata() {
  const skipClearFocus = useSelector(files.selectors.shouldSkipClearFocus());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(files.default.actions.focusParent({id: 'root'}));
    if (skipClearFocus) {
      dispatch(files.default.actions.skipClearFocus({skip: false}));
    } else {
      dispatch(files.default.actions.focus({id: ''}));
    }
  }, []);

  return <WorkspaceMetadata />;
}

export default RouteMetadata;
