import {t} from '@lingui/macro';
import theme from 'config/theme';
import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {Input} from 'interface/base/Input';
import {Textarea} from 'interface/base/Textarea';
import Typography from 'interface/base/Typography';
import {useFormSubmit} from 'interface/common/hooks/useFormSubmit';
import {useGetWorkspaceLogo} from 'interface/common/hooks/useGetWorkspaceLogo';
import {ProfileEditDetails} from 'interface/stacks/app/ProfileEditDetails';
import {useEffect, useMemo, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as workspaceSlice from 'store/slices/workspace';

interface WorkspaceSettingsDetailsProps {
  workspace: WorkspaceListDetail;
  close: () => void;
}

export function WorkspaceSettingsDetails({
  workspace,
  close,
}: WorkspaceSettingsDetailsProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {fetchWorkspaceLogo} = useGetWorkspaceLogo();

  const [name, setName] = useState(workspace.name);
  const [description, setDescription] = useState(
    workspace.description || undefined,
  );

  const updateWorkspace = async () => {
    try {
      if (!!description && description.length < 10) {
        toast.show(t`Description must be at least 10 characters`, {
          type: 'danger',
        });
        return;
      }

      setLoading(true);
      const {result} = await api.workspace.updateWorkspace(
        workspace.folder_name,
        {name, description},
      );
      if (result) {
        toast.show(t`Workspace details updated`, {type: 'info'});
        dispatch(
          workspaceSlice.default.actions.updateWorkspace({
            id: workspace.id,
            workspace: {...workspace, name, description},
          }),
        );
        close();
      }
    } finally {
      setLoading(false);
    }
  };

  const disableDoneButton =
    !name ||
    (name === workspace.name &&
      (!description || description === workspace.description));

  useFormSubmit(updateWorkspace, disableDoneButton || loading);

  useEffect(() => {
    const fetchLogo = async () => {
      await fetchWorkspaceLogo(workspace.id);
    };

    if (workspace.logo !== null) {
      fetchLogo();
    }
  }, [workspace.id]);

  const profileEditDetails = useMemo(() => {
    return (
      <ProfileEditDetails
        id={workspace.id}
        photo={workspace.logoImage}
        // color={workspace.accent_color?.color ?? undefined}
        size={5}
        type="workspace"
      />
    );
  }, [workspace.id, workspace.logoImage]);

  return (
    <View style={styles.root}>
      {profileEditDetails}
      <Typography
        size="xs"
        color={theme.colors.neutral.$700}
        overrides={styles.labelPaddingVertical}>
        {t`Workspace name`}
      </Typography>
      <Input
        overrides={{
          Root: {
            style: styles.inputRoot,
          },
        }}
        aria-label={t`Workspace name`}
        value={name}
        placeholder={workspace.name}
        onChangeValue={setName}
      />
      <Typography
        size="xs"
        color={theme.colors.neutral.$700}
        overrides={styles.label}>
        {t`Workspace description`}
      </Typography>
      <Textarea
        aria-label={t`Workspace description`}
        value={description}
        placeholder="Enter a description"
        onChangeValue={setDescription}
        maxLength={1000}
      />
      <View style={styles.buttonsContainer}>
        <Button variant="secondary" onPress={close}>
          {t`Cancel`}
        </Button>
        <Button
          overrides={{
            Loading: {
              style: {
                color: theme.colors.neutral.$white,
              },
            },
          }}
          variant="primary"
          disabled={disableDoneButton}
          onPress={updateWorkspace}
          loading={loading}>
          {t`Done`}
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {},
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
    marginTop: 8,
  },
  labelPaddingVertical: {
    marginBottom: 9,
    marginTop: 10,
  },
  label: {
    marginBottom: 6,
  },
  inputRoot: {
    marginBottom: 10,
  },
});
