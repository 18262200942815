import type {EventProfile} from 'fast-sdk/src/api/events/consts';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as eventsSlice from 'store/slices/events';
import {getProfileKey} from 'store/slices/events/helpers';
import {useFetchEvents} from './useFetchEvents';

const DEFAULT_LIMIT = 20;

export const useEventActivityTimeline = (
  profile: EventProfile,
  limit?: number,
) => {
  const dispatch = useDispatch();

  const profileState = useSelector(
    eventsSlice.selectors.getProfileState(profile),
  );
  const {events, loading, error, hasMore} = profileState;

  const {fetchEvents} = useFetchEvents();

  const profileKey = getProfileKey(profile);

  const loadMore = useCallback(
    async (forceLoad = false) => {
      if ((loading || !hasMore) && !forceLoad) return;

      await fetchEvents(profile, {
        ...profile,
        limit: limit ?? DEFAULT_LIMIT,
      });
    },
    [fetchEvents, profile, loading, hasMore, limit],
  );

  useEffect(() => {
    if (profileKey) {
      dispatch(eventsSlice.default.actions.resetProfile(profile));
      loadMore(true);
    }
  }, [profileKey]);

  return {
    events,
    loading,
    error,
    hasMore,
    loadMore,
  };
};
