import {useFlag} from '@unleash/proxy-client-react';
import {Fragment, useMemo, useState} from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import {useIntercom} from 'react-use-intercom';

// @ts-ignore
import svgIntercomLogo from 'assets/svgs/logos/Intercom.svg';
import theme from 'config/theme';
import {useParams} from 'extensions/navigation';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {Avatar} from 'interface/common/Avatar';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';

import useShowUpdateNotice from '../hooks/useShowUpdateNotice';
import {AccountDropdown} from '../layout/AccountDropdown';
import AppNewVersionNotice from '../layout/AppNewVersionNotice';

import {
  ROUTES,
  WORKSPACE_CHAT,
  WORKSPACE_METADATA,
  WORKSPACE_NOTES,
  WORKSPACE_ROOT,
  WORKSPACE_STORAGE,
} from 'constants/routes';
import Typography from 'interface/base/Typography';
import useHost from 'interface/common/hooks/useHost';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {AppTabs} from 'interface/stacks/profile-membership/types';
import {PUBLIC_SETTINGS_SECTIONS} from 'interface/stacks/settings/SettingsContent';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {useGetCurrentShareMap} from 'interface/stacks/workspace/hooks/useGetCurrentShareMap';
import * as app from 'store/slices/app';
import * as settings from 'store/slices/settings';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import {isSubdomainValid} from 'utils/common/platform';
import {HeaderOrgButton} from './HeaderOrgButton';
import {HeaderWorkspaceButton} from './HeaderWorkspaceButton';

const commonOverrides = {
  Button: {
    style: {
      width: 32,
      paddingVertical: 0,
    },
  },
};
const selectedButtonOverrides = {
  Button: {
    style: {
      ...commonOverrides.Button.style,
      backgroundColor: theme.colors.neutral.$175,
    },
  },
};

const backgroundStyle = (isActive: boolean) =>
  isActive ? selectedButtonOverrides : commonOverrides;

const getIcon = (name: string) => {
  return <Icon name={name} color={'#515662'} size={22} />;
};

export default function AppUserBar() {
  const intercom = useIntercom();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {subdomain} = useSubDomain();
  const {domain, protocol} = useHost();
  const hasInbox = useFlag('view-inbox');

  const {
    workspaceId,
    sharedCustomName,
    settingsSection: section,
  } = useParams<{
    workspaceId: string;
    sharedCustomName: string;
    settingsSection: string;
  }>();
  const {showUpdateAlert} = useShowUpdateNotice();
  const shareMap = useGetCurrentShareMap();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const organization = useSelector(user.selectors.getSelectedOrganization);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const profilePic = useSelector(user.selectors.getUserProfilePic);
  const orgLogo = useSelector(settings.selectors.getOrganizationLogo);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const workspaces = useSelector(workspace.selectors.getAllJoinedWorkspaces);
  const orgs = useSelector(user.selectors.getUserAllowedSettingsOrgs);

  const currentWorkspace = useMemo(() => {
    return workspaces.find(
      w => w.folder_name === (workspaceId ?? shareMap?.workspaceFolderName),
    );
  }, [workspaces, workspaceId, shareMap]);

  const toggleSearch = () => {
    dispatch(workspace.default.actions.toggleSearch());
  };

  const toggleIntercom = () => {
    intercom.isOpen ? intercom.hide() : intercom.show();
  };

  const openUserMenu = () => {
    intercom.hide();
    setIsUserMenuOpen(true);
  };

  const closeUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  const navigateToSettings = () => {
    if (isSubdomainValid(subdomain)) {
      const url = `${protocol}://${domain}/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.organization}/${subdomain}`;
      window.location.href = url.replace(subdomain, 'go');
      return;
    }
    const isAccountSettings = PUBLIC_SETTINGS_SECTIONS.includes(section);
    if (isAccountSettings) {
      const firstOrg = orgs?.[0];
      if (firstOrg) {
        navigate(
          `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.organization}/${firstOrg.domain}`,
        );
        return;
      }
    }

    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}`);
  };

  const navigateToHome = () => {
    const isInShared = window.location.pathname.includes(
      `/${ROUTES.LOGGED_IN_WITH_ORG.SHARED}/`,
    );
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${isInShared ? AppTabs.Shares : AppTabs.Workspaces}`,
    );
  };

  const navigateToGoDomain = () => {
    const url = `${protocol}://${domain}/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`;
    window.location.href = url.replace(subdomain, 'go');
  };

  const navigateToDataImport = () => {
    // TODO: Implement Data Import View and Navigation
  };

  const navigateToFiles = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${currentWorkspace?.folder_name}/${WORKSPACE_STORAGE}/${WORKSPACE_ROOT}`,
    );
  };

  const navigateToMetadata = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${currentWorkspace?.folder_name}/${WORKSPACE_METADATA}`,
    );
  };

  const navigateToAIChat = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${currentWorkspace?.folder_name}/${WORKSPACE_CHAT}`,
    );
  };

  const navigateToNotes = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${currentWorkspace?.folder_name}/${WORKSPACE_NOTES}`,
    );
  };

  const isOnGoDomain = subdomain === 'go';

  const isWorkspaceContext = Boolean(currentWorkspace);

  const isFilesActive =
    location.pathname.startsWith(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/`) &&
    location.pathname.includes(`/${WORKSPACE_STORAGE}/`);

  const isChatActive =
    location.pathname.startsWith(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/`) &&
    (location.pathname.endsWith(`/${WORKSPACE_CHAT}`) ||
      location.pathname.includes(`/${WORKSPACE_CHAT}/`));

  const isNotesActive =
    location.pathname.startsWith(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/`) &&
    (location.pathname.endsWith(`/${WORKSPACE_NOTES}`) ||
      location.pathname.includes(`/${WORKSPACE_NOTES}/`));

  const isHomeActive = location.pathname.includes(
    ROUTES.LOGGED_IN_WITHOUT_ORG.HOME,
  );
  const isSettingsActive = location.pathname.includes(
    ROUTES.LOGGED_IN_WITH_ORG.SETTINGS,
  );
  const isMetadataActive = location.pathname.endsWith(`/${WORKSPACE_METADATA}`);

  const userInitials = createInitials(
    `${userDetails?.first_name} ${userDetails?.last_name}`,
  );
  const userColor = userDetails?.color || getHashColor(userDetails?.id);

  return (
    <Fragment>
      <View style={styles.root}>
        <View style={styles.top}>
          {isOnGoDomain && isSettingsActive ? (
            <Button
              aria-label="Settings"
              variant="text"
              onPress={navigateToSettings}
              endEnhancer={getIcon('lucide:settings')}
              overrides={{
                Button: {
                  style: {
                    ...commonOverrides.Button.style,
                    backgroundColor: isSettingsActive
                      ? theme.colors.neutral.$175
                      : undefined,
                  },
                },
              }}
            />
          ) : isWorkspaceContext && currentWorkspace ? (
            <HeaderWorkspaceButton workspace={currentWorkspace} />
          ) : !isOnGoDomain ? (
            <HeaderOrgButton organization={organization} orgLogo={orgLogo} />
          ) : (
            <Button
              aria-label="Home"
              variant="text"
              onPress={navigateToHome}
              endEnhancer={getIcon('lucide:house')}
              overrides={backgroundStyle(isHomeActive)}
            />
          )}
          {(!isOnGoDomain || isSettingsActive) && (
            <Button
              aria-label="Go Home"
              variant="text"
              onPress={navigateToGoDomain}
              endEnhancer={getIcon('lucide:home')}
              overrides={commonOverrides}
            />
          )}
          {hasInbox && (
            <Button
              aria-label="Notifications"
              variant="text"
              onPress={() => {}}
              endEnhancer={getIcon('lucide:bell')}
              overrides={commonOverrides}
            />
          )}
          {isWorkspaceContext && <View style={styles.divider} />}
          {/* {isWorkspaceContext && (
            <Button
              aria-label="Data Import"
              variant="text"
              onPress={navigateToDataImport}
              endEnhancer={getIcon('lucide:import')}
              overrides={commonOverrides}
            />
          )} */}
          <Pressable
            style={{
              // @ts-ignore
              cursor: 'default',
            }}>
            {({hovered}) => (
              <Fragment>
                <Button
                  disabled={!workspaceId && !sharedCustomName}
                  aria-label="Search"
                  variant="text"
                  onPress={toggleSearch}
                  endEnhancer={getIcon('lucide:search')}
                  overrides={{
                    Button: {
                      style: {
                        ...commonOverrides.Button.style,
                        opacity: workspaceId || sharedCustomName ? 1 : 0.5,
                      },
                    },
                  }}
                />
                {hovered && (
                  <View style={styles.searchTooltip}>
                    <Typography size="xs" color={theme.colors.neutral.$white}>
                      Enter a Workspace or Share to search.
                    </Typography>
                  </View>
                )}
              </Fragment>
            )}
          </Pressable>
          {isWorkspaceContext && (
            <Button
              aria-label="Files"
              variant="text"
              onPress={navigateToFiles}
              endEnhancer={getIcon('lucide:folder-closed')}
              overrides={backgroundStyle(isFilesActive)}
            />
          )}
          {isWorkspaceContext && (
            <Button
              aria-label="Metadata"
              variant="text"
              onPress={navigateToMetadata}
              endEnhancer={getIcon('lucide:grid-3x3')}
              overrides={backgroundStyle(isMetadataActive)}
            />
          )}
          {isWorkspaceContext && <View style={styles.divider} />}
          {isWorkspaceContext && (
            <Button
              aria-label="AI Chat"
              variant="text"
              onPress={navigateToAIChat}
              endEnhancer={getIcon('lucide:messages-square')}
              overrides={backgroundStyle(isChatActive)}
            />
          )}
          {isWorkspaceContext && (
            <Button
              aria-label="Notes"
              variant="text"
              onPress={navigateToNotes}
              endEnhancer={getIcon('lucide:book-marked')}
              overrides={backgroundStyle(isNotesActive)}
            />
          )}
        </View>
        <View style={styles.bottom}>
          <Button
            aria-label="Intercom"
            variant="text"
            onPress={toggleIntercom}
            endEnhancer={
              <Image source={svgIntercomLogo} style={styles.intercomLogo} />
            }
            overrides={commonOverrides}
          />
          <Button
            aria-label="Settings"
            variant="text"
            onPress={navigateToSettings}
            endEnhancer={getIcon('lucide:settings')}
            overrides={{
              Button: {
                style: {
                  ...commonOverrides.Button.style,
                  backgroundColor: isSettingsActive
                    ? theme.colors.neutral.$175
                    : undefined,
                },
              },
            }}
          />
          {isLoggedIn && (
            <Popup
              isOpen={isUserMenuOpen}
              close={closeUserMenu}
              placement="right-start"
              offset={16}
              triggerElement={
                <Button
                  aria-label="Account"
                  variant="text"
                  onPress={openUserMenu}
                  overrides={commonOverrides}
                  endEnhancer={
                    <Avatar
                      color={userColor}
                      initials={userInitials}
                      photo={profilePic}
                      hideBorder
                      size={1}
                      shapeKind="rounded-square"
                      imageStyle={styles.avatar}
                      innerStyle={styles.avatar}
                    />
                  }
                />
              }>
              <AccountDropdown close={closeUserMenu} />
            </Popup>
          )}
        </View>
      </View>
      {showUpdateAlert && (
        <View style={styles.versionNotice}>
          <AppNewVersionNotice />
        </View>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  root: {
    zIndex: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 64,
    height: '100%',
    paddingBottom: 16,
    paddingHorizontal: 16,
    paddingTop: 8,
    backgroundColor: theme.colors.neutral.$100,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 6,
  },
  top: {
    gap: 12,
    paddingTop: 4,
  },
  bottom: {
    gap: 12,
  },
  versionNotice: {
    position: 'absolute',
    bottom: 10,
    left: 80,
    zIndex: 2,
    width: 240,
  },
  intercomLogo: {
    width: 20,
    height: 22,
  },
  searchTooltip: {
    position: 'absolute',
    zIndex: 3,
    top: -20,
    left: 40,
    width: 140,
    backgroundColor: theme.colors.neutral.$2Base,
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 10,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.neutral.$200,
  },
});
