import {i18n} from '@lingui/core';

export type Language = 'en' | 'es' | 'de' | 'ar' | 'pt' | 'id' | 'ar';

export async function loadLocale(locale = 'en') {
  try {
    const {messages} = await import(
      `../../assets/locales/${locale}/messages.po`
    );
    i18n.load(locale, messages);
    i18n.activate(locale);
  } catch (e) {}
}
