import {t} from '@lingui/macro';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {Organization} from 'store/slices/user/types';
import AddItemCard from './common/AddItemCard';
import ExternalOrgSection from './organization/ExternalOrgSection';
import OrganizationSection from './organization/OrganizationSection';

interface PageContentProps {
  externalShareDomains: string[];
  externalWorkspaceDomains: string[];
  organizations: Organization[];
  expiredOrgs: Organization[];
  isWorkspaces: boolean;
  isSharesLoading: boolean;
  onNewOrganizationPress: () => void;
  searchFilter: string;
}

const PageContent = ({
  organizations,
  expiredOrgs,
  externalShareDomains,
  externalWorkspaceDomains,
  isWorkspaces,
  isSharesLoading,
  onNewOrganizationPress,
  searchFilter,
}: PageContentProps) => {
  const showOrgsEmpty =
    organizations.length === 0 &&
    expiredOrgs.length === 0 &&
    externalWorkspaceDomains.length === 0 &&
    externalShareDomains.length === 0;
  return (
    <View style={styles.root}>
      {organizations.map(organization => (
        <OrganizationSection
          key={`org-section-${organization.id}`}
          organization={organization}
          isWorkspaces={isWorkspaces}
          isSharesLoading={isSharesLoading}
          searchFilter={searchFilter}
        />
      ))}
      {externalWorkspaceDomains.map(domain => (
        <ExternalOrgSection
          key={`org-section-${domain}`}
          orgDomain={domain}
          isWorkspaces={isWorkspaces}
          isSharesLoading={isSharesLoading}
          searchFilter={searchFilter}
        />
      ))}
      {!isWorkspaces &&
        externalShareDomains.map(domain => (
          <ExternalOrgSection
            key={`org-section-${domain}`}
            orgDomain={domain}
            isWorkspaces={isWorkspaces}
            isSharesLoading={isSharesLoading}
          />
        ))}
      {expiredOrgs.map(organization => (
        <OrganizationSection
          key={`org-section-${organization.id}`}
          organization={organization}
          isWorkspaces={isWorkspaces}
          isSharesLoading={isSharesLoading}
          searchFilter={searchFilter}
        />
      ))}
      {showOrgsEmpty && (
        <AddItemCard
          onPress={onNewOrganizationPress}
          label={t`You aren't a member of any Workspaces. Create an Org to get started.`}
          ariaLabel="Add New Organization"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    gap: 48,
  },
});

export default PageContent;
