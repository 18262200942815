import type {PayloadAction} from '@reduxjs/toolkit';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import type {Member} from '../settings/types';

export type Store = {
  workspacesLoaded: boolean;
  workspacesLoading: boolean;
  workspaces: Array<WorkspaceListDetail>;
  sort: WorkspaceSort;
  searchOpen: boolean;
  recentSearches: {
    [id: string]: Array<{
      query: string;
      timestamp: number;
    }>;
  };
  layoutTab: WorkspaceLayoutTabs;
  workspaceStatus: WorkspaceStatus;
  membersMap: {
    [workspaceId: string]: {
      members: Array<Member>;
      loading: boolean;
      error: boolean;
    };
  };
};

export type Actions = {
  SetWorkspacesLoaded: PayloadAction<boolean>;
  SetWorkspacesLoading: PayloadAction<boolean>;
  SetWorkspaces: PayloadAction<{
    workspaces: Array<WorkspaceListDetail>;
  }>;
  LeaveWorkspace: PayloadAction<WorkspaceListDetail>;
  ArchiveWorkspace: PayloadAction<WorkspaceListDetail>;
  UnarchiveWorkspace: PayloadAction<WorkspaceListDetail>;
  DeleteWorkspace: PayloadAction<WorkspaceListDetail>;
  UpdateWorkspace: PayloadAction<{
    id: string;
    workspace: WorkspaceListDetail;
  }>;
  Reset: PayloadAction;
  SetWorkspaceSort: PayloadAction<WorkspaceSort>;
  ToggleSearch: PayloadAction<boolean | undefined>;
  LogRecentSearch: PayloadAction<{
    id: string;
    query: string;
    timestamp: number;
  }>;
  SetWorkspaceLayoutTab: PayloadAction<WorkspaceLayoutTabs>;
  SetWorkspaceStatus: PayloadAction<WorkspaceStatus>;
  SetWorkspacePic: PayloadAction<{
    id: string;
    img: string;
  }>;
  SetLastAccessedWorkspace: PayloadAction<WorkspaceListDetail>;
  SetWorkspaceMembers: PayloadAction<{
    workspaceId: string;
    members?: Array<Member>;
    loading: boolean;
    error: boolean;
  }>;
  SyncWorkspaces: PayloadAction<Array<{id: string; folderName: string}>>;
};

export type WorkspaceSort = {
  order: 'asc' | 'desc';
  category: 'name';
};

export enum WorkspaceLayoutTabs {
  Grid = 'grid',
  List = 'list',
}

export enum WorkspaceStatus {
  All = 'all',
  Joined = 'joined',
  Unjoined = 'unjoined',
}
