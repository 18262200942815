export const DOLAR_CREDIT_FACTOR = 1 / 10_000;

export const METER_COLORS = {
  bandwidth: '#226CFF',
  storage: '#18AEFF',
  users: '#33E695',
  credits: '#FFC20C',
  tokens: '#FF9625',
  doc_pages_ingested: '#FE5561',
  video_seconds_ingested: '#8C64E9',
  audio_seconds_ingested: '#9996A3',
  images_ingested: '#525256',
};

export const METER_LABELS = {
  bandwidth: 'Bandwidth',
  storage: 'Storage',
  users: 'Users',
  credits: 'Credits',
  tokens: 'Tokens',
  doc_pages_ingested: 'Document Pages',
  video_seconds_ingested: 'Video Seconds',
  audio_seconds_ingested: 'Audio Seconds',
  images_ingested: 'Images',
};
