import {STORAGE_ROOT} from 'constants/routes';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import {formatFileId} from 'utils/fast/files';
import {getFileDetails} from 'utils/fast/storage';

// Promise cache outside the hook to be shared across all instances
const fileFetchPromiseCache: Record<string, Promise<any>> = {};

// Track failed file IDs to prevent retrying
const failedFileIds = new Set<string>();

export function useGetFileDetailsCached(
  fileIds: string[],
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const dispatch = useDispatch();
  const formattedIds = fileIds.map(id => formatFileId(id));
  const items = useSelector(files.selectors.getItemsByIds(formattedIds));

  // Use ref to track which files we've attempted to fetch in this component instance
  const attemptedFetchRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    let mounted = true;

    const fetchFile = async (id: string) => {
      // Skip if we already have the item, id is invalid, or previous attempts failed
      if (
        items.find(item => item.id === id) ||
        !id ||
        id === STORAGE_ROOT ||
        failedFileIds.has(id) ||
        attemptedFetchRef.current.has(id)
      ) {
        return;
      }

      // Mark this file as attempted for this component instance
      attemptedFetchRef.current.add(id);

      try {
        // If there's already a fetch in progress for this fileId, reuse that promise
        if (!fileFetchPromiseCache[id]) {
          fileFetchPromiseCache[id] = getFileDetails(id, instanceId, instanceNs)
            .then(({result, node}) => {
              if (result) {
                dispatch(
                  files.default.actions.updateFile({
                    file: node,
                    instanceId,
                    instanceNs,
                  }),
                );
                return node;
              }
              failedFileIds.add(id);
            })
            .catch(() => {
              failedFileIds.add(id);
            })
            .finally(() => {
              // Clean up the cache after the promise resolves or rejects
              delete fileFetchPromiseCache[id];
            });
        }

        // Wait for the cached promise to resolve
        await fileFetchPromiseCache[id];
      } catch (err) {
        // Add to failed list if anything goes wrong
        failedFileIds.add(id);
      }
    };

    formattedIds.forEach(id => {
      fetchFile(id);
    });

    return () => {
      mounted = false;
    };
  }, [formattedIds, items]);

  return {
    items,
  };
}
