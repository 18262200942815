import {WORKSPACE_TRASH} from 'constants/routes';
import {
  differenceInSeconds,
  formatDistanceToNow,
  isToday,
  isWithinInterval,
  isYesterday,
  subDays,
} from 'date-fns';
import {
  StorageItemType,
  type StorageNamespace,
} from 'fast-sdk/src/api/storage/consts';
import type {
  ChatMessage,
  FileFilter,
} from 'fast-sdk/src/api/workspace/aichat/consts';
import type {EntityChat} from 'store/slices/aichat/types';
import type {FilesItem} from 'store/slices/files/types';
import {nowServerDate, parseServerDate} from 'utils/common/dates';

interface ChatGroups {
  today: EntityChat[];
  yesterday: EntityChat[];
  last7: EntityChat[];
  last30: EntityChat[];
  older: EntityChat[];
}

export interface FilterInfo {
  type: 'file' | 'folder' | 'note';
  id: string;
  version?: string;
}

export function formatRelativeTime(date: Date) {
  const seconds = differenceInSeconds(new Date(), date);

  // If less than 60 seconds, return "now"
  if (seconds < 60) {
    return 'now';
  }

  // Use formatDistanceToNow for other cases
  const distance = formatDistanceToNow(date, {
    addSuffix: true, // adds "ago" at the end
    includeSeconds: false,
  });

  return distance;
}

export const getRecentFiles = (
  files: FilesItem[],
  instanceNs: StorageNamespace,
  instanceId: string,
  count = 5,
) =>
  Object.values(files)
    .filter(item => item.type === 'file' || item.type === 'note')
    .filter(item => item.parent !== WORKSPACE_TRASH)
    .filter(item => item.ai?.attach === true)
    .filter(item =>
      instanceNs === 'workspace'
        ? item.workspaceId === instanceId
        : item.shareId === instanceId,
    )
    .sort(
      (a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime(),
    )
    .slice(0, count);

export function clearMessage(text?: string): string {
  return text?.replace(/\n/g, ' ') ?? '';
}

export const GROUP_BY_DAY = {
  today: 'Today',
  yesterday: 'Yesterday',
  last7: 'Last 7 days',
  last30: 'Last 30 days',
  older: 'Older',
};

export function groupChatsByDay(chats: EntityChat[]): ChatGroups {
  const groups = chats.reduce(
    (groups, chat) => {
      const chatDate = parseServerDate(chat.createdAt);
      if (isToday(chatDate)) {
        groups.today.push(chat);
      } else if (isYesterday(chatDate)) {
        groups.yesterday.push(chat);
      } else if (
        isWithinInterval(chatDate, {
          start: subDays(new Date(), 7),
          end: new Date(),
        })
      ) {
        groups.last7.push(chat);
      } else if (
        isWithinInterval(chatDate, {
          start: subDays(new Date(), 30),
          end: new Date(),
        })
      ) {
        groups.last30.push(chat);
      } else {
        groups.older.push(chat);
      }
      return groups;
    },
    {
      today: [] as EntityChat[],
      yesterday: [] as EntityChat[],
      last7: [] as EntityChat[],
      last30: [] as EntityChat[],
      older: [] as EntityChat[],
    },
  );

  const sortByDate = (a: EntityChat, b: EntityChat) =>
    parseServerDate(b.createdAt).getTime() -
    parseServerDate(a.createdAt).getTime();

  return {
    today: groups.today.sort(sortByDate),
    yesterday: groups.yesterday.sort(sortByDate),
    last7: groups.last7.sort(sortByDate),
    last30: groups.last30.sort(sortByDate),
    older: groups.older.sort(sortByDate),
  };
}

export function getFilterInfoFromItems(files: FilesItem[]): FilterInfo[] {
  const mapTypes = {
    [StorageItemType.File]: 'file',
    [StorageItemType.Folder]: 'folder',
    [StorageItemType.Note]: 'file',
  };

  return files.map(file => ({
    type: mapTypes[file.type],
    id: file.id,
    version: file.version,
  }));
}

export function getFilterInfoFromFileFilter(
  files: FileFilter[] | undefined | null,
  type: 'file' | 'folder',
): FilterInfo[] {
  return (
    files?.map(file => ({
      type,
      id: file.node_id,
      version: file.version_id,
    })) ?? []
  );
}

function getFilterInfoFromString(
  fileStrings: string,
  type: 'file' | 'folder',
): FilterInfo[] {
  return fileStrings.split(',').map(fileString => {
    const [id, version] = fileString.split(':');
    return {
      type,
      id,
      version,
    };
  });
}

export function buildTempMessage(
  chatId: string,
  messageId: string,
  message: string,
  personality: string,
  userId: string,
  filesScope: string, // comma separated files
  foldersScope: string, // comma separated folders
  filesAttach: string, // comma separated files
) {
  const filesScopeArray = getFilterInfoFromString(filesScope, 'file');
  const foldersScopeArray = getFilterInfoFromString(foldersScope, 'folder');
  const filesAttachArray = getFilterInfoFromString(filesAttach, 'file');

  const newMessage: ChatMessage = {
    chat_id: chatId,
    message_id: messageId,
    creator: {
      id: userId,
      type: 'user',
    },
    message: {
      text: message,
      scope: {
        profile_scope: undefined,
        specific_files_scope: {
          files: filesScopeArray.map(file => ({
            node_id: file.id,
            version_id: file.version,
          })),
          folders: foldersScopeArray.map(folder => ({
            node_id: folder.id,
          })),
        },
      },
      attached: filesAttachArray.map(file => ({
        node_id: file.id,
        version_id: file.version,
      })),
    },
    state: 'ready',
    created: nowServerDate(),
    updated: nowServerDate(),
    personality,
  };
  return newMessage;
}

export const toBool = (value: string | boolean | undefined): boolean => {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') return value.toLowerCase() === 'true';
  return false;
};
