import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {
  WorkspaceLayoutTabs,
  WorkspaceStatus,
} from 'store/slices/workspace/types';
import {AppTabs} from '../../types';
import {SearchFilter} from '../content/common/SearchFilter';

interface Props {
  activeTab: AppTabs;
  setActiveTab: (value: AppTabs) => void;
  onNewOrganizationPress: () => void;
  isWorkspacesHidden: boolean;
  searchFilter: string;
  setSearchFilter: (value: string) => void;
  workspaces: WorkspaceListDetail[];
}

export default function Header({
  activeTab,
  setActiveTab,
  onNewOrganizationPress,
  isWorkspacesHidden,
  searchFilter,
  setSearchFilter,
  workspaces,
}: Props) {
  const dispatch = useDispatch();

  const appTabs = [
    isWorkspacesHidden
      ? undefined
      : createTabItem(
          AppTabs.Workspaces,
          'Workspaces',
          'lucide:app-window-mac',
          activeTab,
        ),
    createTabItem(AppTabs.Shares, 'Shares', 'lucide:package-2', activeTab),
  ].filter(Boolean);

  const activeLayoutTab = useSelector(
    workspace.selectors.getWorkspaceLayoutTab,
  );
  const activeWorkspaceStatus = useSelector(
    workspace.selectors.getWorkspaceStatus,
  );

  const layoutTabs = [
    createTabItem(
      WorkspaceLayoutTabs.Grid,
      '',
      'lucide:layout-grid',
      activeLayoutTab,
    ),
    createTabItem(
      WorkspaceLayoutTabs.List,
      '',
      'lucide:layout-list',
      activeLayoutTab,
    ),
  ];

  const workspaceStatusTabs = [
    createTabItem(
      WorkspaceStatus.All,
      'All Workspaces',
      null,
      activeWorkspaceStatus,
    ),
    createTabItem(
      WorkspaceStatus.Joined,
      'Joined',
      null,
      activeWorkspaceStatus,
    ),
    createTabItem(
      WorkspaceStatus.Unjoined,
      'Unjoined',
      null,
      activeWorkspaceStatus,
    ),
  ];

  const showFilters =
    activeTab === AppTabs.Workspaces && Boolean(workspaces?.length);

  return (
    <View style={styles.root}>
      <View style={styles.line}>
        <Tabs
          tabs={appTabs}
          activeTab={activeTab}
          setActiveTab={value => setActiveTab(value as AppTabs)}
          tabStyle={styles.mainAppTab}
        />
        <Button
          onPress={onNewOrganizationPress}
          variant="secondary"
          startEnhancer={
            <Icon name="lucide:plus" color={theme.colors.neutral.$black} />
          }>
          <Trans>New Org</Trans>
        </Button>
      </View>
      {showFilters && (
        <View style={styles.line}>
          <SearchFilter value={searchFilter} onChange={setSearchFilter} />
          <Tabs
            tabs={workspaceStatusTabs}
            activeTab={activeWorkspaceStatus}
            setActiveTab={value =>
              dispatch(
                workspace.default.actions.setWorkspaceStatus(
                  value as WorkspaceStatus,
                ),
              )
            }
            tabStyle={styles.workspaceStatusTab}
          />
          <Tabs
            tabs={layoutTabs}
            activeTab={activeLayoutTab}
            setActiveTab={value =>
              dispatch(
                workspace.default.actions.setWorkspaceLayoutTab(
                  value as WorkspaceLayoutTabs,
                ),
              )
            }
            tabStyle={styles.layoutTab}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    gap: 24,
    paddingBottom: 8,
  },
  line: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },
  layoutTab: {
    minWidth: 34,
    paddingHorizontal: 0,
    paddingVertical: 6,
  },
  workspaceStatusTab: {
    minWidth: 34,
    paddingHorizontal: 16,
    paddingVertical: 4,
  },
  mainAppTab: {
    paddingVertical: 4,
  },
});
