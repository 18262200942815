export const formatStringForUrl = (str: string) => {
  return (
    str
      .trim()
      .toLowerCase()
      // Replace special characters with hyphen
      .replace(/[^a-z0-9\s-]/g, '-')
      // Replace spaces with hyphen
      .replace(/\s+/g, '-')
      // Replace multiple consecutive hyphens with a single hyphen
      .replace(/-+/g, '-')
      // Remove leading and trailing hyphens
      .replace(/^-+|-+$/g, '')
  );
};

export const prependedWorkspaceName = (
  workspaceName: string,
  orgId: string,
) => {
  return formatStringForUrl(`${orgId}-${workspaceName}`);
};

export function cleanFileName(str) {
  // Only replace the characters that are definitely invalid in most file systems
  // These include: / \ : * ? " < > |
  const cleanStr = str
    .replace(/[/\\:*?"<>|]/g, '_') // Replace strictly invalid chars with underscore
    .trim() // Remove leading/trailing whitespace
    .replace(/^\.+/, '') // Remove leading dots (hidden files in Unix)
    .replace(/\.+$/, ''); // Remove trailing dots (problematic in Windows)

  return cleanStr || 'unnamed_file'; // Default name if result is empty
}
