import {ROUTES} from 'constants/routes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import type {
  Member,
  OrganizationDetails,
} from 'fast-sdk/src/api/organization/consts';
import {
  type InvitationDetails,
  InvitationStatuses,
  InvitationTypes,
} from 'fast-sdk/src/api/share/consts';
import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import {Loading} from 'interface/common/Loading';
import {useJoinInvitationHandler} from 'interface/common/hooks/useJoinInvitationHandler';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import {AppPublicLayout} from 'interface/stacks/app/layout/AppPublicLayout';
import type {
  OrganizationSummaryPending,
  PendingInvitationDetails,
} from 'interface/stacks/auth/consts';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import ShareHiddenLayout from 'interface/stacks/share/layout/ShareHiddenLayout';
import type React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {InvalidToken} from '../components/InvalidToken';
import {InvitationUserNotMatch} from '../components/InvitationUserNotMatch';
import {OrganizationInvite} from '../components/OrganizationInvite';
import {SharedInvite} from '../components/SharedInvite';
import {WorkspaceInvite} from '../components/WorkspaceInvite';

const INVITATION_TITLE = {
  [InvitationTypes.Organization]: 'Organization',
  [InvitationTypes.Workspace]: 'Workspace',
  [InvitationTypes.Shared]: 'Shared',
};

export function JoinEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const userProfilePic = useSelector(user.selectors.getUserProfilePic);

  const [invitation, setInvitation] = useState<
    PendingInvitationDetails | undefined
  >(undefined);
  const [organization, setOrganization] = useState<
    OrganizationSummaryPending | undefined
  >(undefined);
  const [workspace, setWorkspace] = useState<WorkspaceListDetail | undefined>(
    undefined,
  );

  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isJoinWithCurrentAccount, setIsJoinWithCurrentAccount] =
    useState<boolean>(false);

  const {handleInvitationAcceptance, isLoading: loadingAcceptance} =
    useJoinInvitationHandler(isLoggedIn);
  const {logout} = useLogout();
  const {generateShareUrlWithSubdomain} = useGenerateShareUrl();

  const fetchOrganization = async (
    invitation: InvitationDetails,
    orgFromInvitation?: OrganizationDetails,
  ) => {
    let orgData: OrganizationDetails;

    if (invitation.org || orgFromInvitation) {
      orgData = invitation.org || orgFromInvitation;
    } else if (invitation.workspace) {
      if (!orgFromInvitation) {
        const {org} = await api.organization.getOrganizationPublicDetails(
          invitation.workspace.org_domain,
        );
        orgData = org;
      } else {
        orgData = orgFromInvitation;
      }
    }

    if (orgData)
      setOrganization({
        ...orgData,
        invitedBy: {email: invitation.inviter},
      });
    return orgData;
  };

  const fetchWorkspace = async (
    invitation: InvitationDetails,
    orgFromInvitation?: OrganizationDetails,
  ) => {
    if (invitation.workspace) {
      setWorkspace({
        ...invitation.workspace,
        user_status: WorkspaceUserStatus.Available,
      });
    }

    return await fetchOrganization(invitation, orgFromInvitation);
  };

  const fetchInvitationEntity = async (
    invitation: InvitationDetails,
    orgFromInvitation?: OrganizationDetails,
  ) => {
    let org: OrganizationDetails;
    const members: Member[] = [];
    if (invitation.entity_type === InvitationTypes.Organization)
      org = await fetchOrganization(invitation, orgFromInvitation);
    else if (invitation.entity_type === InvitationTypes.Workspace)
      org = await fetchWorkspace(invitation, orgFromInvitation);
    else if (invitation.entity_type === InvitationTypes.Shared) {
      org = orgFromInvitation;
    }
    return {org, members};
  };

  const fetchInvitation = async (invitationToken: string) => {
    try {
      if (!invitationToken)
        throw new Error('JoinEmail - No invitation token provided');
      const {
        result,
        invitation,
        org: orgFromInvitation,
      } = await api.user.getInvitationDetails(invitationToken);
      if (result) {
        if (invitation.state === InvitationStatuses.Pending) {
          const {org, members} = await fetchInvitationEntity(
            invitation,
            orgFromInvitation,
          );
          const invitationWithToken = {
            ...invitation,
            invitationToken: invitationToken,
            org,
            members,
          };
          setInvitation(invitationWithToken);
          dispatch(
            app.default.actions.setCurrentJoinInvitation({
              joinInvitation: invitationWithToken,
            }),
          );
        } else {
          reportAndLogError(
            new Error(
              `Join Email - Invitation not longer valid for ${JSON.stringify(invitation)}`,
            ),
          );
        }
      }
    } catch (error) {
      reportAndLogError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const reportAndLogError = async (error: Error) => {
    dispatch(app.default.actions.clearCurrentJoinInvitation());
    setError(error);
    handleAppError({
      appError: AppErrors.JoinEmailPageLoadError,
      exception: error,
    });
  };

  const saveCurrentInvitation = (hasClickedOnJoined: boolean) => {
    dispatch(
      app.default.actions.setCurrentJoinInvitation({
        joinInvitation: {
          ...invitation,
          hasClickedOnJoined,
        },
      }),
    );
  };

  const onJoinButtonPress = async () => {
    if (isLoggedIn) {
      await handleInvitationAcceptance({invitation});
    } else {
      saveCurrentInvitation(true);
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNUP}`);
    }
  };

  const handleJoinWithCurrentAccount = () => {
    setIsJoinWithCurrentAccount(true);
  };

  const handleJoinWithDifferentAccount = async () => {
    await logout({
      callback: () => {
        saveCurrentInvitation(false);
      },
      navigateTo: NavigateTo.SIGNUP,
    });
  };

  const setCurrentPage = (invitationType: string) => {
    const pagePrefix = 'Join';
    dispatch(
      app.default.actions.setCurrentPage({
        pageName: invitationType
          ? `${pagePrefix} ${INVITATION_TITLE[invitationType]}`
          : `${pagePrefix} Invitation`,
      }),
    );
  };

  const onInvalidNavigate = () => {
    if (!isLoggedIn) {
      return navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }

    return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
  };

  useEffect(() => {
    setCurrentPage(invitation?.entity_type);
  }, [invitation]);

  useEffect(() => {
    fetchInvitation(searchParams.get('token'));
  }, []);

  const renderWithLayout = (content: React.ReactNode) => (
    <AppPublicLayout contentStyle={{justifyContent: 'space-between'}}>
      {content}
    </AppPublicLayout>
  );

  const handleShareSuccess = async () => {
    await handleInvitationAcceptance({
      invitation,
      disableLoading: true,
      onSuccess: () => {
        requestAnimationFrame(() => {
          window.location.href = generateShareUrlWithSubdomain(
            invitation.share,
            invitation.org,
          );
        });
      },
    });
  };

  const getRedirectOnSuccess = () => {
    return generateShareUrlWithSubdomain(invitation.share, invitation.org);
  };

  const renderContent = useCallback(() => {
    if (isLoading) return renderWithLayout(<Loading centered />);

    const isInvalidInvitationToken =
      error || !invitation || invitation?.state !== InvitationStatuses.Pending;
    if (isInvalidInvitationToken)
      return renderWithLayout(
        <InvalidToken onInvalidNavigate={onInvalidNavigate} />,
      );

    const userDoesntMatch =
      userDetails.id &&
      userDetails.email_address.toLowerCase() !==
        invitation.invitee_email.toLowerCase();

    if (userDoesntMatch && !isJoinWithCurrentAccount)
      return renderWithLayout(
        <InvitationUserNotMatch
          userDetails={userDetails}
          userProfilePic={userProfilePic}
          onJoinWithCurrentAccount={handleJoinWithCurrentAccount}
          onJoinWithDifferentAccount={handleJoinWithDifferentAccount}
        />,
      );

    switch (invitation.entity_type) {
      case InvitationTypes.Organization:
        return (
          organization &&
          renderWithLayout(
            <OrganizationInvite
              loading={loadingAcceptance}
              userDetails={userDetails}
              userProfilePic={userProfilePic}
              invitation={invitation}
              organization={organization}
              onJoinButtonPress={onJoinButtonPress}
            />,
          )
        );
      case InvitationTypes.Workspace:
        return (
          workspace &&
          organization &&
          renderWithLayout(
            <WorkspaceInvite
              loading={loadingAcceptance}
              userDetails={userDetails}
              userProfilePic={userProfilePic}
              invitation={invitation}
              workspace={workspace}
              organization={organization}
              onJoinButtonPress={onJoinButtonPress}
            />,
          )
        );
      case InvitationTypes.Shared:
        return isLoggedIn ? (
          renderWithLayout(
            <SharedInvite
              loading={loadingAcceptance}
              invitation={invitation}
              userDetails={userDetails}
              userProfilePic={userProfilePic}
              onJoinButtonPress={onJoinButtonPress}
            />,
          )
        ) : (
          <ShareHiddenLayout
            sharedCustomName={invitation.share.custom_name}
            shareDetails={invitation.share}
            onSuccess={handleShareSuccess}
            getRedirectOnSuccess={getRedirectOnSuccess}
          />
        );
      default:
        reportAndLogError(
          new Error(
            `JoinEmail - Invalid invitation type found for ${JSON.stringify(invitation)}`,
          ),
        );
    }
  }, [
    isLoading,
    invitation?.id,
    loadingAcceptance,
    isJoinWithCurrentAccount,
    organization?.id,
    workspace?.id,
  ]);

  return renderContent();
}
