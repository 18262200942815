import theme from 'config/theme';
import Footer from 'interface/stacks/app/Footer';
import LogoHeader from 'interface/stacks/onboard/base/LogoHeader';
import {
  StyleSheet,
  View,
  type ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import AppHeaderAccountDropdown from './AppHeaderAccountDropdown';

type Props = {
  showHeaderAndFooter?: boolean;
  children: React.ReactNode;
  contentStyle?: ViewStyle;
};

export function AppPublicLayout({
  children,
  contentStyle,
  showHeaderAndFooter = true,
}: Props) {
  const screen = useWindowDimensions();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const isSmallScreen = screen.width < 790;

  const classes = {
    root: [
      styles.root,
      isSmallScreen && styles.rootSmall,
      {backgroundColor: theme.colors.neutral.$white},
    ],
    content: [styles.content, isSmallScreen && styles.content],
  };

  return (
    <View style={classes.root}>
      {showHeaderAndFooter && (
        <View style={styles.header}>
          <LogoHeader />
          {isLoggedIn && <AppHeaderAccountDropdown />}
        </View>
      )}
      <View style={[classes.content, contentStyle]}>
        {children}
        {showHeaderAndFooter && <Footer />}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  rootSmall: {
    flexDirection: 'column',
  },
  logo: {
    width: 120,
    height: 24,
    margin: 0,
    zIndex: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: 15,
    zIndex: 10,
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'calc(100%-200px)',
    width: '100%',
    flexGrow: 1,
  },
  contentSmall: {
    width: '100%',
    paddingLeft: 0,
  },
});
