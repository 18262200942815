import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button, SIZE} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';
import {AppPublicLayout} from '../layout/AppPublicLayout';

export default function AppNotAvailable() {
  const handleTryAgain = () => {
    window.location.reload();
  };

  return (
    <AppPublicLayout>
      <View style={styles.root}>
        <View style={styles.card}>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Icon
                name="lucide:info"
                size={18}
                color={theme.colors.neutral.$700}
                opacity={0.5}
              />
              <Typography color={theme.colors.neutral.$500}>
                {t`Service Temporarily Unavailable.`}
              </Typography>
            </View>
          </View>
          <View style={styles.content}>
            <View style={styles.signInContainer}>
              <View style={styles.signInTextContainer}>
                <Icon name="lucide:cloud-alert" size={36} />
                <Typography size={'2xl'} color={theme.colors.neutral.$700}>
                  {t`We'll be back shortly.`}
                </Typography>
              </View>
              <Typography
                color={theme.colors.neutral.$700}
                overrides={{textAlign: 'center'}}>
                {t`The application is currently under maintenance. Our team is working to restore service as quickly as possible.`}
              </Typography>
            </View>
            <Button
              size={SIZE.lg}
              onPress={handleTryAgain}
              startEnhancer={
                <Icon
                  name="lucide:refresh-cw"
                  size={18}
                  color={theme.colors.neutral.$white}
                />
              }>
              {t`Try again`}
            </Button>
          </View>
        </View>
      </View>
    </AppPublicLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    flexDirection: 'column',
    borderRadius: 16,
    width: '100%',
    maxWidth: 500,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    marginBottom: 20,
  },
  header: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundImage: 'linear-gradient(90deg, #F0F6FF 0%, #EBEBFF 100%)',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 16,
    paddingHorizontal: 8,
    zIndex: -1,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  signInContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
    marginBottom: 32,
  },
  signInTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
  },
  content: {
    padding: 32,
  },
  logo: {
    width: 40,
    height: 40,
    marginRight: 8,
  },
});
