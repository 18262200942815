import {api} from 'fast-sdk';
import useDebounce from 'interface/common/hooks/useDebounce';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect, useState} from 'react';
import type {ViewStyle} from 'react-native';
import {StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';
import {validateEmail} from 'utils/common/validation';
import {KeyboardListener} from '../../KeyboardListener';
import {AddEmailDropdown, type UserInfo} from './AddEmailDropdown';
import {AddEmailErrorCounter} from './AddEmailErrorCounter';
import {AddEmailInputBox} from './AddEmailInputBox';

const DEBOUNCE_FETCH_TIME = 300;

interface AddEmailTextareaProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
  placeholder?: string;
  subdomain?: string;
  customRootStyle?: ViewStyle;
  public?: boolean;
}

const buildInviteEmails = (inputValue: string, emailDomains: string[]) => {
  if (inputValue.includes('@')) {
    return [
      {
        email: inputValue,
        name: inputValue,
        isInvite: true,
      },
    ];
  }

  return (
    emailDomains
      // We're getting 'null' from the API response, so we need to filter it out
      .filter(domain => Boolean(domain) && domain !== 'null')
      .map(domain => {
        const fullEmail = `${inputValue}@${domain}`;
        return {
          email: fullEmail,
          name: inputValue,
          isInvite: true,
        };
      })
  );
};

export default function AddEmailTextarea(props: AddEmailTextareaProps) {
  const {subdomain} = useSubDomain();
  const orgs = useSelector(user.selectors.getOrganizationsList);

  const [inputValue, setInputValue] = useState('');
  const [inputHeight, setInputHeight] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<UserInfo[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const [emailDomains, setEmailDomains] = useState<string[]>([]);

  const emailsWithInvite = [
    ...(searchResults ?? []),
    ...buildInviteEmails(inputValue, emailDomains),
  ];

  const userBelongsToOrg = orgs.some(org => org.domain === props.subdomain);

  const onFetchUsers = async (query: string) => {
    const {contacts} = await api.users.search({
      search: query,
    });
    setSearchResults(
      Object.entries(contacts).map(([email, name]) => ({email, name})),
    );
  };
  const onFetchUsersDebounced = useDebounce(
    (query: string) => onFetchUsers(query),
    DEBOUNCE_FETCH_TIME,
  );

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
        if (focusedIndex >= 0) {
          e.preventDefault();
          addChip(emailsWithInvite[focusedIndex].email);
          break;
        }
      case 'Tab':
      case ' ':
      case ',':
        if (inputValue.trim()) {
          e.preventDefault();
          addChip(inputValue.trim());
        }
        break;
      case 'Backspace':
        if (inputValue.length === 0 && !isEditing) {
          removeLastChip();
        }
        break;
      case 'Escape':
        clear();
        break;
      case 'ArrowDown':
        moveFocusDown();
        break;
      case 'ArrowUp':
        moveFocusUp();
        break;
    }
  };

  const moveFocusDown = () => {
    const maxIndex = emailsWithInvite.length - 1;
    setFocusedIndex(prev => Math.min(prev + 1, maxIndex));
  };

  const moveFocusUp = () => {
    setFocusedIndex(prev => Math.max(prev - 1, -1));
  };

  const emailsWithErrors = props.emails.filter(
    email => !validateEmail(email),
  ).length;

  const clear = () => {
    setInputValue('');
    setSearchResults([]);
    setFocusedIndex(-1);
  };

  const addChip = (email: string) => {
    props.setEmails(props.emails.concat([email]));
    clear();
  };

  const removeChip = (index: number) => {
    const newEmails = [...props.emails];
    newEmails.splice(index, 1);
    props.setEmails(newEmails);
  };

  const removeLastChip = () => {
    const newEmails = props.emails.slice(0, -1);
    props.setEmails(newEmails);
  };

  const removeAllChipsWithErrors = () => {
    const newEmails = props.emails.filter(email => validateEmail(email));
    props.setEmails(newEmails);
  };

  const updateChip = (index: number, email: string) => {
    const newEmails = [...props.emails];
    newEmails[index] = email;
    props.setEmails(newEmails);
  };

  useEffect(() => {
    if (inputValue) {
      onFetchUsersDebounced(inputValue);
    } else {
      setSearchResults([]);
    }
    setFocusedIndex(-1);
  }, [inputValue]);

  useEffect(() => {
    const getOrg = async () => {
      const emailSubdomain = props.subdomain ?? subdomain;
      if (isSubdomainValid(emailSubdomain)) {
        const endpoint = userBelongsToOrg
          ? api.organization.getOrganizationDetails
          : api.organization.getOrganizationPublicDetails;
        const {org} = await endpoint(emailSubdomain);
        const domains = org?.perm_auth_domains ?? `${emailSubdomain}.com`;
        setEmailDomains(
          !domains ? [] : domains.split(',').map(domain => domain.trim()),
        );
      }
    };
    getOrg();
  }, [props.subdomain, userBelongsToOrg]);

  return (
    <KeyboardListener
      onKeyPress={handleKeyDown}
      customStyle={[styles.container, props.customRootStyle]}>
      <AddEmailInputBox
        inputValue={inputValue}
        placeholder={props.placeholder}
        emails={props.emails}
        onSetInputValue={setInputValue}
        onUpdateInputHeight={setInputHeight}
        setIsEditing={setIsEditing}
        onUpdateEmail={updateChip}
        onRemoveEmail={removeChip}
        onRemoveLastEmail={removeLastChip}
        onAddEmail={addChip}
      />
      {inputValue && (
        <AddEmailDropdown
          items={emailsWithInvite}
          searchTerm={inputValue}
          offsetVertical={inputHeight + 5}
          onOptionSelected={addChip}
          focusedIndex={focusedIndex}
        />
      )}
      <AddEmailErrorCounter
        counter={emailsWithErrors}
        onRemoveAll={removeAllChipsWithErrors}
      />
    </KeyboardListener>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    paddingBottom: 10,
    flexDirection: 'column',
    zIndex: 1,
  },
});
