import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {useOrgLogo} from 'interface/base/OrgLogo';
import {OrgLogoSize} from 'interface/base/OrgLogo/types';

export function useGetAuthHeaderLogo(organization: OrganizationDetails) {
  const {logo} = useOrgLogo({
    orgId: organization?.id,
    orgDetails: organization,
    showBorder: false,
    size: OrgLogoSize.ExtraLarge,
  });

  return {headerLogo: logo};
}
