import theme from 'config/theme';
import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import Typography from 'interface/base/Typography';
import {useWorkspaceGrid} from 'interface/stacks/profile-membership/hooks/useWorkspaceGrid';
import React, {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {
  WorkspaceLayoutTabs,
  WorkspaceStatus,
} from 'store/slices/workspace/types';
import {parseServerDate} from 'utils/common/dates';
import WorkspacesCardsSkeleton, {
  WorkspaceCardSkeleton,
} from '../../skeleton/WorkspacesCardsSkeleton';
import WorkspaceCard from './WorkspaceCard';

interface WorkspacesCardsProps {
  workspaces: WorkspaceListDetail[];
  isMembersLoading?: boolean;
  onWorkspacePress: (workspace: WorkspaceListDetail) => void;
  isReloadingWorkspaces?: boolean;
  searchFilter: string;
}

const WorkspacesCards = ({
  workspaces,
  isMembersLoading = false,
  onWorkspacePress,
  isReloadingWorkspaces = false,
  searchFilter,
}: WorkspacesCardsProps) => {
  const gridStyle = useWorkspaceGrid();

  const workspacesLoading = useSelector(
    workspace.selectors.getWorkspacesLoading,
  );
  const workspacesLoaded = useSelector(workspace.selectors.getWorkspacesLoaded);
  const activeWorkspaceStatus = useSelector(
    workspace.selectors.getWorkspaceStatus,
  );
  const activeLayoutTab = useSelector(
    workspace.selectors.getWorkspaceLayoutTab,
  );

  const showCardsSkeleton =
    (workspacesLoading && !workspaces?.length) || !workspacesLoaded;

  const filteredWorkspaces = workspaces.filter(workspace =>
    workspace.name.toLowerCase().includes(searchFilter.toLowerCase()),
  );

  const joinedWorkspaces = filteredWorkspaces
    .filter(workspace => workspace.user_status === WorkspaceUserStatus.Joined)
    .sort((a, b) => {
      const aUpdated = parseServerDate(a.updated);
      const bUpdated = parseServerDate(b.updated);
      if (aUpdated && bUpdated) {
        return bUpdated.getTime() - aUpdated.getTime();
      }
      return 0;
    });

  const unjoinedWorkspaces = filteredWorkspaces.filter(
    workspace => workspace.user_status === WorkspaceUserStatus.Available,
  );

  const showFirstCardSkeleton =
    isReloadingWorkspaces || (workspaces?.length === 0 && isMembersLoading);

  const containerStyle =
    activeLayoutTab === WorkspaceLayoutTabs.Grid ? gridStyle : styles.list;

  const joinedWorkspacesList = useMemo(() => {
    return (
      (activeWorkspaceStatus === WorkspaceStatus.Joined ||
        activeWorkspaceStatus === WorkspaceStatus.All) &&
      joinedWorkspaces.length > 0 && (
        <View style={styles.workspacesContainer}>
          <Typography
            variant="medium"
            size="md"
            color={theme.colors.neutral.$400}>
            Joined Workspaces
          </Typography>
          <View style={containerStyle}>
            {showFirstCardSkeleton && <WorkspaceCardSkeleton />}
            {joinedWorkspaces.map(workspace => (
              <WorkspaceCard
                key={`workspace-card-${workspace.id}`}
                workspaceId={workspace.id}
                onWorkspacePress={onWorkspacePress}
                layoutTab={activeLayoutTab}
              />
            ))}
          </View>
        </View>
      )
    );
  }, [
    activeWorkspaceStatus,
    joinedWorkspaces,
    showFirstCardSkeleton,
    activeLayoutTab,
    containerStyle,
  ]);

  const unjoinedWorkspacesList = useMemo(() => {
    return (
      (activeWorkspaceStatus === WorkspaceStatus.Unjoined ||
        activeWorkspaceStatus === WorkspaceStatus.All) &&
      unjoinedWorkspaces.length > 0 && (
        <View style={styles.workspacesContainer}>
          <Typography
            variant="medium"
            size="md"
            color={theme.colors.neutral.$400}>
            Unjoined Workspaces
          </Typography>
          <View style={containerStyle}>
            {unjoinedWorkspaces.map(workspace => (
              <WorkspaceCard
                key={`workspace-card-${workspace.id}`}
                workspaceId={workspace.id}
                onWorkspacePress={onWorkspacePress}
                layoutTab={activeLayoutTab}
              />
            ))}
          </View>
        </View>
      )
    );
  }, [
    activeWorkspaceStatus,
    unjoinedWorkspaces,
    activeLayoutTab,
    containerStyle,
  ]);

  if (showCardsSkeleton) {
    return <WorkspacesCardsSkeleton style={gridStyle} />;
  }

  return (
    <View style={styles.root}>
      {joinedWorkspacesList}
      {unjoinedWorkspacesList}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
  },
  list: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
  },
  workspacesContainer: {
    flexDirection: 'column',
    gap: 16,
    paddingTop: 24,
  },
});

export default WorkspacesCards;
