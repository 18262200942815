import {ROUTES, UNAUTHENTICATED_ROUTES} from 'constants/routes';
import {
  ROUTES_EXCLUDED_FROM_ORGANIZATION_LOADING,
  ROUTES_EXEMPT_FROM_USER_DETAILS_VALIDATION,
  SESSION_COOKIE_KEY,
  SESSION_VALIDATION_EXEMPT_ROUTES,
} from 'constants/sessionRoutes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {Requests, api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import type React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {getCookie} from 'utils/common/cookies';
import {getCurrentPath} from 'utils/common/platform';
import {getEnvironmentDomain} from 'utils/common/version';
import Auth from '../../interface/stacks/auth/consts';
import useRefreshCookieSession from './hooks/useRefreshCookieSession';
import type {ISession} from './types';
import {spreadVeAuthCookie} from './utils';

interface SessionProviderProps {
  children: React.ReactNode;
}

const SessionProvider = ({children}: SessionProviderProps) => {
  const dispatch = useDispatch();

  const {logout} = useLogout();

  const [hasSessionValidated, setHasSessionValidated] = useState(false);

  const cookie = getCookie(SESSION_COOKIE_KEY);

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);

  const addEnvironmentDomain = async () => {
    const environmentDomain = await getEnvironmentDomain();
    dispatch(app.default.actions.setDomainOrigin({origin: environmentDomain}));
  };

  const refreshUserDetails = async (currentPath: string) => {
    const shouldLoadUserDetails =
      !ROUTES_EXEMPT_FROM_USER_DETAILS_VALIDATION.includes(currentPath);

    if (shouldLoadUserDetails) {
      const {result, user: userDetails} = await api.user.userDetails();
      if (result) {
        dispatch(user.default.actions.setUserDetails(userDetails));
      }
    }
  };

  const refreshOrganizationList = async (currentPath: string) => {
    const shouldLoadOrganizations =
      !ROUTES_EXCLUDED_FROM_ORGANIZATION_LOADING.includes(currentPath);

    if (shouldLoadOrganizations) {
      const {result, orgs} =
        await api.organization.getListOfAccessibleOrganizations();
      if (result) {
        dispatch(user.default.actions.setOrganizationsList({orgsList: orgs}));
      }
    }
  };

  const setLoadedApp = () => {
    dispatch(app.default.actions.load({loaded: true}));
  };

  const handleOfflineApp = () => {
    dispatch(app.default.actions.setIsOffline(true));
  };

  const handleLogout = async (
    navigateTo?: NavigateTo,
    noClearSession?: boolean,
  ) => {
    await logout({showLoader: false, navigateTo, noClearSession});
  };

  const validateAndRefreshUserSession = async () => {
    const currentPath = getCurrentPath();

    try {
      const shouldSkipSessionValidation =
        currentPath &&
        (SESSION_VALIDATION_EXEMPT_ROUTES.includes(currentPath) ||
          ROUTES.LOGGED_IN_WITHOUT_ORG.VERIFY_EMAIL.includes(currentPath));

      if (shouldSkipSessionValidation) {
        return;
      }

      const {result: isValidSession, error} = await api.auth.checkSession();

      if (isValidSession) {
        await Promise.all([
          refreshUserDetails(currentPath),
          refreshOrganizationList(currentPath),
        ]);
      } else {
        if (error.code === ApiErrors.TokenInvalid) {
          await handleLogout();
        } else if (error.code === ApiErrors.UnexpectedError) {
          handleOfflineApp();
        }
      }
    } catch (err) {
      handleAppError({
        appError: AppErrors.LoadUserSessionDataError,
        exception: err,
      });
    } finally {
      setLoadedApp();
    }
  };

  const refreshSession = async (session: ISession) => {
    Requests.setAuthToken(session.token);
    await Auth.setAuthToken(session.token);
    dispatch(user.default.actions.setUserToken(session.token));
    await validateAndRefreshUserSession();
  };

  const isInAuthenticatedRoute = () => {
    const path = getCurrentPath();
    return UNAUTHENTICATED_ROUTES.includes(path);
  };

  const validateSession = async () => {
    try {
      const session: ISession = cookie ? JSON.parse(cookie) : undefined;
      if (session && !isLoggedIn) {
        await refreshSession(session);
      } else if (!session && isLoggedIn) {
        await handleLogout(
          isInAuthenticatedRoute() ? NavigateTo.NONE : undefined,
        );
      } else if (session && isLoggedIn) {
        if (userDetails.id && userDetails.id !== session.userId) {
          await handleLogout(NavigateTo.NONE, true);
          await refreshSession(session);
        } else {
          refreshSession(session);
        }
      }
    } finally {
      setHasSessionValidated(true);
      setLoadedApp();
    }
  };

  useEffect(() => {
    validateSession();
    addEnvironmentDomain();
    spreadVeAuthCookie();
  }, []);

  useRefreshCookieSession();

  if (hasSessionValidated) {
    return <>{children}</>;
  }

  return <AppLoading />;
};

export default SessionProvider;
