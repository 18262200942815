import theme from 'config/theme';
import type {EventActivity} from 'fast-sdk/src/api/events/consts';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import Skeleton from 'interface/common/Skeleton';
import {useGetUserDetailsCached} from 'interface/common/hooks/useGetUserDetailsCached';
import {StyleSheet, Text, View} from 'react-native';
import {parseServerDate} from 'utils/common/dates';
import type {Variant} from '../../stacks/workspace/storage/base/consts';
import {buildContent, formatTime} from './helpers';

interface Props {
  event: EventActivity;
  connector?: boolean;
  variant?: Variant;
}

export function ActivityTimelineItem({connector, event}: Props) {
  const localDate = parseServerDate(event.created);
  const timestamp = formatTime(localDate);

  const callingUserId = event.calling_user;
  const {user, initials, userName, isLoading} =
    useGetUserDetailsCached(callingUserId);

  const userId = user?.id;

  return (
    <View style={styles.root}>
      <View style={styles.leftContainer}>
        <View style={styles.avatar}>
          {isLoading && <Skeleton width={30} height={30} />}
          {userId && <MemberAvatar initials={initials} memberId={userId} />}
        </View>
        {connector && <View style={styles.connector} />}
      </View>

      <View style={styles.rightContainer}>
        {userName && <Text style={styles.userName}>{userName}</Text>}
        <View style={styles.content}>{buildContent(event)}</View>
        <Text style={styles.timestamp}>{timestamp}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: 10,
  },
  leftContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  rightContainer: {
    flexDirection: 'column',
    marginBottom: 15,
    flex: 1,
  },
  avatar: {
    marginVertical: 4,
    minWidth: 30,
    minHeight: 30,
  },
  connector: {
    width: 2,
    backgroundColor: '#E5E7EB',
    flex: 1,
  },
  userName: {
    fontWeight: '600',
    fontSize: 13,
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  content: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
    // @ts-ignore
    display: 'block',
    lineHeight: 24,
  },
  timestamp: {
    fontSize: 12,
    color: '#6B7280',
    marginTop: 10,
  },
});
