import {createContext, useContext, useState} from 'react';

interface CurrentFolderContextType {
  showCurrentFolder: boolean;
  setShowCurrentFolder: (show: boolean) => void;
}

const CurrentFolderContext = createContext<
  CurrentFolderContextType | undefined
>(undefined);

export function CurrentFolderProvider({children}: {children: React.ReactNode}) {
  const [showCurrentFolder, setShowCurrentFolder] = useState(true);

  return (
    <CurrentFolderContext.Provider
      value={{showCurrentFolder, setShowCurrentFolder}}>
      {children}
    </CurrentFolderContext.Provider>
  );
}

export function useCurrentFolder() {
  const context = useContext(CurrentFolderContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentFolder must be used within a CurrentFolderProvider',
    );
  }
  return context;
}
