import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {AVATAR_SIZES, type AvatarSize} from 'interface/common/Avatar';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import Skeleton from 'interface/common/Skeleton';
import {Fragment} from 'react';
import {StyleSheet, type TextStyle, View, type ViewStyle} from 'react-native';
import type {Member} from 'store/slices/settings/types';

interface Props {
  members: Array<Member> | undefined;
  loading?: boolean;
  text?: string;
  rootStyle?: ViewStyle | ViewStyle[];
  textStyle?: TextStyle | TextStyle[];
  showCount?: boolean;
  maxMembers?: number;
  size?: AvatarSize;
}

const SKELETON_SIZES = {
  '-2': AVATAR_SIZES.AVATAR_SIZE_2L.height,
  '-1': AVATAR_SIZES.AVATAR_SIZE_1L.height,
  '0': AVATAR_SIZES.AVATAR_SIZE_0.height,
  '1': AVATAR_SIZES.AVATAR_SIZE_1.height,
  '2': AVATAR_SIZES.AVATAR_SIZE_2.height,
  '3': AVATAR_SIZES.AVATAR_SIZE_3.height,
  '4': AVATAR_SIZES.AVATAR_SIZE_4.height,
  '5': AVATAR_SIZES.AVATAR_SIZE_5.height,
  '6': AVATAR_SIZES.AVATAR_SIZE_6.height,
};

export function MemberAvatarGroup({
  members,
  loading,
  rootStyle,
  textStyle,
  text,
  showCount = true,
  maxMembers = 5,
  size,
}: Props) {
  const membersToShow = members?.slice(0, maxMembers);

  return (
    <View style={[styles.root, rootStyle]}>
      {loading ? (
        <Skeleton width={80} height={SKELETON_SIZES[`${size ?? 0}`]} />
      ) : (
        Boolean(members?.length) && (
          <Fragment>
            {membersToShow.map((member, index) => (
              <MemberAvatar
                key={member.id}
                memberId={member.id}
                initials={`${member.firstName[0]}${member.lastName[0]}`}
                isStacked={index > 0}
                borderKind="light"
                size={size}
              />
            ))}
            {showCount && (
              <Typography
                overrides={[styles.memberCount, textStyle]}
                size="sm"
                color={theme.colors.neutral.$2Base}>
                {text ||
                  `${members.length} ${members.length === 1 ? 'member' : 'members'}`}
              </Typography>
            )}
          </Fragment>
        )
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  memberCount: {
    marginLeft: 6,
  },
});
