// @ts-ignore
import ExternalAI from 'assets/images/onboarding/slides/external_ai.webp';
// @ts-ignore
import FileIntelligence from 'assets/images/onboarding/slides/file_intelligence.webp';
// @ts-ignore
import KnowledgeCapture from 'assets/images/onboarding/slides/knowledge_capture.webp';
// @ts-ignore
import MeetRipley from 'assets/images/onboarding/slides/meet_ripley.webp';
// @ts-ignore
import TeamCollaboration from 'assets/images/onboarding/slides/team_collaboration.webp';
// @ts-ignore
import Workspaces from 'assets/images/onboarding/slides/workspaces.webp';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {useModal} from 'extensions/viewport/useModal';
import Typography from 'interface/base/Typography';
import Modal from 'interface/common/Modal';
import {getHostData} from 'interface/common/hooks/useHost';
import {WorkspaceCreate} from 'interface/stacks/workspace/dialogs/WorkspaceCreate';
import {useMemo, useState} from 'react';
import {StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as shared from 'store/slices/shared';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import {setCookie} from 'utils/common/cookies';
import {OnboardSteps} from '../lib/consts';
import {LayoutOnboardSlide} from './LayoutOnboardSlide';

export const SLIDES_KEY = 'show_onboard_slides';

const SLIDES = [
  {
    title: 'Welcome to Fast.io!',
    description:
      'Fast.io transforms your team’s files into living knowledge that grows smarter over time.',
    small: 'Upload once. Use everywhere. Remember everything.',
    buttonText: 'Get Started',
  },
  {
    title: 'Workspaces',
    description: 'Workspaces organize your team’s knowledge',
    small:
      "Create dedicated spaces for projects, departments, or topics to keep your team's knowledge structured and accessible.",
    image: Workspaces,
    buttonText: "Let's show you how",
  },
  {
    title: 'File Intelligence',
    description: 'Your files become smart in Fast.io',
    small:
      'We automatically analyze your content, extract insights, and make everything searchable by meaning, not just keywords.',
    image: FileIntelligence,
    buttonText: 'Continue',
  },
  {
    title: 'Meet Ripley',
    description: 'Introducing Ripley, your AI assistant',
    small:
      "Ripley lives inside your workspaces, ready to analyze documents, answer questions, and generate insights from your team's knowledge.",
    image: MeetRipley,
    buttonText: 'Next',
  },
  {
    title: 'Knowledge Capture',
    description: 'Save AI insights back to your workspace',
    small:
      'Capture valuable insights generated by Ripley and store them alongside your original files, creating an intelligence ecosystem that grows over time.',
    image: KnowledgeCapture,
    buttonText: 'Continue',
  },
  {
    title: 'External AI Connection',
    description: 'Connect with your favorite AI tools',
    small:
      'Beyond Ripley, Fast.io works with Claude, Cursor, and other AI tools through the Model Context Protocol—your knowledge, everywhere.',
    image: ExternalAI,
    buttonText: 'Got it',
  },
  {
    title: 'Team Collaboration',
    description: 'Your team’s knowledge flows to everyone',
    small:
      'Share insights across workspaces, preserve institutional memory, and make expertise available to everyone—no matter where they work.',
    image: TeamCollaboration,
    buttonText: 'Continue',
  },
  {
    title: 'Ready to build your first workspace?',
    description:
      'Start by creating a workspace and uploading your first files. We’ll help transform them into searchable, AI-ready knowledge.',
    buttonText: 'Get Started',
  },
];

type Props = {
  onClose: () => void;
};

export function OnboardSlideModal({onClose}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useModal();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const userDetails = useSelector(user.selectors.getUserDetails);
  const organizations = useSelector(user.selectors.getOrganizationsList);
  const workspaces = useSelector(workspace.selectors.getAllWorkspaces);
  const shares = useSelector(shared.selectors.getHasShares);

  const isFirstSlide = currentSlideIndex === 0;
  const isLastSlide = currentSlideIndex === SLIDES.length - 1;
  const currentSlide = SLIDES[currentSlideIndex];

  const isNewUserWithOneOrgCreated = useMemo(() => {
    return organizations.length === 1 && workspaces.length === 1;
  }, [organizations.length, workspaces.length]);

  const isNewUserWithOneOrgUnsubscribed = useMemo(() => {
    const subscriber = organizations?.[0]?.subscriber;
    return organizations.length === 1 && subscriber === false;
  }, [organizations.length]);

  const isNewUserWithoutOrgsOrWorkspaces = useMemo(() => {
    return organizations.length === 0 && workspaces.length === 0 && !shares;
  }, [organizations.length, workspaces.length, shares]);

  const getLastSlideButtonText = () => {
    if (isNewUserWithOneOrgCreated) {
      return 'Get Started';
    }

    if (isNewUserWithOneOrgUnsubscribed) {
      return 'Complete Org Setup';
    }

    if (isNewUserWithoutOrgsOrWorkspaces) {
      return 'Create Org';
    }

    return 'Get Started';
  };

  const createNewOrganization = () => {
    dispatch(onboarding.default.actions.restartOnboarding());
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Organization}`,
    );
  };

  const renewSubscriptionPress = () => {
    const organization = organizations?.[0];

    if (!organization) {
      return;
    }

    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.RENEW_SUBSCRIPTION}?orgDomain=${organization.domain}`,
    );
  };

  const createWorkspace = () => {
    const organization = organizations?.[0];

    if (!organization) {
      return;
    }

    modal.open(
      <WorkspaceCreate organization={organization} close={modal.close} />,
    );
  };

  const handleNextSlide = async () => {
    if (isLastSlide) {
      onClose();
      const {protocol, mainDomain} = await getHostData();
      setCookie({
        name: `${SLIDES_KEY}_${userDetails?.id}`,
        value: 'false',
        domain: mainDomain.replace(/:\d+$/, ''),
        protocol,
        age: 3_600_000,
        path: '/',
      });

      if (isNewUserWithOneOrgCreated) {
        return createWorkspace();
      }

      if (isNewUserWithOneOrgUnsubscribed) {
        return renewSubscriptionPress();
      }

      if (isNewUserWithoutOrgsOrWorkspaces) {
        return createNewOrganization();
      }
    }

    setCurrentSlideIndex(currentSlideIndex + 1);
  };

  const handlePrevSlide = () => {
    setCurrentSlideIndex(currentSlideIndex - 1);
  };

  if (!currentSlide) {
    return null;
  }

  return (
    <Modal
      containerStyle={styles.container}
      bodyStyle={styles.body}
      disableClose>
      <LayoutOnboardSlide
        showBackButton={!isFirstSlide}
        buttonText={
          isLastSlide ? getLastSlideButtonText() : currentSlide.buttonText
        }
        buttonOnPress={handleNextSlide}
        onBackButtonPress={handlePrevSlide}
        image={currentSlide.image}
        isLastSlide={isLastSlide}>
        <Typography
          overrides={
            isFirstSlide
              ? styles.firstText
              : isLastSlide
                ? styles.lastText
                : styles.text
          }
          variant="bold"
          size="3xl"
          color={theme.colors.brand.$1_500}>
          {currentSlide.title}
        </Typography>
        <Typography
          overrides={
            isFirstSlide
              ? styles.firstText
              : isLastSlide
                ? styles.lastText
                : styles.text
          }
          variant="bold"
          size="3xl"
          color={theme.colors.neutral.$700}>
          {currentSlide.description}
        </Typography>
        <Typography
          variant="regular"
          size={isFirstSlide ? 'lg' : 'base'}
          color={theme.colors.neutral.$400}>
          {currentSlide.small}
        </Typography>
      </LayoutOnboardSlide>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 950,
    height: 420,
    padding: 32,
    borderRadius: 24,
  },
  body: {
    height: '100%',
  },
  firstText: {
    fontSize: 32,
    lineHeight: 42,
    marginBottom: 16,
  },
  text: {
    fontSize: 28,
    lineHeight: 34,
    marginBottom: 12,
  },
  lastText: {
    fontSize: 32,
    lineHeight: 42,
    marginBottom: 12,
  },
});
