import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Image, Pressable, StyleSheet, Text} from 'react-native';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as shared from 'store/slices/shared';
import {FileType, typeToIcon} from 'utils/fast/files';

interface Props {
  shareId: string;
}

export function ShareChip({shareId}: Props) {
  const share = useSelector(shared.selectors.getSingleSharedItem(shareId));
  const itemName = share?.name;

  const navigate = useNavigate();
  const handlePress = () => {
    navigate(
      `/${ROUTES.LOGGED_OR_NOT_LOGGED.SHARED}/${share.custom_name}/${share.name}`,
    );
  };

  return (
    <Pressable style={styles.container} onPress={handlePress}>
      <Image
        style={styles.image}
        source={{uri: typeToIcon(FileType.Share)}}
        resizeMode="center"
      />
      {itemName && <Text style={styles.label}>{itemName}</Text>}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.neutral.$11,
    paddingHorizontal: 8,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    // @ts-ignore
    display: 'inline-flex',
    verticalAlign: 'middle',
    maxWidth: '100%',
  },
  label: {
    fontSize: 12,
    color: theme.colors.neutral.$2Base,
    lineHeight: 20,
    // @ts-ignore
    display: 'inline',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  image: {
    width: 16,
    height: 16,
  },
});
