import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import type {PropsWithChildren} from 'react';
import {Image, type ImageSourcePropType, StyleSheet, View} from 'react-native';

type Props = PropsWithChildren<{
  showBackButton?: boolean;
  buttonText?: string;
  buttonOnPress?: () => void;
  image?: ImageSourcePropType;
  isLastSlide?: boolean;
  onBackButtonPress?: () => void;
}>;

export function LayoutOnboardSlide({
  children,
  showBackButton,
  buttonText,
  buttonOnPress,
  image,
  isLastSlide,
  onBackButtonPress,
}: Props) {
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <View style={styles.leftContent}>
          {showBackButton && (
            <Button
              overrides={{
                Button: {
                  style: {
                    alignSelf: 'flex-start',
                  },
                },
              }}
              variant="secondary"
              size="sm"
              onPress={onBackButtonPress}
              startEnhancer={<Icon size={18} name="lucide:arrow-left" />}>
              {t`Back`}
            </Button>
          )}
          <View
            style={[styles.content, isLastSlide && styles.contentLastSlide]}>
            {children}
          </View>
        </View>
        <Button
          overrides={{
            Button: {
              style: {
                alignSelf: 'flex-start',
                paddingVertical: 10,
                paddingHorizontal: 16,
              },
            },
          }}
          variant="primary"
          size="lg"
          onPress={buttonOnPress}
          endEnhancer={
            <Icon
              size={18}
              name="lucide:arrow-right"
              color={theme.colors.neutral.$white}
            />
          }>
          <Typography
            variant="medium"
            size="base"
            color={theme.colors.neutral.$white}>
            {buttonText}
          </Typography>
        </Button>
      </View>
      {image && (
        <View style={styles.right}>
          <Image source={image} style={styles.image} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: theme.colors.neutral.$white,
    flexDirection: 'row',
    gap: 32,
  },
  left: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    maxWidth: 600,
  },
  contentLastSlide: {
    maxWidth: 830,
  },
  image: {
    width: 500,
    height: 356,
    borderRadius: 16,
  },
  right: {
    flexShrink: 0,
    minWidth: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContent: {
    gap: 12,
  },
});
