import {WORKSPACE_CHAT} from 'constants/routes';
import {Route, useLocation, useNavigate} from 'extensions/navigation';
import {SideBarLayout} from 'interface/SideBarLayout';
import type React from 'react';
import {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {useGetNamespaceContext} from '../../hooks/useGetNamespaceContext';
import {useWorkspaceMembers} from '../../hooks/useWorkspaceMembers';
import {NEW_CHAT, NEW_PRIVATE_CHAT} from './consts';
import {AiChat} from './pages/AiChat';
import {AiChatList} from './pages/AiChatList';

export const aiChatRoutes = (
  <Route path={WORKSPACE_CHAT}>
    <Route index element={<AiChatLayout />} />
    <Route path=":id" element={<AiChatLayout />} />
    <Route path=":id/:messageId/markdown" element={<AiChatLayout />} />
  </Route>
);

export function AiChatLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {namespace, instanceId, instanceName} = useGetNamespaceContext();
  const {members, isLoading: membersLoading} = useWorkspaceMembers(instanceId);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openChat = useSelector(
    aichat.selectors.getCurrentChat(namespace, instanceId),
  );

  const isDetailRoute = !location.pathname.endsWith('/chat');
  const baseChatPath = `${location.pathname.split('/chat')[0]}/chat`;

  const handleNewChatPress = (isPrivate: boolean) => {
    dispatch(
      aichat.default.actions.setCurrentChat({
        namespace,
        instanceId,
        chatId: isPrivate ? NEW_PRIVATE_CHAT : NEW_CHAT,
      }),
    );
    navigate(`${baseChatPath}`);
  };

  const handleChatPress = (chatId: string) => {
    dispatch(
      aichat.default.actions.setCurrentChat({namespace, instanceId, chatId}),
    );

    if (chatId === null) {
      navigate(`${baseChatPath}`);
    } else {
      navigate(`${baseChatPath}/${chatId}`);
    }
  };

  const openSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const chatList = useMemo(() => {
    return (
      <AiChatList
        namespace={namespace}
        instanceId={instanceId}
        onNewChatPress={handleNewChatPress}
        onChatPress={chat => handleChatPress(chat.id)}
        onCloseSidebar={() => setIsSidebarOpen(false)}
        workspaceMembers={members}
        showMenu={isSidebarOpen}
      />
    );
  }, [isSidebarOpen]);

  return (
    <SideBarLayout
      isSidebarOpen={isSidebarOpen}
      listScreen={chatList}
      detailScreen={
        <AiChat
          namespace={namespace}
          instanceId={instanceId}
          instanceName={instanceName}
          chatId={openChat}
          messageId={undefined}
          onOpenSidebar={openSidebar}
          onNewChatPress={handleNewChatPress}
          workspaceMembers={members}
          showMenu={!isSidebarOpen}
        />
      }
    />
  );
}
