import useBreakpoints from 'interface/common/hooks/useBreakpoints';
import {useMemo} from 'react';
import {Platform, type StyleProp, type ViewStyle} from 'react-native';

export function useWorkspaceGrid() {
  const breakpoints = useBreakpoints();

  // @ts-ignore
  const style: StyleProp<ViewStyle> | undefined = useMemo(() => {
    const numberOfColumns =
      breakpoints.xs || breakpoints.sm
        ? 1
        : breakpoints.md
          ? 2
          : breakpoints.lg
            ? 3
            : breakpoints.xl || breakpoints['2xl']
              ? 4
              : 5;

    return Platform.OS === 'web'
      ? {
          display: 'grid',
          gridTemplateColumns: `repeat(${numberOfColumns}, minmax(300px, 1fr))`,
          gap: 16,
        }
      : undefined;
  }, [
    breakpoints.xs,
    breakpoints.sm,
    breakpoints.md,
    breakpoints.lg,
    breakpoints.xl,
    breakpoints['2xl'],
  ]);

  return style;
}
