import theme from 'config/theme';
import Skeleton from 'interface/common/Skeleton';
import React from 'react';
import {StyleSheet, View} from 'react-native';

type Props = {
  last: boolean;
};

const PreviewSidePanelVersionSkeleton = ({last}: Props) => {
  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <View style={styles.line} />
        <View style={styles.circle} />
        {!last && <View style={styles.line} />}
      </View>
      <View style={styles.right}>
        <Skeleton width={120} height={20} />
        <View style={styles.user}>
          <Skeleton width={30} height={30} />
          <Skeleton width={180} height={20} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: 75,
    flexDirection: 'row',
    gap: 10,
  },
  left: {
    width: 30,
    alignItems: 'center',
  },
  line: {
    width: 2,
    height: 32.5,
    backgroundColor: theme.colors.neutral.$10,
  },
  circle: {
    width: 10,
    height: 10,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$10,
    borderStyle: 'solid',
    borderRadius: 50,
  },
  right: {
    justifyContent: 'center',
    gap: 5,
  },
  user: {
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
});

export default PreviewSidePanelVersionSkeleton;
