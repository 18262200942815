import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {Avatar} from 'interface/common/Avatar';
import useHost from 'interface/common/hooks/useHost';
import {useHover} from 'interface/common/hooks/useHover';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {StyleSheet} from 'react-native';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';

type Props = {
  workspace: WorkspaceListDetail;
};

export function HeaderWorkspaceButton({workspace}: Props) {
  const {subdomain} = useSubDomain();
  const {domain, protocol} = useHost();

  const {isHover, onHoverIn, onHoverOut} = useHover();

  const commonOverrides = {
    Button: {
      style: {
        width: 32,
        height: 32,
        padding: 0,
        backgroundColor: isHover ? theme.colors.neutral.$black : 'transparent',
      },
    },
  };

  const onPress = () => {
    const url = `${protocol}://${domain}/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`;
    window.location.href = url.replace(subdomain, 'go');
  };

  const workspaceLogo = workspace?.logoImage;
  const workspaceInitials = createInitials(workspace?.name);
  const workspaceColor =
    workspace?.accent_color?.color || getHashColor(workspace?.id);

  return (
    <Button
      overrides={commonOverrides}
      aria-label="Workspace"
      variant="text"
      onPress={onPress}
      onHoverInFn={onHoverIn}
      onHoverOutFn={onHoverOut}
      endEnhancer={
        isHover ? (
          <Icon
            name="lucide:arrow-left"
            color={theme.colors.neutral.$white}
            size={22}
          />
        ) : (
          <Avatar
            color={workspaceColor}
            initials={workspaceInitials}
            hideBorder
            size={0.5}
            shapeKind="rounded-square"
            imageStyle={styles.avatar}
            innerStyle={styles.avatar}
            labelStyle={styles.label}
            photo={workspaceLogo}
          />
        )
      }
    />
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 64,
    height: '100%',
    paddingBottom: 16,
    paddingHorizontal: 16,
    paddingTop: 8,
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 6,
  },
  top: {
    gap: 24,
  },
  bottom: {
    gap: 24,
  },
  label: {
    fontWeight: '500',
  },
});
