import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const defaultUser = {
  id: '',
  plan: '',
  first_name: '',
  last_name: '',
  profile_pic: '',
  email_address: '',
  country_code: '',
  phone_country: 0,
  phone_number: 0,
  tos_agree: 0,
  created: new Date(),
  updated: new Date(),
  valid_email: false,
  valid_phone: false,
  autoupgrade: false,
  subscriber: false,
  suspended: false,
  locked: false,
  '2factor': false,
};

export const initialState: Store = {
  user: defaultUser,
  token: '',
  profilePic: '',
  organizationsList: [],
  membersByOrganization: {},
  selectedOrganization: null,
  websocketAuthTokens: {},
  ssoData: {
    provider: '',
    result: '',
    loading: false,
  },
};

export default createSlice({name: 'user', reducers, initialState});
export {selectors};
