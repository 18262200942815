import theme from 'config/theme';
import {Transfers} from 'fast-sdk';
import Typography from 'interface/base/Typography';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useModalCreateFolder} from 'interface/stacks/workspace/storage/hooks/useModalCreateFolder';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as $files from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import * as user from 'store/slices/user';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {getRecentFiles} from '../../helpers';

interface Props {
  submitDirectQuestion: (question: string, attachFiles: FilesItem[]) => void;
}

const getGreeting = (name: string) => {
  const now = getCurrentUTCDateTime();
  const hours = new Date(now).getHours();
  if (hours < 12) {
    return `Good morning, ${name}!`;
  }
  if (hours < 18) {
    return `Good afternoon, ${name}!`;
  }
  return `Good evening, ${name}!`;
};

const QUESTIONS = [
  'What are the main topics or themes discussed in [$FILE]?',
  'Please generate an analysis of [$FILE]',
  'What is the main content of [$FILE]?',
  'Can you summarize [$FILE] in a few sentences?',
  'What format is [$FILE] in, and what does it contain?',
  'Does [$FILE] contain any sensitive or personal information?',
  'Are there any important keywords or key phrases in [$FILE]?',
];

const GENERIC_QUESTIONS_NO_UPLOAD = [
  'How do I get started with this [$NAMESPACE]?',
  'How can you help me organize my documents?',
];

const GENERIC_QUESTIONS = [
  'How do I get started with this [$NAMESPACE]?',
  'What types of files can I upload?',
];

const generateDirectQuestions = (attachFiles: FilesItem[]) => {
  const fileText = attachFiles.length === 1 ? 'this file' : 'these files';
  const shuffled = [...QUESTIONS].sort(() => Math.random() - 0.5);
  return shuffled
    .slice(0, 4)
    .map(question => question.replace('[$FILE]', fileText));
};

export function AiChatGettingStarted({submitDirectQuestion}: Props) {
  const {namespace, instanceId, instanceName, shareObj} =
    useGetNamespaceContext();
  const focusedParentId = useSelector($files.selectors.getFocusedParent);
  const {uploader} = useWorkspaceContext();
  const {first_name: userFirstName} = useSelector(
    user.selectors.getUserDetails,
  );

  const modalCreateFolder = useModalCreateFolder(
    focusedParentId,
    instanceName,
    namespace,
  );

  const itemList = useSelector($files.selectors.getItemsList) ?? [];
  const recentFiles = getRecentFiles(itemList, namespace, instanceName);
  const hasItems = recentFiles.length > 0;

  const greeting = getGreeting(userFirstName);
  const context = namespace;

  const addFilesFromLocal = async () => {
    const files = await Transfers.pickFiles();
    uploader.instance.addFiles(files, focusedParentId, instanceId);
  };
  const onAddNewFolder = () => {
    modalCreateFolder.open();
  };

  const isUploadEnabled = !(
    shareObj?.share_type === 'send' && shareObj?.shareViewStatus === 'Guest'
  );

  const genericQuestions = (
    isUploadEnabled ? GENERIC_QUESTIONS : GENERIC_QUESTIONS_NO_UPLOAD
  ).map(question => question.replace('[$NAMESPACE]', context));

  return (
    <View style={styles.container}>
      <Typography variant="bold" size="2xl" color={theme.colors.neutral.$700}>
        {greeting}
      </Typography>
      <Typography
        variant="regular"
        size="md"
        color={theme.colors.neutral.$500}
        overrides={styles.greetingSubtitle}>
        Explore patterns and find answers within this {context}. Ask me to
        search, summarize, or analyze anything.
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '50%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 80,
  },
  greetingSubtitle: {
    marginHorizontal: 40,
    marginTop: 8,
  },
});
