import React from 'react';
import {type StyleProp, StyleSheet, View, type ViewStyle} from 'react-native';
import {useSelector} from 'react-redux';
import * as workspaceStore from 'store/slices/workspace';
import {getHashColor} from 'utils/common/color';
import {Avatar} from './Avatar';

interface WorkspaceBadgeProps {
  id: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
}

export function WorkspaceBadge({id, size = 16, style}: WorkspaceBadgeProps) {
  const workspace = useSelector(workspaceStore.selectors.getWorkspaceById(id));
  const logoImage = workspace?.logoImage;
  const avatarPhoto = logoImage;
  return (
    <View style={styles.container}>
      {avatarPhoto ? (
        <Avatar
          color={getHashColor(id)}
          photo={avatarPhoto}
          imageStyle={{width: size, height: size}}
          innerStyle={{width: size, height: size}}
          shapeKind="rounded-square-small"
          initials={''}
          hideBorder
          hideBackground
        />
      ) : (
        <View
          style={[
            styles.workspaceBagde,
            {backgroundColor: getHashColor(id), width: size, height: size},
            style,
          ]}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  workspaceBagde: {
    width: 16,
    height: 16,
    borderRadius: 3,
  },
  container: {
    padding: 2,
  },
});
