import {useCallback} from 'react';
import {Platform} from 'react-native';

import type {MouseEvent, ReactNode} from 'react';

interface MouseProps {
  children: ReactNode;
  onClick?(event: MouseEvent): void;
  onDoubleClick?(event: MouseEvent): void;
  onContextMenu?(event: MouseEvent): void;
}

export function Mouse(props: MouseProps): JSX.Element {
  const doubleClick = useCallback(
    (e: MouseEvent) => {
      if (props.onDoubleClick) {
        e.stopPropagation();
        props.onDoubleClick(e);
      }
    },
    [props.onDoubleClick],
  );

  const contextMenu = useCallback(
    (e: MouseEvent) => {
      if (props.onContextMenu) {
        e.preventDefault();
        e.stopPropagation();
        props.onContextMenu(e);
      }
    },
    [props.onContextMenu],
  );

  if (Platform.OS !== 'web') {
    return props.children as JSX.Element;
  }

  return (
    <div
      onClick={props.onClick ?? undefined}
      onDoubleClick={doubleClick}
      onContextMenu={contextMenu}
      style={{display: 'flex', flex: 1, width: '100%', height: '100%'}}>
      {props.children}
    </div>
  );
}
