import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {PanelGroup} from 'interface/base/Panel';
import AppUserBar from 'interface/stacks/app/AppUserBar';
import {WorkspaceAiChat} from 'interface/stacks/workspace/layout/WorkspaceAiChat';
import {ColumnPanelToggle} from 'interface/stacks/workspace/storage/base/ColumnPanelToggle';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import {type Share, ShareViewStatus} from 'store/slices/shared/types';
import {ShareRecepientView} from '../../ShareRecepientView';
import {ModalAuthentication} from '../ModalAuthentication';
import {StorageHeader} from './StorageHeader';

interface ShareHiddenLayoutProps {
  sharedCustomName: string;
  shareDetails?: ShareItem;
  onSuccess?: () => void;
  getRedirectOnSuccess?: () => string;
}

export default function ShareHiddenLayout({
  sharedCustomName,
  shareDetails,
  onSuccess,
  getRedirectOnSuccess,
}: ShareHiddenLayoutProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modal = useModal();

  //@ts-ignore
  const share: Share = {
    custom_name: sharedCustomName,
    title: undefined,
    description: undefined,
    id: '234567898765',
    filesystem: {
      file_creation: true,
      file_modification: 'all',
      file_download: 'all',
      file_view: 'all',
      folder_creation: true,
      folder_modification: 'all',
    },
    shareViewStatus: ShareViewStatus.Guest,
    isMock: true,
    ...(shareDetails ?? {}),
  };

  useEffect(() => {
    modal.open(
      <ModalAuthentication
        onSuccess={onSuccess}
        getRedirectOnSuccess={getRedirectOnSuccess}
      />,
      {
        disableClose: true,
      },
    );
    setIsModalOpen(true);
  }, [share]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <View style={styles.root}>
      <AppUserBar />
      <View style={styles.body}>
        <View style={styles.flex}>
          <StorageHeader share={share} />
          <View style={styles.content}>
            <PanelGroup direction="horizontal" style={{overflowX: 'auto'}}>
              <ColumnPanelToggle
                columnKey={ColumnManagerInfoKeys.FoldersAndFiles}>
                <ShareRecepientView hiddenShare={share} />
              </ColumnPanelToggle>
              <ColumnPanelToggle columnKey={ColumnManagerInfoKeys.AIChat}>
                <WorkspaceAiChat chatId={undefined} messageId={undefined} />
              </ColumnPanelToggle>
            </PanelGroup>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
  },
  body: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.colors.neutral.$100,
    padding: 3,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
});
