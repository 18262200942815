import store from 'store';
import type {EntityChat, EntityChatMessage} from 'store/slices/aichat/types';
import {
  generateHeading,
  generateMarkdownTable,
  generateSubheading,
} from 'utils/common/markdown';
import {capitalizeFirstLetter} from 'utils/common/string';

export const useGenerateMessageMarkdown = () => {
  const generateMarkdown = (chat: EntityChat, message: EntityChatMessage) => {
    const responseText = message.response?.text;

    const createdAt = `${message.createdAt} UTC`;

    const authorId = message.creator.id;
    const author = store.getState().users.items[authorId];
    const authorInfo = `${author?.first_name} ${author?.last_name} (${author?.email_address})`;

    const baseUrl = window.location.href.split('?')[0];
    const chatLink = `${baseUrl}?chatId=${chat.id}&messageId=${message.id}`;

    const visibility = capitalizeFirstLetter(chat.privacy.visibility);

    const detailsTable = generateMarkdownTable({
      headers: ['Name', 'Value'],
      data: [
        ['Created', createdAt],
        ['Author', authorInfo],
        ['Chat Link', chatLink],
        ['Visibility', visibility],
      ],
    });

    const markdown = `${generateHeading(chat.name)}\n${responseText}\n${generateSubheading('Source Details')}\n${detailsTable}`;

    return markdown;
  };

  return {generateMarkdown};
};
