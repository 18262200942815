import {t} from '@lingui/macro';
import {StyleSheet, View} from 'react-native';

import theme from 'config/theme';
import {Input} from 'interface/base/Input';
import {Textarea} from 'interface/base/Textarea';
import Typography from 'interface/base/Typography';
import type {ShareState} from '../hooks/useShareActions';

interface ShareDetailsProps {
  shareState: ShareState;
}

export function ShareDetails({shareState}: ShareDetailsProps) {
  const {title, description, setTitle, setDescription} = shareState;

  return (
    <View>
      <Typography
        overrides={styles.label}
        size="xs"
        color={theme.colors.neutral.$700}>
        {t`Title`}
      </Typography>
      <Input
        overrides={{
          Root: {
            style: styles.input,
          },
        }}
        aria-label={t`Title`}
        value={title}
        placeholder={'Enter a title'}
        onChangeValue={setTitle}
      />
      <Typography
        overrides={styles.label}
        size="xs"
        color={theme.colors.neutral.$700}>
        {t`Description`}
      </Typography>
      <Textarea
        aria-label="Description"
        value={description}
        onChangeValue={setDescription}
        placeholder="Enter a description"
        maxLength={1000}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 8,
  },
  label: {
    marginBottom: 6,
  },
});
