import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import type {ButtonOverrides} from 'interface/base/Button/types';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Fragment, useState} from 'react';
import {type StyleProp, StyleSheet, type ViewStyle} from 'react-native';

interface Props {
  overrides?: ButtonOverrides;
  icon?: string;
  text?: string;
  onNewChatPress: (isPrivate: boolean) => void;
  customTriggerStyle?: StyleProp<ViewStyle>;
}

export default function CreateNewChatPopup({
  onNewChatPress,
  icon,
  text,
  overrides,
  customTriggerStyle,
}: Props) {
  const [hasMenu, setHasMenu] = useState(false);

  const triggerElement = (
    <Button
      onPress={() => setHasMenu(true)}
      overrides={overrides}
      variant="secondary"
      startEnhancer={
        <Icon name={icon ?? 'lucide:plus'} color={theme.colors.neutral.$700} />
      }>
      <Typography size="xs" color={theme.colors.neutral.$700}>
        {text ?? 'New Chat'}
      </Typography>
    </Button>
  );

  const onOptionPress = (value: boolean) => {
    onNewChatPress(value);
    setHasMenu(false);
  };

  return (
    <Popup
      triggerElement={triggerElement}
      isOpen={hasMenu}
      showBorder={false}
      customContainerStyle={styles.popupContainer}
      customPopupStyle={styles.customPopupStyle}
      customTriggerStyle={customTriggerStyle}
      close={() => setHasMenu(false)}>
      <Fragment>
        <Typography
          size="sm"
          variant="regular"
          color={theme.colors.neutral.$500}
          overrides={styles.title}>
          Create New Chat
        </Typography>
        <MenuItem
          title="New Team Chat"
          icon="lucide:users"
          onPress={() => onOptionPress(false)}
        />
        <MenuItem
          title="New Private Chat"
          icon="lucide:lock"
          onPress={() => onOptionPress(true)}
        />
      </Fragment>
    </Popup>
  );
}

function MenuItem({
  title,
  icon,
  onPress,
}: {title: string; icon: string; onPress: () => void}) {
  return (
    <HoveredView onPress={onPress} style={styles.hoveredView}>
      <Icon name={icon} color={theme.colors.neutral.$700} />
      <Typography size="sm" variant="regular" color={theme.colors.neutral.$700}>
        {title}
      </Typography>
    </HoveredView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 280,
    padding: 24,
    flexDirection: 'column',
  },
  title: {
    marginBottom: 16,
  },
  hoveredView: {
    paddingHorizontal: 8,
    height: 28,
    paddingVertical: 2,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  popupContainer: {
    borderRadius: 16,
    padding: 24,
    width: 280,
    shadowColor: 'rgb(28, 27, 34)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
  },
  customPopupStyle: {
    backgroundColor: 'transparent',
    paddingBottom: 8,
    paddingTop: 8,
    paddingHorizontal: 8,
  },
});
