import theme from 'config/theme';
import {IconButton} from 'interface/common/IconButton';
import {useHover} from 'interface/common/hooks/useHover';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {hexToRGBA} from 'utils/common/color';
import {useGoBack} from '../../hooks/useGoBack';

const BackButton = () => {
  const {isHover, onHoverIn, onHoverOut} = useHover();
  const {onGoBack} = useGoBack();

  return (
    <View style={styles.root}>
      <IconButton
        buttonStyle={styles.button}
        iconStyle={styles.icon}
        buttonHoverStyle={styles.hover}
        onPress={onGoBack}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        icon={
          <Icon
            name="arrow-back"
            size={20}
            color={isHover ? theme.colors.brand.$6 : theme.colors.brand.$5}
          />
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: 36,
    height: 36,
  },
  button: {
    minWidth: '100%',
    minHeight: '100%',
  },
  icon: {
    width: 20,
    height: 20,
  },
  hover: {
    backgroundColor: hexToRGBA(theme.colors.neutral.$white, 0.1),
  },
});

export default BackButton;
