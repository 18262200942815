import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import useFileThumbnailSource from 'interface/stacks/workspace/storage/hooks/useFileThumbnailSource';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import {getFileTypeFromMime} from 'utils/fast/files';

import type {StorageItem} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem} from 'store/slices/files/types';

export function useThumbnails(rows: {file: StorageItem}[]) {
  const [visibleIndices, setVisibleIndices] = useState<number[]>([]);
  const thumbnails = useSelector((state: any) => state.files.thumbnails);
  const {fetchFileThumbnail} = useFileThumbnailSource();
  const dispatch = useDispatch();

  const loadThumbnails = useCallback(
    async (indices: number[]) => {
      const visibleRows = indices.map(idx => rows[idx]).filter(Boolean);

      for (const row of visibleRows) {
        const file = row.file;
        if (!file) continue;

        // Skip loading thumbnails for folders
        const fileType = getFileTypeFromMime(file);
        if (fileType === 'folder') continue;

        // Check if we already have the thumbnail in the Redux store
        const thumbnailKey = `${file.id}-${FileThumbnailSizes.Small}`;
        const existingThumbnail = thumbnails[thumbnailKey];

        if (!existingThumbnail) {
          // Fetch the thumbnail
          const newThumbnail = await fetchFileThumbnail(
            file as FilesItem,
            FileThumbnailSizes.Small,
          );
          if (newThumbnail) {
            // Store it in Redux
            dispatch(
              files.default.actions.setThumbnail({
                thumbnailKey,
                source: newThumbnail,
              }),
            );
          }
        }
      }
    },
    [fetchFileThumbnail, dispatch, thumbnails, rows],
  );

  // Track visible region changes
  const handleVisibleRegionChanged = useCallback(
    (region: {x: number; y: number; width: number; height: number}) => {
      // Calculate which rows are visible
      const visibleRowIndices: number[] = [];
      const startRow = Math.max(0, Math.floor(region.y));
      const endRow = Math.min(
        rows.length - 1,
        Math.ceil(region.y + region.height),
      );

      for (let i = startRow; i <= endRow; i++) {
        visibleRowIndices.push(i);
      }

      setVisibleIndices(visibleRowIndices);
    },
    [rows.length],
  );

  // Load thumbnails when visible rows change
  useEffect(() => {
    if (visibleIndices.length > 0) {
      loadThumbnails(visibleIndices);
    }
  }, [visibleIndices, loadThumbnails]);

  return {
    handleVisibleRegionChanged,
  };
}
