import {ROUTES} from 'constants/routes';
import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {slices} from 'store';

import * as effects from 'interface/stacks/workspace/storage/hooks/files';
import * as files from 'store/slices/files';
import {download} from 'utils/fast/storage';

import {WorkspaceInfoPanelTab} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/types/WorkspaceInfoPanelTab';
import {SharedCustomizeDialog} from 'interface/stacks/workspace/shared/dialogs/SharedCustomizeDialog';
import {useContextMenu} from 'interface/stacks/workspace/storage/hooks/useContextMenu';
import {useModalRename} from 'interface/stacks/workspace/storage/hooks/useModalRename';
import {useModalTrash} from 'interface/stacks/workspace/storage/hooks/useModalTrash';
import {MainDialog} from 'interface/stacks/workspace/storage/quick-share/MainDialog';

import type {CellClickedEventArgs} from '@glideapps/glide-data-grid';
import type {StorageItem} from 'fast-sdk/src/api/storage/consts';

interface UseFileMenuProps {
  workspaceId: string;
}

export function useCellFileMenu({workspaceId}: UseFileMenuProps) {
  const [instance, setInstance] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useModal();
  const popup = usePopup();

  // Context

  const sel = useSelector(files.selectors.getSelection);
  const focus = useSelector(files.selectors.getFocused);
  const isMulti = sel && sel.length > 1 && sel.includes(focus);
  const targetIds = isMulti ? (sel as string[]) : focus ? [focus] : [];
  const firstItem = useSelector(files.selectors.getItem(targetIds[0]));

  // Modals

  const renameModal = useModalRename(
    firstItem?.id || '',
    firstItem?.name || '',
    firstItem?.type || '',
    workspaceId,
    'workspace',
  );

  const trashModal = useModalTrash(targetIds, workspaceId, 'workspace');

  // Actions

  const createShareOnPress = useCallback(() => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspaceId}
        folderParentId={firstItem?.parent}
        close={modal.close}
        items={targetIds}
      />,
    );
  }, [workspaceId, firstItem, targetIds, modal]);

  const downloadItem = useCallback(async () => {
    const ns = 'workspace';
    const id = workspaceId;
    for (const targetId of targetIds) {
      await download(targetId, id, ns);
      await new Promise(resolve => setTimeout(resolve, 1500));
    }
  }, [targetIds, workspaceId]);

  const openComments = useCallback(() => {
    if (!firstItem?.id) return;
    dispatch(slices.files.actions.focus({id: firstItem.id}));
    dispatch(
      slices.files.actions.focusType({type: WorkspaceInfoPanelTab.Comments}),
    );
  }, [firstItem, dispatch]);

  const viewFile = useCallback(() => {
    if (!firstItem?.id) return;
    navigate(
      `/${'workspace'}/${workspaceId}/${ROUTES.LOGGED_IN_WITH_ORG.PREVIEW}/${firstItem.id}`,
    );
  }, [firstItem, workspaceId, navigate]);

  const moveTo = useCallback(
    effects.pick(dispatch, {
      action: 'move',
      destination: 'root',
      ids: targetIds,
    }),
    [targetIds, dispatch],
  );

  const copyTo = useCallback(
    effects.pick(dispatch, {
      action: 'copy',
      destination: 'root',
      ids: targetIds,
    }),
    [targetIds, dispatch],
  );

  const transferTo = useCallback(
    effects.pick(dispatch, {
      action: 'transfer',
      destination: 'root',
      ids: targetIds,
    }),
    [targetIds, dispatch],
  );

  // Setup

  const headerText = firstItem ? firstItem.name : '';

  const menuFiles = useContextMenu({
    headerText,
    addToExistingShareOnPress: transferTo,
    copyToOnPress: copyTo,
    moveToOnPress: moveTo,
    viewOnPress: viewFile,
    downloadOnPress: downloadItem,
    moveToTrashOnPress: () => trashModal.open(),
    quickShareOnPress: () => modal.open(<MainDialog file={firstItem} />),
    commentsOnPress: !isMulti ? openComments : undefined,
    renameOnPress: !isMulti ? () => renameModal.open() : undefined,
    createShareOnPress: createShareOnPress,
  });

  // Handlers

  const open = useCallback(
    (file: StorageItem, e: CellClickedEventArgs) => {
      // Update the focused item
      dispatch(slices.files.actions.focus({id: file.id}));
      // Position popup menu
      popup.setPosition({
        x: e.localEventX,
        y: e.localEventY,
      });
      // Trigger context menu
      setInstance(new Date().toISOString());
    },
    [menuFiles, popup, dispatch],
  );

  const close = useCallback(() => {
    menuFiles.close();
  }, [menuFiles]);

  // Open menu on new instance
  useEffect(() => {
    if (instance) {
      menuFiles.open();
    }
  }, [instance]);

  return {
    open,
    close,
  };
}
