import {api} from 'fast-sdk';
import {WorkspaceUserStatus} from 'fast-sdk/src/api/workspace/consts';
import {useGetWorkspaceLogo} from 'interface/common/hooks/useGetWorkspaceLogo';
import {useDispatch} from 'react-redux';
import * as workspace from 'store/slices/workspace';

export const useGetWorkspaceDetails = () => {
  const {fetchWorkspaceLogo} = useGetWorkspaceLogo();
  const dispatch = useDispatch();

  const fetchWorkspaceDetails = async workspaceId => {
    try {
      const {result, workspace: workspaceDetails} =
        await api.workspace.getWorkspaceDetails(workspaceId);
      if (result) {
        const workspaceDetailsWithStatus = {
          ...workspaceDetails,
          user_status: WorkspaceUserStatus.Joined,
        };
        dispatch(
          workspace.default.actions.updateWorkspace({
            id: workspaceDetails.id,
            workspace: workspaceDetailsWithStatus,
          }),
        );
        fetchWorkspaceLogo(workspaceDetails.id);
        return workspaceDetailsWithStatus;
      }
    } finally {
    }
    return undefined;
  };

  return {
    fetchWorkspaceDetails,
  };
};
