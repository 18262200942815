import {t} from '@lingui/macro';
// @ts-ignore
import GoogleLogo from 'assets/images/login/google-logo.png';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {Input} from 'interface/base/Input';
import {useFormSubmit} from 'interface/common/hooks/useFormSubmit';
import {PasswordInput} from 'interface/stacks/auth/components/PasswordInput';
import {useState} from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {PageSubTitle} from '../../base/PageSubTitle';

export function EmailInfo() {
  const userDetails = useSelector(user.selectors.getUserDetails);
  // The SSO of the user should come from the backend
  const sso = false;

  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  const saveChanges = () => {
    setLoading(true);
    if (newPassword !== confirmPassword) {
      toast.show(
        t`The new password and confirm password should not be different`,
        {type: 'danger'},
      );
      setLoading(false);
      return;
    }

    if (newPassword === currentPassword) {
      toast.show(
        t`Your new password should be different than your current password`,
        {type: 'danger'},
      );
      setLoading(false);
      return;
    }

    api.auth
      .signIn({email: userDetails.email_address, password: currentPassword})
      .then(res => {
        if (res.result) {
          return api.user.updateUserDetails({
            ...userDetails,
            password: newPassword,
          });
        }
        toast.show('The current password is not correct', {type: 'danger'});
        return;
      })
      .then(res => {
        if (res) {
          if (res.result) {
            toast.show(t`Your password has been successfully changed.`, {
              type: 'success',
            });
            setNewPassword('');
            setCurrentPassword('');
            setConfirmPassword('');
          }
        }
      })
      .catch(err => {
        toast.show(err.message, {type: 'danger'});
        analytics.notify(err);
      })
      .finally(() => setLoading(false));
  };

  const disabled =
    !passwordIsValid ||
    !currentPassword ||
    !newPassword ||
    !confirmPassword ||
    loading;
  useFormSubmit(saveChanges, disabled);

  return (
    <View style={styles.root}>
      <PageSubTitle text={t`Your Email`} customStyle={styles.title} />
      <View style={{marginBottom: sso ? '1rem' : '2.5rem'}}>
        <Input
          value={userDetails.email_address}
          onChangeValue={() => {}}
          disabled
          aria-label={t`Email Address`}
        />
      </View>
      {sso ? (
        <View style={styles.ssoContainer}>
          <View style={styles.googleLogo}>
            <Image source={{uri: GoogleLogo}} style={styles.logo} />
          </View>
          <PageSubTitle
            text={t`Managed by Google`}
            customStyle={styles.ssoText}
          />
        </View>
      ) : (
        <View>
          <PageSubTitle text={t`Change Password`} customStyle={styles.label} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 14,
              marginBottom: '1rem',
            }}>
            <PasswordInput
              label={t`Current Password`}
              password={currentPassword}
              setPassword={setCurrentPassword}
              customInputStyle={styles.input}
              ariaLabel={t`Current Password`}
            />
            <PasswordInput
              label={t`New Password`}
              password={newPassword}
              setPassword={setNewPassword}
              customInputStyle={styles.input}
              ariaLabel={t`New Password`}
              withValidation
              setPasswordIsValid={setPasswordIsValid}
            />
            <PasswordInput
              label={t`Confirm Password`}
              password={confirmPassword}
              setPassword={setConfirmPassword}
              ariaLabel={t`Confirm Password`}
              customInputStyle={styles.input}
            />
          </View>
          <View style={styles.buttonContainer}>
            <Button
              variant="secondary"
              onPress={saveChanges}
              disabled={disabled}
              loading={loading}
              overrides={{
                Loading: {
                  style: {
                    color: theme.colors.neutral.$5,
                  },
                },
              }}>
              {t`Save Changes`}
            </Button>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 450,
  },
  label: {
    marginBottom: '1.5rem',
  },
  ssoContainer: {
    backgroundColor: theme.colors.neutral.$13,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$9,
    borderRadius: 50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 184,
  },
  googleLogo: {
    borderRadius: 100,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.15)',
    width: 36,
    height: 36,
    backgroundColor: theme.colors.neutral.$white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 20,
    height: 20,
  },
  ssoText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
    paddingVertical: 5,
    paddingLeft: 10,
    paddingRight: 15,
  },
  input: {
    height: 36,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '1rem',
  },
  title: {
    marginBottom: '0.5rem',
  },
});
