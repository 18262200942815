import {t} from '@lingui/macro';
import theme from 'config/theme';
import {VE_DEBUG_ROUTING_KEY} from 'fast-sdk/src/requests/consts';
import Typography from 'interface/base/Typography';
import {Prompt} from 'interface/common/Prompt';
import {useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {useDispatch} from 'react-redux';
import {scan} from 'react-scan';
import * as app from 'store/slices/app';

interface Props {
  onClose: () => void;
}

export default function AdvancedSettingsModal({onClose}: Props) {
  const dispatch = useDispatch();
  const debugKey = window.localStorage.getItem(VE_DEBUG_ROUTING_KEY);

  const onResetSession = () => {
    dispatch(app.default.actions.setForceLogout(true));
  };

  // handle cmd+s hotkey
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        scan({showToolbar: true});
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <Prompt
      onClose={onClose}
      rootStyle={styles.root}
      buttons={[
        {
          variant: 'secondary',
          text: t`Close`,
          onPress: onClose,
        },
        {
          variant: 'primary',
          text: t`Reset Fastio Session`,
          onPress: onResetSession,
          isSubmit: true,
        },
      ]}>
      <Typography variant="bold" size="xl" color={theme.colors.neutral.$2Base}>
        Advanced Settings
      </Typography>
      <Typography size="md">Debug Code: [{debugKey}]</Typography>
    </Prompt>
  );
}

const styles = StyleSheet.create({
  root: {
    maxWidth: 600,
    maxHeight: 600,
  },
  resetModalButton: {
    width: 200,
    height: 36,
    marginTop: 20,
    minWidth: 57,
    minHeight: 36,
  },
  modalTitleText: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
  },
});
