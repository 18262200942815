import theme from 'config/theme';
import {AppMemberAvatar} from 'interface/stacks/app/AppMemberAvatar';
import ShareHeaderOrg from 'interface/stacks/share/layout/ShareHeader/ShareHeaderOrg';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import ActionButtons from '../components/top-toolbar/ActionButtons';
import BackButton from '../components/top-toolbar/BackButton';
import UserDropdown from '../components/top-toolbar/UserDropdown';

import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import type {MultiplayerMember} from 'interface/multiplayer/types';
import React from 'react';
import type {FilesItem} from 'store/slices/files/types';
import {FileType, getFileTypeFromMime} from 'utils/fast/files';
import EditorTopToolbar from './EditorTopToolbar';

export const HEIGHT_TOP_TOOLBAR = 52;

type Props = {
  file: FilesItem;
  members?: MultiplayerMember[];
  toggleSidebar: () => void;
};

const TopToolbar = ({file, members, toggleSidebar}: Props) => {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const {subdomain} = useSubDomain();

  const type = getFileTypeFromMime(file);
  const hasContent = Boolean(file);
  const isQuickShare = Boolean(file?.quickShare);

  if (!isQuickShare && type === FileType.Markdown) {
    return <EditorTopToolbar members={members} />;
  }

  return (
    <View style={styles.root}>
      <View style={styles.left}>
        {isQuickShare || !hasContent ? (
          <ShareHeaderOrg orgId={subdomain} kind="dark" />
        ) : (
          <>
            <BackButton />
            <Text style={styles.title}>{file?.name}</Text>
          </>
        )}
      </View>
      {isQuickShare && (
        <View style={styles.center}>
          <Text style={styles.title}>{file?.name}</Text>
        </View>
      )}
      <View style={styles.right}>
        {isLoggedIn && (
          <>
            <UserDropdown kind="dark" />
            <View style={styles.divider} />
          </>
        )}
        {hasContent && (
          <>
            <ActionButtons toggleSidebar={toggleSidebar} file={file} />
            <View style={styles.divider} />
          </>
        )}
        {members?.map(member => (
          <AppMemberAvatar
            key={member.clientId}
            member={member}
            borderKind={'light'}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: HEIGHT_TOP_TOOLBAR,
    backgroundColor: theme.colors.neutral.$1,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 5,
    width: '100%',
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  center: {
    gap: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 22,
    color: theme.colors.neutral.$white,
    padding: 7,
  },
  right: {
    flexDirection: 'row-reverse',
  },
  divider: {
    width: 0,
    height: HEIGHT_TOP_TOOLBAR,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$black,
    marginHorizontal: 8,
    marginTop: -8,
  },
});

export default TopToolbar;
