import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {useRef} from 'react';
import {StyleSheet, Text, TextInput, View} from 'react-native';

export interface SearchInputProps {
  value: string;
  onChange: (text: string) => void;
}

export function SearchFilter(props: SearchInputProps) {
  const inputRef = useRef<TextInput>(null);

  return (
    <View style={styles.root}>
      <Icon name="lucide:search" size={20} color={theme.colors.neutral.$700} />
      <View style={styles.contents}>
        <TextInput
          ref={inputRef}
          style={[
            styles.input,
            {
              // @ts-ignore
              outline: 'none',
            },
          ]}
          autoFocus
          selectTextOnFocus
          value={props.value}
          placeholder={'Filter workspaces ...'}
          placeholderTextColor={theme.colors.neutral.$400}
          onChangeText={text => {
            props.onChange(text);
          }}
          onSubmitEditing={() => {
            if (!props.value) return;
          }}
        />
      </View>
      <View style={styles.kbd}>
        <Text style={styles.cmd}>{'⌘'}</Text>
        <Text style={styles.k}>{'K'}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: 36,
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$100,
    borderRadius: 32,
  },
  contents: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 10,
    columnGap: 10,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
  },
  input: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 22,
  },
  kbd: {
    flexDirection: 'row',
    minWidth: 32,
    paddingTop: 2,
    paddingLeft: 6,
    paddingBottom: 2,
    paddingRight: 6,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.05)',
    backgroundColor: theme.colors.neutral.$0,
    boxShadow:
      '0px 1px 3px 0px rgba(28, 27, 34, 0.05), 0px 2px 8px 0px rgba(28, 27, 34, 0.05)',
  },
  cmd: {
    color: theme.colors.neutral.$500,
    textAlign: 'center',
    fontFamily: 'Roboto sans-serif',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
  },
  k: {
    color: theme.colors.neutral.$500,
    textAlign: 'center',
    marginTop: -1 /* fix alignment */,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
  },
});
