import theme from 'config/theme';
import {format, isSameDay} from 'date-fns';
import type {EventActivity} from 'fast-sdk/src/api/events/consts';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {StyleSheet, Text} from 'react-native';
import {CompatChip} from './CompatChip';
import {FolderChip} from './FolderChip';
import {ShareChip} from './ShareChip';
import {UserChip} from './UserChip';
import {WorkspaceChip} from './WorkspaceChip';

export function formatTime(localDate: Date) {
  const now = new Date();
  const diffInMinutes = Math.floor(
    (now.getTime() - localDate.getTime()) / (1000 * 60),
  );
  if (diffInMinutes < 60 && isSameDay(now, localDate)) {
    if (diffInMinutes === 0) {
      return 'Just now';
    }
    return `${diffInMinutes} minutes ago`;
  }
  if (isSameDay(now, localDate)) {
    return format(localDate, 'h:mm a');
  }
  return format(localDate, 'MMMM d, yyyy, h:mm a');
}

export function shouldShowEvent(event: EventActivity) {
  return event.calling_user && event.visibility !== 'internal';
}

export function buildContent(event: EventActivity) {
  if (typeof event.template === 'object') {
    const {description, params} = event.template;

    let paramIndex = 0;
    const chunks = description?.split(/('{{.*?}}')/g).map((chunk, index) => {
      if (chunk.startsWith("'{{") && chunk.endsWith("}}'")) {
        const paramName = params[paramIndex];
        paramIndex++;
        return renderChip(index, paramName, params);
      }
      return (
        <Text key={index} style={styles.text}>
          {chunk}
        </Text>
      );
    });

    return <>{chunks}</>;
  }

  return <Text style={styles.text}>{event.description}</Text>;
}

function renderChip(index: number, param: string, params: string[]) {
  const workspaceContext = useWorkspaceContext();
  const workspaceFolderName = workspaceContext?.workspace?.folder_name;

  const firstChar = param?.charAt(0);
  switch (firstChar) {
    case '2':
      if (param.length > 25) {
        const shareId = params?.at(1);
        const isFolderOnShare = shareId?.startsWith('5');
        return (
          <FolderChip
            key={index}
            fileId={param}
            instanceId={isFolderOnShare ? shareId : workspaceFolderName}
            instanceNs={isFolderOnShare ? 'share' : 'workspace'}
          />
        );
      }
      return <UserChip key={index} userId={param} />;
    case '4':
      return <WorkspaceChip key={index} workspaceId={param} />;
    case '5':
      if (param.length > 25) {
        return <CompatChip key={index} name={param} />;
      }
      return <ShareChip key={index} shareId={param} />;
    default:
      return <CompatChip key={index} name={param} />;
  }
}

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});
