import type {InputState} from './types';

export const getInputState = ({
  focused,
  disabled,
  readonly,
  error,
}: InputState) => {
  const state: string[] = [];
  if (disabled) return 'disabled';
  if (readonly) return 'readonly';
  if (error) state.push('error');
  if (focused) state.push('focused');
  return state.join('');
};
