import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import useFileThumbnailSource from 'interface/stacks/workspace/storage/hooks/useFileThumbnailSource';
import {memo, useEffect, useMemo, useState} from 'react';
import {Image, ImageBackground} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';

import type {FilesItem} from 'store/slices/files/types';

export type ImageContentsProps = {
  file: FilesItem;
  url: string;
  width: number;
  height: number;
  isThumbnail: boolean;
  isFullscreen: boolean;
  setError: (error: boolean) => void;
};

const ImageContents = memo(
  ({
    file,
    url,
    width,
    height,
    isThumbnail,
    isFullscreen,
    setError,
  }: ImageContentsProps) => {
    const [loaded, setLoaded] = useState(false);
    const thumbSm = useSelector(
      files.selectors.selectThumbnail(`${file.id}-${FileThumbnailSizes.Small}`),
    );
    const thumbMd = useSelector(
      files.selectors.selectThumbnail(
        `${file.id}-${FileThumbnailSizes.Medium}`,
      ),
    );
    const thumbLg = useSelector(
      files.selectors.selectThumbnail(
        `${file.id}-${FileThumbnailSizes.Preview}`,
      ),
    );

    const {fetchFileThumbnail} = useFileThumbnailSource();
    const dispatch = useDispatch();

    // Load preview thumbnail if not already loaded
    useEffect(() => {
      const loadThumbnail = async () => {
        if (!thumbLg) {
          const newThumbnail = await fetchFileThumbnail(
            file,
            FileThumbnailSizes.Preview,
          );
          if (newThumbnail) {
            dispatch(
              files.default.actions.setThumbnail({
                thumbnailKey: `${file.id}-${FileThumbnailSizes.Preview}`,
                source: newThumbnail,
              }),
            );
          }
        }
      };
      loadThumbnail();
    }, [file.id, thumbLg, dispatch]);

    const fullImage = useMemo(
      () => (
        <Image
          source={{uri: url}}
          style={{width, height}}
          onLoad={() => {
            if (!loaded) {
              setError(false);
            }
          }}
          onError={() => {
            if (!loaded) {
              setError(true);
            }
          }}
        />
      ),
      [url, width, height, loaded, setError],
    );

    return (
      <ImageBackground
        source={{uri: thumbLg || thumbMd || thumbSm}}
        style={{width, height}}
        onLoad={() => {
          setError(false);
          setLoaded(true);
        }}
        onError={() => {
          setError(true);
        }}>
        {(!isThumbnail || isFullscreen) && fullImage}
      </ImageBackground>
    );
  },
);

export default ImageContents;
