import {STORAGE_ROOT, WORKSPACE_NOTES} from 'constants/routes';
import {
  Route,
  useLocation,
  useNavigate,
  useParams,
} from 'extensions/navigation';
import {SideBarLayout} from 'interface/SideBarLayout';
import type React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import * as files from 'store/slices/files';
import {removeExtension} from 'utils/fast/files';
import {load} from 'utils/fast/storage';
import {useGetNamespaceContext} from '../../hooks/useGetNamespaceContext';
import NoteDetail from './components/NoteDetail';
import {NotesList} from './components/NotesList';

export const notesRoutes = (
  <Route path={WORKSPACE_NOTES}>
    <Route index element={<NotesLayout />} />
    <Route path=":fileId" element={<NotesLayout />} />
  </Route>
);

export function NotesLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {namespace, instanceId, instanceName, workspaceObj} =
    useGetNamespaceContext();
  const {fileId} = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const baseNotesPath = `${location.pathname.split('/notes')[0]}/notes`;

  const file = useSelector(files.selectors.getItem(fileId));

  const handleNewNotePress = () => {
    navigate(`${baseNotesPath}`);
  };

  const handleNotePress = (noteId: string) => {
    navigate(`${baseNotesPath}/${noteId}`);

    if (noteId === null) {
      navigate(`${baseNotesPath}`);
    } else {
      navigate(`${baseNotesPath}/${noteId}`);
    }
  };

  const openSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const notesList = useMemo(() => {
    if (isSidebarOpen) {
      return (
        <NotesList
          namespace={namespace}
          instanceId={instanceName}
          onNewNotePress={handleNewNotePress}
          onNotePress={note => handleNotePress(note.id)}
          onCloseSidebar={() => setIsSidebarOpen(false)}
          showMenu={isSidebarOpen}
        />
      );
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    dispatch(
      app.default.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: `Notes - ${file?.name ? removeExtension(file?.name) : 'Create New Note'}`,
      }),
    );
  }, [file?.name]);

  useEffect(() => {
    if (workspaceObj) {
      load(dispatch, STORAGE_ROOT, workspaceObj?.folder_name, 'workspace');
    }
  }, [workspaceObj?.id]);

  return (
    <SideBarLayout
      isSidebarOpen={isSidebarOpen}
      listScreen={notesList}
      detailScreen={
        <NoteDetail
          instanceId={instanceName}
          instanceNs={namespace}
          key={fileId}
          toggleSidebar={openSidebar}
          showMenu={!isSidebarOpen}
          file={file}
        />
      }
    />
  );
}
