import React from 'react';
import {StyleSheet, View, useWindowDimensions} from 'react-native';
import {AiChatQuestionItem} from './AiChatQuestionItem';

import type {EntityChatMessage} from 'store/slices/aichat/types';
import {AiChatResponseItemMarkdown} from './AiChatResponseItemMarkdown';

interface Props {
  message: EntityChatMessage;
  isLastMessage: boolean;
}

export function AiChatMessageItem({message, isLastMessage}: Props) {
  const {content, response} = message;
  const {height} = useWindowDimensions();

  return (
    <View
      style={[styles.container, isLastMessage && {minHeight: height - 190}]}>
      {content && (
        <AiChatQuestionItem
          key={`${message.id}-question`}
          message={content}
          userId={message.creator.id}
          createdAt={message.createdAt}
        />
      )}
      <AiChatResponseItemMarkdown
        key={`${message.id}-response`}
        isLastMessage={isLastMessage}
        message={message}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 30,
  },
});
