import type {AccentColor, Member} from '../organization/consts';

export interface WorkspaceDetailsResult {
  result: boolean;
  workspace: WorkspaceDetails;
}

export interface WorkspaceCreateResult {
  workspace: {
    id: string;
    folder_name: string;
  };
}

export interface WorkspaceDetails extends WorkspaceListDetail {
  parent: number;
}

export enum PermJoin {
  'Only Org Owners' = 'Only Org Owners',
  'Admin or above' = 'Admin or above',
  'Member or above' = 'Member or above',
  'No one can join automatically' = 'No one can join automatically',
}

export enum PermManageMembers {
  'Admin or above' = 'Admin or above',
  'Member or above' = 'Member or above',
  'Guest or above' = 'Guest or above',
}

export type WorkspaceList = {
  result: boolean;
  results: number; // The number of workspaces that this organization have
  workspaces: Array<WorkspaceListDetail>;
};

export type WorkspaceListDetail = {
  id: string;
  name: string;
  folder_name: string;
  description?: string;
  logo: string;
  logoImage?: string;
  created: string;
  updated: string;
  user_status: WorkspaceUserStatus;
  closed: boolean;
  perm_join?: PermJoin;
  archived: boolean;
  suspended: boolean;
  accent_color: AccentColor;
  perm_member_manage?: PermManageMembers;
  org_domain: string;
  locked: boolean;
  intelligence?: boolean;
};

export type WorkspaceMembersList = {
  result: boolean;
  results: number;
  users: Array<WorkspaceMember>;
};

export type WorkspaceInvitationsList = {
  result: boolean;
  results: number;
  invitations: Array<WorkspaceInvitation>;
};

export interface WorkspaceMember extends Member {
  id: string;
  permissions: string;
}

export type WorkspaceInvitation = {
  id: string;
  inviter: string;
  invitee_email: string;
  invitee_uid: string | null;
  entity: {
    id: string;
    id_alt: string;
  };
  state: string;
  created: string;
};

export type WorkspaceMetadataTemplate = {
  id: string;
  system_template: boolean;
  instanceId: string | null;
  orgId: string | null;
  category: string;
  name: string;
  description: string;
  locked: boolean;
  priority: number;
  enabled: boolean;
  deleted: string | null;
  updated: string;
  created: string;
  fields: Array<{
    name: string;
    description: string;
    required: boolean;
    type: string;
    min: number | null;
    max: number | null;
    default: any | null;
    fixed_list: any[];
  }>;
};

export type WorkspaceMetadataTemplatesResponse = {
  result: boolean;
  count: number;
  items: WorkspaceMetadataTemplate[];
};

export type WorkspaceMetadataTemplateResponse = {
  result: boolean;
  item: WorkspaceMetadataTemplate;
};

export enum WorkspaceUserStatus {
  Joined = 'joined',
  Available = 'available',
}

export type MemberPermission =
  | 'owner'
  | 'admin'
  | 'member'
  | 'guest'
  | 'view'
  | 'any';

export type Industry = {
  title: string;
  description: string;
};

export type IndustryList = Record<string, Industry>;
