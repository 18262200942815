import {STORAGE_ROOT} from 'constants/routes';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import MarkdownDraftViewer from 'interface/stacks/content-viewer/base/markdown/MarkdownDraftViewer';
import MarkdownFileViewer from 'interface/stacks/content-viewer/base/markdown/MarkdownFileViewer';
import {EditorProvider} from 'interface/stacks/content-viewer/context/EditorContext';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import NoteTopToolbar from './NoteTopToolbar';

interface NoteDetailProps {
  instanceId: string;
  instanceNs: StorageNamespace;
  toggleSidebar: () => void;
  showMenu: boolean;
  file: FilesItem;
}

export default function NoteDetail({
  instanceId,
  instanceNs,
  showMenu,
  toggleSidebar,
  file,
}: NoteDetailProps) {
  return (
    <EditorProvider
      initialFileName={file?.name ?? 'Untitled Note'}
      instanceId={instanceId}
      instanceNs={instanceNs}
      file={file}
      parentFolder={file?.parent ?? STORAGE_ROOT}>
      <View style={styles.root}>
        <NoteTopToolbar showMenu={showMenu} onOpenSidebar={toggleSidebar} />
        <View style={styles.content}>
          {file ? (
            <MarkdownFileViewer file={file} />
          ) : (
            <MarkdownDraftViewer markdownText={''} />
          )}
        </View>
      </View>
    </EditorProvider>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
});
