import {type PropsWithChildren, forwardRef} from 'react';
import {
  type NativeSyntheticEvent,
  StyleSheet,
  type TextInput,
  type TextInputFocusEventData,
  View,
} from 'react-native';
import {Input} from '../Input';
import type {TextInputAllowedProps} from '../Input/types';
import Typography from '../Typography';
import type {TextAreaOverrides} from './types';

interface Props extends TextInputAllowedProps {
  overrides?: TextAreaOverrides;
  value: string;
  onChangeValue: (text: string) => void;
  numberOfLines?: number;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  onBlurFn?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onFocusFn?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
}

export const Textarea = forwardRef<TextInput, PropsWithChildren<Props>>(
  (
    {
      overrides,
      value,
      onChangeValue,
      numberOfLines,
      placeholder,
      maxLength,
      disabled,
      onBlurFn,
      onFocusFn,
      ...props
    },
    ref,
  ) => {
    const randomId = (Math.random() * 1000000).toString();

    return (
      <View style={overrides?.Root?.style}>
        <Input
          overrides={{
            Input: {
              style: {
                ...(numberOfLines ? {} : styles.textarea),
                ...overrides?.TextArea?.style,
              },
            },
          }}
          ref={ref}
          nativeID={randomId}
          multiline
          numberOfLines={numberOfLines}
          value={value ?? ''}
          placeholder={placeholder}
          disabled={disabled}
          onChangeValue={onChangeValue}
          onBlurFn={onBlurFn}
          onFocusFn={onFocusFn}
          autoComplete="off"
          maxLength={maxLength}
          {...props}
        />
        {maxLength && (
          <Typography
            overrides={[
              styles.characterCount,
              overrides?.CharacterCount?.style,
            ]}
            size="xs">
            {value?.length ?? 0}/{maxLength}
          </Typography>
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  textarea: {
    minHeight: 160,
  },
  characterCount: {
    alignSelf: 'flex-end',
  },
});
