import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';
import {useSubDomain} from './useSubDomain';

const getIsOwner = (parentId: number, userId: string) =>
  `${parentId}` === `${userId}`;

export default function useOrgDetails(domain?: string) {
  const [searchParams] = useSearchParams();

  const {subdomain: orgSubdomain} = useSubDomain();

  const subdomain = domain ?? orgSubdomain;

  const organizationsList = useSelector(user.selectors.getOrganizationsList);
  const userDetails = useSelector(user.selectors.getUserDetails);

  const [loading, setLoading] = useState(true);
  const [orgDomain, setOrgDomain] = useState<string>();
  const [details, setDetails] = useState<OrganizationDetails | null>(null);
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const storedDetails = useMemo(() => {
    const org = organizationsList.find(userOrg => userOrg.domain === orgDomain);

    if (!org) {
      return null;
    }

    setIsOwner(getIsOwner(org.parent, userDetails.id));
    return org;
  }, [orgDomain, organizationsList, userDetails.id]);

  useEffect(() => {
    if (isSubdomainValid(subdomain)) {
      return setOrgDomain(subdomain);
    }

    const domain = searchParams.get('orgDomain');
    if (domain) {
      setOrgDomain(domain);
    }
  }, [subdomain, searchParams]);

  useEffect(() => {
    if (!orgDomain) {
      return;
    }

    async function fetchOrgDetails() {
      const {result, org} =
        await api.organization.getOrganizationDetails(orgDomain);
      if (result) {
        setDetails(org);

        if (userDetails?.email_address && org?.parent) {
          setIsOwner(getIsOwner(org.parent, userDetails.id));
        }
      }
      setLoading(false);
    }

    if (storedDetails) {
      setLoading(false);
      return setDetails(storedDetails);
    }

    fetchOrgDetails();
  }, [storedDetails, userDetails?.email_address, userDetails?.id, orgDomain]);

  return {details, isOwner, loading};
}
