import {ROUTES} from 'constants/routes';
import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import useFetchOrgs from 'interface/common/hooks/useFetchOrgs';
import {useGetUserDetails} from 'interface/common/hooks/useGetUserDetails';
import {useGetUserProfilePhoto} from 'interface/common/hooks/useGetUserProfilePhoto';
import {useOrgMembers} from 'interface/common/hooks/useOrgMembers';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as activity from 'store/slices/activity';
import {EntityType} from 'store/slices/activity/types';
import * as userSlice from 'store/slices/user';
import type {User} from 'store/slices/user/types';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {getCurrentPath} from 'utils/common/platform';
import {shouldUpdateCache} from '../utils';
import useItemChangeDetector from './useItemChangeDetector';

interface UseSyncUserDataOptions {
  user: User;
}

const useSyncUserData = ({user}: UseSyncUserDataOptions) => {
  const dispatch = useDispatch();

  const {fetchOrganizations} = useFetchOrgs();
  const {getMembers} = useOrgMembers();

  const userLogo = useSelector(userSlice.selectors.getUserProfilePic);
  const membersByOrg = useSelector(
    userSlice.selectors.getMembersByOrganizationString,
  );

  const userActivities =
    useSelector(activity.selectors.getUserActivities(user?.id))?.activities ??
    {};

  const userMembershipActivity = userActivities[ActivityTypes.MEMBERSHIP];
  const userDetailsActivity = userActivities[ActivityTypes.DETAILS];
  const userAssetsActivity = userActivities[ActivityTypes.ASSETS];

  const {fetchUserDetails} = useGetUserDetails();
  const {fetchUserProfilePhoto} = useGetUserProfilePhoto();

  const updateEntityLastClientUpdate = useCallback(
    (userId: string, activityType: string) => {
      const lastClientUpdate = getCurrentUTCDateTime();
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [userId],
          activities: [activityType],
          entityType: EntityType.User,
          lastClientUpdate,
        }),
      );
    },
    [],
  );

  const onMembershipChange = useCallback(() => {
    if (user?.id) {
      updateEntityLastClientUpdate(user.id, ActivityTypes.MEMBERSHIP);
    }
  }, []);

  const onUserChange = useCallback(() => {
    if (user?.id) {
      updateEntityLastClientUpdate(user.id, ActivityTypes.DETAILS);
    }
  }, []);

  const onUserLogoChange = useCallback(() => {
    if (user?.id) {
      updateEntityLastClientUpdate(user.id, ActivityTypes.ASSETS);
    }
  }, []);

  useEffect(() => {
    onUserLogoChange();
  }, [userLogo]);

  useEffect(() => {
    onUserChange();
  }, [user]);

  useEffect(() => {
    onMembershipChange();
  }, [membersByOrg]);

  useEffect(() => {
    const currentPath = getCurrentPath();
    if (currentPath === ROUTES.LOGGED_IN_WITHOUT_ORG.HOME) {
      if (userMembershipActivity) {
        const {lastServerUpdate, lastClientUpdate} = userMembershipActivity;
        if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
          fetchOrganizations();
        }
      }
    }
  }, [userMembershipActivity]);

  useEffect(() => {
    if (userDetailsActivity) {
      const {lastServerUpdate, lastClientUpdate} = userDetailsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchUserDetails();
      }
    }
  }, [userDetailsActivity]);

  useEffect(() => {
    if (userAssetsActivity) {
      const {lastServerUpdate, lastClientUpdate} = userAssetsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchUserProfilePhoto(user.id);
      }
    }
  }, [userAssetsActivity]);

  const onMembershipActivityChange = useCallback(organization => {
    const currentPath = getCurrentPath();
    if (currentPath === ROUTES.LOGGED_IN_WITHOUT_ORG.HOME) {
      const membershipActivity = userActivities.membership;
      if (membershipActivity) {
        const {lastServerUpdate, lastClientUpdate} = membershipActivity;
        if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
          getMembers(organization.domain, organization.id);
        }
      }
    }
  }, []);

  useItemChangeDetector(
    userSlice.selectors.getOrganizationsList,
    onMembershipActivityChange,
  );
};

export default useSyncUserData;
