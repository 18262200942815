import theme from 'config/theme';
import {format} from 'date-fns';
import {useEventActivityTimeline} from 'extensions/activities/useEventActivityTimeline';
import {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {parseServerDate} from 'utils/common/dates';
import type {Variant} from '../../stacks/workspace/storage/base/consts';
import Typography from '../Typography';
import {ActivityTimelineItem} from './ActivityTimelineItem';
import {shouldShowEvent} from './helpers';
import {ActivityTimelineGroupItemSkeleton} from './skeleton/ActivityTimelineItemSkeleton';

type Props = {
  workspaceId: string;
  parentId: string;
  selectedId: string;
  limit?: number;
  variant?: Variant;
};

const getEventParams = (
  workspaceId: string,
  parentId: string,
  selectedId: string,
) => {
  const objectId = !selectedId || selectedId === 'root' ? null : selectedId;

  if (parentId) {
    if (parentId === 'root') {
      return objectId
        ? {workspace_id: workspaceId, object_id: objectId}
        : {workspace_id: workspaceId};
    }
    if (parentId.startsWith('5')) {
      return objectId
        ? {share_id: parentId, object_id: objectId}
        : {share_id: parentId};
    }
    if (parentId.startsWith('2')) {
      if (!selectedId) {
        return {workspace_id: workspaceId, object_id: parentId};
      }
      return objectId
        ? {workspace_id: workspaceId, object_id: objectId}
        : {workspace_id: workspaceId};
    }
  }
  return {workspace_id: workspaceId};
};

export default function ActivityTimeline({
  workspaceId,
  parentId,
  selectedId,
  limit,
  variant,
}: Props) {
  const {events, loading} = useEventActivityTimeline(
    getEventParams(workspaceId, parentId, selectedId),
    limit,
  );

  const groupedEvents = events
    .filter(event => shouldShowEvent(event))
    .reduce(
      (groups, event) => {
        const userDate = parseServerDate(event.created);
        const date = format(new Date(userDate), 'MMMM do');

        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(event);
        return groups;
      },
      {} as Record<string, typeof events>,
    );

  const skeletonRows = useMemo(() => {
    return Array.from({length: 3}, (_, groupIndex) => (
      <ActivityTimelineGroupItemSkeleton key={groupIndex} />
    ));
  }, []);

  return (
    <View style={styles.root}>
      {loading && skeletonRows}
      {!loading &&
        Object.entries(groupedEvents).map(([date, dayEvents], index) => (
          <View key={`${date}-${index}`}>
            <View style={styles.dateContainer}>
              <Typography size="xs" color={theme.colors.neutral.$3}>
                {date}
              </Typography>
            </View>
            {dayEvents.map((event, index) => {
              return (
                <ActivityTimelineItem
                  key={
                    event.category + event.sub_category + event.created + index
                  }
                  event={event}
                  variant={variant}
                  connector={index !== dayEvents.length - 1}
                />
              );
            })}
          </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
  },
  dateContainer: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 20,
    width: 'fit-content',
    backgroundColor: theme.colors.neutral.$12,
    marginBottom: 15,
  },
});
