import {
  CompactSelection,
  DataEditor,
  GridCellKind,
} from '@glideapps/glide-data-grid';
import theme from 'config/theme';
import {useCallback, useEffect, useRef, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';
import slices from 'store/slices/files';

import FileCell from '../cells/FileCell';
import {useCellFileMenu} from '../hooks/useCellFileMenu';
import {NoResults} from './NoResults';

import type {
  CellClickedEventArgs,
  DataEditorRef,
  GridCell,
  GridSelection,
  Item,
  SpriteMap,
} from '@glideapps/glide-data-grid';
import type {MetadataState} from '../types';

interface DataGridProps {
  metadata: MetadataState;
  folderId: string;
  workspaceId: string;
}

export function DataGrid({metadata, folderId, workspaceId}: DataGridProps) {
  const {
    rows,
    columns,
    hiddenColumns,
    isLoading,
    templates,
    templateId,
    getTemplateData,
  } = metadata;
  const {open: fileMenuOpen} = useCellFileMenu({workspaceId});
  const [selection, setSelection] = useState<GridSelection>({
    columns: CompactSelection.empty(),
    rows: CompactSelection.empty(),
  });

  const editor = useRef<DataEditorRef>(null);
  const dispatch = useDispatch();

  // Get the active template with its field definitions
  const activeTemplate = templateId ? getTemplateData(templateId) : undefined;

  // Get placeholder row count based on the selected template's object count
  const getPlaceholderRowCount = useCallback(() => {
    if (!templateId) return 5; // Default placeholder count
    const selectedTemplate = templates.find(t => t.template_id === templateId);
    return selectedTemplate?.object_count || 5;
  }, [templateId, templates]);

  // Check if we should show the grid with placeholder rows
  const shouldShowPlaceholders = isLoading && templateId;

  const sort = useCallback(
    (index: number) => {
      metadata.sortColumn(columns[index].id);
    },
    [metadata, columns],
  );

  const select = useCallback(
    (newSelection: GridSelection) => {
      const cur = newSelection?.current?.cell;
      const rng = newSelection?.current?.range;
      const stack = newSelection?.current?.rangeStack;

      // No current cell, clear selection + focus
      if (!cur) {
        dispatch(slices.actions.select({ids: []}));
        dispatch(slices.actions.focus({id: undefined}));
        setSelection({
          current: undefined,
          columns: CompactSelection.empty(),
          rows: CompactSelection.empty(),
        });
        return;
      }

      // Update selection
      setSelection({
        ...newSelection,
        current: {
          cell: cur,
          rangeStack: newSelection.current?.rangeStack ?? [],
          range: {
            x: 0,
            y: rng?.y,
            width: columns.length,
            height: rng?.height,
          },
        },
      });

      // Range selection
      if (rng) {
        const {y, height} = rng;
        // Get all rows in range
        const ids: Array<string> = [];
        for (let i = y; i < y + height; i++) ids.push(rows[i].file.id);
        // Get all rows in stack
        const stackIds: Array<string> = [];
        for (let i = 0; i < stack.length; i++) {
          const {y, height} = stack[i];
          for (let j = y; j < y + height; j++) stackIds.push(rows[j].file.id);
        }
        dispatch(
          slices.actions.select({ids: [...ids, ...stackIds], noToggle: true}),
        );
      }

      // Focus file of current cell
      if (cur) {
        const [_, row] = cur;
        const fileId = rows[row].file.id;
        dispatch(slices.actions.focus({id: fileId}));
        return;
      }
    },
    [columns, dispatch, rows],
  );

  const menu = useCallback(
    (cell: Item, e: CellClickedEventArgs) => {
      if (!cell) return;
      const [_, rowIndex] = cell;
      if (rows?.[rowIndex]) {
        const file = rows[rowIndex].file;
        if (file) {
          fileMenuOpen(file, e);
        }
      }
    },
    [rows, fileMenuOpen],
  );

  const getCellContent = useCallback(
    (cell: Item): GridCell => {
      const [col, row] = cell;

      // For rows beyond the data we have, return loading cells
      // This creates placeholder rows during loading
      if (shouldShowPlaceholders && (rows.length === 0 || row >= rows.length)) {
        return {
          kind: GridCellKind.Loading,
          allowOverlay: false,
          skeletonHeight: 20,
          skeletonWidth: 100,
          skeletonWidthVariability: 0.5,
        };
      }

      const dataRow = rows[row];

      // If there's no data row yet (during loading), return loading cell
      if (!dataRow) {
        return {
          kind: GridCellKind.Loading,
          allowOverlay: false,
          skeletonHeight: 20,
          skeletonWidth: 100,
          skeletonWidthVariability: 0.5,
        };
      }

      const field = columns[col].id as keyof (typeof rows)[0];
      const d = dataRow?.[field];

      // Handle loading state
      if (d === undefined) {
        return {
          kind: GridCellKind.Loading,
          allowOverlay: false,
          skeletonHeight: 20,
          skeletonWidth: 100,
          skeletonWidthVariability: 0.5,
        };
      }

      // Handle special fields that have predefined types
      if (field === 'name' && Array.isArray(d)) {
        return {
          kind: GridCellKind.Custom,
          allowOverlay: false,
          copyData: d[0].text,
          data: {
            kind: 'file-cell',
            name: d[0].text,
            icon: d[0].icon,
            image: d[0].img,
          },
        };
      }

      if (field === 'size' || field === 'created') {
        return {
          kind: GridCellKind.Text,
          allowOverlay: false,
          displayData: d.toString(),
          data: d,
        };
      }

      // For custom metadata fields, look up the field type in the active template
      if (activeTemplate?.fields) {
        // Find the field definition in the template
        const fieldDef = activeTemplate.fields.find(
          f => f.name.toLowerCase() === field.toLowerCase(),
        );

        if (fieldDef) {
          // Use the field's type information
          switch (fieldDef.type.toLowerCase()) {
            case 'int':
            case 'integer':
            case 'float':
            case 'number':
            case 'decimal': {
              const numValue = Number.parseFloat(d.toString());
              if (!Number.isNaN(numValue)) {
                return {
                  kind: GridCellKind.Number,
                  allowOverlay: false,
                  displayData: d.toString(),
                  copyData: d.toString(),
                  data: numValue,
                };
              }
              break;
            }
            case 'bool':
            case 'boolean': {
              const boolValue =
                d === 'true' || d === true || d === '1' || d === 1;
              return {
                kind: GridCellKind.Boolean,
                allowOverlay: false,
                copyData: boolValue ? 'true' : 'false',
                data: boolValue,
              };
            }
            case 'date':
            case 'time':
            case 'datetime': {
              return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                displayData: d.toString(),
                data: d,
              };
            }
            case 'json': {
              const json = JSON.parse(d);
              return Array.isArray(json)
                ? {
                    kind: GridCellKind.Bubble,
                    allowOverlay: false,
                    data: json,
                  }
                : {
                    kind: GridCellKind.Text,
                    allowOverlay: false,
                    displayData: d.toString(),
                    data: d,
                  };
            }
          }
        }
      }

      // Default to text for any other types
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData: d.toString(),
        data: d,
      };
    },
    [rows, columns, activeTemplate, shouldShowPlaceholders],
  );

  // Clear selection when folder changes
  useEffect(() => {
    setSelection({
      columns: CompactSelection.empty(),
      rows: CompactSelection.empty(),
    });
  }, [folderId]);

  // Determine actual row count for the grid
  const rowCount = shouldShowPlaceholders
    ? getPlaceholderRowCount()
    : rows.length;

  // Only show NoResults when not loading and there are no rows
  if (!isLoading && rows.length === 0) {
    return <NoResults />;
  }

  return (
    <View style={styles.root}>
      <DataEditor
        ref={editor}
        width="100%"
        height="100%"
        rows={rowCount}
        columns={columns.filter(column => !hiddenColumns.includes(column.id))}
        getCellContent={getCellContent}
        customRenderers={[FileCell]}
        scaleToRem={true}
        smoothScrollX={true}
        smoothScrollY={true}
        minColumnWidth={100}
        maxColumnWidth={2000}
        maxColumnAutoWidth={600}
        rangeSelect="multi-rect"
        rowSelect="multi"
        rowHeight={40}
        columnSelect="none"
        headerIcons={headerIcons}
        onColumnMoved={metadata.rearrangeColumn}
        onColumnResize={metadata.resizeColumn}
        onColumnProposeMove={(_, endIndex) => endIndex !== 0}
        onCellContextMenu={menu}
        onHeaderClicked={sort}
        onVisibleRegionChanged={metadata.handleVisibleRegionChanged}
        getCellsForSelection={true}
        onHeaderMenuClick={e => {
          console.log('>> header menu clicked', e);
        }}
        onHeaderContextMenu={e => {
          console.log('>> header context menu', e);
        }}
        gridSelection={selection}
        onGridSelectionChange={select}
        // rowMarkers={{
        //   kind: 'both',
        //   checkboxStyle: 'square',
        // }}
        theme={{
          fontFamily:
            '-apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          cellHorizontalPadding: 12,
          cellVerticalPadding: 12,
          headerIconSize: 12,
          baseFontStyle: 'normal 12px',
          headerFontStyle: 'normal 12px',
          headerBottomBorderColor: theme.colors.neutral.$100,
          borderColor: theme.colors.neutral.$100,
          drilldownBorder: theme.colors.neutral.$100,
          horizontalBorderColor: theme.colors.neutral.$100,
          accentColor: theme.colors.brand.$4,
          accentLight: theme.colors.neutral.$75,
          bgCell: theme.colors.neutral.$0,
          bgCellMedium: theme.colors.neutral.$0,
          bgHeader: theme.colors.neutral.$0,
          bgBubble: theme.colors.neutral.$100,
          bgBubbleSelected: theme.colors.neutral.$150,
          bgHeaderHasFocus: theme.colors.neutral.$0,
          bgHeaderHovered: theme.colors.neutral.$0,
          bgIconHeader: theme.colors.neutral.$0,
          bgSearchResult: theme.colors.neutral.$0,
          textDark: theme.colors.neutral.$700,
          textLight: theme.colors.neutral.$700,
          textMedium: theme.colors.neutral.$700,
          textBubble: theme.colors.neutral.$700,
          textHeader: theme.colors.neutral.$500,
          textGroupHeader: theme.colors.neutral.$500,
          textHeaderSelected: theme.colors.neutral.$700,
        }}
      />
    </View>
  );
}

const headerIcons: SpriteMap = {
  up: () =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m18 15l-6-6l-6 6"/></svg>`,
  down: () =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m6 9l6 6l6-6"/></svg>`,
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingHorizontal: 4,
  },
});
