import theme from 'config/theme';
import {HoveredView} from 'interface/base/HoveredView';
import Typography from 'interface/base/Typography';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import {useGetUserDetailsCached} from 'interface/common/hooks/useGetUserDetailsCached';
import {StyleSheet, View} from 'react-native';
import {useParams} from 'react-router';
import type {FilesItem} from 'store/slices/files/types';
import {parseServerDate} from 'utils/common/dates';
import {removeExtension} from 'utils/fast/files';
import {TimeLabel} from '../../WorkspaceAiChat/components/AiChatTimeLabel';

interface Props {
  note: FilesItem;
  onPress: () => void;
}

export function NoteItem({note, onPress}: Props) {
  const {fileId} = useParams();

  const userId = note.origin.creator;

  const {initials} = useGetUserDetailsCached(userId);

  const updatedAt = parseServerDate(note.modified);

  const isActive = fileId === note.id;

  return (
    <HoveredView
      style={[styles.container, isActive && styles.backgroundActive]}
      onPress={onPress}>
      <View style={styles.titleRow}>
        <Typography
          size="md"
          variant="medium"
          color={theme.colors.neutral.$700}
          numberOfLines={1}>
          {removeExtension(note.name)}
        </Typography>
      </View>

      <View style={styles.detailsRow}>
        <MemberAvatar initials={initials} memberId={userId} size={-2} />

        <TimeLabel date={updatedAt} />
      </View>
    </HoveredView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingTop: 16,
    paddingBottom: 6,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$75,
  },
  backgroundActive: {
    backgroundColor: theme.colors.neutral.$50,
  },
  titleRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsRow: {
    marginTop: 6,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    flexWrap: 'wrap',
  },
  body: {
    width: '100%',
    paddingVertical: 6,
  },
  parentFolder: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});
