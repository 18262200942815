import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import {
  type Store,
  WorkspaceLayoutTabs,
  type WorkspaceSort,
  WorkspaceStatus,
} from './types';

export const defaultSort: WorkspaceSort = {category: 'name', order: 'asc'};

export const initialState: Store = {
  workspacesLoaded: false,
  workspacesLoading: false,
  workspaces: [],
  sort: defaultSort,
  searchOpen: false,
  recentSearches: {},
  layoutTab: WorkspaceLayoutTabs.Grid,
  workspaceStatus: WorkspaceStatus.All,
  membersMap: {},
};
const sliceName = 'workspace';

export default createSlice({name: sliceName, reducers, initialState});
export {selectors};
