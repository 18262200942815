import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useHover} from 'interface/common/hooks/useHover';
import {Pressable, StyleSheet} from 'react-native';
import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';
import {AiChatMembers} from './AiChatMembers';

interface Props {
  chat: EntityChat;
  workspaceMembers: Member[];
  isSelected: boolean;
  onPress: () => void;
}
export function AiChatItem({
  chat,
  workspaceMembers,
  onPress,
  isSelected,
}: Props) {
  const isPrivate = chat.privacy?.visibility === 'private';
  const {isHover, onHoverIn, onHoverOut} = useHover();

  return (
    <Pressable
      onPress={onPress}
      //@ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      style={[
        styles.container,
        isSelected && styles.backgroundActive,
        isHover && styles.hoverStyle,
      ]}>
      <Typography
        size="sm"
        variant="regular"
        color={theme.colors.neutral.$700}
        overrides={styles.chatName}
        numberOfLines={1}>
        {chat.name}
      </Typography>
      {isPrivate && (
        <Icon name="lucide:lock" size={16} color={theme.colors.neutral.$300} />
      )}
      <AiChatMembers
        chatId={chat.id}
        workspaceMembers={workspaceMembers}
        maxMembers={2}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingStart: 8,
    paddingEnd: 2,
    paddingVertical: 2,
    borderRadius: 6,
    gap: 8,
  },
  backgroundActive: {
    backgroundColor: theme.colors.neutral.$100,
  },
  hoverStyle: {
    backgroundColor: theme.colors.neutral.$75,
  },
  chatName: {
    flex: 1,
  },
});
