import {DEFAULT_ERROR_TOAST_OPTIONS} from 'constants/errors';
import {ErrorFlowActions} from 'errors/types';
import {useEffect, useRef, useState} from 'react';
import {Toast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as errors from 'store/slices/errors';
import {ErrorToast} from '../ErrorToast';

const OLD_MESSAGES_CLEAR_INTERVAL_MS = 5 * 1000;

export const useShowToastErrors = () => {
  const toastErrors = useSelector(errors.selectors.getToastErrors);
  const [displayedToasts, setDisplayedToasts] = useState<string[]>([]);
  const displayedMessages = useRef(new Set());

  const isNewError = errorItem => {
    if (!errorItem) return false;

    if (displayedToasts.includes(errorItem.id)) return false;

    const messageContent =
      errorItem.errorConfiguration?.message || errorItem.message;
    if (displayedMessages.current.has(messageContent)) {
      setDisplayedToasts(prev => [...prev, errorItem.id]);
      return false;
    }

    return true;
  };

  const getToastOptions = errorItem => {
    const {
      errorConfiguration: {flowAction, allowRetry, toastOptions},
    } = errorItem;
    const canBeRetried = flowAction === ErrorFlowActions.Toast && allowRetry;

    return {
      ...DEFAULT_ERROR_TOAST_OPTIONS,
      ...toastOptions,
      id: errorItem.id,
      duration: canBeRetried
        ? 0
        : (toastOptions?.duration ?? DEFAULT_ERROR_TOAST_OPTIONS.duration),
    };
  };

  const showErrorToast = (errorItem, toastOptions) => {
    const messageContent = errorItem.message;
    displayedMessages.current.add(messageContent);

    Toast.show(
      <ErrorToast errorItem={errorItem} toastId={toastOptions.id} />,
      toastOptions,
    );
    setDisplayedToasts(prev => [...prev, toastOptions.id]);
  };

  const clearOldMessages = () => {
    displayedMessages.current.clear();
    setDisplayedToasts([]);
  };

  useEffect(() => {
    if (toastErrors.length) {
      const latestError = toastErrors?.at(-1);
      if (isNewError(latestError)) {
        const toastOptions = getToastOptions(latestError);
        showErrorToast(latestError, toastOptions);
      }
    }
  }, [toastErrors]);

  useEffect(() => {
    const interval = setInterval(
      clearOldMessages,
      OLD_MESSAGES_CLEAR_INTERVAL_MS,
    );
    return () => clearInterval(interval);
  }, []);
};
