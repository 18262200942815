import theme from 'config/theme';
import type {ShareType} from 'fast-sdk/src/api/share/consts';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {capitalizeFirstLetter} from 'utils/common/string';
import {ShareTypeColor, ShareTypeIcon} from 'utils/fast/shares';

interface ShareTypeCellProps {
  shareType: ShareType;
}

const ShareTypeCell = ({shareType}: ShareTypeCellProps) => {
  return (
    <View
      style={[styles.shareType, {backgroundColor: ShareTypeColor[shareType]}]}>
      <Icon
        name={ShareTypeIcon[shareType]}
        color={theme.colors.neutral.$2Base}
      />
      <Typography size="xs" color={theme.colors.neutral.$2Base}>
        {capitalizeFirstLetter(shareType)}
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  shareType: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 100,
  },
});

export default ShareTypeCell;
