import type {ModalOptions} from 'extensions/viewport/ModalContext';
import type React from 'react';
import {useState} from 'react';
import {BaseModal} from './BaseModal';

export function useModalLocal() {
  const [ariaLabel, setAriaLabel] = useState<string>();
  const [content, setContent] = useState<JSX.Element>();
  const [options, setOptions] = useState<ModalOptions>({});
  const [visible, setVisible] = useState<boolean>(false);

  const ModalWrapper: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
      <>
        {children}
        <BaseModal
          visible={visible}
          content={content}
          options={options}
          ariaLabel={ariaLabel}
          onClose={close}
        />
      </>
    );
  };

  return {
    visible: visible,
    open: (newContent: JSX.Element, options?: ModalOptions) => {
      setOptions(options ?? {});
      setContent(newContent);
      setVisible(true);
    },
    close: () => {
      setVisible(false);
      setOptions({});
    },
    setAriaLabel: (ariaLabel: string) => {
      setAriaLabel(ariaLabel);
    },
    ModalWrapper,
  };
}
