import {theme} from 'config/themes';
import type {ModalOptions} from 'extensions/viewport/ModalContext';
import type React from 'react';
import {Modal, StyleSheet, View, useWindowDimensions} from 'react-native';

interface Props {
  options?: ModalOptions;
  content?: JSX.Element;
  visible?: boolean;
  ariaLabel?: string;
  onClose?: () => void;
}

export function BaseModal({
  options,
  content,
  visible,
  ariaLabel,
  onClose,
}: Props) {
  const screen = useWindowDimensions();
  const classes = {
    root: [
      !options.frameless ? styles.root : styles.noframe,
      options.frameless ? styles.noframe : styles.root,
    ],
    content: [
      options.frameless ? styles.noframeContent : styles.content,
      screen.width < 500 && styles.contentSmall,
    ],
  };

  return (
    <>
      <Modal
        role="dialog"
        transparent
        visible={visible}
        style={options.style}
        aria-label={ariaLabel}
        onDismiss={
          options.forceChoice
            ? options.disableClose
              ? undefined
              : onClose
            : undefined
        }>
        <View
          style={classes.root}
          onPointerDown={options.disableClose ? undefined : onClose}>
          <View
            style={classes.content}
            onPointerDown={e => e.stopPropagation()}>
            {content}
          </View>
        </View>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.dialog.overlay,
    // @ts-ignore
    overflow: 'auto',
  },
  content: {
    minWidth: 360,
    minHeight: 225,
    justifyContent: 'center',
    margin: 'auto',
  },
  contentSmall: {
    minWidth: 300,
  },
  noframe: {
    flex: 1,
  },
  noframeContent: {
    flex: 0,
  },
});
