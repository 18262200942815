import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import {useEditorContext} from 'interface/stacks/content-viewer/context/EditorContext';
import useFileMenu from 'interface/stacks/content-viewer/hooks/useFileMenu';
import {useGoBack} from 'interface/stacks/content-viewer/hooks/useGoBack';
import type React from 'react';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';

interface EditorActionsProps {
  showFileMenu?: boolean;
  showCancelButton?: boolean;
  onCancelPress?: () => void;
}

export const EditorActions = ({
  showFileMenu = true,
  showCancelButton = true,
  onCancelPress,
}: EditorActionsProps) => {
  const {onGoBack} = useGoBack();

  const {file, handleSave, hasChanges, saveLoading} = useEditorContext();

  const [updatedFile, setUpdatedFile] = useState<FilesItem | null>(file);

  const {menu, ref} = useFileMenu(updatedFile);

  const onSavePress = async () => {
    const newFile = await handleSave(updatedFile);
    if (newFile) {
      setUpdatedFile(newFile);
    }
  };

  const handleCancelPress = () => {
    if (onCancelPress) {
      onCancelPress();
    } else {
      onGoBack();
    }
  };

  useEffect(() => {
    if (file) {
      setUpdatedFile(file);
    }
  }, [file]);

  const isSaveDisabled = Boolean(!hasChanges && updatedFile);
  const cancelText = hasChanges
    ? updatedFile
      ? 'Discard changes'
      : 'Cancel'
    : 'Close';

  return (
    <View style={styles.container}>
      <Button
        onPress={onSavePress}
        loading={saveLoading}
        disabled={isSaveDisabled}>
        {t`Save`}
      </Button>
      {(showCancelButton || (hasChanges && updatedFile)) && (
        <Button
          onPress={handleCancelPress}
          variant="secondary"
          disabled={saveLoading}>
          {t`${cancelText}`}
        </Button>
      )}
      {showFileMenu && updatedFile && (
        <HoveredView
          style={styles.menuButton}
          theme="dark"
          reference={ref}
          onPress={e => menu(e)}>
          <Icon
            name="lucide:ellipsis"
            size={24}
            color={theme.colors.neutral.$white}
          />
        </HoveredView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  menuButton: {
    padding: 8,
  },
});
