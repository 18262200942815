import {api} from 'fast-sdk';
import type {ShareMemberListInvitationItem} from 'fast-sdk/src/api/share/consts';
import Modal from 'interface/common/Modal';
import {useCallback, useEffect, useState} from 'react';
import useFetchShareViewStatus from '../hooks/useFetchShareViewStatus';
import useGetShareDetails from '../hooks/useGetShareDetails';
import {AddRecipientModalContent} from './AddRecipientModalContent';
import {ShareSettings} from './ShareSettings';

interface Props {
  shareId: string;
  workspaceFolderName: string;
  done: () => void;
  displayTitle?: boolean;
}

export enum ShareMembersView {
  ShareSettings = 'shareSettings',
  AddRecipient = 'addRecipient',
}

export function ShareModal({
  shareId,
  workspaceFolderName,
  done,
  displayTitle = true,
}: Props) {
  const {share, isShareLoaded} = useGetShareDetails({
    shareId,
    workspaceFolderName,
  });
  const {refetchShareMembers} = useFetchShareViewStatus({
    share,
    sharedCustomName: share?.custom_name,
    isShareLoaded,
  });

  const [emails, setEmails] = useState<Array<string>>([]);
  const [invitations, setInvitations] =
    useState<Array<ShareMemberListInvitationItem> | null>(null);
  const [loadingInvitations, setLoadingInvitations] = useState(true);

  const refetchShareMemberList = () => {
    refetchShareMembers();
    fetchInvitations();
  };

  const onInvitesSend = (success: boolean) => {
    if (success) {
      refetchShareMemberList();
    }
    setEmails([]);
  };

  const fetchInvitations = useCallback(async () => {
    setLoadingInvitations(true);
    try {
      const {result, invitations} =
        await api.share.getAllShareMembersInvitation(share?.id);
      if (result) setInvitations(invitations);
    } finally {
      setLoadingInvitations(false);
    }
  }, [share?.id]);

  useEffect(() => {
    if (share?.id) {
      fetchInvitations();
    }
  }, [share?.id]);

  const currentView =
    emails.length > 0
      ? ShareMembersView.AddRecipient
      : ShareMembersView.ShareSettings;

  return (
    <Modal
      title={`Share Settings${displayTitle ? ` - ${share?.name}` : ''}`}
      close={done}>
      <ShareSettings
        share={share}
        emails={emails}
        setEmails={setEmails}
        done={done}
        currentView={currentView}
        invitations={invitations}
        loadingInvitations={loadingInvitations}
        fetchInvitations={refetchShareMemberList}
      />
      {currentView === ShareMembersView.AddRecipient && (
        <AddRecipientModalContent
          share={share}
          emails={emails}
          setEmails={setEmails}
          onInvitesSend={onInvitesSend}
        />
      )}
    </Modal>
  );
}
