import LogoDark from 'assets/fastio_logo_assets/logo_dark.svg';
import LogoLight from 'assets/fastio_logo_assets/logo_light.svg';
import {ROUTES} from 'constants/routes';
import {Image, Pressable, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import * as app from 'store/slices/app';

interface AppLogoProps {
  kind?: 'light' | 'dark';
}

export default function AppLogo({kind = 'light'}: AppLogoProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const handleOnPress = () => {
    const isSignInRoutes = Object.values(ROUTES.NOT_LOGGED_IN).includes(
      location.pathname.slice(1),
    );
    if (!isLoggedIn && isSignInRoutes) {
      window.location.href = 'https://fast.io/';
    } else {
      navigate('/');
    }
  };

  return (
    <Pressable onPress={handleOnPress}>
      <Image
        source={kind === 'light' ? LogoLight : LogoDark}
        style={styles.logo}
        alt="Fast.io logo"
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  logo: {
    width: 129,
    height: 48,
  },
});
