import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {useFetchSingleChatData} from 'interface/stacks/workspace/layout/WorkspaceAiChat/hooks/useFetchSingleChatData';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as activity from 'store/slices/activity';
import type {ActivityTypeWithTimestamp} from 'store/slices/activity/types';
import * as aichat from 'store/slices/aichat';
import {getCurrentUTCDateTime, getDatesDifference} from 'utils/common/dates';
import useItemChangeDetector from './useItemChangeDetector';

interface UseSyncChatDataOptions {
  namespace: StorageNamespace;
  profileId: string;
  profileName: string;
  activities: ActivityTypeWithTimestamp;
}

const useSyncChatData = ({
  namespace,
  profileId,
  profileName,
  activities,
}: UseSyncChatDataOptions) => {
  const dispatch = useDispatch();
  const currentChatId = useSelector(
    aichat.selectors.getCurrentChat(namespace, profileId),
  );
  const {fetchChatData} = useFetchSingleChatData(
    namespace,
    profileId,
    profileName,
  );

  // Memoize the startStreaming function to avoid recreating it on every render
  const startStreaming = useCallback(
    (chatId: string) => {
      console.log(`Starting streaming for chat: ${chatId}`);
      dispatch(aichat.default.actions.setTriggerStreaming(chatId));
    },
    [dispatch],
  );

  // Increased time window for streaming updates to catch more events
  const checkStreamUpdate = (
    lastServerUpdate: string,
    lastClientUpdate: string,
  ) => {
    const msDifference = getDatesDifference(
      lastServerUpdate,
      getCurrentUTCDateTime(),
    );
    // Increase window from 5 to 8 seconds to be more permissive with updates
    return msDifference < 8 * 1000;
  };

  // Make chat updates more responsive by reducing the time window
  const checkChatUpdate = (
    lastServerUpdate: string,
    lastClientUpdate: string,
  ) => {
    try {
      const msDifference = getDatesDifference(
        lastServerUpdate,
        lastClientUpdate,
      );
      // Reduce the window to make updates more responsive
      return msDifference < 60 * 1000; // 1 minute instead of 2
    } catch (error) {
      return true;
    }
  };

  // Enhanced handler for AI chat changes
  const onAiChatChange = useCallback(
    ({id: storageActivityKey, lastServerUpdate}) => {
      const shouldStream = storageActivityKey.endsWith('-IN_PROGRESS');

      if (shouldStream) {
        const cleanStorageKey = storageActivityKey.replace('-IN_PROGRESS', '');
        const cleanChatId = cleanStorageKey.split(':')[1];
        const chatActivity = activities[cleanStorageKey];

        // More permissive check to ensure we don't miss streaming updates
        const shouldTriggerStream =
          currentChatId === cleanChatId &&
          (!chatActivity?.lastClientUpdate ||
            checkStreamUpdate(
              lastServerUpdate,
              chatActivity?.lastClientUpdate,
            ));

        if (shouldTriggerStream) {
          // Trigger stream update and also ensure we have the latest data
          startStreaming(cleanChatId);
          fetchChatData(cleanChatId);
        }
      } else {
        const cleanChatId = storageActivityKey.split(':')[1];
        const chatActivity = activities[storageActivityKey];

        if (
          !chatActivity?.lastClientUpdate ||
          checkChatUpdate(lastServerUpdate, chatActivity?.lastClientUpdate)
        ) {
          if (currentChatId === null || currentChatId === cleanChatId) {
            fetchChatData(cleanChatId);
          }
        }
      }
    },
    [activities, currentChatId, fetchChatData, startStreaming],
  );

  const chatActivitySelector =
    namespace === 'share'
      ? activity.selectors.getShareActivityForChat
      : namespace === 'workspace'
        ? activity.selectors.getWorkspaceActivityForChat
        : undefined;

  useItemChangeDetector(
    chatActivitySelector(profileId, `${ActivityTypes.AI_CHAT}:`),
    onAiChatChange,
  );

  // Add a safety net to periodically refresh the current chat to ensure we don't miss updates
  useEffect(() => {
    if (!currentChatId) return;

    // Only refresh every 20 seconds as a fallback - this is a safety net
    // Not too frequent to avoid unnecessary network traffic
    const refreshInterval = setInterval(() => {
      fetchChatData(currentChatId);
    }, 20000);

    return () => clearInterval(refreshInterval);
  }, [currentChatId, fetchChatData]);
};

export default useSyncChatData;
