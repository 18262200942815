import {t} from '@lingui/macro';
import theme from 'config/theme';
// import {Button} from 'interface/common/Button';
import {Button} from 'interface/base/Button';
import {Input} from 'interface/base/Input';
import {Textarea} from 'interface/base/Textarea';
import Typography from 'interface/base/Typography';
import Modal from 'interface/common/Modal';
import {useFormSubmit} from 'interface/common/hooks/useFormSubmit';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import type {Organization} from 'store/slices/user/types';
import useCreateWorkspace from '../hooks/useCreateWorkspace';
import {useGetWorkspaceDetails} from '../hooks/useGetWorkspaceDetails';

interface WorkspaceCreateProps {
  close: () => void;
  organization?: Organization;
  setIsReloading?: (loading: boolean) => void;
}

export function WorkspaceCreate(props: WorkspaceCreateProps) {
  const toast = useToast();
  const [workspaceName, setWorkspaceName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  const currentSelectedOrganization = useSelector(
    user.selectors.getSelectedOrganization,
  );
  const orgToCreateWorkspace =
    props.organization || currentSelectedOrganization;

  const {fetchWorkspaceDetails} = useGetWorkspaceDetails();
  const {createWorkspace} = useCreateWorkspace();

  const reloadWorkspaces = async (workspaceId: string) => {
    if (props.setIsReloading) {
      try {
        props.setIsReloading(true);
        await fetchWorkspaceDetails(workspaceId);
      } finally {
        props.setIsReloading(false);
      }
    } else {
      fetchWorkspaceDetails(workspaceId);
    }
  };

  const createNewWorkspace = async () => {
    if (!!description && description.length < 10) {
      toast.show(t`Description must be at least 10 characters`, {
        type: 'danger',
      });
      return;
    }

    try {
      setCreateLoading(true);
      const {result, workspace: workspaceCreated} = await createWorkspace({
        subdomain: orgToCreateWorkspace.domain,
        orgId: orgToCreateWorkspace.id,
        name: workspaceName.trim(),
        description: description?.trim(),
        intelligence: true,
      });
      if (result) {
        toast.show(`The "${workspaceName.trim()}" workspace is created`, {
          type: 'success',
        });
        reloadWorkspaces(workspaceCreated.id);
      }
    } finally {
      setCreateLoading(false);
      props.close();
    }
  };

  useFormSubmit(createNewWorkspace, !workspaceName);

  return (
    <Modal title={t`Create a Workspace`} close={props.close}>
      <Typography
        overrides={styles.label}
        variant="regular"
        size="xs"
        color={theme.colors.neutral.$700}>
        {t`Workspace name`}
      </Typography>
      <Input
        overrides={{
          Root: {
            style: styles.formEntryRoot,
          },
        }}
        placeholder={t`Enter workspace name`}
        value={workspaceName}
        onChangeValue={setWorkspaceName}
      />
      <Typography
        overrides={styles.label}
        variant="regular"
        size="xs"
        color={theme.colors.neutral.$700}>
        {t`Workspace description`}
      </Typography>
      <Textarea
        overrides={{
          Root: {
            style: styles.formEntryRoot,
          },
        }}
        aria-label={t`Workspace description`}
        value={description}
        placeholder="Enter a description"
        onChangeValue={setDescription}
        maxLength={1000}
      />

      <View style={styles.buttonsContainer}>
        <Button variant="secondary" onPress={props.close}>{t`Cancel`}</Button>
        <Button
          variant="primary"
          loading={createLoading}
          onPress={createNewWorkspace}
          disabled={!workspaceName}
          overrides={{
            Loading: {
              style: {
                color: theme.colors.neutral.$white,
              },
            },
          }}>{t`Create`}</Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 450,
    paddingBottom: 24,
    backgroundColor: '#fff',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 24,
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
  },
  label: {
    marginBottom: 6,
  },
  formEntryRoot: {
    marginBottom: 8,
  },
});
