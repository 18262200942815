import theme from 'config/theme';
import {api} from 'fast-sdk';
import type {ItemVersion} from 'fast-sdk/src/api/storage/consts';
import Typography from 'interface/base/Typography';
import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {parseServerDate} from 'utils/common/dates';
import PreviewSidePanelVersionChange from '../PreviewSidePanelVersionChange';
import PreviewSidePanelVersionSkeleton from '../PreviewSidePanelVersionSkeleton';
import {useGetNodeType} from '../hooks/useGetNodeType';

const VERSIONS_SKELETON_ITEMS = 2;

type Props = {
  workspaceId: string;
  parentId: string;
  selectedId: string;
};

export function InfoPanelVersionsTab({
  workspaceId,
  parentId,
  selectedId,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [versions, setVersions] = useState<ItemVersion[]>([]);

  const {type} = useGetNodeType(parentId, workspaceId);

  useEffect(() => {
    const fetchVersions = async () => {
      const isShare = parentId?.startsWith('5');

      const instanceNs = isShare ? 'share' : 'workspace';
      const instanceId = isShare ? parentId : workspaceId;
      const fileId = selectedId || parentId;

      if (instanceId && fileId) {
        try {
          setLoading(true);
          const storage = api.storage.init(instanceNs, instanceId);
          const {result, versions} = await storage.getVersions(fileId);

          if (result) {
            setVersions(versions.items);
          } else {
            setVersions([]);
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchVersions();
  }, [workspaceId, parentId, selectedId]);

  return (
    <>
      <View>
        <View style={styles.current}>
          <View style={styles.left}>
            <View style={styles.circle} />
            <View style={styles.line} />
          </View>
          <Typography
            variant="semi-bold"
            size="sm"
            color={theme.colors.neutral.$2Base}
            overrides={styles.currentVersion}>
            Current Version
          </Typography>
        </View>
        {loading &&
          [...Array(VERSIONS_SKELETON_ITEMS)].map((_, index) => (
            <PreviewSidePanelVersionSkeleton
              key={`item-${index}`}
              last={index === VERSIONS_SKELETON_ITEMS - 1}
            />
          ))}
        {!loading &&
          versions.map((version, index) => (
            <PreviewSidePanelVersionChange
              key={`version-${version.created}`}
              action={version.origin.operations[0] ?? 'modified'}
              date={parseServerDate(version.created)}
              userId={version.origin.creator}
              last={index === versions.length - 1}
              type={type}
            />
          ))}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  current: {
    height: 50,
    borderRadius: 7,
    backgroundColor: theme.colors.brand.$9,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'flex-end',
  },
  left: {
    width: 30,
    alignItems: 'center',
  },
  currentVersion: {
    alignSelf: 'center',
  },
  line: {
    width: 2,
    height: 20,
    backgroundColor: theme.colors.brand.$7,
  },
  circle: {
    width: 10,
    height: 10,
    backgroundColor: theme.colors.brand.$7,
    borderRadius: 50,
  },
  link: {
    alignSelf: 'flex-start',
  },
  linkText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.brand.$4Base,
  },
});
