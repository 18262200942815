// @ts-ignore
import AiLogo from 'assets/svgs/aichat/ai.svg';
import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {Image, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import type {FilesItem} from 'store/slices/files/types';
import CreateNewChatPopup from '../../WorkspaceAiChat/components/CreateNewChatPopup';
import {NEW_CHAT, NEW_PRIVATE_CHAT} from '../../WorkspaceAiChat/consts';
import {TooltipPressable} from './TooltipPressable';

interface Props {
  file: FilesItem;
  type: 'folder' | 'file' | 'share' | 'workspace' | 'other' | 'note';
}

export function InfoPanelAiSection({file, type}: Props) {
  const dispatch = useDispatch();

  const isWorkspace = type === 'workspace';
  const isAiReady = isWorkspace ? true : file.ai.state === 'ready';
  const isAttachable = isWorkspace ? false : file.ai.attach;

  const aiNotReadyMessage = isWorkspace
    ? ''
    : file.ai.state === 'pending'
      ? 'AI indexing pending'
      : file.ai.state === 'in_progress'
        ? 'AI indexing in progress'
        : file.ai.state === 'failed'
          ? 'AI indexing failed'
          : 'AI indexing not supported';

  const aiNotReadyIcon = isWorkspace
    ? ''
    : file.ai.state === 'disabled'
      ? 'lucide:info'
      : file.ai.state === 'failed'
        ? 'lucide:alert-triangle'
        : 'lucide:clock';

  const {namespace, instanceId} = useGetNamespaceContext();
  const columnInfoOverride = useSelector(app.selectors.columnInfoOverride);
  const currentChat = useSelector(
    aichat.selectors.getCurrentChat(namespace, instanceId),
  );

  const attachTooltipText = isAttachable
    ? currentChat
      ? 'Attach to AI Chat'
      : 'Attach to new AI Chat'
    : 'Unable to attach to AI Chat';

  const showAttachButton =
    currentChat &&
    columnInfoOverride[ColumnManagerInfoKeys.AIChat] &&
    (type === 'file' || type === 'note');

  const handleAttach = () => {
    if (currentChat) {
      dispatch(aichat.default.actions.addAttachFiles({files: [file]}));
    } else {
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: NEW_CHAT,
        }),
      );
      dispatch(aichat.default.actions.addAttachFiles({files: [file]}));
    }
  };

  const handleNewChatPress = (isPrivate: boolean) => {
    dispatch(
      app.default.actions.setColumnInfoOverride({
        [ColumnManagerInfoKeys.AIChat]: true,
      }),
    );
    if (isPrivate) {
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: NEW_PRIVATE_CHAT,
        }),
      );
    } else {
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: NEW_CHAT,
        }),
      );
    }

    dispatch(aichat.default.actions.addAttachFiles({files: [file]}));
  };

  return (
    <View style={styles.container}>
      {isAiReady ? (
        <View style={styles.state}>
          <Image source={AiLogo} style={{width: 18, height: 18}} />
          <Typography
            variant="regular"
            size="xs"
            color={theme.colors.neutral.$700}>
            AI indexed
          </Typography>
        </View>
      ) : (
        <View style={styles.state}>
          <Icon name={aiNotReadyIcon} color={theme.colors.neutral.$5} />
          <Typography
            variant="regular"
            size="xs"
            color={theme.colors.neutral.$700}>
            {aiNotReadyMessage}
          </Typography>
        </View>
      )}
      <View style={styles.buttonsContainer}>
        <CreateNewChatPopup
          onNewChatPress={handleNewChatPress}
          icon="lucide:messages-square"
          text="New Chat"
          overrides={{
            Button: {
              style: styles.popupButton,
            },
            Text: {style: styles.buttonText},
          }}
          customTriggerStyle={{flex: 1, maxWidth: '50%'}}
        />
        {showAttachButton && (
          <TooltipPressable
            tooltipText={attachTooltipText}
            buttonStyle={[styles.attach]}
            disabled={!isAttachable}
            onPress={() => handleAttach()}>
            <View style={{flexDirection: 'row', gap: 6}}>
              <Icon name="lucide:paperclip" />
              <Typography
                variant="regular"
                size="xs"
                color={theme.colors.neutral.$700}>
                Attach
              </Typography>
            </View>
          </TooltipPressable>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 8,
  },
  state: {
    flex: 1,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 6,
    minHeight: 32,
    paddingHorizontal: 8,
    paddingVertical: 4,
    gap: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  attach: {
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 6,
    height: 32,
    // width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonsContainer: {
    flexDirection: 'row',
    gap: 8,
  },
  buttonContainer: {
    flexBasis: '50%',
  },
  buttonText: {
    fontSize: 12,
  },
  popupButton: {
    width: '100%',
  },
});
