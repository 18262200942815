import events from 'extensions/events';
import {type ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {StyleSheet, type TextStyle, View, type ViewStyle} from 'react-native';

import theme from 'config/theme';
import {Button, type VARIANT} from 'interface/base/Button';
import type {ButtonOverrides} from 'interface/base/Button/types';
import {Input} from 'interface/base/Input';
import Typography from 'interface/base/Typography';
import Modal, {type ModalSize} from './Modal';
import {useFormSubmit} from './hooks/useFormSubmit';

export interface PromptProps {
  title?: string;
  text?: string;
  maxWidth?: number;
  maxHeight?: number;
  rootStyle?: ViewStyle;
  children?: ReactNode;
  buttons?: PromptButton[];
  options?: PromptOptions;
  onClose: () => void;
  size?: ModalSize;
}

export interface PromptButton {
  variant?: keyof typeof VARIANT;
  text?: string;
  disabled?: boolean | ((v: string) => boolean);
  textStyle?: TextStyle;
  buttonStyle?: ViewStyle;
  onPress?: (inputValue?: string) => void;
  isSubmit?: boolean;
  overrides?: ButtonOverrides;
}

export interface PromptOptions {
  type?: 'default' | 'plain-text' | 'secure-text';
  keyboardType?: 'default' | 'email-address' | 'numeric' | 'number-pad';
  autoCompleteType?: 'email' | 'name' | 'password' | 'username' | 'off';
  selectInput?: 'none' | 'all' | 'filename';
  defaultValue?: string;
  maxLength?: number;
  placeholder?: string;
  primaryColor?: string;
  forceChoice?: boolean;
  isMinimal?: boolean;
}

export function Prompt(props: PromptProps) {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(props.options?.defaultValue ?? undefined);

  const pressButton = useCallback((button: PromptButton, value?: string) => {
    if (button.onPress) {
      button.onPress(value);
    }
  }, []);

  const submit = () => {
    const submitButton = props.buttons?.find(b => b.isSubmit);
    const isSubmitDisabled =
      typeof submitButton?.disabled === 'boolean'
        ? submitButton.disabled
        : submitButton?.disabled?.(input);
    if (submitButton && !loading && !isSubmitDisabled) {
      setLoading(true);
      submitButton.onPress?.(input);
    }
  };

  const disabled = useMemo(() => {
    const submitButton = props.buttons?.find(b => b.isSubmit);
    return typeof submitButton?.disabled === 'boolean'
      ? submitButton.disabled
      : submitButton?.disabled?.(input);
  }, [props.buttons, input]);

  useFormSubmit(submit, disabled);

  const renderButton = useCallback(
    (button: PromptButton, index: number) => (
      <Button
        key={index}
        overrides={button.overrides}
        variant={button.variant}
        loading={button.isSubmit && loading}
        disabled={
          (button.isSubmit && loading) ||
          (typeof button?.disabled === 'boolean'
            ? button.disabled
            : button?.disabled?.(input))
        }
        onPress={async e => {
          e.stopPropagation();
          if (button.isSubmit) {
            setLoading(true);
          }
          pressButton(button, input);
        }}>
        {button.text}
      </Button>
    ),
    [pressButton, input, loading],
  );

  useEffect(
    () =>
      events.addKeyListener('up', e => {
        if (e.key === 'Escape') props.onClose();
      }).unsubscribe,
    [props.onClose],
  );

  return (
    <Modal title={props.title} size={props.size}>
      {props.children}
      {!!props.text && !props.children && (
        <Typography
          variant="regular"
          size="md"
          color={theme.colors.neutral.$700}>
          {props.text}
        </Typography>
      )}
      {props.options?.type && props.options.type !== 'default' && (
        <Input
          autoFocus
          onChangeValue={setInput}
          maxLength={props.options?.maxLength}
          defaultValue={props.options?.defaultValue}
          keyboardType={props.options?.keyboardType}
          placeholder={props.options?.placeholder}
          // @ts-ignore
          autoCompleteType={props.options?.autoCompleteType}
          secureTextEntry={props.options?.type === 'secure-text'}
          selectionMode={props.options?.selectInput}
        />
      )}
      {props.buttons && props.buttons.length > 0 && (
        <View style={styles.buttons}>{props.buttons.map(renderButton)}</View>
      )}
    </Modal>
  );
}

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 24,
    gap: 6,
  },
  close: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    marginRight: 6,
    padding: 8,
    height: 46,
    width: 46,
    right: 0,
  },
  spacer: {
    width: 6,
  },
});
