import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Requests, api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {Button} from 'interface/common/Button';
import {FormError} from 'interface/common/FormError';
import {useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import user from 'store/slices/user';
import {handleSignNavigation} from 'utils/fast/auth';
import {RouteLayout} from '../_layout/RouteLayout';
import Auth from '../consts';

interface PasswordResetCompletedProps {
  password: string;
}

export const PasswordResetCompleted = (props: PasswordResetCompletedProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const continueToDashboard = async () => {
    setErrorMessage('');
    const userEmail = await Auth.getResetPasswordEmail();
    if (!userEmail) {
      // The user email should be stored in the indexedDB in the forgot password page
      // if this toast is showing the email is not stored correctly in the forgot password
      // page, so we need to redirect the user to the "forgot password" page again
      toast.show(
        t`The user email is not embedded correctly please try the process again`,
        {type: 'danger'},
      );
      navigate(ROUTES.NOT_LOGGED_IN.FORGOT_PASSWORD);
      return;
    }
    setLoginLoading(true);

    try {
      const signInRes = await api.auth.signIn({
        email: userEmail,
        password: props.password,
      });

      if (!signInRes.result) {
        throw Error(signInRes.error?.text);
      }

      Requests.setAuthToken(signInRes.auth_token);
      await Auth.setAuthToken(signInRes.auth_token);
      await Auth.setUserEmail(userEmail);
      dispatch(user.actions.setUserToken(signInRes.auth_token));

      const [userRes, availableProfilesRes] = await Promise.all([
        api.user.userDetails(),
        api.user.getAvailableProfiles(),
      ]);

      if (userRes.error?.code === ApiErrors.UserNotValidated) {
        return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`);
      }

      if (!userRes.result) {
        return setErrorMessage(userRes.error?.text);
      }

      dispatch(user.actions.setUserDetails(userRes.user));

      if (!availableProfilesRes.result) {
        return setErrorMessage(availableProfilesRes.error?.text);
      }

      const {has_orgs, has_workspaces, has_shares} = availableProfilesRes;
      dispatch(
        user.actions.setAvailableProfiles({
          hasOrgs: has_orgs,
          hasWorkspaces: has_workspaces,
          hasShares: has_shares,
        }),
      );

      handleSignNavigation(navigate, null, null, {
        authToken: signInRes.auth_token,
        user: userRes.user,
        hasOrgs: has_orgs,
        hasWorkspaces: has_workspaces,
        hasShares: has_shares,
        isSignIn: false,
      });
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <RouteLayout
      title={t`Password reset complete`}
      subTitle=""
      customContentContainerStyle={{marginBottom: '2rem'}}>
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <Button
        type="Primary"
        label={t`Continue`}
        ariaLabel={t`Continue`}
        customRootStyle={{height: 48}}
        onPress={continueToDashboard}
        loading={loginLoading}
        disabled={loginLoading}
        customLoadingColor={theme.colors.neutral.$white}
      />
    </RouteLayout>
  );
};
