import {t} from '@lingui/macro';
import theme from 'config/theme';
import type {ShareLink} from 'fast-sdk/src/api/share/consts';
import {Input} from 'interface/base/Input';
import Typography from 'interface/base/Typography';
import {Button} from 'interface/common/Button';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {validateUrl} from 'utils/common/validation';

interface LinkFormProps {
  currentLink: ShareLink;
  setCurrentLink: (link?: ShareLink) => void;
  saveLink: () => void;
  removeAndCancelLink: () => void;
  isDraft: boolean;
}

export default function LinkForm({
  currentLink,
  setCurrentLink,
  saveLink,
  removeAndCancelLink,
  isDraft,
}: LinkFormProps) {
  const [touched, setTouched] = useState(false);
  const isValidUrl = currentLink.url && validateUrl(currentLink.url);
  return (
    <View style={styles.linkForm}>
      <Typography
        overrides={styles.label}
        size="xs"
        color={theme.colors.neutral.$700}>
        {t`Label`}
      </Typography>
      <Input
        value={currentLink.title}
        placeholder={'Enter a label'}
        onChangeValue={title => setCurrentLink({...currentLink, title})}
      />
      <Typography
        overrides={[styles.label, {marginTop: 8}]}
        size="xs"
        color={theme.colors.neutral.$700}>
        {t`Label`}
      </Typography>
      <Input
        placeholder={'https://mywebsite.com'}
        value={currentLink.url}
        onChangeValue={url => setCurrentLink({...currentLink, url})}
        error={touched && !isValidUrl}
        errorMessage={t`Invalid url`}
        aria-label="URL"
        onFocusFn={() => setTouched(true)}
      />
      <View style={styles.formFooter}>
        <View>
          {!isDraft && (
            <Button
              type="Secondary"
              label={t`Remove`}
              onPress={removeAndCancelLink}
            />
          )}
        </View>
        <View style={styles.rightButtons}>
          <Button
            type="Secondary"
            label={t`Cancel`}
            onPress={() => setCurrentLink(undefined)}
          />
          <Button
            type="Primary"
            label={isDraft ? t`Add Link` : t`Save Link`}
            onPress={saveLink}
            disabled={!currentLink.title || !isValidUrl}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  linkForm: {
    flexDirection: 'column',
    display: 'flex',
  },
  formFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 6,
  },
  rightButtons: {
    flexDirection: 'row',
    gap: 10,
  },
  label: {
    marginBottom: 6,
  },
});
