import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  prev: null,
  next: null,
  files: [],
  urls: {},
  isThumbnail: null,
  uploadedBy: null,
  showPromoDialog: false,
};

export default createSlice({name: 'content-viewer', reducers, initialState});
export {selectors};
