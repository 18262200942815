import type {ButtonState} from './types';

export const getButtonState = ({
  hovered,
  focused,
  disabled,
  loading,
}: ButtonState) => {
  if (loading) return 'default';
  if (disabled) return 'disabled';
  if (hovered) return 'hover';
  if (focused) return 'focus';
  return 'default';
};
