import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {api} from 'fast-sdk';
import type {User} from 'fast-sdk/src/api/user/consts';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {Button} from 'interface/common/Button';
import {FormError} from 'interface/common/FormError';
import {Loading} from 'interface/common/Loading';
import {useProccessPendingInvitation} from 'interface/common/hooks/useProccessPendingInvitation';
import {Fragment, useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {RouteLayout} from '../_layout/RouteLayout';
import OTPInput from '../components/OTPInput';
import Auth from '../consts';

interface SignUpVerificationProps {
  isModal?: boolean;
  onEmailVerified?: (userDetails: User, token: string) => void;
  onTryDifferentEmail?: () => void;
}

export function SignUpVerification(props: SignUpVerificationProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>();
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const invitation = useSelector(app.selectors.getCurrentJoinInvitation);
  const redirectTo = useSelector(app.selectors.getRedirectTo);
  const token = useSelector(user.selectors.getToken);

  const {handleInvitationFlow} = useProccessPendingInvitation();

  useEffect(() => {
    async function getEmail() {
      const email = await Auth.getUserEmail();
      setEmail(email);
    }

    getEmail();
  }, []);

  useEffect(() => {
    if (email) {
      sendVerification();
    }
  }, [email]);

  const fetchUserDetails = async () => {
    const {result, user: userDetails} = await api.user.userDetails();

    if (result) {
      dispatch(user.default.actions.setUserDetails({...userDetails}));

      if (props.onEmailVerified) {
        return props.onEmailVerified(userDetails, token);
      }

      if (invitation) {
        return handleInvitationFlow(invitation);
      }

      if (redirectTo) {
        return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
      }

      return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
    }

    return undefined;
  };

  const validateCode = async (code: string) => {
    if (!email) {
      return setErrorMessage(
        'There is a problem with your email please try again',
      );
    }

    if (code) {
      setPageLoading(true);
      const validateResponse = await api.user.validateEmail({
        email_token: code,
        email,
      });

      if (!validateResponse.result) {
        setPageLoading(false);
        return setErrorMessage('Invalid code');
      }

      toast.show('Your email validation was successful', {
        type: 'success',
      });

      await fetchUserDetails();

      setPageLoading(false);
    }
  };

  const sendVerification = async () => {
    setErrorMessage('');
    setLoading(true);

    try {
      const {result, error} = await api.user.sendValidationEmail(email);

      if (result) {
        toast.show(`A verification link was sent to ${email}`, {
          type: 'success',
        });
      } else {
        if (error.code === ApiErrors.UserEmailAlreadyVerified) {
          setErrorMessage('User already exists');
          await fetchUserDetails();
        } else {
          setErrorMessage(error.text);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onStartTyping = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  return (
    <RouteLayout
      title="Check your email for a code"
      subTitle={`A code was sent to ${email}. If you don't see the email, check your spam folder. This code is only valid for 10 minutes.`}
      customContentContainerStyle={{marginBottom: '3rem'}}
      customRootMaxWidth={props.isModal ? '100%' : undefined}
      disableAutoMargin={props.isModal}>
      {loading ? (
        <Loading size="large" customColor={theme.colors.brand.$4Base} />
      ) : (
        <Fragment>
          {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
          <View style={styles.fullWidth}>
            <OTPInput
              validate={validateCode}
              disabled={pageLoading}
              onStartTyping={onStartTyping}
            />
            {pageLoading ? (
              <View style={styles.loadingContainer}>
                <Loading size="small" />
                <Text style={styles.loadingText}>Verifying code…</Text>
              </View>
            ) : (
              <View style={styles.bottom}>
                <Button
                  type="Text"
                  label={t`Resend verification email`}
                  onPress={() => sendVerification()}
                  customRootStyle={{paddingVertical: '1rem'}}
                />
                <Button
                  type="Text"
                  label={t`Try a different email`}
                  onPress={() => {
                    dispatch(user.default.actions.deleteUserDetails());
                    Auth.setAuthToken('');
                    props.onTryDifferentEmail?.();
                  }}
                  customRootStyle={{paddingVertical: '1rem'}}
                />
              </View>
            )}
          </View>
        </Fragment>
      )}
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 48,
    padding: 10,
    marginBottom: '1.5rem',
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
  fullWidth: {
    width: '100%',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    height: 48,
    borderRadius: 5,
    marginBottom: '1.5rem',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: theme.colors.danger.$8,
    borderWidth: 2,
    borderColor: theme.colors.danger.$4Base,
    borderRadius: 5,
    marginBottom: '1.5rem',
  },
  errorMessage: {
    color: theme.colors.danger.$e2,
    textAlign: 'center',
    width: '100%',
  },
  bottom: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 42,
  },
  loadingContainer: {
    flexDirection: 'row',
    gap: 10,
    height: 24,
    marginTop: 21,
  },
  loadingText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$5,
  },
});
