import {useParams} from 'extensions/navigation';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import ContentViewer from 'interface/stacks/content-viewer/ContentViewer';
import {ShareNotAvailableCard} from 'interface/stacks/share/layout/ShareNotAvailableCard';
import {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as contentViewer from 'store/slices/content-viewer';
import useGetQuickShare from '../hooks/useGetQuickShare';
import {PromoDialog} from './PromoDialog';

const QuickShareViewer = () => {
  const {quickShareId} = useParams();
  const dispatch = useDispatch();

  const showPromoDialog = useSelector(
    contentViewer.selectors.getShowPromoDialog,
  );

  const {file, isLoading} = useGetQuickShare(quickShareId);

  useEffect(() => {
    dispatch(
      contentViewer.default.actions.setNeighbors({prev: null, next: null}),
    );
  }, []);

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <Fragment>
      <ContentViewer
        file={file}
        emptyState={<ShareNotAvailableCard isQuickShare />}
      />
      {showPromoDialog && <PromoDialog />}
    </Fragment>
  );
};

export default QuickShareViewer;
