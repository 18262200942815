import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';

import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {NoResultsAvailable} from 'interface/common/NoResultsAvailable';
import {Fragment} from 'react';
import * as files from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import {GROUP_BY_DAY, NOTE_LIST_HEADER_HEIGHT} from '../consts';
import {groupNotesByDay} from '../helpers';
import {NoteItem} from './NoteItem';
import {NoteListHeader} from './NoteListHeader';

interface Props {
  namespace: string;
  instanceId: string;
  onNotePress: (note: FilesItem) => void;
  onNewNotePress: () => void;
  onCloseSidebar: () => void;
  showMenu?: boolean;
}

export function NotesList({
  namespace,
  instanceId,
  onNotePress,
  onNewNotePress,
  onCloseSidebar,
  showMenu,
}: Props) {
  const notes = useSelector(files.selectors.getNotes(namespace, instanceId));

  const groupedNotes = groupNotesByDay(notes);
  return (
    <View style={styles.container}>
      {showMenu ? (
        <NoteListHeader
          onNewNotePress={onNewNotePress}
          onCloseSidebar={onCloseSidebar}
        />
      ) : (
        <View style={{height: NOTE_LIST_HEADER_HEIGHT}} />
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollView}>
        <View style={styles.notesContainer}>
          {!notes?.length ? (
            <NoResultsAvailable message="No notes available" />
          ) : (
            <View style={styles.notesList}>
              {groupedNotes &&
                Object.entries(groupedNotes).map(([key, items]) => (
                  <Fragment>
                    {items.length > 0 && (
                      <Typography
                        overrides={styles.groupTitle}
                        size="sm"
                        color={theme.colors.neutral.$500}>
                        {GROUP_BY_DAY[key]}
                      </Typography>
                    )}

                    {items.map((note, index) => (
                      <NoteItem
                        key={index}
                        note={note}
                        onPress={() => onNotePress(note)}
                      />
                    ))}
                  </Fragment>
                ))}
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.neutral.$white,
  },
  scrollView: {
    borderRightWidth: 1,
    borderColor: theme.colors.neutral.$75,
    height: '100%',
  },
  notesContainer: {
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  notesList: {
    width: '100%',
    alignSelf: 'center',
    paddingLeft: 16,
  },
  groupTitle: {
    marginVertical: 16,
  },
});
