import {ROUTES} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import {useModal} from 'extensions/viewport/useModal';
import useFetchOrgs from 'interface/common/hooks/useFetchOrgs';
import {useShares} from 'interface/common/hooks/useShares';
import {useEffect, useMemo, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import {getCookie} from 'utils/common/cookies';
import {AppLoading} from '../app/AppLoading';
import {OnboardSlideModal, SLIDES_KEY} from '../onboard/base/OnboardSlideModal';
import {OnboardSteps} from '../onboard/lib/consts';
import {useLayoutWorkspaces} from '../workspace/hooks/useLayoutWorkspaces';
import PageContent from './components/content';
import PageHeader from './components/header';
import PageSkeleton from './components/skeleton';
import useRedirectTo from './hooks/useRedirecTo';
import {AppTabs} from './types';

export default function ProfileMembership() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal = useModal();

  const {activeTab} = useParams<{activeTab: AppTabs}>();

  const {fetchOrganizations, loading: isFetchingOrgs} = useFetchOrgs();
  const {
    fetchAndUpdateShares,
    loading: isSharesLoading,
    setSharesLoaded,
  } = useShares();
  const {fetchAndUpdateWorkspaces, isFetchLoading: isFetchingWorkspaces} =
    useLayoutWorkspaces({
      onWorkspacesRefresh: fetchAndUpdateShares,
      setSharesLoaded,
    });
  const {redirectTo, hasActiveSession} = useRedirectTo();

  const userDetails = useSelector(user.selectors.getUserDetails);
  const organizations = useSelector(user.selectors.getOrganizationsList);
  const {externalShareDomains, externalWorkspaceDomains} = useSelector(
    user.selectors.getExternalOrgDomains,
  );
  const workspaces = useSelector(workspace.selectors.getAllWorkspaces);

  const [searchFilter, setSearchFilter] = useState('');
  const [isRedirecting] = useState(Boolean(redirectTo));

  const setActiveTab = (tab: AppTabs) => {
    if (tab !== activeTab)
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${tab}`);
  };

  const setCurrentPage = (tabPageName: string) => {
    dispatch(
      app.default.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: `Home - ${tabPageName}`,
      }),
    );
  };

  const handleCreateNewOrganization = () => {
    dispatch(onboarding.default.actions.restartOnboarding());
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Organization}`,
    );
  };

  const [orgs, expiredOrgs] = useMemo(() => {
    return [
      organizations.filter(org => org.subscriber),
      organizations.filter(org => !org.subscriber),
    ];
  }, [organizations]);

  const openOnboardSlideModal = () => {
    const showOnboardSlides = getCookie(`${SLIDES_KEY}_${userDetails?.id}`);

    const isNewUserWithOneOrgCreated =
      organizations.length === 1 && workspaces.length === 1;
    const subscriber = organizations?.[0]?.subscriber;
    const isNewUserWithOneOrgUnsubscribed =
      organizations.length === 1 && subscriber === false;
    const isNewUserWithoutOrgsOrWorkspaces =
      organizations.length === 0 && workspaces.length === 0;

    if (
      !showOnboardSlides &&
      (isNewUserWithOneOrgCreated ||
        isNewUserWithOneOrgUnsubscribed ||
        isNewUserWithoutOrgsOrWorkspaces)
    ) {
      modal.open(<OnboardSlideModal onClose={modal.close} />, {
        disableClose: true,
      });
    }
  };

  useEffect(() => {
    fetchOrganizations();
    const disabledCache = Boolean(redirectTo);
    fetchAndUpdateWorkspaces(disabledCache);
  }, []);

  useEffect(() => {
    const tabPageName =
      activeTab === AppTabs.Workspaces ? 'Workspaces' : 'Shares';
    setCurrentPage(tabPageName);
  }, [activeTab]);

  const isWorkspaces = activeTab === AppTabs.Workspaces;
  const loading =
    isFetchingOrgs &&
    !organizations.length &&
    !externalShareDomains.length &&
    !externalWorkspaceDomains.length;

  const isWorkspacesHidden = Boolean(
    !organizations.length &&
      !externalWorkspaceDomains?.length &&
      externalShareDomains?.length,
  );

  useEffect(() => {
    const shouldDefaultToShares =
      isWorkspacesHidden && activeTab === AppTabs.Workspaces;

    if (shouldDefaultToShares) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${AppTabs.Shares}`);
    }
  }, [isWorkspacesHidden]);

  useEffect(() => {
    if (!isFetchingWorkspaces && !isFetchingOrgs) {
      openOnboardSlideModal();
    }
  }, [
    organizations.length,
    workspaces.length,
    isFetchingOrgs,
    isFetchingWorkspaces,
  ]);

  if (isRedirecting || !hasActiveSession) {
    return <AppLoading />;
  }

  return (
    <View style={styles.root}>
      <PageHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onNewOrganizationPress={handleCreateNewOrganization}
        isWorkspacesHidden={isWorkspacesHidden}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        workspaces={workspaces}
      />
      {loading ? (
        <PageSkeleton isWorkspaces={isWorkspaces} />
      ) : (
        <PageContent
          externalShareDomains={externalShareDomains}
          externalWorkspaceDomains={externalWorkspaceDomains}
          organizations={orgs}
          expiredOrgs={expiredOrgs}
          isWorkspaces={isWorkspaces}
          isSharesLoading={isSharesLoading}
          onNewOrganizationPress={handleCreateNewOrganization}
          searchFilter={searchFilter}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 24,
    gap: 32,
    paddingBottom: 64,
  },
});
