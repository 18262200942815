import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {useModal} from 'extensions/viewport/useModal';
import {Transfers} from 'fast-sdk';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {DropdownButton} from 'interface/common/DropdownButton';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import {StyleSheet, View} from 'react-native';
import {SharedCustomizeDialog} from '../shared/dialogs/SharedCustomizeDialog';
import {useModalCreateFolder} from './hooks/useModalCreateFolder';

interface Props {
  folderKey: string;
  instanceId: string;
  instanceAlt: string;
  instanceNs: StorageNamespace;
  uploader: UploaderState;
  close: () => void;
}

export function NewStorageNodeDropdown({
  folderKey,
  instanceId,
  instanceAlt,
  instanceNs,
  uploader,
  close,
}: Props) {
  const modal = useModal();
  const navigate = useNavigate();

  const modalCreateFolder = useModalCreateFolder(
    folderKey,
    instanceAlt,
    instanceNs,
  );

  const openCreateShareDialog = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={instanceAlt}
        close={modal.close}
        folderParentId={folderKey}
      />,
    );
    close();
  };

  const addFilesFromLocal = async () => {
    const files = await Transfers.pickFiles();
    uploader.instance.addFiles(files, folderKey, instanceId);
  };

  const onAddNewFolder = () => {
    modalCreateFolder.open();
    close();
  };

  const onUploadFromDevice = async () => {
    addFilesFromLocal();
    close();
  };

  const onUploadFromWorkspace = () => {
    console.log('TODO: Upload from workspace');
    close();
  };

  const onCreateNote = () => {
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.MARKDOWN_EDITOR}`, {
      state: {
        text: '',
        fileName: 'Untitled note',
        instanceId: instanceAlt,
        instanceNs: instanceNs,
        parentFolder: folderKey,
      },
    });
    close();
  };

  const isShare = instanceNs === 'share';

  return (
    <View style={styles.root} aria-live="polite">
      {!isShare && (
        <DropdownButton
          iconName="package-variant-closed"
          label="Create share"
          onPress={openCreateShareDialog}
          key="Create share"
        />
      )}
      <DropdownButton
        iconName="lucide:notebook-text"
        label="Create note"
        onPress={onCreateNote}
        key="Create note"
      />
      <DropdownButton
        showDivider
        iconName="folder-plus"
        label="Add new folder"
        onPress={onAddNewFolder}
        key="Add new folder"
      />
      <DropdownButton
        iconName="tray-arrow-up"
        label="Upload from device"
        onPress={onUploadFromDevice}
        key="Upload from device"
      />
      {/* <DropdownButton
        iconName="monitor-dashboard"
        label="Upload from workspace"
        onPress={onUploadFromWorkspace}
        key="Upload from workspace"
      /> */}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 250,
    boxShadow:
      '0px 6px 12px 0px rgba(0, 0, 0, 0.07), 0px 0px 0px 1px rgba(0, 0, 0, 0.04)',
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 6,
    paddingVertical: 4,
  },
});
