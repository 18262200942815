import {api} from 'fast-sdk';
import type {WorkspaceMember} from 'fast-sdk/src/api/workspace/consts';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import store from 'store';
import * as app from 'store/slices/app';
import type {Member} from 'store/slices/settings/types';
import * as workspace from 'store/slices/workspace';

type Options = {
  enabled?: boolean;
};

const CACHE_KEY = 'workspace-members';
const LIMIT = 1;
const TIME = 0.5;

export const useWorkspaceMembers = (
  workspaceId: string,
  options: Options = {enabled: true},
) => {
  const dispatch = useDispatch();

  const {members, loading, error} = useSelector(
    workspace.selectors.getWorkspaceMembers(workspaceId),
  );

  const buildMembers = (members: Array<WorkspaceMember>) => {
    const memberList: Array<Member> = [];
    for (const member of members) {
      memberList.push({
        authentication: member.permissions,
        firstName: member.first_name,
        lastName: member.last_name,
        email: member.email_address,
        profilePic: member.profile_pic,
        dateJoined: new Date(),
        id: member.id,
      });
    }
    return memberList;
  };

  const fetchWorkspaceMembers = useCallback(async () => {
    if (!workspaceId || !workspaceId.startsWith('4')) return;

    const key = `${CACHE_KEY}-${workspaceId}`;

    const canBeCalled = app.selectors.canEndpointBeCalled(
      key,
      LIMIT,
      TIME,
    )(store.getState());

    if (!canBeCalled) return;

    dispatch(
      workspace.default.actions.setWorkspaceMembers({
        workspaceId,
        loading: true,
        error: false,
      }),
    );

    try {
      const response = await api.workspace.getWorkspaceMembers(workspaceId);
      if (response.result) {
        const memberList = buildMembers(response.users);
        dispatch(
          workspace.default.actions.setWorkspaceMembers({
            workspaceId,
            loading: false,
            error: false,
            members: memberList,
          }),
        );
        dispatch(
          app.default.actions.setEndpointCallsLimitEntry({
            key,
            times: LIMIT,
            sent: canBeCalled ? new Date() : undefined,
          }),
        );
      } else {
        dispatch(
          workspace.default.actions.setWorkspaceMembers({
            workspaceId,
            loading: false,
            error: true,
          }),
        );
      }
    } catch (error) {
      dispatch(
        workspace.default.actions.setWorkspaceMembers({
          workspaceId,
          loading: false,
          error: true,
        }),
      );
    }
  }, [workspaceId]);

  useEffect(() => {
    if (options.enabled) fetchWorkspaceMembers();
  }, [options.enabled, fetchWorkspaceMembers]);

  return {
    isLoading: loading,
    isError: error,
    members,
  };
};
