import {createSlice} from '@reduxjs/toolkit';
import {WorkspaceInfoPanelTab} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/types/WorkspaceInfoPanelTab';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {FileLayoutType} from 'interface/stacks/workspace/storage/FolderHeaderLayout';
import {FileSummaryTabs} from 'interface/stacks/workspace/storage/FolderHeaderSummary';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  sort: {category: 'name', order: 'asc'},
  filter: {type: 'all'},
  layout: FileListItemLayout.ListNormal,
  items: {},
  share: {},
  workspace: {},
  selection: [],
  focusedParentId: '',
  focusedId: '',
  following: null,
  focusedType: WorkspaceInfoPanelTab.FileInfo,
  fileLayoutType: FileLayoutType.Storage,
  fileSummaryType: FileSummaryTabs.Details,
  thumbnails: {},
  skipClearFocus: false,
  skipResetFolderView: false,
};
const sliceName = 'files';

const filesSliceTransform = {
  in: (state, key) => {
    if (key === sliceName) {
      return {
        ...state,
        pick: initialState.pick,
      };
    }
    return state;
  },
  out: state => state,
};

export default createSlice({name: 'sliceName', reducers, initialState});
export {filesSliceTransform, selectors};
