import {t} from '@lingui/macro';
import theme from 'config/theme';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import Typography from 'interface/base/Typography';
import {BREAKPOINTS} from 'interface/common/hooks/useBreakpoints';
import {Fragment, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {slices} from 'store';
import {selectors} from 'store/slices/files';

export enum FileLayoutType {
  Storage = 'storage',
  Metadata = 'metadata',
}

export function FolderHeaderLayout({containerWidth}: {containerWidth: number}) {
  const [tabHovered, setTabHovered] = useState(false);
  const isSmall = containerWidth && containerWidth < BREAKPOINTS.sm;
  const active = useSelector(selectors.getFileLayoutType);
  const tabs = [
    createTabItem(
      FileLayoutType.Storage,
      isSmall ? '' : 'Files',
      'lucide:file',
      active,
      18,
      theme.colors.brand.$1_500,
    ),
    createTabItem(
      FileLayoutType.Metadata,
      isSmall ? '' : 'Metadata',
      'lucide:square-code',
      active,
      18,
      theme.colors.brand.$1_500,
    ),
  ];

  const dispatch = useDispatch();
  return (
    <Pressable style={styles.root}>
      {({hovered}) => (
        <Fragment>
          <Tabs
            tabs={tabs}
            activeTab={active}
            rootStyle={styles.root}
            tabStyle={isSmall ? styles.tab : styles.tabFull}
            activeTextColor={isSmall ? undefined : theme.colors.brand.$1_500}
            onHoverIn={() => setTabHovered(true)}
            onHoverOut={() => setTabHovered(false)}
            setActiveTab={(value: FileLayoutType) =>
              dispatch(slices.files.actions.fileLayoutType({type: value}))
            }
          />
          {(tabHovered || hovered) && (
            <View style={[styles.tooltip, !isSmall && {left: 0}]}>
              <Typography size="xs" color={theme.colors.neutral.$white}>
                {active === FileLayoutType.Storage
                  ? t`Showing files`
                  : t`Showing metadata`}
              </Typography>
              <View style={styles.arrow} pointerEvents="none" />
            </View>
          )}
        </Fragment>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    height: 32,
  },
  tab: {
    minWidth: 32,
    paddingLeft: 0,
    paddingRight: 0,
    height: 28,
    flex: 1,
  },
  tabFull: {
    minWidth: 108,
    height: 28,
    flex: 1,
  },
  tooltip: {
    position: 'absolute',
    zIndex: 10,
    top: -55,
    left: -80,
    width: 160,
    backgroundColor: theme.colors.neutral.$2Base,
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 10,
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    bottom: -5,
    left: 'calc(50% + 30px)',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5.5,
    borderRightWidth: 5.5,
    borderTopWidth: 6,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: theme.colors.neutral.$2Base,
    zIndex: 99999,
  },
  activeText: {
    color: theme.colors.brand.$1_500,
  },
});
