import theme from 'config/theme';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {
  formatExifMetadataItem,
  getFileExifMetadata,
} from 'interface/stacks/content-viewer/utils';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import {capitalizeFirstLetter} from 'utils/common/string';
import {useGetNodeType} from '../hooks/useGetNodeType';
import {InfoPanelDetailItem} from './InfoPanelDetailItem';

interface Props {
  focusedId: string;
  workspaceId: string;
  dark?: boolean;
}

interface DetailItem {
  label: string;
  value: string | number;
}

const renderMetadataRow = ({label, value}: DetailItem, dark: boolean) => {
  const [formattedLabel, formattedValue] = formatExifMetadataItem([
    label,
    value,
  ]);
  return (
    <InfoPanelDetailItem
      key={label}
      title={formattedLabel}
      value={formattedValue?.toString() ?? '-'}
      dark={dark}
    />
  );
};

export function InfoPanelDetailsTab({focusedId, workspaceId, dark}: Props) {
  const {type, item} = useGetNodeType(focusedId, workspaceId);

  const rows: DetailItem[] = [];
  switch (type) {
    case 'folder':
    case 'note':
    case 'file': {
      const fileItem = item as FilesItem;
      rows.push({
        label: 'Type',
        value: capitalizeFirstLetter(type),
      });
      rows.push({label: 'Size', value: fileItem.size});
      rows.push({
        label: 'Date Created',
        value: fileItem.created,
      });
      if (type === 'file' || type === 'note') {
        const metadata = getFileExifMetadata(fileItem);
        Object.entries(metadata ?? []).forEach(([k, v]) => {
          rows.push({label: k, value: v.toString()});
        });
      }
      break;
    }
    case 'share': {
      const shareItem = item as Share;
      const type = `Share, ${capitalizeFirstLetter(shareItem.share_type)}`;

      rows.push({label: 'Type', value: type});
      rows.push({label: 'Size', value: shareItem.size});
      rows.push({
        label: 'Date Created',
        value: shareItem.created,
      });
      break;
    }
    case 'workspace': {
      const workspaceItem = item as WorkspaceListDetail;
      rows.push({label: 'Type', value: 'Workspace'});
      rows.push({label: 'Size', value: null});
      rows.push({
        label: 'Date Created',
        value: workspaceItem.created,
      });
      break;
    }
  }

  return (
    <View style={[styles.root, dark && {backgroundColor: '#232738'}]}>
      {rows.map(row => renderMetadataRow(row, dark))}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'column',
    padding: 8,
    gap: 8,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 6,
  },
});
