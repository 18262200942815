import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseServerDate} from 'utils/common/dates';
import {
  getStorageMetadataList,
  getStorageTemplates,
  getWorkspaceTemplate,
  getWorkspaceTemplates,
} from 'utils/fast/metadata';
import {DEFAULT_COLUMNS} from '../consts';
import {getColumns, getRow, getTemplateIcon} from '../utils';
import {useThumbnails} from './useThumbnails';

import type {GridColumn} from '@glideapps/glide-data-grid';
import type {MetadataItem, TemplateItem} from 'fast-sdk/src/api/storage/consts';
import type {WorkspaceMetadataTemplate} from 'fast-sdk/src/api/workspace/consts';
import {slices} from 'store';
import type {MetadataState, SortState} from '../types';

export function useMetadata(
  folderKey = 'root',
  disabled?: boolean,
): MetadataState {
  const {
    instanceName,
    instanceId,
    namespace: instanceNs,
  } = useGetNamespaceContext();
  const [metadataColumns, setMetadataColumns] = useState<string[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [metadataList, setMetadataList] = useState<MetadataItem[]>([]);
  const [templates, setTemplates] = useState<TemplateItem[]>([]);
  const [templateId, setTemplateId] = useState<string | null>();
  const [templateDb, setTemplateDb] = useState<
    Record<string, WorkspaceMetadataTemplate>
  >({});
  const [isLoading, setIsLoading] = useState(false);
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});
  const [sortState, setSortState] = useState<SortState>({
    columnId: null,
    direction: 'desc',
  });
  const thumbnails = useSelector((state: any) => state.files.thumbnails);

  const dispatch = useDispatch();

  const getTemplateData = useCallback(
    (id: string) => {
      const lookup = templateDb[id];
      return {
        ...lookup,
        icon: getTemplateIcon(lookup?.category),
      };
    },
    [templateDb],
  );

  const selectTemplate = useCallback((id: string) => {
    setTemplateId(id);
  }, []);

  const toggleColumn = useCallback((column: string, enabled: boolean) => {
    if (!enabled) {
      setHiddenColumns(prev => [...prev, column]);
    } else {
      setHiddenColumns(prev => prev.filter(c => c !== column));
    }
  }, []);

  const resizeColumn = useCallback(
    (
      column: GridColumn,
      _newSize: number,
      _colIndex: number,
      newSizeWithGrow: number,
    ) => {
      setColumnWidths(prev => ({
        ...prev,
        [column.id]: newSizeWithGrow,
      }));
    },
    [],
  );

  const sortColumn = useCallback((columnId: string) => {
    setSortState(prev => {
      // If the same column is clicked, toggle the sort direction
      if (prev.columnId === columnId) {
        return {
          columnId,
          direction: prev.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      // If a different column is clicked, sort descending by default
      return {
        columnId,
        direction: 'desc',
      };
    });
  }, []);

  const rearrangeColumn = useCallback(
    (startIndex: number, endIndex: number) => {
      setMetadataColumns(prev => {
        const newCols = [...prev];
        const [toMove] = newCols.splice(startIndex, 1);
        newCols.splice(endIndex, 0, toMove);
        return newCols;
      });
    },
    [],
  );

  const updateTemplateDb = useCallback(
    async (ids: string[]) => {
      const results = await Promise.all(
        ids.map(id => getWorkspaceTemplate(instanceId, id)),
      );
      // Merge results into existing templateDb
      setTemplateDb(prev => ({
        ...prev,
        ...results.reduce(
          (acc, result) => {
            if (result.result && result.item) {
              acc[result.item.id] = result.item;
            }
            return acc;
          },
          {} as Record<string, WorkspaceMetadataTemplate>,
        ),
      }));
    },
    [instanceId],
  );

  // Fetch all workspace template data when instance changes
  useEffect(() => {
    if (!instanceId || disabled) return;
    const fetchTemplates = async () => {
      const {result, items} = await getWorkspaceTemplates(instanceId);
      if (result && items.length > 0) {
        setTemplateDb(prev => ({
          ...prev,
          ...items.reduce(
            (acc, item) => {
              acc[item.id] = item;
              return acc;
            },
            {} as Record<string, WorkspaceMetadataTemplate>,
          ),
        }));
      } else {
        setTemplateDb({});
      }
    };
    fetchTemplates();
  }, [instanceId, disabled]);

  // Fetch used templates when folder/instance changes
  useEffect(() => {
    if (!folderKey || !instanceId || !instanceNs || disabled) return;
    const fetchTemplates = async () => {
      setIsLoading(true);
      try {
        // Get templates available in the current folder
        const {result, items} = await getStorageTemplates(
          folderKey,
          instanceId,
          instanceNs,
        );
        if (result && items.length > 0) {
          setTemplates(items);
          const {template_id} = items.reduce((max, current) =>
            current.object_count > max.object_count ? current : max,
          );
          selectTemplate(template_id);
          updateTemplateDb(items.map(item => item.template_id));
        } else {
          // Reset info if no templates found
          setMetadataColumns([]);
          setTemplates([]);
          setMetadataList([]);
          setTemplateId(null);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTemplates();
  }, [folderKey, instanceId, instanceNs, disabled]);

  // When template changes, update columns based on template fields
  useEffect(() => {
    if (!templateId) return;

    // Get the template data to access its fields
    const template = templateDb[templateId];

    if (template?.fields) {
      // Start with default columns
      const templateColumns = new Set<string>(DEFAULT_COLUMNS);

      // Add template fields as columns
      template.fields.forEach(field => {
        templateColumns.add(field.name);
      });

      // Update metadata columns with template fields even before metadata items are loaded
      setMetadataColumns(Array.from(templateColumns));
    }
  }, [templateId, templateDb]);

  // Fetch metadata list when selected template or folderkey changes
  useEffect(() => {
    if (!folderKey || !instanceId || !instanceNs || !templateId || disabled)
      return;
    const fetchMetadata = async () => {
      setIsLoading(true);
      try {
        // Get metadata for files using this template
        const {result, items} = await getStorageMetadataList(
          folderKey,
          templateId,
          instanceId,
          instanceNs,
        );
        if (result && items.length > 0) {
          setMetadataList(items);
          // Extract unique column names from metadata
          const uniqueKeys = new Set<string>(DEFAULT_COLUMNS);
          items.forEach(item => {
            item.metadata.forEach(meta => {
              uniqueKeys.add(meta.key);
            });
          });
          setMetadataColumns(Array.from(uniqueKeys));
          // Cache items in redux
          items.forEach(item => {
            dispatch(
              slices.files.actions.updateFile({
                file: item.node_id,
                instanceId: instanceName,
                instanceNs,
              }),
            );
          });
        } else {
          setMetadataList([]);
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMetadata();
  }, [templateId, folderKey, disabled]);

  // Sort the rows when sortState changes
  const sortedRows = useMemo(() => {
    // Map files to rows before sorting, excluding hidden columns
    const rowsToSort = metadataList
      .map(metadata =>
        getRow(metadata, metadataColumns, hiddenColumns, thumbnails),
      )
      .filter(row => row !== undefined);

    // If no sorting is applied, return unsorted rows
    if (!sortState.columnId) return rowsToSort;

    // Sort the rows based on the sortState
    return [...rowsToSort].sort((a, b) => {
      const columnId = sortState.columnId;

      // Skip sorting by hidden columns
      if (hiddenColumns.includes(columnId)) return 0;

      const aValue = a[columnId];
      const bValue = b[columnId];

      // Handle special case for name column which stores object with text property
      if (columnId === 'name') {
        const aName = aValue[0].text.toLowerCase();
        const bName = bValue[0].text.toLowerCase();
        return sortState.direction === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      }

      // Handle date comparison for created column
      if (columnId === 'created') {
        const aDate = parseServerDate(a.file.created).getTime();
        const bDate = parseServerDate(b.file.created).getTime();
        return sortState.direction === 'asc' ? aDate - bDate : bDate - aDate;
      }

      // Handle size comparison
      if (columnId === 'size') {
        const aSize = a.file.size;
        const bSize = b.file.size;
        return sortState.direction === 'asc' ? aSize - bSize : bSize - aSize;
      }

      // Default string comparison for custom metadata columns
      if (aValue !== undefined && bValue !== undefined) {
        // Handle number values
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortState.direction === 'asc'
            ? aValue - bValue
            : bValue - aValue;
        }

        // Convert to strings for comparison
        const aString = String(aValue).toLowerCase();
        const bString = String(bValue).toLowerCase();
        return sortState.direction === 'asc'
          ? aString.localeCompare(bString)
          : bString.localeCompare(aString);
      }

      // Handle cases where one value is undefined
      if (aValue === undefined && bValue !== undefined) {
        return sortState.direction === 'asc' ? -1 : 1;
      }
      if (aValue !== undefined && bValue === undefined) {
        return sortState.direction === 'asc' ? 1 : -1;
      }

      return 0;
    });
  }, [metadataColumns, metadataList, sortState, hiddenColumns, thumbnails]);

  const allColumns = useMemo(() => {
    return metadataColumns.map(column => {
      const columnId = column;
      const displayName = column
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return {
        name: columnId,
        displayName,
        enabled: !hiddenColumns.includes(columnId),
      };
    });
  }, [metadataColumns, hiddenColumns]);

  // Use the thumbnails hook with the current rows
  const {handleVisibleRegionChanged} = useThumbnails(sortedRows);

  return useMemo(
    () => ({
      getTemplateData,
      selectTemplate,
      toggleColumn,
      resizeColumn,
      rearrangeColumn,
      sortColumn,
      templateId,
      isLoading,
      templates,
      hiddenColumns,
      sortState,
      columns: getColumns(
        metadataColumns,
        columnWidths,
        sortState,
        hiddenColumns,
        sortedRows,
      ),
      allColumns,
      rows: sortedRows,
      handleVisibleRegionChanged,
    }),
    [
      isLoading,
      templates,
      templateId,
      metadataList,
      metadataColumns,
      hiddenColumns,
      columnWidths,
      folderKey,
      sortState,
      sortedRows,
      allColumns,
      selectTemplate,
      toggleColumn,
      resizeColumn,
      rearrangeColumn,
      sortColumn,
      handleVisibleRegionChanged,
    ],
  );
}
