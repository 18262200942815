import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {StyleSheet} from 'react-native';
import {TooltipPressable} from '../../WorkspaceInfoPanel/components/TooltipPressable';

type Props = {
  iconName: string;
  onPress: () => void;
  tooltipText?: string;
};

export function AiChatMenuButton({iconName, onPress, tooltipText}: Props) {
  return (
    <TooltipPressable
      tooltipText={tooltipText}
      onPress={onPress}
      wrap
      hoverable
      position={{vertical: 'bottom', horizontal: 'left'}}
      buttonStyle={styles.button}>
      <Icon name={iconName} color={theme.colors.neutral.$700} />
    </TooltipPressable>
  );
}

const styles = StyleSheet.create({
  button: {
    width: 32,
    height: 32,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
