import {useState} from 'react';
import type {EntityChatMessage} from 'store/slices/aichat/types';
import {getFilterInfoFromFileFilter} from '../helpers';
import {useCreateNewMessage} from './useCreateNewChatMessage';
export const useRegenerateMessage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {createChatMessage} = useCreateNewMessage();

  // TODO: AICHAT. Chat for a new API call that takes in only chatId and messageId
  const regenerateMessage = async (message: EntityChatMessage) => {
    setIsLoading(true);
    try {
      const attached = getFilterInfoFromFileFilter(
        message.content.attached,
        'file',
      );
      const scopeFiles = getFilterInfoFromFileFilter(
        message.content?.scope?.specific_files_scope?.files,
        'file',
      );
      const scopeFolders = getFilterInfoFromFileFilter(
        message.content?.scope?.specific_files_scope?.folders,
        'folder',
      );

      await createChatMessage(
        message.chatId,
        message.content?.text,
        message.personality,
        [...scopeFiles, ...scopeFolders],
        attached,
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, regenerateMessage};
};
