import {useState} from 'react';
import {StyleSheet, View} from 'react-native';

interface ColorPickerProps {
  color: string;
  onBlur: (color: string) => void;
}

export function ColorPicker(props: React.PropsWithChildren<ColorPickerProps>) {
  const [color, setColor] = useState(props.color);
  return (
    <View style={styles.root}>
      {props.children}
      <input
        type="color"
        value={`#${color}`}
        onChange={e => setColor(e.target.value.slice(1))}
        onBlur={() => props.onBlur(color)}
        style={{
          position: 'absolute',
          cursor: 'pointer',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    flexDirection: 'row',
  },
});
