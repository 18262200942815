import theme from 'config/theme';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {useCopyLink} from 'interface/stacks/app/hooks/useCopyLink';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import type {Member} from 'store/slices/settings/types';
import {AICHAT_TOOLBAR_HEIGHT, NEW_CHAT, NEW_PRIVATE_CHAT} from '../consts';
import {useUpdateChat} from '../hooks/useUpdateChat';
import {AiChatMembers} from './AiChatMembers';
import {AiChatMenuButton} from './AiChatMenuButton';
import {PrivateChatButton} from './PrivateChatButton';

interface Props {
  workspaceId: string;
  chatId: string;
  title: string;
  isPrivate: boolean;
  onOpenSidebar?: () => void;
  onNewChatPress: (isPrivate: boolean) => void;
  workspaceMembers: Member[];
  showMenu: boolean;
}

export function AiChatHeader({
  workspaceId,
  chatId,
  title,
  isPrivate,
  onOpenSidebar,
  onNewChatPress,
  workspaceMembers,
  showMenu,
}: Props) {
  const [name, setName] = useState(title);
  const {copyLink} = useCopyLink();
  const {isLoading: updateChatLoading, updateChat} = useUpdateChat(workspaceId);

  const changeChatName = async () => {
    await updateChat(chatId, name);
  };

  const handleCopyLinkPress = () => {
    const baseUrl = window.location.href.split('?')[0];
    copyLink(`${baseUrl}?chatId=${chatId}`, 'Link copied');
  };

  const isNewChat = chatId === NEW_CHAT || chatId === NEW_PRIVATE_CHAT;
  const titleDisabled = isNewChat || updateChatLoading;

  useEffect(() => {
    setName(title);
  }, [title]);

  const newPublicChat = () => {
    onNewChatPress(false);
  };

  const newPrivateChat = () => {
    onNewChatPress(true);
  };

  return (
    <View style={[styles.container]}>
      {showMenu && (
        <AiChatMenuButton
          iconName="lucide:sidebar"
          onPress={onOpenSidebar}
          tooltipText="Open sidebar"
        />
      )}
      {showMenu && !isNewChat && (
        <AiChatMenuButton
          iconName="lucide:edit"
          onPress={newPublicChat}
          tooltipText="New chat"
        />
      )}
      {showMenu && !isNewChat && (
        <AiChatMenuButton
          iconName="lucide:lock"
          onPress={newPrivateChat}
          tooltipText="New private chat"
        />
      )}
      {showMenu ? (
        <View style={styles.title}>
          <CustomTextInput
            value={name}
            customRootStyle={{width: '100%'}}
            customInputStyle={[
              styles.titleInput,
              {cursor: titleDisabled ? 'default' : 'text'},
            ]}
            setValue={setName}
            placeholder={'Chat name...'}
            ariaLabel={'Chat name'}
            disabled={titleDisabled}
            onBlur={changeChatName}
          />
        </View>
      ) : (
        <View style={{flex: 1}} />
      )}
      {isPrivate && <PrivateChatButton chatId={chatId} />}
      <AiChatMembers chatId={chatId} workspaceMembers={workspaceMembers} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 16,
    gap: 4,
    borderBottomColor: theme.colors.neutral.$75,
    position: 'relative',
    height: AICHAT_TOOLBAR_HEIGHT,
    backgroundColor: theme.colors.neutral.$0,
  },
  title: {
    flex: 1,
    width: '100%',
  },
  titleInput: {
    backgroundColor: 'transparent',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$700,
    boxShadow: '0',
  },
});
