import Tabs, {createTabItem} from 'interface/base/Tabs';
import useBillingDetails from 'interface/common/hooks/useBillingDetails';
import useBillingMeters from 'interface/common/hooks/useBillingMeters';
import {useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useParams} from 'react-router';
import {BillingTabs} from '../../base/consts';
import useBillingMembers from '../../hooks/useBillingMembers';
import type {Params} from '../../types';
import {CreditUsage} from './tabs/CreditUsage';
import {Summary} from './tabs/Summary';
import {WorkspaceUsage} from './tabs/WorkspaceUsage';

export function Billing() {
  const {subdomain} = useParams<Params>();
  const [activeTab, setActiveTab] = useState(BillingTabs.Summary);
  const {members, loading: loadingMembers} = useBillingMembers(subdomain);
  const {billingStatus, loading: loadingBillingStatus} =
    useBillingDetails(subdomain);
  const {meters, loading: loadingMeters} = useBillingMeters(
    subdomain,
    'credits',
  );

  const tabs = [
    createTabItem(
      BillingTabs.Summary,
      'Summary and Management',
      'lucide:book-text',
      activeTab,
    ),
    createTabItem(
      BillingTabs.WorkspaceUsage,
      'Workspace Usage',
      'lucide:users',
      activeTab,
    ),
    createTabItem(
      BillingTabs.CreditUsage,
      'Credit Usage',
      'lucide:coins',
      activeTab,
    ),
  ];

  return (
    <ScrollView style={styles.root}>
      <View style={styles.tabs}>
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={value => setActiveTab(value as BillingTabs)}
        />
      </View>
      <View style={styles.content}>
        {activeTab === BillingTabs.Summary && (
          <Summary
            billingDetails={billingStatus}
            members={members?.length}
            loadingMembers={loadingMembers}
            loadingBillingDetails={loadingBillingStatus}
            navigateToUserList={() => setActiveTab(BillingTabs.WorkspaceUsage)}
            creditsConsumed={
              meters?.data_points?.[meters.data_points.length - 1]?.value
            }
          />
        )}
        {activeTab === BillingTabs.WorkspaceUsage && (
          <WorkspaceUsage members={members} loading={loadingMembers} />
        )}
        {activeTab === BillingTabs.CreditUsage && (
          <CreditUsage
            domain={subdomain}
            creditsMeters={meters?.data_points}
            metersPricing={billingStatus?.current_plan?.pricing?.meters}
            loading={loadingMeters}
          />
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  tabs: {
    paddingTop: 8,
    paddingLeft: 8,
  },
  content: {
    marginTop: 48,
  },
});
