import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import {ShareExpiration} from './ShareExpiration';
import ShareLinks from './ShareLinks';

interface Props {
  share: Share;
}

export default function ShareHeaderInfo({share}: Props) {
  const expirationDate = share.expires ? new Date(share.expires) : undefined;
  return (
    <View style={styles.infoContainer}>
      <View style={styles.titleContainer}>
        {share.title ? (
          <Typography
            size="2xl"
            color={theme.colors.neutral.$2Base}
            variant="medium">
            {share.title}
          </Typography>
        ) : (
          <Skeleton width={200} height={34} animation={false} />
        )}
        {expirationDate && (
          <ShareExpiration
            expirationDate={expirationDate}
            shareType={share.share_type}
          />
        )}
      </View>
      {share.title ? (
        <Typography
          size="md"
          color={theme.colors.neutral.$2Base}
          overrides={styles.description}>
          {share.description}
        </Typography>
      ) : (
        <Skeleton
          width={300}
          height={44}
          style={{marginTop: 4}}
          animation={false}
        />
      )}
      <ShareLinks share={share} />
    </View>
  );
}

const styles = StyleSheet.create({
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 8,
  },
  description: {
    marginTop: 2,
    marginBottom: 12,
  },
});
