import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';
import {AICHAT_TOOLBAR_HEIGHT} from '../consts';
import {AiChatMenuButton} from './AiChatMenuButton';

interface Props {
  onNewChatPress: (isPrivate: boolean) => void;
  onCloseSidebar: () => void;
}

export function AiChatListHeader({onNewChatPress, onCloseSidebar}: Props) {
  const newPublicChat = () => {
    onNewChatPress(false);
  };
  const newPrivateChat = () => {
    onNewChatPress(true);
  };
  return (
    <View style={styles.container}>
      <AiChatMenuButton
        iconName="lucide:arrow-left-from-line"
        onPress={onCloseSidebar}
        tooltipText="Close sidebar"
      />
      <AiChatMenuButton
        iconName="lucide:edit"
        onPress={newPublicChat}
        tooltipText="New chat"
      />
      <AiChatMenuButton
        iconName="lucide:lock"
        onPress={newPrivateChat}
        tooltipText="New private chat"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 16,
    gap: 4,
    height: AICHAT_TOOLBAR_HEIGHT,
    borderBottomColor: theme.colors.neutral.$75,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
});
