import {Trans, t} from '@lingui/macro';
import images from 'config/images';
import theme from 'config/theme';
import useBuildCookieSession from 'extensions/session/hooks/useBuildCookieSession';
import {api} from 'fast-sdk';
import type {User} from 'fast-sdk/src/api/user/consts';
import {useProccessPendingInvitation} from 'interface/common/hooks/useProccessPendingInvitation';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {slices} from 'store';
import * as app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import * as user from 'store/slices/user';
import type {SSOMethod} from 'store/slices/user/types';
import {
  getPreviouslySignedInMethod,
  getPreviouslySignedInResult,
  handleSignNavigation,
  setPreviouslySignedInResult,
  setPreviouslySignedInMethod as storeSetPreviouslySignedInMethod,
} from 'utils/fast/auth';
import {AuthPageTypes, RouteLayout} from '../_layout/RouteLayout';
import {LoginButton} from '../components/LoginButton';
import {LoginButtons} from '../components/LoginButtons';
import {LoginPageError} from '../components/LoginPageError';
import {useSetRedirectTo} from '../hooks/useSetRedirectTo';

type Props = {
  type: AuthPageTypes;
  isModal?: boolean;
  onSigninOrSignup?: () => void;
  onForgotPassword?: () => void;
  onSigninOrSignupWithEmail?: () => void;
  onSigninOrSignupWithEmailSuccess?: (user: User, token: string) => void;
  onOpenLoginPopup?: () => Promise<void>;
};

export function Signin(props: Props) {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const dimension = useWindowDimensions();
  const {subdomain} = useSubDomain();
  const {buildAndSetCookie} = useBuildCookieSession();

  useSetRedirectTo();

  const [previouslySignedInAccepted, setPreviouslySignedInAccepted] =
    useState<boolean>();
  const [previouslySignedInMethod, setPreviouslySignedInMethod] = useState<
    SSOMethod | ''
  >();

  const currentJoinInvitation = useSelector(
    app.selectors.getCurrentJoinInvitation,
  );
  const userToken = useSelector(user.selectors.getToken);
  const sso = useSelector(user.selectors.getSSOData);
  const organization = useSelector(user.selectors.getSelectedOrganization);

  const {handleInvitationFlow} = useProccessPendingInvitation();

  const isSignUpPage = props.type === AuthPageTypes.SIGNUP;

  const renderInformation = useCallback(() => {
    if (currentJoinInvitation?.org) {
      const {org} = currentJoinInvitation;
      return {
        title: (
          <Text>
            {isSignUpPage ? 'Create an account to join' : 'Sign in to join'}
            {'\n'}
            <Text style={{color: theme.colors.brand.$3}}>{org.name}</Text>
          </Text>
        ),
        subTitle: `Use your work email to ${isSignUpPage ? 'create an account' : 'sign in'}`,
      };
    }

    if (isSignUpPage) {
      return {
        title: 'Create an account',
        subTitle: 'Select an option to create your Fast.io account.',
      };
    }
    return {
      title: 'Sign in to Fast.io',
      subTitle: 'Enter your email address and password to continue.',
    };
  }, [currentJoinInvitation?.org, subdomain, isSignUpPage, organization]);

  const {title, subTitle} = useMemo(
    () => renderInformation(),
    [renderInformation],
  );

  useEffect(() => {
    const getPreviousData = async () => {
      const preMethod = await getPreviouslySignedInMethod();
      const preResult = await getPreviouslySignedInResult();
      setPreviouslySignedInMethod(preMethod as SSOMethod | '');
      setPreviouslySignedInAccepted(preResult ? preResult : false);
    };
    getPreviousData();

    if (!userToken) {
      return;
    }

    const getSSOData = async () => {
      const [userRes, availableProfilesRes] = await Promise.all([
        api.user.userDetails(),
        api.user.getAvailableProfiles(),
      ]);

      if (!userRes.result) {
        return toast.show(userRes.error?.text, {type: 'danger'});
      }

      dispatch(user.default.actions.setUserDetails(userRes.user));

      if (!availableProfilesRes.result) {
        toast.show(availableProfilesRes.error?.text, {type: 'danger'});
      }

      const {has_orgs, has_workspaces, has_shares} = availableProfilesRes;
      dispatch(
        user.default.actions.setAvailableProfiles({
          hasOrgs: has_orgs,
          hasWorkspaces: has_workspaces,
          hasShares: has_shares,
        }),
      );

      buildAndSetCookie([], [], userRes.user, userToken);

      handleSignNavigation(
        navigate,
        currentJoinInvitation,
        handleInvitationFlow,
        {
          authToken: userToken,
          user: userRes.user,
          hasOrgs: has_orgs,
          hasWorkspaces: has_workspaces,
          hasShares: has_shares,
          isSignIn: props.type === AuthPageTypes.SIGNIN,
        },
      );

      if (sso?.provider) {
        await storeSetPreviouslySignedInMethod(sso.provider);
      }
      if (sso?.result) {
        await setPreviouslySignedInResult(JSON.parse(sso.result));
      }
    };

    getSSOData();
  }, [userToken, sso?.provider, sso?.result]);

  useEffect(() => {
    return () => {
      dispatch(user.default.actions.setSSOData({loading: false}));
    };
  }, []);

  useEffect(() => {
    dispatch(
      slices.app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: isSignUpPage ? 'Sign up' : 'Sign in',
      }),
    );
  }, [isSignUpPage]);

  const backgroundColor = theme.colors.neutral.$white;
  const borderBackgroundColor = theme.colors.neutral.$12;

  if (sso?.loading) {
    return (
      <View style={styles.loadingContainer}>
        <AppLoading />
      </View>
    );
  }

  return (
    <RouteLayout
      title={title}
      subTitle={subTitle}
      // subContent={subContent}
      // headerLogo={headerLogo}
      type={props.type}
      customRootMaxWidth={props.isModal ? '100%' : undefined}
      onSignupOrSignin={props.onSigninOrSignup}
      onForgotPassword={props.onForgotPassword}>
      <View style={styles.root}>
        {!previouslySignedInAccepted && previouslySignedInMethod ? (
          <LoginPageError errorOption={previouslySignedInMethod} />
        ) : null}
        <LoginButtons
          signUpButtons={isSignUpPage}
          isModal={props.isModal}
          afterOpenLoginPopup={props.onOpenLoginPopup}
        />
        <Fragment>
          <View style={styles.orContainer}>
            <Text style={[styles.or, {backgroundColor: backgroundColor}]}>
              <Trans>or</Trans>
            </Text>
            <View
              style={[
                styles.orBorder,
                {
                  backgroundColor: borderBackgroundColor,
                  width: dimension.width > 790 ? '100%' : dimension.width - 40,
                },
              ]}
            />
          </View>
          <LoginButton
            signUpButton
            title={isSignUpPage ? t`Sign up with email` : t`Sign in with email`}
            customRootStyle={{marginBottom: 0}}
            onPress={() =>
              props.onSigninOrSignupWithEmail
                ? props.onSigninOrSignupWithEmail()
                : isSignUpPage
                  ? navigate('/signup/email')
                  : navigate('/signin/email')
            }
            icon={{
              uri: images.blackEmailIcon,
            }}
            height={16}
            width={20}
            fullWidth={props.isModal}
          />
        </Fragment>
      </View>
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: 15,
  },
  orContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: '0.5rem',
    marginVertical: '1.5rem',
  },
  or: {
    position: 'absolute',
    flexWrap: 'nowrap',
    paddingHorizontal: 10,
    zIndex: 1,
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '400',
    color: theme.colors.neutral.$5,
  },
  orBorder: {
    height: 1,
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
