import React from 'react';
import * as RRP from 'react-resizable-panels';

export const Panel = React.forwardRef<
  HTMLDivElement,
  RRP.PanelProps & {flex?: boolean}
>((props, ref) => {
  return (
    <RRP.Panel
      {...props}
      // @ts-ignore
      ref={ref}
      style={{display: props.flex ? 'flex' : 'block'}}
    />
  );
});

export function PanelGroup(props: RRP.PanelGroupProps) {
  return <RRP.PanelGroup {...props} />;
}

export function PanelHandle(props: RRP.PanelResizeHandleProps) {
  return <RRP.PanelResizeHandle {...props} />;
}
