import analytics from 'extensions/analytics';

import {api} from 'fast-sdk';
import store, {slices} from 'store';

import type {AnyAction, Dispatch} from '@reduxjs/toolkit';
import {WORKSPACE_ROOT, WORKSPACE_TRASH} from 'constants/routes';
import {
  PreviewType,
  type StorageItem,
  type StorageNamespace,
} from 'fast-sdk/src/api/storage/consts';

// API

export async function load(
  dispatch: Dispatch<AnyAction>,
  folderId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const isRoot = folderId === WORKSPACE_ROOT;
  const isTrash = folderId === WORKSPACE_TRASH;
  const storage = api.storage.init(instanceNs, instanceId);

  dispatch(slices.files.actions.load({folderId, instanceId}));

  // No results, nothing to load
  const list = await storage.getList(folderId);

  dispatch(slices.files.actions.load(undefined));

  if (!list.result) return;

  // Load subfolder details
  let folderDetails: StorageItem;
  if (!isRoot && !isTrash) {
    folderDetails =
      store.getState().files.items[folderId] ??
      (await storage.getDetails(folderId))?.node;
  }

  // Save info to redux
  dispatch(
    slices.files.actions.updateFolder({
      id: folderId,
      list: list.nodes.items,
      instanceId,
      instanceNs,
      folderDetails,
    }),
  );
}

export async function move(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  folderId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Moved items');
  const storage = api.storage.init(instanceNs, instanceId);
  const promises = ids.map(id => storage.move(id, folderId));
  dispatch(slices.files.actions.move({ids, destination: folderId}));
  const results = await Promise.all(promises);
  const completes = results.filter(r => r.result);
  const errors = results.filter(r => 'error' in r);
  return {completes, errors};
}

export async function copy(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  folderId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Copied items');
  const storage = api.storage.init(instanceNs, instanceId);
  const promises = ids.map(id => storage.copy(id, folderId));
  dispatch(slices.files.actions.copy({ids, destination: folderId}));
  const results = await Promise.all(promises);
  const completes = results.filter(r => r.result);
  const errors = results.filter(r => 'error' in r);
  return {completes, errors};
}

export async function transfer(
  ids: string[],
  targetId: string,
  targetFolder: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Transferred items');
  const storage = api.storage.init(instanceNs, instanceId);
  const promises = ids.map(id => storage.transfer(id, targetId, targetFolder));
  const results = await Promise.all(promises);
  const completes = results.filter(r => r.result);
  const errors = results.filter(r => 'error' in r);
  return {completes, errors};
}

export async function restore(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  folderId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Restored items');
  const storage = api.storage.init(instanceNs, instanceId);
  const promises = ids.map(id => storage.restoreTrash(id));
  const results = await Promise.all(promises);
  const completes = results.filter(r => r.result);
  const errors = results.filter(r => 'error' in r);
  // Temp: remove from the local state until activities work with trash
  dispatch(slices.files.actions.archiveFiles({ids}));
  return {completes, errors};
}

export async function archive(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Archived items');
  const storage = api.storage.init(instanceNs, instanceId);
  const promises = ids.map(id => storage.delete(id));
  dispatch(slices.files.actions.select({ids: []}));
  const results = await Promise.all(promises);
  const completes = results.filter(r => r.result);
  const errors = results.filter(r => 'error' in r);
  return {completes, errors};
}

export async function purge(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Purged items');
  const storage = api.storage.init(instanceNs, instanceId);
  const promises = ids.map(id => storage.delete(id));
  dispatch(slices.files.actions.select({ids: []}));
  const results = await Promise.all(promises);
  const completes = results.filter(r => r.result);
  const errors = results.filter(r => 'error' in r);
  return {completes, errors};
}

export async function purgeAll(
  dispatch: Dispatch<AnyAction>,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Purged all items');
  const storage = api.storage.init(instanceNs, instanceId);
  dispatch(slices.files.actions.select({ids: []}));
  return await storage.emptyTrash();
}

export async function search(
  query: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const storage = api.storage.init(instanceNs, instanceId);
  return await storage.search(query);
}

export async function rename(
  id: string,
  name: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Renamed item');
  const storage = api.storage.init(instanceNs, instanceId);
  return await storage.update(id, name);
}

export async function download(
  id: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Downloaded item');
  const storage = api.storage.init(instanceNs, instanceId);
  const request = instanceNs.includes('quickshare')
    ? {result: true, token: undefined}
    : await storage.readRequest(id);
  if (request.result) {
    const downloadUrl = storage.readOpen(id, request.token);
    window.location.href = downloadUrl;
  }
}

export async function createFolder(
  name: string,
  folderId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  analytics.log('Created folder');
  const storage = api.storage.init(instanceNs, instanceId);
  return await storage.createFolder(folderId, name);
}

export async function getFileDetails(
  fileId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  if (!instanceId) return {result: false, node: null};
  const storage = api.storage.init(instanceNs, instanceId);
  const {result, node} = await storage.getDetails(fileId);
  return {result, node};
}

export async function getFileUrl(
  fileId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const storage = api.storage.init(instanceNs, instanceId);
  const request = instanceNs.includes('quickshare')
    ? {token: undefined}
    : await storage.readRequest(fileId);
  const url = storage.readOpen(fileId, request.token);
  return url;
}

export async function getPreviewFile(
  type: PreviewType,
  fileId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const storage = api.storage.init(instanceNs, instanceId);
  const preview = storage.preview(fileId);
  const response = await preview.preauthorize(type);
  if (response.result) {
    const {downloadToken, primaryFilename, path} = response;
    if (type === PreviewType.HLSSTREAM) {
      return path;
    }
    const file = await preview.readFile(type, downloadToken, primaryFilename);
    return file;
  }
  return null;
}

/** @deprecated Does not seem to be implemented anywhere */
export async function getPreviewPrimary(
  type: PreviewType,
  fileId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const storage = api.storage.init(instanceNs, instanceId);
  const preview = storage.preview(fileId);
  const response = await preview.readPrimary(type);
  return response;
}
