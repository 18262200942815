import {DEFAULT_ERROR_CONFIG, DEFAULT_RETRY_OPTIONS} from 'constants/errors';
import analytics from 'extensions/analytics';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import type {ApiError, OK} from 'fast-sdk/src/requests/types';
import store from 'store';
import app from 'store/slices/app';
import errors from 'store/slices/errors';
import {ApiErrorsConfiguration} from './apiErrorsConfiguration';
import {AppErrors} from './appErrors';
import {AppErrorsConfiguration} from './appErrorsConfiguration';
import type {ErrorConfiguration} from './types';

type DialogOptions = {
  title?: string;
  message?: string;
};

type RetryFn = () => Promise<OK>;

interface ErrorHandlingParams<T extends ApiErrors | AppErrors> {
  errorConfiguration: ErrorConfiguration;
  errorCode: T;
  errorText?: string;
  exception?: Error;
  retryFn: RetryFn;
  stack?: string;
  dialogOptions?: DialogOptions;
}

interface ErrorResult<T extends ApiErrors | AppErrors> {
  code: T;
  text: string;
}

const handleError = <T extends ApiErrors | AppErrors>({
  errorConfiguration,
  errorCode,
  errorText,
  exception,
  retryFn,
  stack,
  dialogOptions,
}: ErrorHandlingParams<T>): ErrorResult<T> => {
  const {key: name, friendlyMessage, disabledServices} = errorConfiguration;

  const message =
    friendlyMessage ?? errorText ?? exception?.message ?? 'Unknown error';

  analytics.notify(
    {
      message: errorText ?? exception?.message ?? 'Unknown error',
      name,
      errorClass: `${errorCode}`,
      errorMessage: stack ?? exception?.stack,
    },
    undefined,
    {
      disabledServices,
    },
  );

  store.dispatch(
    errors.actions.addError({
      message: dialogOptions?.message ?? message,
      title: `We've encountered a problem ${dialogOptions?.title ?? `(CODE: ${name})`}`,
      errorCode,
      errorConfiguration: {
        ...DEFAULT_ERROR_CONFIG,
        ...errorConfiguration,
      },
      retryFn,
      exception,
      originalErrorText: errorText,
      availableRetries:
        errorConfiguration.retryOptions?.maxRetries ??
        DEFAULT_RETRY_OPTIONS.maxRetries,
    }),
  );

  return {
    code: errorCode,
    text: message,
  };
};

export const handleApiError = (
  error: ApiError,
  retryFn?: () => Promise<OK>,
  stack?: string,
  dialogOptions?: DialogOptions,
): ApiError => {
  try {
    const {code: errorCode, text: errorText} = error;

    const errorConfiguration: ErrorConfiguration =
      ApiErrorsConfiguration[errorCode] ??
      ApiErrorsConfiguration[ApiErrors.UnknownError];

    switch (errorCode) {
      case ApiErrors.AuthorizationInvalid:
        store.dispatch(app.actions.setForceLogout(true));
        return error;
      case ApiErrors.OrgNotSubscribed: {
        // Extract subdomain from url to check if current subdomain and request subdomain are the same
        const subdomainFromUrl = error.apiUrl
          ?.split('/org/')?.[1]
          ?.split('/')?.[0];

        store.dispatch(
          app.actions.setRedirectToRenewSubscription({
            redirect: true,
            subdomain: subdomainFromUrl,
          }),
        );
        return error;
      }
      default:
        return handleError({
          errorConfiguration,
          errorCode,
          errorText,
          retryFn,
          stack,
          dialogOptions,
        });
    }
  } catch (err) {
    analytics.notify({
      message: `Unexpected error occurred while handling error ${(err as Error).message}`,
      name: ApiErrorsConfiguration[ApiErrors.UnknownError].key,
      errorClass: `${ApiErrors.UnknownError}`,
    });
    console.error('Unexpected error occurred while handling error', error);
    return error;
  }
};

type HandleAppErrorParams = {
  appError: AppErrors;
  text?: string;
  exception?: Error;
  retryFn?: RetryFn;
  stack?: string;
  dialogOptions?: DialogOptions;
};

export const handleAppError = ({
  appError,
  text,
  exception,
  retryFn,
  stack,
  dialogOptions,
}: HandleAppErrorParams): ErrorResult<AppErrors> => {
  const errorConfiguration: ErrorConfiguration =
    AppErrorsConfiguration[appError] ??
    AppErrorsConfiguration[AppErrors.UnknownError];

  return handleError({
    errorConfiguration,
    errorCode: appError,
    errorText: text,
    exception,
    retryFn,
    stack,
    dialogOptions,
  });
};
