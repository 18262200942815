import {ROUTES} from 'constants/routes';
import {Outlet, useParams} from 'extensions/navigation';
import useHasAccessToWorkspace from 'extensions/session/hooks/useHasAccessToWorkspace';
import {getSession} from 'extensions/session/utils';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import useFetchShareDetails from 'interface/stacks/share/hooks/useFetchShareDetails';
import useGetShareDetails from 'interface/stacks/share/hooks/useGetShareDetails';
import {useGetFileDetails} from 'interface/stacks/workspace/hooks/useGetFileDetails';
import {useGetWorkspaceDetails} from 'interface/stacks/workspace/hooks/useGetWorkspaceDetails';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as files from 'store/slices/files';
import * as workspaces from 'store/slices/workspace';
import {useNavigateToGo} from './hooks/navigator/useNavigateToGo';

export default function WorkspaceAccessLayout() {
  const {workspaceId, fileId, sharedCustomName, instanceId, instanceNs} =
    useParams();

  const dispatch = useDispatch();
  const {checkWorkspaceAccess} = useHasAccessToWorkspace();
  const {navigateToGo} = useNavigateToGo();

  const {fetchShareDetailsByCustomName, fetchShareDetails} =
    useFetchShareDetails();
  const {fetchFileDetails} = useGetFileDetails();
  const {fetchWorkspaceDetails} = useGetWorkspaceDetails();

  const file = useSelector(files.selectors.getItem(fileId));
  const workspace = useSelector(
    workspaces.selectors.getWorkspaceByAlt(workspaceId ?? instanceId),
  );
  const {share} = useGetShareDetails({
    sharedCustomName,
    shareId: instanceId,
    fetchDisabled: true,
  });
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const [accessValid, setAccessValid] = useState(false);

  const redirectToHome = () => {
    navigateToGo(`${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
  };

  const refreshWorkspace = async () => {
    if (workspaceId || (instanceNs === 'workspace' && instanceId)) {
      const workspaceToAccess = workspaceId ?? instanceId;
      const hasAccess = checkWorkspaceAccess(workspaceToAccess);
      if (!hasAccess || workspaceToAccess === 'root') {
        navigateToGo(ROUTES.LOGGED_IN_WITHOUT_ORG.HOME);
        return;
      }
      if (workspace) {
        fetchWorkspaceDetails(workspaceToAccess);
      } else {
        const result = await fetchWorkspaceDetails(workspaceToAccess);
        if (!result) {
          return redirectToHome();
        }
      }
    }
  };

  const refreshFile = async () => {
    const instanceNsToAccess =
      instanceNs ?? (workspaceId ? 'workspace' : undefined);
    const instanceIdToAccess = instanceId ?? workspaceId;
    if (instanceNsToAccess && instanceIdToAccess && fileId) {
      if (!file) {
        const result = await fetchFileDetails(
          fileId,
          instanceIdToAccess,
          instanceNsToAccess as StorageNamespace,
        );
        if (!result) {
          return redirectToHome();
        }
      }
    }
  };

  const refreshShare = async () => {
    if (sharedCustomName || (instanceNs === 'share' && instanceId)) {
      if (!share) {
        const result = sharedCustomName
          ? await fetchShareDetailsByCustomName(sharedCustomName)
          : await fetchShareDetails(undefined, instanceId);
      }
    }
  };

  const validateAccess = async () => {
    try {
      if (isLoggedIn) {
        await refreshWorkspace();
        await refreshFile();
        await refreshShare();
      }
    } catch (error) {
      redirectToHome();
    } finally {
      setTimeout(() => {
        setAccessValid(true);
      });
    }
  };

  const syncWorkspaces = () => {
    const activeWorkspaces = getSession()?.workspaces;
    dispatch(workspaces.default.actions.syncWorkspaces(activeWorkspaces ?? []));
  };

  useEffect(() => {
    syncWorkspaces();
    validateAccess();
  }, []);

  if (accessValid) return <Outlet />;

  return <AppLoading />;
}
