import {colors} from 'config/styles';
import theme from 'config/theme';
import {Outlet} from 'extensions/navigation';
import {MobileDisclaimerBanner} from 'interface/common/MobileDisclaimerBanner';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import Footer from 'interface/stacks/app/Footer';
import UserDropdown from 'interface/stacks/content-viewer/components/top-toolbar/UserDropdown';
import LogoHeader from 'interface/stacks/onboard/base/LogoHeader';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {isMobileBrowser} from 'utils/common/platform';

type Props = {
  showCurrentUser?: boolean;
  showAccountDropdown?: boolean;
};

export function PageLayout({showCurrentUser, showAccountDropdown}: Props) {
  const screen = useWindowDimensions();
  const {logout} = useLogout();

  const {email_address} = useSelector(user.selectors.getUserDetails) ?? {};

  const isSmallScreen = screen.width < 790;
  const lightColors = colors.mainLightColors;

  const classes = {
    root: [
      styles.root,
      isSmallScreen && styles.rootSmall,
      {backgroundColor: theme.colors.neutral.$white},
    ],
    text: [{color: lightColors.text}, styles.text],
    outletContainer: [
      styles.outletContainer,
      isSmallScreen && styles.outletContainerSmall,
    ],
  };

  return (
    <View style={classes.root}>
      {isMobileBrowser() && <MobileDisclaimerBanner />}
      <View style={styles.header}>
        <LogoHeader />
        {showCurrentUser && (
          <View style={styles.accountPillContainer}>
            <View style={styles.accountPillItem}>
              <Text style={styles.accountPillText}>Signed in as </Text>
              <Text style={[styles.accountPillText, {fontWeight: '600'}]}>
                {email_address}
              </Text>
            </View>
            <View style={styles.accountPillDivider} />
            <Pressable
              style={styles.accountPillItem}
              onPress={() => logout({navigateTo: NavigateTo.LOGOUT})}>
              <Text style={styles.accountPillText}>Sign Out</Text>
            </Pressable>
          </View>
        )}
        {showAccountDropdown && <UserDropdown />}
      </View>
      <ScrollView contentContainerStyle={[classes.outletContainer]}>
        <Outlet />
        <Footer />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  rootSmall: {
    flexDirection: 'column',
  },
  logo: {
    width: 120,
    height: 24,
    margin: 0,
    zIndex: 5,
  },
  outletContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minWidth: 'calc(100%-200px)',
    width: '100%',
    marginTop: 20,
    flexGrow: 1,
  },
  outletContainerSmall: {
    width: '100%',
    paddingLeft: 0,
  },
  text: {
    fontWeight: '400',
    fontSize: 13,
    lineHeight: 21,
    marginLeft: 10,
    color: '#ffffff',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: 15,
    zIndex: 10,
  },
  accountPillContainer: {
    height: 36,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: theme.colors.neutral.$9,
    flexDirection: 'row',
  },
  accountPillText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
  },
  accountPillDivider: {
    height: '100%',
    width: 1,
    backgroundColor: theme.colors.neutral.$9,
  },
  accountPillItem: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
