import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useLocation} from 'react-router';
import MarkdownCreator from '../MarkdownCreator';

interface MarkdownEditorState {
  fileName: string;
  text: string;
  instanceId: string;
  instanceNs: StorageNamespace;
  parentFolder: string;
}

const RouteMarkdownEditor = () => {
  const location = useLocation();

  const state = location.state as MarkdownEditorState;

  return (
    <MarkdownCreator
      text={state?.text}
      fileName={state?.fileName}
      instanceId={state?.instanceId}
      instanceNs={state?.instanceNs}
      parentFolder={state?.parentFolder}
    />
  );
};
export default RouteMarkdownEditor;
