import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {useOrgLogo} from 'interface/base/OrgLogo';
import {OrgLogoSize} from 'interface/base/OrgLogo/types';
import Typography from 'interface/base/Typography';
import {Fragment, useEffect, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {useParams} from 'react-router';
import type {Organization} from 'store/slices/user/types';
import {SettingsGeneralMenuButtonItem as Item} from '../../base/SettingsGeneralMenuButtonItem';
import {type SettingsMenuLink, SettingsType} from '../../base/consts';
import type {Params} from '../../types';

type Props = {
  org: Organization;
  menuLinks: SettingsMenuLink[];
  isExpanded?: boolean;
};

export function OrgAccordion({
  org,
  menuLinks,
  isExpanded: isExpandedProp,
}: Props) {
  const {settingsSection, subdomain} = useParams<Params>();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(isExpandedProp);
  }, [isExpandedProp]);

  const toggleExpanded = () => setIsExpanded(prev => !prev);

  const {logo} = useOrgLogo({
    orgId: org.id,
    orgDetails: org,
    size: OrgLogoSize.ExtraSmall,
    showBorder: false,
  });

  return (
    <Fragment>
      <Pressable onPress={toggleExpanded} style={styles.root}>
        <View style={styles.left}>
          {logo}
          <Typography
            variant="medium"
            size="sm"
            color={theme.colors.neutral.$700}>
            {org.name}
          </Typography>
        </View>
        <Icon
          name={`lucide:chevron-${isExpanded ? 'up' : 'down'}`}
          size={16}
          color={theme.colors.neutral.$200}
        />
      </Pressable>
      {isExpanded && (
        <View style={styles.accordionContainer}>
          {menuLinks.map(linkItem => (
            <Item
              title={linkItem.title}
              iconName={linkItem.iconName}
              link={`${linkItem.link}/${org.domain}`}
              sectionName={linkItem.sectionName}
              active={
                SettingsType[settingsSection] === linkItem.sectionName &&
                org.domain === subdomain
              }
              key={linkItem.link}
            />
          ))}
        </View>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  root: {
    height: 40,
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    flexDirection: 'row',
    gap: 8,
  },
  accordionContainer: {
    marginBottom: 8,
  },
});
