import {t} from '@lingui/macro';
import theme from 'config/theme';
import {DEFAULT_INVITE_SHARE_MESSAGE} from 'constants/invites';
import {api} from 'fast-sdk';
import {Textarea} from 'interface/base/Textarea';
import Typography from 'interface/base/Typography';
import {FooterActions} from 'interface/common/Modal';
import usePermissionsPicker from 'interface/common/hooks/usePermissionsPicker';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import type {Share} from 'store/slices/shared/types';
import {uniqueValues} from 'utils/common/validation';

interface Props {
  share: Share;
  emails: Array<string>;
  setEmails: (emails: Array<string>) => void;
  onInvitesSend: (success: boolean) => void;
}

export function AddRecipientModalContent({
  share,
  emails,
  setEmails,
  onInvitesSend,
}: Props) {
  const toast = useToast();

  const [message, setMessage] = useState<string>(undefined);
  const [sendingInvites, setSendingInvites] = useState(false);

  const {permission, PickerComponent} = usePermissionsPicker({
    initialPermission: 'guest',
    disabled: sendingInvites,
    isGuestOptionEnabled: true,
  });

  const inviteMembers = async () => {
    setSendingInvites(true);
    const successfulEmails = [];
    const errorEmails = [];
    try {
      const emailsToSend = uniqueValues(emails);
      if (emailsToSend.length > 0) {
        await Promise.all(
          emailsToSend.map(async email => {
            const {result} = await api.share.addShareMember(
              share.id,
              email,
              permission,
              message ?? DEFAULT_INVITE_SHARE_MESSAGE,
            );
            result ? successfulEmails.push(email) : errorEmails.push(email);
          }),
        );
        if (successfulEmails.length === emailsToSend.length)
          toast.show(
            t`Existing org members added to share, email invites sent to new members`,
            {type: 'neutral'},
          );
        else
          toast.show(t`Unable to send emails to: ${errorEmails.join(', ')}`, {
            type: 'danger',
          });
      }
    } finally {
      setSendingInvites(false);
      onInvitesSend(successfulEmails.length > 0);
    }
  };

  const onCancel = () => {
    setEmails([]);
  };

  return (
    <View>
      <View style={styles.pickerContainer}>{PickerComponent}</View>
      <Typography
        overrides={styles.label}
        size="xs"
        variant="bold"
        color={theme.colors.neutral.$700}>
        {t`Message (optional)`}
      </Typography>
      <Textarea
        value={message}
        onChangeValue={setMessage}
        aria-label={t`Message (optional)`}
        numberOfLines={4}
        placeholder={t`Enter a message...`}
      />
      <FooterActions
        actionButtonLabel={t`Invite`}
        isFormInvalid={emails.length === 0}
        onSave={inviteMembers}
        onCancel={onCancel}
        isActionLoading={sendingInvites}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pickerContainer: {
    flexDirection: 'row-reverse',
  },
  label: {
    marginBottom: 6,
  },
});
