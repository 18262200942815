import {Input} from 'interface/base/Input';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import Layout from './Layout';

interface Props {
  next: () => void;
}

export default function OrganizationName(props: Props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const organizationName = useSelector(
    onboarding.selectors.getOrganizationName,
  );
  const [orgName, setOrgName] = useState<string>(organizationName);

  const next = () => {
    dispatch(onboarding.default.actions.setOrganizationName({orgName}));
    props.next();
  };

  return (
    <Layout
      title="What's the name of your company or team?"
      subtitle="This will be the name of your Fast.io organization - choose something that your team will recognize."
      next={next}
      disabled={!orgName || orgName.length < 3 || orgName.length > 100}>
      <View style={styles.root}>
        <Input
          overrides={{
            Root: {
              style: {
                width: '100%',
              },
            },
          }}
          autoFocus
          value={orgName}
          onChangeValue={setOrgName}
          placeholder="Ex: Acme Inc, Marketing Team, Design Department"
          aria-label="Organization Name"
          error={orgName && (orgName.length < 3 || orgName.length > 100)}
          errorMessage="name should be between 3 and 100 characters"
        />
        <Text style={styles.helperText}>Examples:</Text>
        <View style={styles.examplesContainer}>
          <View style={styles.exampleRow}>
            <View style={styles.bullet} />
            <Text style={styles.exampleText}>
              Small business: "Tom's Plumbing"
            </Text>
          </View>
          <View style={styles.exampleRow}>
            <View style={styles.bullet} />
            <Text style={styles.exampleText}>
              Team in a company: "Coca-Cola Marketing"
            </Text>
          </View>
          <View style={styles.exampleRow}>
            <View style={styles.bullet} />
            <Text style={styles.exampleText}>
              Freelancer: "Sarah Smith Design"
            </Text>
          </View>
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 16,
  },
  input: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    height: 48,
  },
  helperText: {
    fontSize: 14,
    lineHeight: 20,
    color: '#475467',
    maxWidth: '100%',
    marginTop: 12,
  },
  examplesContainer: {
    marginTop: 8,
  },
  exampleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 6,
  },
  bullet: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: '#475467',
    marginRight: 8,
  },
  exampleText: {
    fontSize: 14,
    lineHeight: 20,
    color: '#475467',
  },
});
