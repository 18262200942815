import {useFlag} from '@unleash/proxy-client-react';
import {Uploads} from 'interface/stacks/uploads/views/Uploads';
import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {useOutletContext} from 'react-router';
import * as files from 'store/slices/files';
import type {Share} from 'store/slices/shared/types';
import Comments from './components/Comments';
import useFetchShareFiles from './hooks/useFetchShareFiles';
import type {ShareFilesNavigation} from './hooks/useShareFilesNavigation';
import ShareFiles from './layout/ShareFiles';
import {ShareFooter} from './layout/ShareFooter';
import ShareHeaderInfo from './layout/ShareHeaderInfo';

import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';

interface ShareRecepientViewProps {
  hiddenShare?: Share;
}

export function ShareRecepientView({hiddenShare}: ShareRecepientViewProps) {
  const dispatch = useDispatch();

  const [shareFromContext, uploader, shareFilesNavigation] =
    useOutletContext<[Share, UploaderState, ShareFilesNavigation]>() ?? [];

  const share = shareFromContext ?? hiddenShare;

  const {folderKey} = shareFilesNavigation ?? {};

  const filesData = useFetchShareFiles({share, folderKey});

  const showComments = useFlag('share-comments') && share.comments.enabled;

  useEffect(() => {
    if (share.id) {
      dispatch(files.default.actions.focusParent({id: share.id}));
      dispatch(files.default.actions.focus({id: ''}));
    }
  }, [share.id]);

  return (
    <View style={styles.root}>
      <View style={styles.body}>
        <ShareHeaderInfo share={share} />
        <ShareFiles
          share={share}
          filesData={filesData}
          shareFilesNavigation={shareFilesNavigation}
        />
        {shareFromContext && showComments && <Comments share={share} />}
      </View>
      {shareFromContext && (
        <ShareFooter
          shareFilesNavigation={shareFilesNavigation}
          filesData={filesData}
        />
      )}
      {shareFromContext && <Uploads {...uploader} />}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'column',
  },
  body: {
    padding: 12,
    flex: 1,
    flexDirection: 'column',
  },
});
