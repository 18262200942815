import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {useHover} from 'interface/common/hooks/useHover';
import {Pressable, StyleSheet} from 'react-native';
import {Toggle} from './Toggle';

type Props = {
  label: string;
  onChange: (inp: boolean) => void;
  checked?: boolean | undefined;
  allowIndeterminate?: boolean;
  showHover?: boolean;
};

export function ToggleWithLabel({
  label,
  onChange,
  checked,
  showHover = true,
}: Props) {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  return (
    <Pressable
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPress={() => onChange(!checked)}
      style={[styles.root, showHover && isHover && styles.hovered]}>
      <Toggle checked={checked} onChange={null} clickable={false} />
      <Typography
        size="sm"
        color={theme.colors.neutral.$700}
        overrides={styles.text}>
        {label}
      </Typography>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    padding: 8,
    gap: 10,
    alignItems: 'center',
    borderRadius: 6,
  },
  hovered: {
    backgroundColor: theme.colors.neutral.$13,
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
