import {PlanType} from 'store/slices/settings/types';
import type {PlanMetaData} from './types';

export const DISCOUNT = 20; // percentage

export const PLANS_INFO: {[inp in PlanType]: PlanMetaData} = {
  Startup: {
    name: PlanType.Startup,
    description:
      'Perfect for freelancers, content creators, and remote workers',
    ids: {
      monthly: 'startup_monthly',
      yearly: 'startup_yearly',
    },
    features: [
      '1 users minimum, up to 3',
      'Up to 3 workspaces',
      'Up to 100 GB file size',
      '500 GB of storage',
      'Global CDN included',
    ],
    highlights: [
      '200 GB transfer bandwidth included',
      'Custom branding',
      'Customizable subdomain',
      'Priority support (live chat and email)',
    ],
    previousPlan: null,
    userNumber: 1,
    price: 8.75,
  },
  Team: {
    name: PlanType.Team,
    description: 'Perfect for entrepreneurs and small shops',
    ids: {
      monthly: 'team_monthly',
      yearly: 'team_yearly',
    },
    features: [
      '3 users minimum, up to 10',
      'Up to 25 workspaces',
      'Up to 200 GB file size',
      '1 TB of storage',
      'Global CDN included',
    ],
    highlights: [
      '1 TB transfer bandwidth included',
      'Custom branding',
      'Customizable subdomain',
      'Priority support (live chat and email)',
    ],
    previousPlan: PlanType.Startup,
    userNumber: 3,
    price: 15,
  },
  Business: {
    name: PlanType.Business,
    description:
      'Perfect for professional services, marketing teams and educators',
    ids: {
      monthly: 'business_monthly',
      yearly: 'business_yearly',
    },
    features: [
      '10 users minimum, unlimited max',
      'Up to 100 workspaces',
      'Unlimited max file size',
      'Unlimited storage (10 TB included)',
      'Global CDN included',
    ],
    highlights: [
      'Unlimited transfer bandwidth (10 TB included)',
      'Customizable share URLs',
      'API access',
      'Priority support (phone, live chat, and email)',
    ],
    previousPlan: PlanType.Team,
    userNumber: 10,
    price: 30,
  },
  Enterprise: {
    name: PlanType.Enterprise,
    description:
      'Perfect for professional services, marketing teams and educators',
    ids: {
      monthly: 'enterprise_monthly',
      yearly: 'enterprise_yearly',
    },
    features: [
      '10 users minimum, unlimited max',
      'Up to 100 workspaces',
      'Unlimited max file size',
      'Unlimited storage (10 TB included)',
      'Global CDN included',
    ],
    highlights: [
      'Unlimited transfer bandwidth (10 TB included)',
      'Customizable share URLs',
      'API access',
      'Priority support (phone, live chat, and email)',
    ],
    previousPlan: PlanType.Team,
    userNumber: 10,
    price: Number.NaN,
  },
};

export enum OnboardSteps {
  Profile = 'profile',
  Organization = 'organization',
  Subdomain = 'subdomain',
  Questions = 'questions',
  Payment = 'payment',
  Success = 'success',
  Invites = 'invites',
}

export const totalSteps = Object.keys(OnboardSteps).length;

export const getCurrentStepIndex = (step: OnboardSteps) => {
  return Object.values(OnboardSteps).indexOf(step) + 1;
};

export const getStepFromIndex = (index: number) => {
  return Object.values(OnboardSteps)[index - 1];
};
