import {ROUTES} from 'constants/routes';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {getCurrentPath} from 'utils/common/platform';
import {useGetUserProfilePhoto} from './useGetUserProfilePhoto';

export const useProfilePhoto = () => {
  const {fetchUserProfilePhoto} = useGetUserProfilePhoto();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const currentProfilePic = useSelector(user.selectors.getUserProfilePic);

  const hasUserProfilePic = Boolean(userDetails?.profile_pic);

  useEffect(() => {
    if (
      isLoggedIn &&
      !currentProfilePic &&
      getCurrentPath() !== ROUTES.NOT_LOGGED_IN.LOGOUT &&
      hasUserProfilePic
    ) {
      fetchUserProfilePhoto(userDetails.id);
    }
  }, [isLoggedIn, hasUserProfilePic]);
};
