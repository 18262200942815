import FolderIcon from 'assets/svgs/fileIcons/Folder.svg';
import FileIcon from 'assets/svgs/fileIcons/Unknown.svg';
import theme from 'config/theme';
import Skeleton from 'interface/common/Skeleton';
import {Fragment} from 'react';
import {Image, StyleSheet, View} from 'react-native';

const FOLDER_ROW_COUNT = 3;
const FILE_ROW_COUNT = 5;

export function MockFiles() {
  return (
    <View style={styles.mockFiles}>
      {[...Array(FOLDER_ROW_COUNT)].map((_, index) => (
        <View style={styles.fileItem}>
          <Image
            style={[styles.image]}
            source={{uri: FolderIcon}}
            resizeMode="center"
          />
          {index % 2 ? (
            <Fragment>
              <Skeleton width={180} height={20} animation={false} />
              <Skeleton width={40} height={20} animation={false} />
              <Skeleton width={180} height={20} animation={false} />
            </Fragment>
          ) : (
            <Skeleton width={400} height={20} animation={false} />
          )}
        </View>
      ))}
      {[...Array(FILE_ROW_COUNT)].map((_, index) => (
        <View style={[styles.fileItem, {opacity: 0.5 - index * 0.1}]}>
          <Image
            style={[styles.image]}
            source={{uri: FileIcon}}
            resizeMode="center"
          />
          {index % 2 ? (
            <Fragment>
              <Skeleton width={180} height={20} animation={false} />
              <Skeleton width={40} height={20} animation={false} />
              <Skeleton width={180} height={20} animation={false} />
            </Fragment>
          ) : (
            <Skeleton width={400} height={20} animation={false} />
          )}
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  mockFiles: {
    flexDirection: 'column',
    marginTop: 20,
    width: '100%',
  },
  fileItem: {
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    width: '100%',
    minHeight: 44,
  },
  image: {
    width: 28,
    height: 28,
    color: theme.colors.neutral.$12,
  },
});
