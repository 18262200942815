import {api} from 'fast-sdk';
import {ShareLevel} from 'fast-sdk/src/api/share/consts';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import {
  type Share,
  type ShareMember,
  ShareViewStatus,
} from 'store/slices/shared/types';
import * as user from 'store/slices/user';
import {getMembersFromUsers} from 'utils/common/members';

type ShareMembersState = [string, ShareMember[]] | undefined;
type ShareStatusState = [string, ShareViewStatus] | undefined;

interface ShareState {
  members: ShareMember[];
  status: ShareViewStatus;
}

const DEFAULT_STATE: ShareState = {
  members: [],
  status: ShareViewStatus.Guest,
};

interface UseFetchShareViewStatusProps {
  share?: Share;
  isShareLoaded: boolean;
  sharedCustomName: string;
  enabled?: boolean;
}

export default function useFetchShareViewStatus({
  share,
  sharedCustomName,
  enabled = true,
  isShareLoaded,
}: UseFetchShareViewStatusProps) {
  const dispatch = useDispatch();
  const userDetails = useSelector(user.selectors.getUserDetails);

  const [viewStatus, setViewStatus] = useState<ShareViewStatus>(
    share ? ShareViewStatus.Guest : ShareViewStatus.Loading,
  );
  const [members, setMembers] = useState<ShareMembersState>(undefined);
  const [status, setStatus] = useState<ShareStatusState>(undefined);

  const determineViewStatus = (
    members: ShareMember[],
    userId: string,
  ): ShareViewStatus => {
    const currentUserPermissions = members.find(member => member.id === userId);

    if (currentUserPermissions?.permissions === 'owner')
      return ShareViewStatus.Owner;
    if (currentUserPermissions?.permissions === 'guest')
      return ShareViewStatus.Guest;
    return ShareViewStatus.Editor;
  };

  const updateShareState = (customName: string, state: ShareState) => {
    if (share) {
      dispatch(
        shared.default.actions.setShareMembers({share, members: state.members}),
      );
      dispatch(
        shared.default.actions.setShareViewStatus({
          share,
          status: state.status,
        }),
      );
    } else {
      setMembers([customName, state.members]);
      setStatus([customName, state.status]);
    }
    setViewStatus(state.status);
  };

  const handleGuestShare = (customName: string) => {
    updateShareState(customName, DEFAULT_STATE);
  };

  const handleError = (customName: string) => {
    updateShareState(customName, DEFAULT_STATE);
  };

  const fetchShareMembers = async (customName: string) => {
    try {
      if (
        share?.share_level === ShareLevel.Excluded ||
        share?.share_level === ShareLevel.Guest
      ) {
        return handleGuestShare(customName);
      }

      const {result, users, error} =
        await api.share.getAllShareMembers(customName);
      if (result && users) {
        const newMembers = getMembersFromUsers(users as ShareMember[]);
        const newStatus = determineViewStatus(newMembers, userDetails.id);
        updateShareState(customName, {members: newMembers, status: newStatus});
      } else {
        const newStatus =
          error?.code === ApiErrors.ProfileNotFound
            ? ShareViewStatus.NotFound
            : error?.code === ApiErrors.ShareCannotViewMembers
              ? ShareViewStatus.Guest
              : ShareViewStatus.Closed;
        updateShareState(customName, {members: [], status: newStatus});
      }
    } catch (error) {
      handleError(customName);
    }
  };

  useEffect(() => {
    if (sharedCustomName && enabled) {
      fetchShareMembers(sharedCustomName);
    }
  }, [sharedCustomName, enabled]);

  useEffect(() => {
    if (
      share?.id &&
      share.custom_name === members?.[0] &&
      share.custom_name === status?.[0]
    ) {
      updateShareState(share.custom_name, {
        members: members[1],
        status: status[1],
      });
    } else {
      if (isShareLoaded && sharedCustomName === members?.[0]) {
        updateShareState(share?.custom_name, {
          members: [],
          status: share ? ShareViewStatus.Guest : ShareViewStatus.Closed,
        });
      }
    }
  }, [share?.id, members, status, isShareLoaded]);

  return {
    shareViewStatus: viewStatus,
    refetchShareMembers: () => fetchShareMembers(sharedCustomName),
  };
}
