import theme from 'config/theme';
import {UploadsBar} from 'interface/stacks/uploads/views/UploadsBar';
import {UploadsItem} from 'interface/stacks/uploads/views/UploadsItem';
import {useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';

const UPLOADER_SIZE = {
  width: 320,
  height: 320,
  right: 10,
  bottom: 10,
};

export function Uploads(uploader: UploaderState) {
  const [maximized, setMaximized] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const members = [];

  if (uploader.uploads.length === 0) return null;

  return (
    <View style={styles.root}>
      <UploadsBar
        {...uploader}
        {...{maximized, showAll, setMaximized, setShowAll}}
      />
      {maximized && (
        <ScrollView style={styles.list} showsVerticalScrollIndicator={false}>
          {Array.from(uploader.uploads).map(item => (
            <UploadsItem
              key={item.metadata.uuid}
              {...{
                uploader,
                item,
                member: members?.find(m => m.id === item.memberId),
              }}
            />
          ))}
        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    left: '50%',
    width: UPLOADER_SIZE.width,
    transform: [{translateX: -UPLOADER_SIZE.width / 2}],
    maxHeight: UPLOADER_SIZE.height,
    bottom: UPLOADER_SIZE.bottom,
    right: UPLOADER_SIZE.right,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
    backgroundColor: theme.colors.neutral.$1,
    borderRadius: 7,
  },
  list: {
    maxHeight: UPLOADER_SIZE.height - 40,
  },
});
