import {useNavigate} from 'extensions/navigation';
import {useNavigateToSubdomain} from 'interface/common/hooks/navigator/useNavigateToSubdomain';
import {useGetOrganizationDetailsCached} from 'interface/common/hooks/useGetOrganizationDetailsCached';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect, useState} from 'react';
import type {Share} from 'store/slices/shared/types';
import {GO_SUBDOMAIN} from 'utils/fast/constants';
import useGenerateShareUrl from './useGenerateShareUrl';

const useNavigateToShare = () => {
  const navigate = useNavigate();
  const {navigateToSubdomain} = useNavigateToSubdomain();
  const {generateShareUrl} = useGenerateShareUrl();
  const {subdomain} = useSubDomain();

  const [orgId, setOrgId] = useState<string | undefined>(undefined);
  const [navigateUrl, setNavigateUrl] = useState<string | undefined>(undefined);

  const {org: shareOrg} = useGetOrganizationDetailsCached(orgId);

  const navigateToShare = (share: Share, internal?: boolean) => {
    const shareUrl = generateShareUrl(share, internal);
    if (subdomain === GO_SUBDOMAIN) {
      setOrgId(share.parent_org);
      setNavigateUrl(shareUrl);
    } else {
      navigate(shareUrl, {replace: true});
    }
  };

  useEffect(() => {
    if (navigateUrl && shareOrg) {
      navigateToSubdomain(shareOrg.domain, navigateUrl);
      setNavigateUrl(undefined);
      setOrgId(undefined);
    }
  }, [navigateUrl, shareOrg]);

  return {
    navigateToShare,
  };
};

export default useNavigateToShare;
