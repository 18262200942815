import {WorkspaceUserStatus} from 'fast-sdk/src/api/workspace/consts';
import type {Actions, Store} from 'store/slices/workspace/types';
import {resetSlice} from 'store/utils';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {initialState} from '.';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setWorkspacesLoaded(
  state: Store,
  action: Actions['SetWorkspacesLoaded'],
) {
  const workspacesLoaded = action.payload;
  state.workspacesLoaded = workspacesLoaded;
}

export function setWorkspacesLoading(
  state: Store,
  action: Actions['SetWorkspacesLoading'],
) {
  const isLoading = action.payload;
  state.workspacesLoading = isLoading;
}

export function setWorkspaces(state: Store, action: Actions['SetWorkspaces']) {
  const {workspaces} = action.payload;

  state.workspaces = workspaces;
}

export function leaveWorkspace(
  state: Store,
  action: Actions['LeaveWorkspace'],
) {
  const workspace = action.payload;
  const workspaceToLeave = state.workspaces.find(w => w.id === workspace.id);
  if (workspaceToLeave) {
    workspaceToLeave.user_status = WorkspaceUserStatus.Available;
  }
}

export function archiveWorkspace(
  state: Store,
  action: Actions['ArchiveWorkspace'],
) {
  const workspace = action.payload;
  const workspaceToArchive = state.workspaces.find(w => w.id === workspace.id);
  if (workspaceToArchive) {
    workspaceToArchive.archived = true;
  }
}

export function unarchiveWorkspace(
  state: Store,
  action: Actions['UnarchiveWorkspace'],
) {
  const workspace = action.payload;
  const workspaceToUnarchive = state.workspaces.find(
    w => w.id === workspace.id,
  );
  if (workspaceToUnarchive) {
    workspaceToUnarchive.archived = false;
  }
}

export function deleteWorkspace(
  state: Store,
  action: Actions['DeleteWorkspace'],
) {
  const workspace = action.payload;
  state.workspaces = state.workspaces.filter(w => w.id !== workspace.id);
}

export function updateWorkspace(
  state: Store,
  action: Actions['UpdateWorkspace'],
) {
  const {id, workspace} = action.payload;
  const index = state.workspaces.findIndex(w => w.id === id);

  if (index !== -1) {
    state.workspaces[index] = {
      ...state.workspaces[index],
      ...workspace,
      updated: getCurrentUTCDateTime(),
    };
  } else {
    state.workspaces.unshift(workspace);
  }
}

export function setWorkspaceSort(
  state: Store,
  action: Actions['SetWorkspaceSort'],
) {
  state.sort = action.payload;
}

export function toggleSearch(state: Store, action: Actions['ToggleSearch']) {
  state.searchOpen = action.payload ?? !state.searchOpen;
}

export function logRecentSearch(
  state: Store,
  action: Actions['LogRecentSearch'],
) {
  const {id, query, timestamp} = action.payload;
  if (!state.recentSearches?.[id]) {
    state.recentSearches[id] = [];
  }

  // Remove existing duplicate query if it exists
  state.recentSearches[id] = state.recentSearches[id].filter(
    search => search.query !== query,
  );

  // Add new search to the beginning
  state.recentSearches[id] = [
    {query, timestamp},
    ...state.recentSearches[id].slice(0, 4),
  ];
}

export function setWorkspaceLayoutTab(
  state: Store,
  action: Actions['SetWorkspaceLayoutTab'],
) {
  state.layoutTab = action.payload;
}

export function setWorkspaceStatus(
  state: Store,
  action: Actions['SetWorkspaceStatus'],
) {
  state.workspaceStatus = action.payload;
}

export function setWorkspacePic(
  state: Store,
  action: Actions['SetWorkspacePic'],
) {
  const {id, img} = action.payload;
  const workspace = state.workspaces.find(w => w.id === id);
  if (workspace) {
    workspace.logoImage = img;
  }
}

export function setWorkspaceMembers(
  state: Store,
  action: Actions['SetWorkspaceMembers'],
) {
  const {workspaceId, members, loading, error} = action.payload;
  if (!state.membersMap) {
    state.membersMap = {};
  }
  state.membersMap[workspaceId] = {
    members: members ?? state.membersMap[workspaceId]?.members ?? [],
    loading,
    error,
  };
}

export function syncWorkspaces(
  state: Store,
  action: Actions['SyncWorkspaces'],
) {
  const activeWorkspaces = action.payload;
  const filteredWorkspaces = state.workspaces.filter(w =>
    activeWorkspaces.find(aw => aw.id === w.id),
  );
  state.workspaces = Array.from(
    new Map(
      filteredWorkspaces.map(workspace => [workspace.id, workspace]),
    ).values(),
  );
}
