import {REDIRECT_TO_COOKIE} from 'constants/sessionRoutes';
import useBuildCookieSession from 'extensions/session/hooks/useBuildCookieSession';
import type {User} from 'fast-sdk/src/api/user/consts';
import Modal from 'interface/common/Modal';
import {getHostData} from 'interface/common/hooks/useHost';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {AuthPageTypes} from 'interface/stacks/auth/_layout/RouteLayout';
import {ForgotPassword} from 'interface/stacks/auth/pages/ForgotPassword';
import {SigninWithEmail} from 'interface/stacks/auth/pages/SignInWithEmail';
import {SignUpVerification} from 'interface/stacks/auth/pages/SignUpVerification';
import {SignUpWithEmail} from 'interface/stacks/auth/pages/SignUpWithEmail';
import {Signin} from 'interface/stacks/auth/pages/Signin';
import React, {useState} from 'react';
import {StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {setCookie} from 'utils/common/cookies';
import {GO_SUBDOMAIN} from 'utils/fast/constants';

export enum ModalAuthPage {
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  SIGNIN_WITH_EMAIL = 'SIGNIN_WITH_EMAIL',
  SIGNUP_WITH_EMAIL = 'SIGNUP_WITH_EMAIL',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
}

const MODAL_WIDTH_MAP = {
  [ModalAuthPage.SIGNIN]: 500,
  [ModalAuthPage.SIGNUP]: 500,
  [ModalAuthPage.SIGNIN_WITH_EMAIL]: 500,
  [ModalAuthPage.SIGNUP_WITH_EMAIL]: 540,
  [ModalAuthPage.FORGOT_PASSWORD]: 500,
  [ModalAuthPage.EMAIL_VERIFICATION]: 500,
};

interface ModalAuthenticationProps {
  onSuccess?: () => void;
  getRedirectOnSuccess?: () => string;
}

export function ModalAuthentication({
  onSuccess,
  getRedirectOnSuccess,
}: ModalAuthenticationProps) {
  const dispatch = useDispatch();
  const {subdomain} = useSubDomain();

  const {buildAndSetCookie} = useBuildCookieSession();

  const token = useSelector(user.selectors.getToken);

  const [currentPage, setCurrentPage] = useState<ModalAuthPage>(
    token ? ModalAuthPage.EMAIL_VERIFICATION : ModalAuthPage.SIGNUP,
  );

  const handleSigninOrSignup = () => {
    if (currentPage === ModalAuthPage.SIGNUP) {
      setCurrentPage(ModalAuthPage.SIGNIN);
    } else {
      setCurrentPage(ModalAuthPage.SIGNUP);
    }
  };

  const handleSigninOrSignupWithEmail = () => {
    if (currentPage === ModalAuthPage.SIGNUP) {
      setCurrentPage(ModalAuthPage.SIGNUP_WITH_EMAIL);
    } else {
      setCurrentPage(ModalAuthPage.SIGNIN_WITH_EMAIL);
    }
  };

  const handleForgotPassword = () => {
    setCurrentPage(ModalAuthPage.FORGOT_PASSWORD);
  };

  const handleSignin = () => {
    setCurrentPage(ModalAuthPage.SIGNIN);
  };

  const handleSignup = () => {
    setCurrentPage(ModalAuthPage.SIGNUP);
  };

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      window.location.reload();
    }
  };

  const handleEmailVerified = (userDetails: User, token: string) => {
    buildAndSetCookie([], [], userDetails, token);
    handleSuccess();
  };

  const handleSuccessSignup = () => {
    setCurrentPage(ModalAuthPage.EMAIL_VERIFICATION);
  };

  const handleSuccessSignin = (
    userDetails: User | undefined,
    token: string,
  ) => {
    if (userDetails) {
      buildAndSetCookie([], [], userDetails, token);
      handleSuccess();
    } else {
      setCurrentPage(ModalAuthPage.EMAIL_VERIFICATION);
    }
  };

  const handleTryDifferentEmail = () => {
    setCurrentPage(ModalAuthPage.SIGNIN);
  };

  const onOpenLoginPopup = async () => {
    const redirectPage = getRedirectOnSuccess
      ? getRedirectOnSuccess()
      : window.location.href;
    if (subdomain === GO_SUBDOMAIN) {
      dispatch(app.default.actions.setRedirectTo(redirectPage));
    } else {
      const {protocol, mainDomain} = await getHostData();
      setCookie({
        name: REDIRECT_TO_COOKIE,
        value: redirectPage,
        domain: mainDomain.replace(/:\d+$/, ''),
        protocol,
      });
    }
  };

  return (
    <Modal
      disableClose
      containerStyle={{...styles.root, width: MODAL_WIDTH_MAP[currentPage]}}>
      {currentPage === ModalAuthPage.SIGNUP && (
        <Signin
          type={AuthPageTypes.SIGNUP}
          isModal
          onSigninOrSignup={handleSigninOrSignup}
          onSigninOrSignupWithEmail={handleSigninOrSignupWithEmail}
          onForgotPassword={handleForgotPassword}
          onSigninOrSignupWithEmailSuccess={handleSuccessSignin}
          onOpenLoginPopup={onOpenLoginPopup}
        />
      )}
      {currentPage === ModalAuthPage.SIGNIN && (
        <Signin
          type={AuthPageTypes.SIGNIN}
          isModal
          onSigninOrSignup={handleSigninOrSignup}
          onSigninOrSignupWithEmail={handleSigninOrSignupWithEmail}
          onForgotPassword={handleForgotPassword}
          onSigninOrSignupWithEmailSuccess={handleSuccessSignin}
          onOpenLoginPopup={onOpenLoginPopup}
        />
      )}
      {currentPage === ModalAuthPage.SIGNIN_WITH_EMAIL && (
        <SigninWithEmail
          isModal
          onForgotPassword={handleForgotPassword}
          onSignupOrSignin={handleSignup}
          onSuccessSignin={handleSuccessSignin}
        />
      )}
      {currentPage === ModalAuthPage.SIGNUP_WITH_EMAIL && (
        <SignUpWithEmail
          isModal
          onSignupOrSignin={handleSignin}
          onSuccessSignup={handleSuccessSignup}
        />
      )}
      {currentPage === ModalAuthPage.FORGOT_PASSWORD && (
        <ForgotPassword isModal onSignin={handleSignin} />
      )}
      {currentPage === ModalAuthPage.EMAIL_VERIFICATION && (
        <SignUpVerification
          isModal
          onEmailVerified={handleEmailVerified}
          onTryDifferentEmail={handleTryDifferentEmail}
        />
      )}
    </Modal>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 0,
  },
});
