import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';
import {AiChatMenuButton} from '../../WorkspaceAiChat/components/AiChatMenuButton';
import {NOTE_LIST_HEADER_HEIGHT} from '../consts';

interface Props {
  onNewNotePress: () => void;
  onCloseSidebar: () => void;
}

export function NoteListHeader({onNewNotePress, onCloseSidebar}: Props) {
  return (
    <View style={styles.container}>
      <AiChatMenuButton
        iconName="lucide:arrow-left-from-line"
        onPress={onCloseSidebar}
        tooltipText="Close sidebar"
      />
      <AiChatMenuButton
        iconName="lucide:square-plus"
        onPress={onNewNotePress}
        tooltipText="New note"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 16,
    gap: 4,
    height: NOTE_LIST_HEADER_HEIGHT,
    borderBottomColor: theme.colors.neutral.$75,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
});
