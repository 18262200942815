import type {InvitationStatuses} from '../share/consts';

export type Organization = {
  name: string;
  domain: string;
  description?: string;
  facebook?: string;
  homepage?: string;
  perm_auth_domains: string | null;
  perm_join: MemberManagePerm;
  billing_plan?: string;
  billing_email?: string;
  encryption_key?: string;
  accent_color?: {color: string; opacity: number};
  industry?: string;
};

export enum MemberManagePerm {
  'Admin or above' = 'Admin or above',
  'Member or above' = 'Member or above',
  'Guest or above' = 'Guest or above',
}

export type AccentColor = {
  color: string;
  opacity: number;
};

export type OrganizationDetailsResponse = {
  result: boolean;
  org: OrganizationDetails;
};

export type OrganizationDetails = {
  domain: string;
  id: string;
  parent: number;
  created: Date;
  updated: Date;
  name: string;
  billing_email: string;
  encryption_key: string;
  description?: string;
  logo?: string;
  accent_color?: AccentColor;
  facebook?: string;
  twitter?: string;
  youtube?: string;
  instagram?: string;
  homepage?: string;
  perm_member_manage: string;
  perm_auth_domains?: string;
  plan?: string;
  platform: number;
  storage: number;
  subscriber: boolean;
  locked: boolean;
  suspended: boolean;
  closed: boolean;
  subscription?: {
    intent_client_secret: string;
    public_key: string;
  };
};

export type OrganizationMember = Member & {
  permissions: 'owner' | 'admin';
};

export type MembersList = {
  result: boolean;
  results: number; // The number of the members in this organization
  users: Array<OrganizationMember>;
};

export type InvitationsList = {
  result: boolean;
  results: number; // The number of the invitations in this organization
  invitations: Array<Invitation>;
};

export type MemberDetails = {
  result: boolean;
  user: Member;
};

export type Member = {
  '2factor': boolean;
  closed: boolean;
  country_code: 'US';
  created: string;
  email_address: string;
  first_name: string;
  id: string;
  last_name: string;
  locked: boolean;
  phone_country: string;
  phone_number: string;
  profile_pic: string;
  suspended: boolean;
  tos_agree: boolean;
  updated: string;
  valid_email: boolean;
  valid_phone: boolean;
};

export type Invitation = {
  id: string;
  inviter: string;
  invitee_email: string;
  invitee_uid: string | null;
  entity: {
    id: string;
    id_alt: string;
  };
  state: InvitationStatuses;
  created: string;
};

export type OrganizationList = {
  orgs: Array<OrganizationDetails>;
};

export type OrgSubscription = {
  intent_client_secret: string;
  public_key: string;
  plan: string;
  subscription: string;
  trial: boolean;
};

export type OrganizationListMember = {
  id: string;
  domain: string;
  subscription_upgrade: OrgSubscription;
};

export type OrganizationPermission =
  | 'owner'
  | 'admin'
  | 'member'
  | 'guest'
  | 'view'
  | 'any';

export type CreateOrganizationResponse = {
  org: OrganizationListMember;
};
