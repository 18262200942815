import theme from 'config/theme';
import {useFocus} from 'interface/common/hooks/useFocus';
import {
  type SelectionMode,
  useTextSelection,
} from 'interface/common/hooks/useTextSelection';
import type React from 'react';
import {type PropsWithChildren, forwardRef} from 'react';
import {
  type NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  type TextInputChangeEventData,
  type TextInputFocusEventData,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import {Icon} from '../Icon';
import Typography from '../Typography';
import {SIZE, SIZES} from './consts';
import type {InputOverrides, TextInputAllowedProps} from './types';
import {getInputState} from './utils';

interface Props extends TextInputAllowedProps {
  overrides?: InputOverrides;
  size?: keyof typeof SIZE;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  selectionMode?: SelectionMode;
  onChangeValue?: (value: string) => void;
  onChangeEvent?: (
    event: NativeSyntheticEvent<TextInputChangeEventData>,
  ) => void;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  errorMessage?: string;
  onBlurFn?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onFocusFn?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
}

export const Input = forwardRef<TextInput, PropsWithChildren<Props>>(
  (
    {
      overrides,
      size = SIZE.md,
      startEnhancer,
      endEnhancer,
      selectionMode,
      onChangeValue,
      onChangeEvent,
      disabled,
      readonly,
      error,
      errorMessage,
      onFocusFn,
      onBlurFn,
      ...props
    },
    ref,
  ) => {
    const {selection, disableSelection} = useTextSelection(
      selectionMode,
      props.defaultValue,
    );
    const {focused, onFocus, onBlur} = useFocus();

    const handleFocus = (
      event: NativeSyntheticEvent<TextInputFocusEventData>,
    ) => {
      onFocus?.();
      onFocusFn?.(event);
    };

    const handleBlur = (
      event: NativeSyntheticEvent<TextInputFocusEventData>,
    ) => {
      onBlur?.();
      onBlurFn?.(event);
    };

    const handleChange = (
      e: NativeSyntheticEvent<TextInputChangeEventData>,
    ) => {
      onChangeEvent?.(e);
      disableSelection();
    };

    const state = getInputState({
      focused,
      disabled,
      readonly,
      error,
    });

    const sizeStyles = SIZES[size];

    const rootStyles: ViewStyle = {
      ...styles.root,
      ...styles[`root${state}`],
      ...overrides?.Root?.style,
    };

    const inputStyles: TextStyle = {
      ...styles.input,
      ...sizeStyles,
      ...styles[`input${state}`],
      ...overrides?.Input?.style,
    };

    const startEnhancerStyles: ViewStyle = {
      ...styles.enhancerContainer,
      ...overrides?.StartEnhancer?.style,
    };

    const endEnhancerStyles: ViewStyle = {
      ...styles.enhancerContainer,
      ...overrides?.EndEnhancer?.style,
    };

    return (
      <View style={[styles.wrapper, overrides?.Wrapper?.style]}>
        <View style={rootStyles}>
          {startEnhancer && (
            <View style={startEnhancerStyles}>{startEnhancer}</View>
          )}
          <View style={styles.inputContainer}>
            <TextInput
              ref={ref}
              style={inputStyles}
              onFocus={handleFocus}
              onBlur={handleBlur}
              editable={!disabled && !readonly}
              placeholderTextColor={theme.colors.neutral.$6}
              selection={selection}
              onSelectionChange={disableSelection}
              {...props}
              onChange={handleChange}
              onChangeText={onChangeValue}
            />
          </View>
          {endEnhancer && <View style={endEnhancerStyles}>{endEnhancer}</View>}
        </View>

        {error && errorMessage && !focused && (
          <View
            style={styles.errorMessageContainer}
            aria-live="polite"
            aria-hidden={!error}>
            <Icon
              name="lucide:triangle-alert"
              size={20}
              color={theme.colors.danger.$4Base}
              aria-label="Error"
            />
            <Typography size="xs" overrides={styles.errorMessage}>
              {errorMessage}
            </Typography>
          </View>
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  wrapper: {
    gap: 6,
  },
  root: {
    // @ts-ignore
    outlineWidth: 1,
    outlineColor: theme.colors.neutral.$100,
    outlineStyle: 'solid',
    borderRadius: 6,
    flexDirection: 'row',
    cursor: 'text',
    backgroundColor: theme.colors.neutral.$75,
  },
  rootfocused: {
    // @ts-ignore
    outlineColor: theme.colors.brand.$1_400,
    outlineWidth: 2,
    outlineStyle: 'solid',
    backgroundColor: theme.colors.neutral.$white,
  },
  rooterror: {
    // @ts-ignore
    outlineColor: theme.colors.brand.$4_100,
    outlineWidth: 2,
    outlineStyle: 'solid',
    backgroundColor: theme.colors.brand.$4_100,
  },
  rooterrorfocused: {
    // @ts-ignore
    outlineColor: theme.colors.brand.$4_400,
    outlineWidth: 2,
    outlineStyle: 'solid',
    backgroundColor: theme.colors.neutral.$white,
  },
  rootdisabled: {
    opacity: 0.5,
  },
  rootreadonly: {
    backgroundColor: theme.colors.neutral.$white,
  },
  enhancerContainer: {
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  inputContainer: {
    flexGrow: 1,
  },
  input: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$700,
    borderRadius: 6,
  },
  inputfocused: {
    color: theme.colors.neutral.$700,
  },
  inputdisabled: {
    // @ts-ignore
    cursor: 'not-allowed',
  },
  inputreadonly: {
    color: theme.colors.neutral.$500,
  },
  errorMessage: {
    color: theme.colors.danger.$e2,
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 7,
  },
});
