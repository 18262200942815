import {ROUTES} from 'constants/routes';
import {REDIRECT_TO_COOKIE} from 'constants/sessionRoutes';
import analytics from 'extensions/analytics';
import {useLocation, useNavigate} from 'extensions/navigation';
import {Requests, Utilities, api} from 'fast-sdk';
import type {SSOTokenParams} from 'fast-sdk/src/api/auth/consts';
import {Loading} from 'interface/common/Loading';
import Auth from 'interface/stacks/auth/consts';
import {useCallback, useEffect, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {clearCookie, getCookie} from 'utils/common/cookies';

interface LoginSSOProps {
  provider: 'Google' | 'Microsoft' | 'Facebook';
}

export function LoginSSO(props: LoginSSOProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const location = useLocation();
  const urlQueries = useMemo(() => Utilities.URLToObject(location.search), []);
  const navigate = useNavigate();
  const providerMapper = useCallback(
    async (provider: LoginSSOProps['provider'], urlQueries: SSOTokenParams) => {
      switch (provider) {
        case 'Google':
          return api.auth.getGoogleSSOToken(urlQueries);
        case 'Facebook':
          return api.auth.getFacebookSSOToken(urlQueries);
        case 'Microsoft':
          return api.auth.getMicrosoftSSOToken(urlQueries);
      }
    },
    [],
  );

  const getSendToken = async () => {
    try {
      const response = await providerMapper(props.provider, urlQueries);

      if (response.result) {
        const token = response.token;

        if (token) {
          Requests.setAuthToken(token);
          await Auth.setAuthToken(token);
          dispatch(user.default.actions.setUserToken(token));
          dispatch(
            user.default.actions.setSSOData({
              provider: props.provider,
              result: JSON.stringify(true),
              loading: true,
            }),
          );
          const redirectTo = getCookie(REDIRECT_TO_COOKIE);
          if (redirectTo) {
            dispatch(app.default.actions.setRedirectTo(redirectTo));
          }
        }
        return;
      }
    } catch (error) {
      analytics.notify(error);
      toast.show(error.message, {type: 'danger'});
      dispatch(user.default.actions.setUserToken(''));
      dispatch(app.default.actions.setRedirectTo(''));
      dispatch(
        user.default.actions.setSSOData({
          provider: props.provider,
          result: JSON.stringify(false),
          loading: false,
        }),
      );
    } finally {
      clearCookie(REDIRECT_TO_COOKIE);
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }
  };

  useEffect(() => {
    if (urlQueries) {
      getSendToken();
    }
  }, []);

  return (
    <View style={styles.root}>
      <Loading />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
