import {useGetFileDetailsCached} from 'interface/common/hooks/useGetFileDetailsCached';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';

export function useGetLastMessageFiles(chatId: string) {
  const {namespace, instanceId} = useGetNamespaceContext();
  const chat = useSelector(
    aichat.selectors.getChat(namespace, instanceId, chatId),
  );
  let scopeIds = [];
  let attachIds = [];

  if (chat) {
    const lastMessage =
      chat.lastMessage ?? chat.messages[chat.messages.length - 1];

    scopeIds = [
      ...(lastMessage?.content.scope?.specific_files_scope?.files ?? []),
      ...(lastMessage?.content.scope?.specific_files_scope?.folders ?? []),
    ].map(fileFilter => fileFilter.node_id);

    attachIds = [...(lastMessage?.content.attached ?? [])].map(
      file => file.node_id,
    );
  }

  const {items: scopeFiles} = useGetFileDetailsCached(
    scopeIds,
    instanceId,
    namespace,
  );
  const {items: attachedFiles} = useGetFileDetailsCached(
    attachIds,
    instanceId,
    namespace,
  );

  return {
    chat: chat,
    lastScopedFiles: scopeFiles,
    lastAttachedFiles: attachedFiles,
  };
}
