import '@blocknote/core/fonts/inter.css';
import {BlockNoteView} from '@blocknote/mantine';
import '@blocknote/mantine/style.css';
import {GradientVariant} from 'interface/stacks/app/GradientProgressBar';
import LoadingProgress from 'interface/stacks/app/LoadingProgress';
import {Fragment, useEffect} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import {useEditorContext} from '../../context/EditorContext';
import useFileUrl from '../../hooks/useFileUrl';
import {HEIGHT_TOP_TOOLBAR} from '../EditorTopToolbar';

interface Props {
  file: FilesItem;
  theme?: 'light' | 'dark';
}

const MARGIN_TOP = 64;

export default function MarkdownFileViewer({file, theme = 'light'}: Props) {
  const {editor, initializeEditor, markdownLoaded} = useEditorContext();

  const {url} = useFileUrl(file);

  async function fetchText() {
    const response = await fetch(url);
    const text = await response.text();
    initializeEditor(text);
  }

  useEffect(() => {
    if (url) {
      fetchText();
    }
  }, [url]);

  return (
    <Fragment>
      {markdownLoaded ? (
        <ScrollView contentContainerStyle={styles.root}>
          <BlockNoteView editor={editor} theme={theme} />
        </ScrollView>
      ) : (
        <View style={styles.loader}>
          <LoadingProgress
            variant={GradientVariant.Secondary}
            showImage={false}
          />
        </View>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: MARGIN_TOP,
    maxWidth: 800,
    maxHeight: `calc(100vh - ${HEIGHT_TOP_TOOLBAR + MARGIN_TOP * 2}px)`,
    padding: 15,
    width: '100%',
    alignSelf: 'center',
  },
  loader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
