import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {Input} from 'interface/base/Input';
import Typography from 'interface/base/Typography';
import {PasswordInputValidation} from 'interface/stacks/auth/components/PasswordInputValidation';
import {useState} from 'react';
import {
  Pressable,
  StyleSheet,
  type TextInputProps,
  View,
  type ViewStyle,
} from 'react-native';

interface Props {
  password: string;
  setPassword: (password: string) => void;
  setPasswordIsValid?: (inp: boolean) => void;
  withValidation?: boolean;
  customRootStyle?: ViewStyle;
  customInputRootStyle?: ViewStyle;
  customInputStyle?: ViewStyle;
  disabled?: boolean;
  label?: string;
  ariaLabel?: string;
  customProps?: TextInputProps;
  errorMessage?: string;
}

export function PasswordInput(props: Props) {
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  return (
    <View style={[styles.root, props.customRootStyle]}>
      <View style={styles.inputContainer}>
        <View style={styles.inputWithLabelContainer}>
          {props.label && (
            <Typography
              size="xs"
              variant="bold"
              color={theme.colors.neutral.$2Base}>
              {props.label}
            </Typography>
          )}
          <Input
            overrides={{
              Root: {
                style: {...props.customInputRootStyle, width: '100%'},
              },
              Input: {
                style: {...props.customInputStyle, width: '100%'},
              },
            }}
            value={props.password}
            onChangeValue={(password: string) => props.setPassword(password)}
            placeholder="Enter your password"
            disabled={props.disabled}
            aria-label={props.ariaLabel ? props.ariaLabel : t`Password`}
            endEnhancer={
              <Pressable
                aria-label="Show Password"
                onPress={() => setHidePassword(prev => !prev)}>
                <Icon
                  name={hidePassword ? 'lucide:eye' : 'lucide:eye-off'}
                  size={24}
                  color={theme.colors.neutral.$6}
                />
              </Pressable>
            }
            errorMessage={props.errorMessage}
            error={Boolean(props.errorMessage)}
            secureTextEntry={hidePassword}
            {...props.customProps}
          />
        </View>
      </View>
      {props.withValidation && (
        <View
          style={{width: '100%'}}
          aria-live="polite"
          aria-hidden={!props.withValidation}>
          <PasswordInputValidation
            password={props.password}
            setPasswordIsValid={props.setPasswordIsValid}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  iconContainer: {
    position: 'relative',
    right: 50,
    backgroundColor: 'transparent',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    padding: 10,
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  inputWithLabelContainer: {
    width: '100%',
    gap: 6,
  },
});
