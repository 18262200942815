import {View} from 'react-native';
import {Outlet} from 'react-router';

import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {MobileDisclaimerBanner} from 'interface/common/MobileDisclaimerBanner';
import AppUserBar from 'interface/stacks/app/AppUserBar';
import {Fragment} from 'react';
import {StyleSheet} from 'react-native';
import {isMobileBrowser} from 'utils/common/platform';
import {SettingsMenu} from '../base/SettingsMenu';

const SEPARATION = 3;

export function LayoutSettings() {
  return (
    <Fragment>
      {isMobileBrowser() && <MobileDisclaimerBanner />}
      <View style={styles.root}>
        <AppUserBar />
        <View style={styles.right}>
          <View style={styles.header}>
            <Typography
              variant="regular"
              size="md"
              color={theme.colors.neutral.$700}>
              Settings
            </Typography>
          </View>
          <View style={styles.content}>
            <SettingsMenu />
            <Outlet />
          </View>
        </View>
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    backgroundColor: theme.colors.neutral.$100,
    flexDirection: 'row',
    minWidth: 900,
  },
  header: {
    backgroundColor: theme.colors.neutral.$0,
    width: '100%',
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    marginTop: SEPARATION,
  },
  content: {
    flexDirection: 'row',
    flexGrow: 1,
    maxHeight: `calc(100% - 42px - ${SEPARATION * 2}px)`,
    marginBottom: SEPARATION,
  },
  right: {
    flex: 1,
    flexGrow: 1,
    marginRight: SEPARATION,
  },
  wrapper: {
    minWidth: 900,
  },
});
