import {Requests} from 'fast-sdk';
import {useState} from 'react';

export function useGenericSSE(url: string) {
  const [events, setEvents] = useState<string[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const startStream = async () => {
    const abortController = new AbortController();

    try {
      const response = await fetch(url, {
        signal: abortController.signal,
        headers: {
          Authorization: `Bearer ${Requests.getAuthToken()}`,
        },
      });

      const result = response.headers.get('x-ve-result') === 'true';
      // if (!result) {
      //   throw new Error('Error result');
      // }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsConnected(true);
      const reader = response.body?.getReader();
      if (!reader) throw new Error('No reader available');

      let buffer = '';
      while (true) {
        const {value, done} = await reader.read();
        if (done) break;
        buffer += new TextDecoder().decode(value);
        const events = parseSSEEvents(buffer).filter(
          event => event.event === 'data',
        );

        const data = events
          .map(event => event.data)
          .filter(item => item && !!item?.word)
          .map(item => item.word);
        if (data.length > 0) {
          buffer = '';
          setEvents(prev => [...prev, ...data]);
        }
      }
      setHasCompleted(true);
      return true;
    } catch (err) {
      console.error('Stream error:', err);
      setError(err instanceof Error ? err : new Error(String(err)));
      setIsConnected(false);
      return false;
    } finally {
      setIsConnected(false);
      setHasCompleted(true);
      abortController.abort();
    }
  };

  return {
    events,
    error,
    isConnected,
    hasCompleted,
    startStream,
  };
}

interface ParsedEvent {
  event: string;
  data?: any;
}

function parseSSEEvents(chunk: string): ParsedEvent[] {
  const events: ParsedEvent[] = [];
  const eventStrings = chunk.split('\n\n').filter(str => str.trim());

  for (const eventString of eventStrings) {
    const lines = eventString.split('\n');
    const event: ParsedEvent = {
      event: '',
    };

    for (const line of lines) {
      if (line.startsWith('event:')) {
        event.event = line.slice(6).trim();
      } else if (line.startsWith('data:')) {
        try {
          event.data = JSON.parse(line.slice(5).trim());
        } catch {
          event.data = line.slice(5).trim();
        }
      }
    }

    if (event.event) {
      events.push(event);
    }
  }

  return events;
}
