import {api} from 'fast-sdk';
import {useDispatch} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {blobToBase64} from 'utils/common/image';

export const useGetWorkspaceLogo = () => {
  const dispatch = useDispatch();

  const setProfilePic = (workspaceId: string, profilePic: string) => {
    if (profilePic) {
      dispatch(
        workspace.default.actions.setWorkspacePic({
          id: workspaceId,
          img: profilePic,
        }),
      );
    }
  };

  const fetchWorkspaceLogo = async (workspaceId: string) => {
    const profilePic = await api.workspace.getLogoAsset(workspaceId);
    blobToBase64(profilePic, img => {
      setProfilePic(workspaceId, img);
    });
  };

  return {fetchWorkspaceLogo};
};
