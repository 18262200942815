import theme from 'config/theme';
import type React from 'react';
import {useState} from 'react';
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';
import Popover, {
  PopoverPlacement as Placement,
} from 'react-native-popover-view';

type BasePlacement = 'top' | 'bottom' | 'left' | 'right' | 'floating' | 'auto';
type Alignment = 'start' | 'end' | '';
type PlacementType =
  | `${BasePlacement}${`-${Alignment}` | ''}`
  | 'auto'
  | 'floating';

type Props = {
  children: React.ReactNode;
  triggerElement:
    | React.ReactNode
    | ((props: {isOpen: boolean}) => React.ReactNode);
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  placement?: PlacementType;
  offset?: number;
  autoClose?: boolean;
  customPopupStyle?: StyleProp<ViewStyle>;
  customContainerStyle?: StyleProp<ViewStyle>;
  customTriggerStyle?: StyleProp<ViewStyle>;
  showArrow?: boolean;
  close?: () => void;
  showBorder?: boolean;
};

const mapPlacementToPopoverPlacement = (
  placement: PlacementType,
): Placement => {
  const [base] = placement.split('-') as [BasePlacement, Alignment];

  switch (base) {
    case 'top':
      return Placement.TOP;
    case 'bottom':
      return Placement.BOTTOM;
    case 'left':
      return Placement.LEFT;
    case 'right':
      return Placement.RIGHT;
    case 'floating':
      return Placement.FLOATING;
    case 'auto':
      return Placement.AUTO;
    default:
      return Placement.BOTTOM;
  }
};

export function Popup({
  children,
  triggerElement,
  isOpen: controlledIsOpen,
  onOpenChange,
  placement = 'bottom',
  offset = 0,
  autoClose = true,
  customPopupStyle,
  customContainerStyle,
  customTriggerStyle,
  showArrow = false,
  showBorder = true,
  close,
}: Props) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);

  const isControlled = controlledIsOpen !== undefined;
  const isOpen = isControlled ? controlledIsOpen : uncontrolledIsOpen;

  const handleOpenChange = (newIsOpen: boolean) => {
    if (!isControlled) {
      setUncontrolledIsOpen(newIsOpen);
    }
    onOpenChange?.(newIsOpen);
  };

  const handleClose = () => {
    handleOpenChange(false);
    close?.();
  };

  const renderTrigger = () => {
    if (typeof triggerElement === 'function') {
      return triggerElement({isOpen});
    }
    return triggerElement;
  };

  const popoverPlacement = mapPlacementToPopoverPlacement(placement);

  return (
    <Popover
      isVisible={isOpen}
      onRequestClose={() => {
        if (autoClose) {
          handleClose();
        }
      }}
      placement={popoverPlacement}
      offset={offset}
      popoverStyle={[customPopupStyle]}
      from={
        <Pressable
          style={[styles.trigger, customTriggerStyle]}
          onPress={() => handleOpenChange(!isOpen)}>
          {renderTrigger()}
        </Pressable>
      }
      backgroundStyle={styles.transparent}
      arrowSize={showArrow ? {height: 10, width: 10} : {height: 0, width: 0}}>
      <View
        style={[
          styles.content,
          showBorder && styles.border,
          customContainerStyle,
        ]}>
        {typeof children === 'function'
          ? children({close: handleClose})
          : children}
      </View>
    </Popover>
  );
}

const styles = StyleSheet.create({
  content: {
    overflow: 'hidden',
    minWidth: 'max-content',
    minHeight: 'max-content',
  },
  border: {
    borderColor: theme.colors.neutral.$10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 6,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  trigger: {
    // @ts-expect-error
    display: 'initial',
  },
});
