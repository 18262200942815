import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useDispatch} from 'react-redux';
import store from 'store';
import * as app from 'store/slices/app';
import * as workspace from 'store/slices/workspace';
import {useFetchWorkspaces} from './useFetchWorkspaces';

interface UseLayoutWorkspacesOptions {
  updateLayoutLoader?: boolean;
  onWorkspacesRefresh?: (workspaces: WorkspaceListDetail[]) => void;
  setSharesLoaded?: () => void;
}

const LIMIT = 1;
const TIME = 0.4;

export const useLayoutWorkspaces = ({
  updateLayoutLoader = true,
  onWorkspacesRefresh = () => {},
  setSharesLoaded,
}: UseLayoutWorkspacesOptions | undefined = {}) => {
  const dispatch = useDispatch();

  const {fetchWorkspaces, isLoading: isFetchLoading} = useFetchWorkspaces();

  const fetchAndUpdateWorkspaces = async (disabledCache = false) => {
    try {
      const key = 'workspaces-all';
      const canBeCalled = app.selectors.canEndpointBeCalled(
        key,
        LIMIT,
        TIME,
      )(store.getState());

      if (!disabledCache && !canBeCalled) {
        setSharesLoaded?.();
        return;
      }

      if (updateLayoutLoader)
        dispatch(workspace.default.actions.setWorkspacesLoading(true));

      const workspaces = await fetchWorkspaces();

      if (workspaces) {
        dispatch(
          workspace.default.actions.setWorkspaces({
            workspaces,
          }),
        );

        if (!disabledCache) {
          dispatch(
            app.default.actions.setEndpointCallsLimitEntry({
              key,
              times: LIMIT,
              sent: canBeCalled ? new Date() : undefined,
            }),
          );
          onWorkspacesRefresh?.(workspaces);
        }
      } else {
        setSharesLoaded?.();
      }

      return workspaces;
    } finally {
      dispatch(workspace.default.actions.setWorkspacesLoaded(true));
      if (updateLayoutLoader)
        dispatch(workspace.default.actions.setWorkspacesLoading(false));
    }
  };

  return {fetchAndUpdateWorkspaces, isFetchLoading};
};
