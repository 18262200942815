import {api} from 'fast-sdk';
import type {MemberPermission} from 'fast-sdk/src/api/workspace/consts';
import {useCallback, useState} from 'react';

type InvitationResponse = {
  successEmails: Array<string>;
  failedEmails: Array<string>;
};

const useOrgInvitation = (subdomain: string, orgName: string) => {
  const [loading, setLoading] = useState(false);

  const sendInvitations = useCallback(
    async (
      emails: Array<string>,
      permission: MemberPermission = 'member',
      message?: string,
    ): Promise<InvitationResponse> => {
      try {
        setLoading(true);
        const successEmails = [];
        const failedEmails = [];

        await Promise.all(
          emails.map(async email => {
            try {
              const response = await api.organization.addMember(
                subdomain,
                email,
                permission,
                message || `You've been invited to the ${orgName} organization`,
              );

              if (response.result) {
                successEmails.push(email);
              } else {
                failedEmails.push(email);
              }
            } catch (error) {
              failedEmails.push(email);
            }
          }),
        );

        return {successEmails, failedEmails};
      } finally {
        setLoading(false);
      }
    },
    [subdomain, orgName],
  );

  return {
    sendInvitations,
    loading,
  };
};

export default useOrgInvitation;
