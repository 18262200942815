import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {ActivityIndicator, StyleSheet} from 'react-native';

interface Props {
  isEnabled: boolean;
  onPress: () => void;
}

export function AiChatInputButton({isEnabled, onPress}: Props) {
  return (
    <Button
      onPress={onPress}
      variant="primary"
      disabled={!isEnabled}
      startEnhancer={
        isEnabled ? (
          <Icon name="lucide:arrow-up" color={theme.colors.neutral.$0} />
        ) : (
          <ActivityIndicator size="small" color={theme.colors.neutral.$0} />
        )
      }
      overrides={{
        Button: {
          style: {
            backgroundColor: theme.colors.brand.$4,
            width: 30,
            height: 30,
            minHeight: 0,
            opacity: isEnabled ? 1 : 0.5, // Add opacity based on isEnabled state
          },
        },
      }}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
    borderTopColor: theme.colors.neutral.$75,
    borderTopWidth: 1,
    alignItems: 'center',
  },
  inputWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  inputContainer: {
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 8,
    flex: 1,
    overflow: 'visible',
    gap: 16,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$75,
    width: '100%',
  },
  aiDisclaimer: {
    marginTop: 4,
  },
  customizeSection: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopColor: theme.colors.neutral.$100,
    borderTopWidth: 1,
    paddingTop: 8,
    gap: 8,
    paddingHorizontal: 16,
  },
  spacer: {
    flex: 1,
  },
  selectFilesSection: {
    marginHorizontal: 16,
  },
  inputRow: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    paddingHorizontal: 16,
    gap: 12,
  },
  input: {
    width: '100%',
    padding: 0,
    borderWidth: 0.5,
    borderColor: 'transparent',
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    height: 'fit-content',
    paddingEnd: 16,
  },
  help: {
    width: 'auto',
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
});
