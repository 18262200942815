/**
 * AI Chat Message Creation Module
 *
 * This hook manages the lifecycle of creating new messages within existing AI chats.
 * It provides a seamless message creation experience with optimized state handling.
 *
 * Key responsibilities:
 * - Creating new messages with immediate user feedback
 * - Managing file attachments and content scoping for context-aware responses
 * - Orchestrating API interactions for message creation
 * - Handling state transitions between temporary and permanent message resources
 * - Maintaining consistent UI throughout asynchronous operations
 */

import {api} from 'fast-sdk';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import * as user from 'store/slices/user';
import {CHAT_FOLDER_SCOPE_DEPTH, TEMP_MESSAGE_ID} from '../consts';
import {type FilterInfo, buildTempMessage} from '../helpers';

export const useCreateNewMessage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {namespace, instanceId, instanceName} = useGetNamespaceContext();
  const dispatch = useDispatch();

  const userId = useSelector(user.selectors.getUserDetails)?.id;

  /**
   * Creates a new message in an existing chat.
   *
   * This function handles the complete message creation workflow:
   * - Creates a temporary message for immediate user feedback
   * - Configures file scoping and attachments for context-aware AI responses
   * - Makes the API request to create the permanent message
   * - Updates state with the permanent message ID from the API
   * - Handles error conditions gracefully
   *
   * @param chatId - The ID of the chat to add the message to
   * @param message - The message content
   * @param personality - The AI personality setting for this message
   * @param scopeFiles - Files to include in the AI's context scope
   * @param attachFiles - Files to attach to the message
   * @returns The new message ID if successful
   */
  const createChatMessage = async (
    chatId: string,
    message: string,
    personality: string,
    scopeFiles: FilterInfo[],
    attachFiles: FilterInfo[],
  ) => {
    setIsLoading(true);
    const filesScope = scopeFiles
      .filter(file => file.type === 'file' || file.type === 'note')
      .map(file => `${file.id}:${file.version}`)
      .join(',');
    const foldersScope = scopeFiles
      .filter(file => file.type === 'folder')
      .map(file => `${file.id}:${CHAT_FOLDER_SCOPE_DEPTH}`)
      .join(',');
    const filesAttach = attachFiles
      .filter(file => file.type === 'file' || file.type === 'note')
      .map(file => `${file.id}:${file.version}`)
      .join(',');
    try {
      dispatch(aichat.default.actions.setTriggerStreaming(null));

      // STEP 1: Create a temporary message with placeholder ID
      const newMessage = buildTempMessage(
        chatId,
        TEMP_MESSAGE_ID,
        message,
        personality,
        userId,
        filesScope,
        foldersScope,
        filesAttach,
      );

      // Add temporary message to Redux (shows in UI immediately)
      dispatch(
        aichat.default.actions.addChatMessage({
          namespace,
          instanceId,
          message: newMessage,
        }),
      );

      // STEP 2: Send API request to create the real message
      const createMessageResponse = await api.workspaceAichat.createChatMessage(
        namespace,
        instanceName,
        chatId,
        message,
        personality,
        filesScope,
        foldersScope,
        filesAttach,
      );

      if (createMessageResponse.result) {
        const newMessageId = createMessageResponse?.message?.id;

        // STEP 3: Update the message ID in place (more efficient than delete+add)
        dispatch(
          aichat.default.actions.updateTempMessageId({
            namespace,
            instanceId,
            chatId,
            tempMessageId: TEMP_MESSAGE_ID,
            newMessageId: newMessageId,
          }),
        );
        return newMessageId;
      }
      throw new Error('Failed to create message');
    } catch (error) {
      // Remove the temporary message if there was an API error
      dispatch(
        aichat.default.actions.deleteChatMessage({
          namespace,
          instanceId,
          chatId,
          messageId: TEMP_MESSAGE_ID,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, createChatMessage};
};
