import theme from 'config/theme';
import Skeleton from 'interface/common/Skeleton';
import {FolderBreadcrumbsItem} from 'interface/stacks/workspace/storage/FolderBreadcrumbsItem';
import {ColumnManager} from 'interface/stacks/workspace/storage/base/ColumnManager';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';

export interface StorageHeaderProps {
  share: Share;
}

export function StorageHeader({share}: StorageHeaderProps) {
  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.breadcrumbs}>
          {share.title ? (
            <FolderBreadcrumbsItem
              id={share.id}
              name={share.title}
              root={undefined}
              state={'Current'}
              workspaceId={undefined}
              members={[]}
              isLast
              onPressRoot={undefined}
              onPress={undefined}
              isShared
            />
          ) : (
            <Skeleton width={200} height={32} animation={false} />
          )}
        </View>
        <ColumnManager />
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: StyleSheet.hairlineWidth,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingStart: 8,
    paddingEnd: 12,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$white,
    justifyContent: 'space-between',
  },
  breadcrumbs: {
    gap: 4,
    marginVertical: 6,
    marginHorizontal: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
