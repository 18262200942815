import theme from 'config/theme';
import {getBaseUrl} from 'fast-sdk/src/requests/utils';
import Markdown from 'interface/base/Markdown';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import React, {useEffect, useMemo, useRef} from 'react';
import {StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {EntityChatMessage} from 'store/slices/aichat/types';
import {TEMP_MESSAGE_ID} from '../../consts';
import {useGenericSSE} from '../../hooks/useGenericSSE';
import {TypingIndicator} from '../TypingIndicator';

interface Props {
  message: EntityChatMessage;
}

export function AiChatStreamingResponseItem({message}: Props) {
  const dispatch = useDispatch();
  const {namespace, instanceName} = useGetNamespaceContext();
  const streamingChatId = useSelector(aichat.selectors.getStreamingChatId());
  const retryAttemptRef = useRef(0);

  const chatId = message.chatId;
  const messageId = message.id;
  const triggerStreaming = streamingChatId === chatId;

  const isInProgress = message.state === 'in_progress';
  const isReady = message.state === 'ready';
  const isTempMessage = messageId === TEMP_MESSAGE_ID;

  const {events, isConnected, error, hasCompleted, startStream} = useGenericSSE(
    getBaseUrl(
      `/${namespace}/${instanceName}/ai/chat/${chatId}/message/${messageId}/read`,
    ),
  );
  const response = `${events.join('')}`;

  const renderTypingIndicator = useMemo(() => {
    return <TypingIndicator />;
  }, []);

  useEffect(() => {
    const isStreaming =
      !isTempMessage && (isInProgress || isReady) && triggerStreaming;

    if (isStreaming) {
      startStream();
      dispatch(aichat.default.actions.setTriggerStreaming(null));
      // Reset retry counter when we start streaming intentionally
      retryAttemptRef.current = 0;
    }
  }, [triggerStreaming]);

  // Add error recovery mechanism
  useEffect(() => {
    // If there's an error, connection is not active, message is not complete, and we haven't exceeded retry attempts
    if (
      error &&
      !isConnected &&
      !hasCompleted &&
      (isInProgress || isReady) &&
      !isTempMessage &&
      retryAttemptRef.current < 3
    ) {
      // Simple retry with incrementing delay to avoid overwhelming the server
      const retryDelay = Math.min(1000 * (retryAttemptRef.current + 1), 5000);

      const timeoutId = setTimeout(() => {
        retryAttemptRef.current += 1;
        console.log(
          `Retrying stream connection (attempt ${retryAttemptRef.current})...`,
        );
        startStream();
      }, retryDelay);

      return () => clearTimeout(timeoutId);
    }
  }, [error, isConnected, hasCompleted, isInProgress, isReady, isTempMessage]);

  return (
    <>
      {response.length === 0 && renderTypingIndicator}
      <Markdown style={markdownStyles}>{response}</Markdown>
    </>
  );
}

const markdownStyles = StyleSheet.create({
  body: {
    color: theme.colors.neutral.$700,
    fontSize: 14,
    lineHeight: 24,
    paddingStart: 40,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 8,
  },
  buttons: {
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  spacer: {
    flex: 1,
  },
  iconContainer: {
    padding: 4,
  },
  popupContent: {
    width: 100,
    height: 100,
  },
});
