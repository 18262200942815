import theme from 'config/theme';
import {format, isToday, isYesterday} from 'date-fns';
import {
  FilePreviewState,
  FileThumbnailSizes,
  PreviewType,
} from 'fast-sdk/src/api/storage/consts';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {Icon} from 'interface/base/Icon';
import {NoteIcon} from 'interface/base/NoteIcon';
import {ShareTypeBadge} from 'interface/base/ShareTypeBadge';
import Typography from 'interface/base/Typography';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import MediaComponent from 'interface/stacks/content-viewer/components/MediaComponent';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {useMemo} from 'react';
import {Image, StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import {bytesize} from 'utils/common/data';
import {parseServerDate} from 'utils/common/dates';
import {FileType, getFileTypeFromMime, typeToIcon} from 'utils/fast/files';

interface Props {
  type: 'folder' | 'file' | 'share' | 'workspace' | 'other' | 'note';
  item: FilesItem | Share | WorkspaceListDetail | undefined;
}

const renderDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, "'Today");
  }

  if (isYesterday(date)) {
    return format(date, "'Yesterday");
  }

  return format(date, 'MM/dd/yy');
};

export function InfoPanelHeader({type, item}: Props) {
  const render = useMemo(() => {
    switch (type) {
      case 'note':
      case 'file':
        return <FileHeader item={item as FilesItem} />;
      case 'folder':
        return <FileHeader item={item as FilesItem} />;
      case 'share':
        return <ShareHeader item={item as Share} />;
      case 'workspace':
        return <WorkspaceHeader item={item as WorkspaceListDetail} />;
      default:
        return null;
    }
  }, [type, item?.id, item]);

  return render;
}

function FileHeader({item}: {item: FilesItem}) {
  const type = getFileTypeFromMime(item);

  const availablePreviews = Object.entries(item?.previews ?? {})
    .filter(([, preview]) => preview.state === FilePreviewState.READY)
    .map(([key]) => key);

  const PREVIEW_TYPE_PRIORITY = [
    PreviewType.HLSSTREAM,
    PreviewType.IMAGE,
    PreviewType.THUMBNAIL,
  ] as const;

  const preview = PREVIEW_TYPE_PRIORITY.find(type =>
    availablePreviews.includes(type),
  );

  if (preview || type === FileType.Audio) {
    const showThumbnail =
      preview === PreviewType.IMAGE || preview === PreviewType.THUMBNAIL;

    return (
      <View style={styles.mediaContainer}>
        {showThumbnail ? (
          <FileThumbnail item={item} size={FileThumbnailSizes.Preview} />
        ) : (
          <MediaComponent file={item} isThumbnail />
        )}
      </View>
    );
  }

  return (
    <GenericHeader
      item={item}
      icon={
        type === FileType.Markdown ? (
          <NoteIcon size={34} />
        ) : (
          <Image
            style={styles.folderIcon}
            source={{uri: typeToIcon(type)}}
            resizeMode="center"
          />
        )
      }
      type={type}
      size={item.size}
    />
  );
}

function WorkspaceHeader({item}: {item: WorkspaceListDetail}) {
  return (
    <GenericHeader
      item={item}
      icon={<WorkspaceBadge id={item.id} size={30} />}
      type="Workspace"
    />
  );
}

function ShareHeader({item}: {item: Share}) {
  return (
    <GenericHeader
      item={item}
      icon={<ShareTypeBadge shareType={item.share_type} size={34} />}
      type={`Share, ${item.share_type}`}
      size={item.size}
    />
  );
}

interface HeaderProps {
  item: FilesItem | Share | WorkspaceListDetail;
  icon: React.ReactNode;
  type: string;
  size?: string | number;
}

function GenericHeader({item, icon, type, size}: HeaderProps) {
  const created = parseServerDate(item.created);
  const formattedCreated = renderDate(created);

  return (
    <View style={[styles.root]}>
      <View style={styles.header}>
        {icon}
        <View style={styles.column}>
          <Typography
            size="md"
            color={theme.colors.neutral.$700}
            overrides={styles.overflowEllipsis}>
            {item.name}
          </Typography>

          <View style={[styles.row, {gap: 4}]}>
            <Icon
              name="lucide:clock"
              size={16}
              color={theme.colors.neutral.$300}
            />
            <Typography
              size="xs"
              color={theme.colors.neutral.$500}
              overrides={styles.overflowEllipsis}>
              {formattedCreated}
            </Typography>
          </View>
        </View>
      </View>
      <View style={styles.item}>
        <Typography
          size="xs"
          color={theme.colors.neutral.$700}
          overrides={{textTransform: 'capitalize'}}>
          {type}
        </Typography>

        {size && (
          <Typography size="xs" color={theme.colors.neutral.$700}>
            ({bytesize(size as number, 2)})
          </Typography>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 6,
  },
  header: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$100,
  },
  icon: {
    width: 34,
    height: 34,
    backgroundColor: theme.colors.neutral.$500,
  },
  folderIcon: {
    width: 34,
    height: 34,
  },
  column: {
    flexDirection: 'column',
    flex: 1,
  },
  divider: {
    width: 1,
    height: '100%',
    backgroundColor: theme.colors.neutral.$100,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  item: {
    padding: 12,
    gap: 4,
    flexDirection: 'row',
  },
  overflowEllipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  mediaContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: 262,
    backgroundColor: theme.colors.neutral.$15,
  },
});
