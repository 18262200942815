import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {usePopup} from 'extensions/viewport/usePopup';
import {useCallback, useState} from 'react';
import {
  Image,
  type ImageSourcePropType,
  type PointerEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
  useWindowDimensions,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface LoginButtonProps {
  icon: ImageSourcePropType;
  title: string;
  onPress: () => void;
  width?: number;
  height?: number;
  signUpButton?: boolean;
  previouslyError?: boolean;
  previouslySignedIn?: boolean;
  customRootStyle?: ViewStyle;
  withoutMarginBottom?: boolean;
  fullWidth?: boolean;
}

export function LoginButton(props: LoginButtonProps) {
  const [hovered, setHovered] = useState<boolean>(false);
  const {open, close, reference, visible, setType, setPlacement} =
    usePopup() ?? {};

  const openPopup = (e: PointerEvent) => {
    setType('hoverable');
    setPlacement(dimension.width > 790 ? 'right-start' : 'top-end');
    if (e.nativeEvent) {
      open(
        <Text
          style={[
            styles.tooltip,
            {
              marginLeft: dimension.width > 790 ? '1rem' : 0,
              marginBottom: dimension.width < 790 ? '1rem' : 0,
            },
          ]}>
          <View
            style={dimension.width > 790 ? styles.arrowLeft : styles.arrowDown}
          />
          <Trans>You previously signed in using this option.</Trans>
        </Text>,
      );
    }
  };

  const colorStyle = useCallback(() => {
    if (hovered) {
      return {
        backgroundColor: theme.colors.neutral.$white,
        borderColor: theme.colors.neutral.$10,
      };
    }
    return {
      backgroundColor: theme.colors.neutral.$13,
      borderColor: theme.colors.neutral.$12,
    };
  }, [hovered]);

  const dimension = useWindowDimensions();

  return (
    <Pressable
      role="button"
      style={[
        styles.container,
        {
          width: props.fullWidth
            ? '100%'
            : dimension.width > 790
              ? 380
              : '100%',
          backgroundColor: colorStyle().backgroundColor,
          borderColor: colorStyle().borderColor,
          marginBottom: props.withoutMarginBottom ? 0 : '1rem',
        },
        props.customRootStyle,
      ]}
      onPress={props.onPress}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}>
      <View style={styles.logoContainer}>
        <Image
          source={props.icon}
          style={{
            ...styles.logo,
            width: `${props.width ? props.width : 24}px`,
            height: `${props.height ? props.height : 24}px`,
          }}
        />
      </View>
      <Text
        style={{
          ...styles.title,
          color: theme.colors.neutral.$2Base,
        }}>
        {props.title}
      </Text>
      <View style={styles.rightIcon}>
        {!props.signUpButton && props.previouslySignedIn ? (
          <Pressable
            ref={reference}
            style={[styles.iconContainer, styles.previouslyIconContainer]}
            onHoverOut={close}
            onHoverIn={e => {
              !visible && openPopup(e as PointerEvent);
            }}>
            <Icon
              name="history"
              size={20}
              color={theme.colors.neutral.$white}
            />
          </Pressable>
        ) : null}
        {!props.signUpButton && props.previouslyError ? (
          <Pressable
            style={[styles.iconContainer, styles.errorIconContainer]}
            ref={reference}>
            <Icon name="close" size={20} color={theme.colors.neutral.$white} />
          </Pressable>
        ) : null}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: 48,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    width: '75%',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
    textAlign: 'center',
  },
  logoContainer: {
    width: '12.5%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  rightIcon: {
    width: '12.5%',
    height: '100%',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  iconContainer: {
    width: '70%',
    height: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  previouslyIconContainer: {
    backgroundColor: theme.colors.success.$4Base,
    borderColor: 'rgba(34, 135, 77, 0.2)',
  },
  errorIconContainer: {
    backgroundColor: theme.colors.danger.$4Base,
    borderColor: 'rgba(173, 71, 48, 0.2)',
  },
  tooltip: {
    backgroundColor: theme.colors.neutral.$2Base,
    width: 160,
    height: 55,
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 19,
    color: theme.colors.neutral.$white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    borderRadius: 5,
  },
  arrowLeft: {
    position: 'absolute',
    right: 160,
    top: 22.5,
    zIndex: 5,
    width: 0,
    height: 0,
    borderTopWidth: 6,
    borderTopColor: 'transparent',
    borderBottomWidth: 6,
    borderBottomColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: theme.colors.neutral.$2Base,
  },
  arrowDown: {
    position: 'absolute',
    right: 10,
    top: 55,
    zIndex: 5,
    width: 0,
    height: 0,
    borderLeftWidth: 6,
    borderLeftColor: 'transparent',
    borderRightWidth: 6,
    borderRightColor: 'transparent',
    borderTopWidth: 10,
    borderTopColor: theme.colors.neutral.$2Base,
  },
});
