import {
  GridCellKind,
  TextCellEntry,
  getMiddleCenterBias,
} from '@glideapps/glide-data-grid';
import {roundedRect} from './draw';

import type {CustomCell, CustomRenderer} from '@glideapps/glide-data-grid';

const CELL_NAME = 'file-cell';

export type FileCell = CustomCell<FileCellProps>;
export interface FileCellProps {
  readonly kind: typeof CELL_NAME;
  readonly icon: string;
  readonly name?: string;
  readonly image?: string;
}

const renderer: CustomRenderer<FileCell> = {
  kind: GridCellKind.Custom,
  isMatch: (c): c is FileCell => (c.data as any).kind === CELL_NAME,
  draw: (args, cell) => {
    const {ctx, rect, theme, imageLoader, col, row} = args;
    const {image, name, icon} = cell.data;

    const xPad = 8;
    const rectWidth = 36;
    const rectHeight = 28;
    const borderRadius = 4;
    const drawX = rect.x + xPad;
    const drawY = rect.y + (rect.height - rectHeight) / 2;

    ctx.save();

    // Draw the background rect & image
    if (image) {
      ctx.beginPath();
      roundedRect(ctx, drawX, drawY, rectWidth, rectHeight, borderRadius);
      ctx.fillStyle = '#F7F7F8';
      ctx.fill();

      const img = imageLoader.loadOrGetImage(image, col, row);

      if (img !== undefined) {
        ctx.save();
        ctx.beginPath();
        roundedRect(ctx, drawX, drawY, rectWidth, rectHeight, borderRadius);
        ctx.clip();

        // Calculate dimensions to maintain aspect ratio and fit within the rectangle
        const imgAspect = img.width / img.height;
        const rectAspect = rectWidth / rectHeight;

        let renderWidth = 0;
        let renderHeight = 0;
        let offsetX: number = drawX;
        let offsetY: number = drawY;

        if (imgAspect > rectAspect) {
          // Image is wider - scale to fit width
          renderWidth = rectWidth;
          renderHeight = rectWidth / imgAspect;
          offsetY = drawY + (rectHeight - renderHeight) / 2;
        } else {
          // Image is taller - scale to fit height
          renderHeight = rectHeight;
          renderWidth = rectHeight * imgAspect;
          offsetX = drawX + (rectWidth - renderWidth) / 2;
        }
        // Ensure we're strictly within bounds
        if (renderWidth > rectWidth) renderWidth = rectWidth;
        if (renderHeight > rectHeight) renderHeight = rectHeight;
        // Draw image centered and maintaining aspect ratio
        ctx.drawImage(img, offsetX, offsetY, renderWidth, renderHeight);
        ctx.restore();
      }
    }

    // Otherwise, draw the svg icon if icon is set
    else if (icon) {
      const img = imageLoader.loadOrGetImage(icon, col, row);
      if (img !== undefined) {
        // Calculate scaling to fit SVG (40x40)
        // into our rectangle (36x28) while maintaining aspect ratio
        const box = 40;
        const scale = Math.min(rectWidth / box, rectHeight / box);
        const scaledWidth = box * scale;
        const scaledHeight = box * scale;
        // Center the SVG in the rectangle
        const iconX = drawX + (rectWidth - scaledWidth) / 2;
        const iconY = drawY + (rectHeight - scaledHeight) / 2;
        // Draw the SVG icon without clipping or background
        ctx.drawImage(img, iconX, iconY, scaledWidth, scaledHeight);
      }
    }

    // Draw the file name text
    if (name) {
      ctx.font = `500 13px ${theme.fontFamily}`;
      ctx.fillStyle = theme.textDark;
      ctx.fillText(
        name,
        drawX + rectWidth + xPad,
        rect.y + rect.height / 2 + getMiddleCenterBias(ctx, theme),
      );
    }

    ctx.restore();
    return true;
  },
  provideEditor: () => p => {
    const {isHighlighted, onChange, value} = p;
    return (
      <TextCellEntry
        highlight={isHighlighted}
        autoFocus={true}
        value={value.data.name ?? ''}
        disabled={value.readonly ?? false}
        onChange={e =>
          onChange({
            ...value,
            data: {
              ...value.data,
              name: e.target.value,
            },
          })
        }
      />
    );
  },
  onPaste: (v, d) => ({
    ...d,
    name: v,
  }),
};

export default renderer;
