import {ROUTES} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import SettingsContent, {
  PUBLIC_SETTINGS_SECTIONS,
} from 'interface/stacks/settings/SettingsContent';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import * as user from 'store/slices/user';
import type {Params} from '../types';

export function RouteSettings() {
  const {settingsSection: section, subdomain} = useParams<Params>();

  const orgs = useSelector(user.selectors.getUserAllowedSettingsOrgs);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      slices.app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Settings,
        settings: section,
      }),
    );
  }, [section]);

  useEffect(() => {
    const isAccountSettings = PUBLIC_SETTINGS_SECTIONS.includes(section);
    const isSubdomainInOrgs = orgs.some(org => org.domain === subdomain);
    if (!isSubdomainInOrgs && !isAccountSettings) {
      const firstOrg = orgs?.[0];
      if (firstOrg) {
        navigate(
          `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.organization}/${firstOrg.domain}`,
        );
      } else {
        navigate(
          `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.profile}`,
        );
      }
    }
  }, [subdomain]);

  return <SettingsContent type={SettingsType[section]} />;
}

export default RouteSettings;
