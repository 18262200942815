import ChandlerLogo from 'assets/svgs/aichat/chandler.svg';
import RipleyLogo from 'assets/svgs/aichat/ripley.svg';

export const NEW_CHAT = 'NEW_CHAT';
export const NEW_PRIVATE_CHAT = 'NEW_PRIVATE_CHAT';
export const TEMP_MESSAGE_ID = 'TEMP';
export const CHAT_FOLDER_SCOPE_DEPTH = 5;

export const CHAT_POLL_INTERVAL = 15 * 1000;
export const ACTIVE_CHAT_TIME_THRESHOLD = 22 * 60 * 1000; // 1 minute
export const ACTIVE_CHAT_TIME_CHECK_INTERVAL = 0.5 * 60 * 1000; // 30 seconds

export const AICHAT_TOOLBAR_HEIGHT = 50;

export const PERSONALITY_ICON = {
  concise: RipleyLogo,
  detailed: RipleyLogo,
  fun: ChandlerLogo,
};

export const PERSONALITY_NAMES = {
  concise: 'Ripley',
  detailed: 'Ripley',
  fun: 'Chandler',
};

export type AiChatFilterType = 'scope' | 'attachment';
