import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import {OnboardSteps} from '../lib/consts';

const actions = onboarding.default.actions;
const selectors = onboarding.selectors;

const useSubscriptionStatus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector(selectors.getError);

  const handleError = () => {
    dispatch(actions.setError({error: true}));
  };

  const handleRetry = () => {
    dispatch(actions.setError({error: false}));
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Payment}`,
    );
  };

  return {
    error,
    handleError,
    handleRetry,
  };
};

export default useSubscriptionStatus;
