import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import type {MeterInfo} from 'interface/stacks/settings/components/billing/types';
import type React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

interface Props {
  meters: MeterInfo[];
  selectedMetrics: string[];
  onUpdateSelectedMetrics: (metrics: string[]) => void;
}

export function MetricsFilter({
  meters,
  selectedMetrics,
  onUpdateSelectedMetrics,
}: Props) {
  const isSelected = (metric: MeterInfo) => selectedMetrics.includes(metric.id);

  const handleUpdateSelectedMetrics = (metric: MeterInfo) => {
    if (isSelected(metric)) {
      onUpdateSelectedMetrics(selectedMetrics.filter(m => m !== metric.id));
    } else {
      if (metric.id === 'credits') {
        onUpdateSelectedMetrics(['credits']);
      } else {
        onUpdateSelectedMetrics(
          [...selectedMetrics, metric.id].filter(m => m !== 'credits'),
        );
      }
    }
  };

  const sortedMeters = [
    {
      id: 'credits',
      name: 'Cumulative',
      color: meters.find(m => m.id === 'credits')?.color,
    },
    ...meters.filter(m => !['credits'].includes(m.id)),
  ];

  return (
    <View style={styles.container}>
      {sortedMeters.map((metric, index) => (
        <>
          <TouchableOpacity
            key={metric.id}
            style={[styles.pill, isSelected(metric) && styles.selectedPill]}
            onPress={() => handleUpdateSelectedMetrics(metric)}>
            <View style={[styles.circle, {backgroundColor: metric.color}]} />
            <Typography
              variant="regular"
              size="sm"
              selectable={false}
              color={
                isSelected(metric)
                  ? theme.colors.neutral.$700
                  : theme.colors.neutral.$500
              }>
              {metric.name}
            </Typography>
          </TouchableOpacity>
          {index === 0 && (
            <View style={styles.divider}>
              <View style={styles.line} />
            </View>
          )}
        </>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: 8,
  },
  pill: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 999,
    gap: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    backgroundColor: theme.colors.neutral.$75,
    borderColor: 'transparent',
  },
  selectedPill: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.neutral.$400,
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: 999,
  },
  divider: {
    width: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    width: 1,
    height: 30,
    backgroundColor: theme.colors.neutral.$75,
  },
});

export default MetricsFilter;
