import {Input} from 'interface/base/Input';
import {Textarea} from 'interface/base/Textarea';
import {ProfileEditDetails} from 'interface/stacks/app/ProfileEditDetails';
import {StyleSheet} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import type {ShareState} from '../../../shared/dialogs/SharedCustomizeDialog/hooks/useShareActions';
import Section from './Section';
import SectionTitle from './SectionTitle';

interface Props {
  share: Share;
  shareState: ShareState;
  updateShare: (updatedShare: Partial<Share>) => void;
}

export default function ShareMainInfo({share, shareState, updateShare}: Props) {
  const {title, description, setTitle, setDescription} = shareState;

  const handleSetTitle = () => {
    if (title !== share.title) {
      updateShare({
        title,
      });
    }
  };

  const handleSetDescription = () => {
    if (description !== share.description) {
      updateShare({
        description,
      });
    }
  };

  return (
    <Section>
      <SectionTitle>Share Details</SectionTitle>
      <ProfileEditDetails id={share.id} photo={share.logo} type="share" />
      <Input
        overrides={{
          Root: {
            style: styles.inputRoot,
          },
        }}
        value={title}
        placeholder={'Enter a title'}
        onChangeValue={setTitle}
        onBlurFn={handleSetTitle}
      />
      <Textarea
        value={description}
        onChangeValue={setDescription}
        placeholder="Enter a description"
        onBlurFn={handleSetDescription}
        maxLength={1000}
      />
    </Section>
  );
}

const styles = StyleSheet.create({
  inputRoot: {
    marginTop: 10,
    marginBottom: 8,
  },
});
