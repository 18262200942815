import {resetSlice} from 'store/utils';
import {initialState} from '.';
import type {Actions, Store} from './types';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setOrganizationName(
  state: Store,
  action: Actions['setOrganizationName'],
) {
  const {orgName} = action.payload;
  state.organizationName = orgName;
}

export function setSubdomain(state: Store, action: Actions['setSubdomain']) {
  const {subdomain} = action.payload;
  state.subdomain = subdomain;
}

export function setCoWorkerEmails(
  state: Store,
  action: Actions['setCoWorkersEmail'],
) {
  const {emails} = action.payload;
  state.coWorkerEmails = emails;
}

export function setStartOnboardingEmail(
  state: Store,
  action: Actions['setStartOnboardingEmail'],
) {
  const {email} = action.payload;
  state.startOnboardingEmail = email;
}

export function setIntentClientSecret(
  state: Store,
  action: Actions['setIntentClientSecret'],
) {
  const {intentClientSecret} = action.payload;
  state.intentClientSecret = intentClientSecret;
}

export function setCreatedOrganization(
  state: Store,
  action: Actions['setCreatedOrganization'],
) {
  const {id, name, domain} = action.payload;
  state.createdOrganizationId = id;
  state.createdOrganizationName = name;
  state.createdOrganizationDomain = domain;
}

export function removeOrganizationName(state: Store) {
  state.organizationName = initialState.organizationName;
}

export function removeSubdomain(state: Store) {
  state.subdomain = initialState.subdomain;
}

export function removeCoWorkerEmails(state: Store) {
  state.coWorkerEmails = initialState.coWorkerEmails;
}

export function removeStartOnboardingEmail(state: Store) {
  state.startOnboardingEmail = initialState.startOnboardingEmail;
}

export function finishOnboarding(state: Store) {
  state.finished = true;
}

export function removeFinishOnboarding(state: Store) {
  state.finished = false;
}

export function setUserData(state: Store, action: Actions['setUserData']) {
  const {user} = action.payload;
  state.user = {...state.user, ...user};
}

export function setError(state: Store, action: Actions['setError']) {
  const {error} = action.payload;
  state.error = error;
}

export function restartOnboarding(state: Store) {
  Object.assign(state, initialState);
}

export function setPublicKey(state: Store, action: Actions['setPublicKey']) {
  const {key} = action.payload;
  state.publicKey = key;
}

export function setIndustries(state: Store, action: Actions['setIndustries']) {
  const {industries} = action.payload;
  state.industries = industries;
}

export function setSelectedIndustry(
  state: Store,
  action: Actions['setSelectedIndustry'],
) {
  const {industry} = action.payload;
  state.selectedIndustry = industry;
}
