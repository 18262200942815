import theme from 'config/theme';
import {format} from 'date-fns';
import {usePopup} from 'extensions/viewport/usePopup';
import {ShareLevel} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useFileListItem} from 'interface/stacks/workspace/storage/hooks/useFileListItem';
import React, {type MouseEvent, useCallback, useRef} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import type {Share} from 'store/slices/shared/types';
import * as workspace from 'store/slices/workspace';
import {parseServerDate} from 'utils/common/dates';
import ShareTypeCell from './ShareTypeCell';
import ShareWorkspace from './ShareWorkspace';

interface ShareTableRowProps {
  share: Share;
  onPress: () => void;
}

const ShareTableRow = ({share, onPress}: ShareTableRowProps) => {
  const popup = usePopup();
  const menuRef = useRef<any>();
  const shareLevel = share.share_level;
  const canManageShare = shareLevel === ShareLevel.Owner;
  const fileListItem = useFileListItem(undefined, {
    //@ts-expect-error
    item: share,
    shareds: true,
    workspaceId: share?.parent_workspace,
    skipGetFile: true,
    optionsVisibility: {
      hideArchive: !canManageShare,
      hideCustomizeShare: !canManageShare,
      hideShare: !canManageShare,
      hideMoveTo: true,
      hideRename: true,
    },
  });

  const workspaceDetails = useSelector(
    workspace.selectors.getWorkspaceById(share.parent_workspace),
  );

  const handleOpenMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (event?.nativeEvent) {
        popup.setPlacement('bottom-start');
        popup.setPosition({
          x: event.nativeEvent.pageX,
          y: event.nativeEvent.pageY,
        });
        fileListItem.menu(event, menuRef);
      }
    },
    [menuRef],
  );

  return (
    <Pressable style={styles.row} onPress={onPress}>
      <View style={styles.cell}>
        <Typography size="sm" color={theme.colors.neutral.$2Base}>
          {share.name}
        </Typography>
      </View>
      <View style={styles.cell}>
        <ShareTypeCell shareType={share.share_type} />
      </View>
      <View style={styles.cell}>
        <ShareWorkspace
          workspaceId={share.parent_workspace}
          workspaceName={workspaceDetails?.name}
        />
      </View>
      <View style={styles.cell}>
        <Typography size="sm" color={theme.colors.neutral.$2Base}>
          {format(parseServerDate(share.created), 'dd/MM/yyyy, HH:mm aa')}
        </Typography>
      </View>
      <View style={[styles.cell, styles.actionCell]}>
        {Boolean(workspaceDetails) && (
          <Button
            ref={menuRef}
            overrides={{
              Button: {
                style: styles.buttonMenu,
              },
            }}
            aria-label="Open Share Menu"
            variant="text"
            // @ts-expect-error
            onPress={handleOpenMenu}
            endEnhancer={
              <Icon
                name={'lucide:ellipsis'}
                color={theme.colors.neutral.$2Base}
              />
            }
          />
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$10,
  },
  cell: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  actionCell: {
    flex: 1,
    height: 40,
  },
  buttonMenu: {
    width: 40,
    height: 40,
  },
});

export default ShareTableRow;
