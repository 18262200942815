/**
 * Generates a markdown table from input data
 * @param {Object} input - The input data
 * @param {Array<string>} input.headers - Array of header names
 * @param {Array<Array<string>>} input.data - 2D array of table data
 * @returns {string} - Formatted markdown table
 */
export function generateMarkdownTable(input: {
  headers: string[];
  data: string[][];
}) {
  const {headers, data} = input;

  if (!headers || !Array.isArray(headers) || !data || !Array.isArray(data)) {
    throw new Error(
      'Invalid input format. Expected {headers: string[], data: string[][]}',
    );
  }

  // Create header row with bold text
  const headerRow = `| ${headers.map(header => `**${header}**`).join(' | ')} |`;

  // Create separator row
  const separatorRow = `| ${headers.map(() => '--------').join(' | ')} |`;

  // Create data rows
  const dataRows = data.map(row => `| ${row.join(' | ')} |`).join('\n');

  // Combine all rows without the empty row
  return [headerRow, separatorRow, dataRows].join('\n');
}

export function generateHeading(heading: string) {
  return `## ${heading}`;
}

export function generateSubheading(heading: string) {
  return `### ${heading}`;
}
