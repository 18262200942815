import {useWindowDimensions} from 'react-native';

export const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1080,
  xl: 1380,
  '2xl': 1536,
  '3xl': 1920,
} as const;

export type Breakpoint = keyof typeof BREAKPOINTS;

export default function useBreakpoints() {
  const {width} = useWindowDimensions();

  return {
    xs: width < BREAKPOINTS.sm,
    sm: width >= BREAKPOINTS.sm && width < BREAKPOINTS.md,
    md: width >= BREAKPOINTS.md && width < BREAKPOINTS.lg,
    lg: width >= BREAKPOINTS.lg && width < BREAKPOINTS.xl,
    xl: width >= BREAKPOINTS.xl && width < BREAKPOINTS['2xl'],
    '2xl': width >= BREAKPOINTS['2xl'] && width < BREAKPOINTS['3xl'],
    '3xl': width >= BREAKPOINTS['3xl'],
    width,
  };
}
