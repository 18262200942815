import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import {getCookie, setCookie} from './cookies';

export const COLUMN_KEY = 'columnManager';

export const COLUMN_CATEGORY = {
  percentage: 'percentage',
  visibility: 'visibility',
  maximized: 'maximized',
} as const;

export const COLUMN_VALUES = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: {
    percentage: {
      workspaces: 15,
      shares: 15,
    },
    visibility: true,
    maximized: true,
  },
  [ColumnManagerInfoKeys.FoldersAndFiles]: {
    percentage: undefined,
    visibility: true,
    maximized: true,
  },
  [ColumnManagerInfoKeys.InfoPanel]: {
    percentage: {
      workspaces: 15,
      shares: 15,
    },
    visibility: true,
    maximized: false,
  },
  [ColumnManagerInfoKeys.ShareConfiguration]: {
    percentage: {
      workspaces: 20,
      shares: 20,
    },
    visibility: true,
    maximized: true,
  },
  [ColumnManagerInfoKeys.AIChat]: {
    percentage: {
      workspaces: 35,
      shares: 30,
    },
    visibility: true,
    maximized: true,
  },
};

export type ColumnCategory = keyof typeof COLUMN_CATEGORY;

export const updateColumnManagerValue = (
  key: string,
  value: any,
  category: ColumnCategory,
) => {
  const cookie = getCookie(COLUMN_KEY);
  const columnData = cookie ? JSON.parse(cookie) : COLUMN_VALUES;

  if (!columnData) {
    return;
  }

  columnData[key][category] = value;

  const newCookie = JSON.stringify(columnData);

  const origin = window.location.origin.split('://');
  const protocol = origin[0];
  const fullDomain = origin[origin.length - 1];
  const domain = fullDomain.split('.').slice(1).join('.');

  setCookie({
    name: COLUMN_KEY,
    value: newCookie,
    domain: domain.replace(/:\d+$/, ''),
    protocol,
    age: 3_600_000,
    path: '/',
    sameSite: 'Lax',
  });
};

export const getColumnManagerValue = (
  key: string,
  category: ColumnCategory,
) => {
  const cookie = getCookie(COLUMN_KEY);
  const columnData = cookie ? JSON.parse(cookie) : undefined;

  if (!columnData) {
    return undefined;
  }

  return columnData[key]?.[category];
};
