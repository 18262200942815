import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import NewLayout from './Layout';
import {TagButton} from './base/TagButton';

interface Props {
  next: () => void;
}

export default function Questions({next}: Props) {
  const selectedIndustry = useSelector(
    onboarding.selectors.getSelectedIndustry,
  );
  const industries = useSelector(onboarding.selectors.getIndustries);

  const dispatch = useDispatch();

  return (
    <NewLayout
      title="What kind of work do you do?"
      subtitle="This helps our systems better understand your organization and optimize your Fast.io experience. You can change this later."
      next={next}
      disabled={!selectedIndustry}>
      <View style={styles.root}>
        <View style={styles.contentContainer}>
          {industries.map(industry => (
            <TagButton
              key={industry.id}
              customButtonStyle={
                industry.id === 'unspecified' && styles.unspecifiedButton
              }
              text={industry.title}
              onPress={() => {
                dispatch(
                  onboarding.default.actions.setSelectedIndustry({
                    industry: industry.id,
                  }),
                );
              }}
              isActive={selectedIndustry === industry.id}
            />
          ))}
        </View>
      </View>
    </NewLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 42,
  },
  contentContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  unspecifiedButton: {
    // @ts-expect-error
    order: 1,
  },
});
