import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {useContextMenu} from 'interface/stacks/workspace/storage/hooks/useContextMenu';
import {useModalRename} from 'interface/stacks/workspace/storage/hooks/useModalRename';
import {useModalTrash} from 'interface/stacks/workspace/storage/hooks/useModalTrash';
import {MainDialog} from 'interface/stacks/workspace/storage/quick-share/MainDialog';
import {createRef, useCallback} from 'react';
import type {GestureResponderEvent} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import {download} from 'utils/fast/storage';
import {useEditorContext} from '../context/EditorContext';

const useFileMenu = (file: FilesItem) => {
  const modal = useModal();
  const popup = usePopup();

  const {instanceId, instanceNs} = useEditorContext();
  const fileId = file?.id;

  const renameModal = useModalRename(
    file ? file.id : '',
    file ? file.name : '',
    file ? file.type : '',
    instanceId,
    instanceNs,
  );

  const trashModal = useModalTrash([fileId], instanceId, instanceNs);

  const downloadItem = useCallback(() => {
    const id = file?.quickShare ? file.quickShare?.id : instanceId;
    download(fileId, id, file.quickShare ? 'quickshare' : instanceNs);
  }, [fileId, instanceId]);

  const menuFiles = useContextMenu({
    headerText: undefined,
    commentsOnPress: () => {},
    downloadOnPress: downloadItem,
    //moveToTrashOnPress: () => trashModal.open(),
    quickShareOnPress:
      file?.type === 'file'
        ? () => {
            modal.open(<MainDialog file={file} />);
          }
        : undefined,
    renameOnPress: file?.type === 'file' ? () => renameModal.open() : undefined,
  });
  const menuAnchorRef = createRef<any>();
  const menu = useCallback(
    (e: MouseEvent | GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (popup) {
        // @ts-ignore
        if (e?.nativeEvent) {
          popup.setPosition({
            // @ts-ignore
            x: e.nativeEvent.pageX,
            // @ts-ignore
            y: e.nativeEvent.pageY,
          });
        }
        popup.setRef(menuAnchorRef);
      }
      menuFiles.open();
    },
    [menuFiles],
  );

  return {menu, ref: menuFiles.reference, downloadItem};
};

export default useFileMenu;
