import {SESSION_COOKIE_KEY} from 'constants/sessionRoutes';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {getAgeFromToken} from 'fast-sdk/src/utils';
import {getHostData} from 'interface/common/hooks/useHost';
import type {Organization, User} from 'store/slices/user/types';
import {setCookie} from 'utils/common/cookies';
import type {ISession} from '../types';

export default function useBuildCookieSession() {
  const buildSession = (
    orgs: Organization[],
    workspaces: WorkspaceListDetail[],
    userDetails: User,
    token: string,
  ) => {
    return {
      orgs:
        orgs?.map(org => ({
          id: org.id,
          domain: org.domain,
        })) ?? [],
      workspaces:
        workspaces?.map(workspace => ({
          id: workspace.id,
          folderName: workspace.folder_name,
        })) ?? [],
      userId: userDetails?.id || undefined,
      token,
    };
  };

  const buildAndSetCookie = async (
    orgs: Organization[],
    workspaces: WorkspaceListDetail[],
    userDetails: User,
    token: string,
  ) => {
    if (token) {
      const {protocol, mainDomain} = await getHostData();
      setCookie({
        name: SESSION_COOKIE_KEY,
        value: JSON.stringify(
          buildSession(orgs, workspaces, userDetails, token) as ISession,
        ),
        domain: mainDomain.replace(/:\d+$/, ''),
        protocol,
        age: getAgeFromToken(token),
      });
    }
  };

  return {
    buildAndSetCookie,
  };
}
