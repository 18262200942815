import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {NEW_CHAT, NEW_PRIVATE_CHAT} from './consts';

import Typography from 'interface/base/Typography';
import {useGetNamespaceContext} from '../../hooks/useGetNamespaceContext';
import {useWorkspaceMembers} from '../../hooks/useWorkspaceMembers';

interface Props {
  chatId?: string;
  messageId?: string;
}

export function WorkspaceAiChat({chatId, messageId}: Props) {
  const dispatch = useDispatch();
  const {namespace, instanceId, instanceName} = useGetNamespaceContext();

  const openChat = useSelector(
    aichat.selectors.getCurrentChat(namespace, instanceId),
  );
  const {members, isLoading: membersLoading} = useWorkspaceMembers(instanceId);

  const handleNewChatPress = (isPrivate: boolean) => {
    if (isPrivate) {
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: NEW_PRIVATE_CHAT,
        }),
      );
    } else {
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: NEW_CHAT,
        }),
      );
    }
  };

  const handleChatPress = (chatId: string) => {
    dispatch(
      aichat.default.actions.setCurrentChat({namespace, instanceId, chatId}),
    );
  };

  useEffect(() => {
    if (chatId) {
      handleChatPress(chatId);
    }
  }, [chatId]);

  return (
    <View style={styles.container}>
      <Typography variant="regular">This was moved to Side Menu</Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
});
