/**
 * AI Chat Redux Slice Reducers
 *
 * This module contains the reducers for the AI Chat feature's Redux state management.
 * It handles chat creation, message management, and streaming responses.
 *
 * Key functionality:
 * - Creating and managing chats with seamless ID transitions
 * - Handling chat message lifecycle (creation, updates, streaming responses)
 * - Maintaining efficient state transitions during asynchronous operations
 * - Supporting workspace and share namespace chat contexts
 */

import {resetSlice} from 'store/utils';
import {nowServerDate} from 'utils/common/dates';
import {initialState} from '.';
import {buildChat, buildChatMessage, buildEmptyChat} from './helpers';
import type {Actions, Store} from './types';

import {
  NEW_CHAT,
  NEW_PRIVATE_CHAT,
} from 'interface/stacks/workspace/layout/WorkspaceAiChat/consts';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setChats(state: Store, action: Actions['setChats']) {
  const {namespace, instanceId, chats: newChats} = action.payload;

  const oldChats = getNamespace(state, namespace, instanceId).chats;

  // Create a set of new chat IDs for efficient lookup
  const newChatIds = new Set(newChats.map(chat => chat.chat_id));
  Object.keys(oldChats).forEach(chatId => {
    if (!newChatIds.has(chatId)) {
      delete oldChats[chatId];
    }
  });

  newChats.forEach(chat => {
    const existingMessages = oldChats[chat.chat_id]?.messages || [];
    oldChats[chat.chat_id] = buildChat(chat, existingMessages);
  });
}

export function setChatMessages(
  state: Store,
  action: Actions['setChatMessages'],
) {
  const {namespace, instanceId, chatId, messages} = action.payload;
  getNamespace(state, namespace, instanceId).chats[chatId].messages =
    messages.map(message => buildChatMessage(message));
}

/**
 * Adds a new chat to the Redux store.
 *
 * This function handles both temporary chats (with placeholder IDs) and
 * permanent chats from the API. It properly initializes the chat based
 * on whether it's a new empty chat or populated with API data.
 */
export function addChat(state: Store, action: Actions['addChat']) {
  const {namespace, instanceId, chatId, chat, isPrivate} = action.payload;
  let newChat = undefined;
  if (!chat || chatId === NEW_CHAT || chatId === NEW_PRIVATE_CHAT) {
    // Creating a temporary chat (either for a new chat or while waiting for API response)
    newChat = buildEmptyChat(chatId, isPrivate);
  } else {
    // Creating a real chat based on API response data
    newChat = buildChat(chat);
  }
  getNamespace(state, namespace, instanceId).chats[chatId] = newChat;
}

export function updateChatName(
  state: Store,
  action: Actions['updateChatName'],
) {
  const {namespace, instanceId, chatId, name} = action.payload;
  getNamespace(state, namespace, instanceId).chats[chatId].name = name;
}

export function publishChat(state: Store, action: Actions['publishChat']) {
  const {namespace, instanceId, chatId} = action.payload;
  getNamespace(state, namespace, instanceId).chats[chatId].privacy.visibility =
    'public';
}

/**
 * Deletes a chat from the Redux store.
 *
 * Used to remove chats from the Redux store, typically when a chat is deleted by the user
 * or when cleaning up temporary resources.
 */
/**
 * Updates a chat's ID in the Redux store while preserving its content.
 *
 * This function performs an in-place ID update by:
 * 1. Creating a copy of the existing chat
 * 2. Updating the ID in the chat object and all its messages
 * 3. Adding the chat with the new ID to the store
 * 4. Removing the chat with the old ID
 * 5. Updating current chat reference if needed
 *
 * Used when transitioning from temporary to permanent chat IDs.
 */
export function updateChatId(state: Store, action: Actions['updateChatId']) {
  const {namespace, instanceId, oldChatId, newChatId} = action.payload;
  const namespaceObj = getNamespace(state, namespace, instanceId);

  // Only proceed if the old chat exists
  if (namespaceObj.chats[oldChatId]) {
    // Copy the chat with all its messages and state
    const chatCopy = {...namespaceObj.chats[oldChatId]};

    // Update the ID in the chat object
    chatCopy.id = newChatId;

    // Update message chat IDs
    chatCopy.messages = chatCopy.messages.map(message => ({
      ...message,
      chatId: newChatId,
    }));

    // Add the chat with the new ID
    namespaceObj.chats[newChatId] = chatCopy;

    // Remove the chat with the old ID
    delete namespaceObj.chats[oldChatId];

    // Update currentChat reference if it was pointing to the old chat
    if (namespaceObj.currentChat === oldChatId) {
      namespaceObj.currentChat = newChatId;
    }
  }
}

/**
 * Updates a message's ID in the Redux store while preserving its content.
 *
 * Performs an in-place update of a message ID, typically used when
 * transitioning from a temporary message ID to a permanent one after
 * receiving the API response.
 */
export function updateTempMessageId(
  state: Store,
  action: Actions['updateTempMessageId'],
) {
  const {namespace, instanceId, chatId, tempMessageId, newMessageId} =
    action.payload;
  const chat = getNamespace(state, namespace, instanceId).chats[chatId];

  if (chat) {
    // Find the message with the temporary ID
    const messageIndex = chat.messages.findIndex(
      msg => msg.id === tempMessageId,
    );

    if (messageIndex !== -1) {
      // Update the message ID in-place
      chat.messages[messageIndex].id = newMessageId;
    }
  }
}

/**
 * Deletes a chat from the Redux store.
 *
 * Removes the specified chat from the appropriate namespace in the Redux store.
 */
export function deleteChat(state: Store, action: Actions['deleteChat']) {
  const {namespace, instanceId, chatId} = action.payload;
  delete getNamespace(state, namespace, instanceId).chats[chatId];
}

export function deleteChatMessage(
  state: Store,
  action: Actions['deleteChatMessage'],
) {
  const {namespace, instanceId, chatId, messageId} = action.payload;
  const chat = getNamespace(state, namespace, instanceId).chats[chatId];
  chat.messages = chat.messages.filter(message => message.id !== messageId);
}

/**
 * Adds a message to a chat in the Redux store.
 *
 * This is called in two scenarios:
 * 1. When adding a temporary message with a temporary ID
 * 2. When adding a permanent message with a real ID from the API
 *
 * The function builds and adds the chat message to the appropriate chat in the store.
 */
export function addChatMessage(
  state: Store,
  action: Actions['addChatMessage'],
) {
  const {namespace, instanceId, message} = action.payload;
  getNamespace(state, namespace, instanceId).chats[
    message.chat_id
  ].messages.push(buildChatMessage(message));
}

export function addChatResponse(
  state: Store,
  action: Actions['addChatResponse'],
) {
  const {namespace, instanceId, chatId, messageId, response} = action.payload;
  const chat = getNamespace(state, namespace, instanceId).chats[chatId];
  const message = chat.messages.find(m => m.id === messageId);
  if (message) {
    message.state = 'complete';
    message.response = {
      text: response,
      error: null,
      created: nowServerDate(),
      cost: {
        credits: {
          tokens: 0,
        },
      },
      citations: [],
    };
  }
}

export function setCurrentChat(
  state: Store,
  action: Actions['setCurrentChat'],
) {
  const {namespace, instanceId, chatId} = action.payload;
  getNamespace(state, namespace, instanceId).currentChat = chatId;
  state.scopeFiles = {};
  state.attachFiles = {};
}

export function setTriggerStreaming(
  state: Store,
  action: Actions['setTriggerStreaming'],
) {
  state.triggerStreaming = action.payload;
}

export function setPersonality(
  state: Store,
  action: Actions['setPersonality'],
) {
  state.personality = action.payload;
}

const getNamespace = (state: Store, namespace: string, instanceId: string) => {
  if (namespace === 'workspace') {
    // Initialize workspaces if it doesn't exist
    if (!state.workspaces) {
      state.workspaces = {};
    }
    // Create workspace namespace if it doesn't exist
    if (!state.workspaces[instanceId]) {
      state.workspaces[instanceId] = {
        chats: {},
        currentChat: null,
      };
    }
    return state.workspaces[instanceId];
  }
  if (namespace === 'share') {
    // Initialize shares if it doesn't exist
    if (!state.shares) {
      state.shares = {};
    }
    // Create share namespace if it doesn't exist
    if (!state.shares[instanceId]) {
      state.shares[instanceId] = {
        chats: {},
        currentChat: null,
      };
    }
    return state.shares[instanceId];
  }
  return null;
};

export function addScopeFiles(state: Store, action: Actions['addScopeFiles']) {
  const {files} = action.payload;
  if (!state.scopeFiles) {
    state.scopeFiles = {};
  }
  files.forEach(file => {
    state.scopeFiles[file.id] = file;
  });
}

export function addAttachFiles(
  state: Store,
  action: Actions['addAttachFiles'],
) {
  const {files} = action.payload;
  if (!state.attachFiles) {
    state.attachFiles = {};
  }
  files.forEach(file => {
    state.attachFiles[file.id] = file;
  });
}

export function clearAttachFiles(
  state: Store,
  action: Actions['clearAttachFiles'],
) {
  state.attachFiles = {};
}

export function removeScopeFile(
  state: Store,
  action: Actions['removeScopeFile'],
) {
  const {fileId} = action.payload;
  if (!state.scopeFiles) {
    state.scopeFiles = {};
  }
  delete state.scopeFiles[fileId];
}

export function removeAttachFile(
  state: Store,
  action: Actions['removeAttachFile'],
) {
  const {fileId} = action.payload;
  if (!state.attachFiles) {
    state.attachFiles = {};
  }
  delete state.attachFiles[fileId];
}

export function clearScopeFiles(
  state: Store,
  action: Actions['clearScopeFiles'],
) {
  state.scopeFiles = {};
}

export function setSource(state: Store, action: Actions['setSource']) {
  state.source = action.payload;
}
