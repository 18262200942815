import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import {GO_SUBDOMAIN} from 'utils/fast/constants';
import useBuildCookieSession from './useBuildCookieSession';

export default function useRefreshCookieSession() {
  const {subdomain} = useSubDomain();
  const dispatch = useDispatch();

  const userDetails = useSelector(user.selectors.getUserDetails);
  const orgs = useSelector(user.selectors.getOrganizationsList);
  const workspaces = useSelector(workspace.selectors.getAllJoinedWorkspaces);
  const token = useSelector(user.selectors.getToken);

  const {buildAndSetCookie} = useBuildCookieSession();

  const reloadSession = async () => {
    if (subdomain === GO_SUBDOMAIN && token) {
      buildAndSetCookie(orgs, workspaces, userDetails, token);
      if (userDetails?.id) {
        dispatch(app.default.actions.setHasActiveSession());
      }
    }
  };

  useEffect(() => {
    reloadSession();
  }, [orgs, workspaces, token, userDetails]);

  return {
    reloadSession,
  };
}
