import {Outlet, useOutletContext} from 'extensions/navigation';
import {ShareType} from 'fast-sdk/src/api/share/consts';
import type {ShareFilesNavigation} from 'interface/stacks/share/hooks/useShareFilesNavigation';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type React from 'react';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {EntityType} from 'store/slices/activity/types';
import * as app from 'store/slices/app';
import {type Share, ShareViewStatus} from 'store/slices/shared/types';
import useEntityCommunication from '../hooks/useEntityCommunication';
import useSyncShareData from '../hooks/useSyncShareData';

const ShareActivityProvider = () => {
  const {initializeActivityForEntity} = useEntityCommunication();
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const context =
    useOutletContext<[Share, UploaderState, ShareFilesNavigation]>();
  const [share, , shareFilesNavigation] = context;

  const shouldInitializeActivity = useMemo(() => {
    const shouldInitializeActivity =
      isLoggedIn &&
      share?.id &&
      (share.shareViewStatus === ShareViewStatus.Editor ||
        share.shareViewStatus === ShareViewStatus.Owner ||
        (share.shareViewStatus === ShareViewStatus.Guest &&
          (share.share_type === ShareType.Exchange ||
            share.share_type === ShareType.Send)));

    return shouldInitializeActivity;
  }, [isLoggedIn, share?.id, share?.shareViewStatus]);

  useEffect(() => {
    if (shouldInitializeActivity) {
      return initializeActivityForEntity({
        entityId: share?.id,
        entityType: EntityType.Share,
      });
    }
  }, [shouldInitializeActivity, share?.id]);

  useSyncShareData({
    share,
    shareFilesNavigation,
  });

  return <Outlet context={context} />;
};

export default ShareActivityProvider;
