import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

export function MobileDisclaimerBanner() {
  return (
    <View style={styles.root}>
      <Typography
        overrides={styles.text}
        color={theme.colors.neutral.$700}
        size="xs">
        This app is not yet optimized for mobile. For the best experience,
        please view on desktop.
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    backgroundColor: theme.colors.brand.$1_100,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 900,
  },
  text: {
    textAlign: 'center',
  },
});
