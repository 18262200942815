import theme from 'config/theme';
import ActivityTimeline from 'interface/base/ActivityTimeline';
import Typography from 'interface/base/Typography';
import {PreviewSidePanelComments} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/PreviewSidePanelComments';
import {Fragment, useMemo} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import PreviewFileThumbnail from '../../storage/thumbnail/PreviewFileThumbnail';
import {InfoPanelAiSection} from './components/InfoPanelAiSection';
import {InfoPanelDetailsTab} from './components/InfoPanelDetailsTab';
import {InfoPanelHeader} from './components/InfoPanelHeader';
import {InfoPanelTabHeader} from './components/InfoPanelTabHeader';
import {InfoPanelVersionsTab} from './components/InfoPanelVersionsTab';
import {useGetNodeType} from './hooks/useGetNodeType';
import {WorkspaceInfoPanelTab} from './types/WorkspaceInfoPanelTab';

export interface WorkspaceInfoPanelProps {
  focusedParentId: string;
  focusedId: string;
  workspaceId: string;
}

export function WorkspaceInfoPanel(props: WorkspaceInfoPanelProps) {
  const {focusedParentId, focusedId, workspaceId} = props;
  const focusedType = useSelector(selectors.getFocusedType);

  const item = useSelector(selectors.getItem(focusedId));
  const isFile = item?.type === 'file';
  const isLink = item?.type === 'link';

  const {type, item: nodeItem} = useGetNodeType(
    item ? focusedId : focusedParentId,
    workspaceId,
  );

  // @ts-ignore
  const intelligence: boolean | string = nodeItem?.intelligence;
  const showAiPanel =
    item?.ai ||
    (type === 'workspace' &&
      (intelligence === 'true' ||
        (typeof intelligence === 'boolean' && intelligence)));

  const thumbnail = useMemo(
    () => <PreviewFileThumbnail item={item} />,
    [item?.id],
  );

  const activityTimeline = useMemo(
    () => (
      <ActivityTimeline
        workspaceId={workspaceId}
        parentId={focusedParentId}
        selectedId={focusedId}
      />
    ),
    [focusedParentId, focusedId, workspaceId],
  );

  const recentActivity = useMemo(
    () => (
      <ActivityTimeline
        workspaceId={workspaceId}
        parentId={focusedParentId}
        selectedId={focusedId}
        limit={4}
      />
    ),
    [focusedParentId, focusedId, workspaceId],
  );

  const versionHistory = useMemo(
    () => (
      <InfoPanelVersionsTab
        workspaceId={workspaceId}
        parentId={focusedParentId}
        selectedId={focusedId}
      />
    ),
    [focusedParentId, focusedId, workspaceId],
  );

  const hasNodeSelected = Boolean(
    ((focusedParentId && focusedId) || focusedParentId !== 'root') &&
      !isLink &&
      (!focusedParentId?.startsWith('5') || focusedId?.startsWith('2')),
  );

  const showVersions =
    hasNodeSelected && focusedType === WorkspaceInfoPanelTab.Versions;

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.root}>
        <InfoPanelHeader type={type} item={nodeItem} />
        <InfoPanelTabHeader hasNodeSelected={hasNodeSelected} />
        {focusedType === WorkspaceInfoPanelTab.FileInfo && (
          <Fragment>
            {showAiPanel && <InfoPanelAiSection file={item} type={type} />}
            {item?.summary && (
              <Fragment>
                <TabTitle title="Summary" />
                <Typography size="sm" color={theme.colors.neutral.$700}>
                  {item.summary.short}
                </Typography>
              </Fragment>
            )}
            <TabTitle title="Details" />
            <InfoPanelDetailsTab
              focusedId={item ? focusedId : focusedParentId}
              workspaceId={workspaceId}
            />
            <TabTitle title="Recent Activity" />
            {recentActivity}
          </Fragment>
        )}
        {focusedType === WorkspaceInfoPanelTab.Activity && (
          <Fragment>
            <TabTitle title="Activity" />
            {activityTimeline}
          </Fragment>
        )}
        {showVersions && (
          <Fragment>
            <TabTitle title="Version History" />
            {versionHistory}
          </Fragment>
        )}
        {focusedType === WorkspaceInfoPanelTab.Comments && (
          <Fragment>
            <TabTitle title="Comments" />
            <PreviewSidePanelComments item={item} thumbnail={thumbnail} />
          </Fragment>
        )}
      </View>
    </ScrollView>
  );
}

function TabTitle({title}: {title: string}) {
  return (
    <Typography size="md" color={theme.colors.neutral.$700}>
      {title}
    </Typography>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'column',
    gap: 16,
    padding: 16,
  },
  name: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  nameContainer: {
    paddingHorizontal: 20,
    paddingBottom: 5,
    paddingTop: 15,
  },
});
