import {isToday, isYesterday} from 'date-fns';
import type {FilesItem} from 'store/slices/files/types';
import {parseServerDate} from 'utils/common/dates';

interface NotesGroups {
  today: FilesItem[];
  yesterday: FilesItem[];
  older: FilesItem[];
}

export function groupNotesByDay(notes: FilesItem[]): NotesGroups {
  const groups = notes.reduce(
    (groups, note) => {
      const noteDate = parseServerDate(note.modified ?? note.created);
      if (isToday(noteDate)) {
        groups.today.push(note);
      } else if (isYesterday(noteDate)) {
        groups.yesterday.push(note);
      } else {
        groups.older.push(note);
      }
      return groups;
    },
    {
      today: [] as FilesItem[],
      yesterday: [] as FilesItem[],
      older: [] as FilesItem[],
    },
  );

  const sortByDate = (a: FilesItem, b: FilesItem) =>
    parseServerDate(b.modified ?? b.created).getTime() -
    parseServerDate(a.modified ?? a.created).getTime();

  return {
    today: groups.today.sort(sortByDate),
    yesterday: groups.yesterday.sort(sortByDate),
    older: groups.older.sort(sortByDate),
  };
}
