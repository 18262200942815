import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import type {BillingStatus} from 'fast-sdk/src/api/billing/consts';
import Typography from 'interface/base/Typography';
import type {Params} from 'interface/stacks/settings/types';
import {Fragment} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {BillingControlItem} from '../BillingControlItem';
import BillingInfo from '../BillingInfo';

const CURRENCY_SYMBOL = {
  usd: '$',
};

const getCurrencySymbol = (currency: string) => {
  return CURRENCY_SYMBOL[currency] || currency;
};

export type Props = {
  billingDetails: BillingStatus;
  members: number;
  loadingMembers: boolean;
  loadingBillingDetails: boolean;
  navigateToUserList: () => void;
  creditsConsumed: number;
};

export function Summary({
  billingDetails,
  members,
  loadingMembers,
  loadingBillingDetails,
  navigateToUserList,
  creditsConsumed,
}: Props) {
  const {subdomain} = useParams<Params>();
  const navigate = useNavigate();

  const navigateToCancelSubscription = () => {
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.CANCEL_SUBSCRIPTION}/${subdomain}`);
  };

  const userDetails = useSelector(user.selectors.getUserDetails);
  const userOrgs = useSelector(user.selectors.getOrganizationsList);
  const organization = userOrgs.find(org => org.domain === subdomain);
  const permission = useSelector(
    user.selectors.getUserPermissionByOrg(userDetails.id, organization.id),
  );
  const isOwner = permission === 'owner';

  const loading =
    !billingDetails?.current_plan || loadingBillingDetails || loadingMembers;

  const {current_plan, subscription, customer} = billingDetails || {};

  const price = `${getCurrencySymbol(customer?.currency)}${current_plan?.pricing?.meters?.users?.price_per_unit}`;

  const periodEnd = subscription?.current_period_end
    ? new Date(subscription?.current_period_end)
    : undefined;
  const periodStart = subscription?.current_period_start
    ? new Date(subscription?.current_period_start)
    : undefined;

  return (
    <View>
      <Typography variant="medium" size="md" color={theme.colors.neutral.$700}>
        Overview
      </Typography>
      <View style={styles.content}>
        <BillingInfo
          planType={current_plan?.title}
          periodEnd={periodEnd}
          periodStart={periodStart}
          formattedPricePerUser={price}
          billableUsers={members}
          freeUsers={current_plan?.pricing.meters.users.free_units}
          freeCredits={current_plan?.pricing.meters.credits.free_units}
          creditsConsumed={creditsConsumed}
          onUserListPress={navigateToUserList}
          loading={loading}
        />
      </View>
      {isOwner && (
        <Fragment>
          <Typography
            variant="medium"
            size="md"
            color={theme.colors.neutral.$700}>
            Billing Controls
          </Typography>
          <View style={styles.billingControls}>
            {/* <BillingControlItem
          buttonText="Manage Billing"
          buttonOnPress={() => {}}>
          <Typography
            variant="regular"
            size="sm"
            color={theme.colors.neutral.$700}>
            Update payment method or billing contact
          </Typography>
        </BillingControlItem> */}
            {/* <BillingControlItem buttonText="View" buttonOnPress={() => {}}>
          <View style={styles.multipleTextContainer}>
            <Typography
              variant="regular"
              size="sm"
              color={theme.colors.neutral.$700}>
              Billing History:
            </Typography>
            <Typography
              variant="regular"
              size="sm"
              color={theme.colors.neutral.$500}>
              view past transactions and download invoices
            </Typography>
          </View>
        </BillingControlItem> */}
            <BillingControlItem
              buttonText="Cancel Subscription"
              buttonOnPress={navigateToCancelSubscription}>
              <Typography
                variant="regular"
                size="sm"
                color={theme.colors.neutral.$700}>
                Need to cancel your subscription?
              </Typography>
            </BillingControlItem>
          </View>
        </Fragment>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    marginTop: 16,
    marginBottom: 32,
  },
  billingControls: {
    marginTop: 16,
    gap: 8,
  },
  multipleTextContainer: {
    flexDirection: 'row',
    gap: 6,
  },
});
