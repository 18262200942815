import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Pressable, StyleSheet, View, type ViewStyle} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {IconButton} from './IconButton';

export enum ModalSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  AUTO = 'auto',
}

interface ModalProps {
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  close?: () => void;
  size?: ModalSize;
  disableClose?: boolean;
  containerStyle?: ViewStyle;
  bodyStyle?: ViewStyle;
}

interface FooterActionsProps {
  actionButtonLabel: string;
  isFormInvalid: boolean;
  isActionLoading: boolean;
  onSave: () => void;
  onCancel: () => void;
}

interface ModalSectionProps {
  title: string;
  iconName?: string;
  onIconPress?: () => void;
  showDivider?: boolean;
}

export function FooterActions({
  actionButtonLabel,
  isFormInvalid,
  isActionLoading,
  onSave,
  onCancel,
}: FooterActionsProps) {
  return (
    <View style={styles.buttonsContainer}>
      <Button variant="secondary" onPress={onCancel}>
        {t`Cancel`}
      </Button>
      <Button
        variant="primary"
        disabled={isActionLoading || isFormInvalid}
        onPress={onSave}
        loading={isActionLoading}>
        {actionButtonLabel}
      </Button>
    </View>
  );
}

export function ModalSection({
  title,
  iconName,
  onIconPress,
  showDivider = false,
}: ModalSectionProps) {
  return (
    <View style={[styles.sectionHeader, showDivider && styles.sectionDivider]}>
      <Typography
        variant="semi-bold"
        size="md"
        color={theme.colors.neutral.$2Base}>
        {title}
      </Typography>
      {iconName && onIconPress && (
        <IconButton
          buttonStyle={styles.sectionIcon}
          icon={
            <MCIcon name={iconName} size={20} color={theme.colors.neutral.$5} />
          }
          onPress={onIconPress}
        />
      )}
    </View>
  );
}

export default function Modal({
  title,
  children,
  footer,
  close,
  size = ModalSize.MEDIUM,
  disableClose = false,
  containerStyle,
  bodyStyle,
}: ModalProps) {
  const modal = useModal();

  const handleClose = () => {
    if (close) {
      close();
    } else {
      modal.close();
    }
  };

  return (
    <View style={styles.root}>
      <View style={[styles.innerContainer, styles[size], containerStyle]}>
        {!disableClose && (
          <View style={styles.closeIconContainer}>
            <Pressable
              accessibilityRole="button"
              style={styles.closeIcon}
              onPress={handleClose}>
              <Icon name="lucide:x" size={20} color={theme.colors.neutral.$5} />
            </Pressable>
          </View>
        )}
        <Typography variant="semi-bold" color={theme.colors.neutral.$2Base}>
          <Trans>{title}</Trans>
        </Typography>
        <View style={[title && styles.body, bodyStyle]}>{children}</View>
        {footer}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  innerContainer: {
    borderRadius: 7,
    backgroundColor: theme.colors.neutral.$white,
    padding: 24,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
  },
  closeIconContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  closeIcon: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    marginTop: 16,
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
    zIndex: -1,
    marginTop: 24,
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    paddingBottom: 14,
  },
  sectionTitle: {
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '600',
    color: theme.colors.neutral.$2Base,
  },
  sectionIcon: {
    minWidth: 20,
    minHeight: 20,
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
  },
  [ModalSize.SMALL]: {
    width: 420,
  },
  [ModalSize.MEDIUM]: {
    width: 550,
  },
  [ModalSize.LARGE]: {
    width: 800,
  },
  [ModalSize.AUTO]: {
    width: 'auto',
  },
});
