import {ROUTES} from 'constants/routes';
import {Navigate, Outlet} from 'extensions/navigation';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';

export default function VerificationRoute() {
  const storeToken = useSelector(user.selectors.getToken);

  if (storeToken) {
    return <Outlet />;
  }

  return <Navigate to={ROUTES.NOT_LOGGED_IN.SIGNIN} replace />;
}
