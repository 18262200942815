import theme from 'config/theme';
import {useHover} from 'interface/common/hooks/useHover';
import {
  type GestureResponderEvent,
  Pressable,
  type StyleProp,
  StyleSheet,
  type ViewStyle,
} from 'react-native';

interface Props {
  children: React.ReactNode;
  onPress?: (e?: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
  theme?: 'light' | 'dark';
  reference?: any;
}

export function HoveredView({
  children,
  onPress,
  style,
  theme = 'light',
  reference,
}: Props) {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  return (
    <Pressable
      ref={reference}
      onPress={onPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      style={[
        style,
        isHover && theme === 'light' && styles.hoveredLight,
        isHover && theme === 'dark' && styles.hoveredDark,
      ]}>
      {children}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  hoveredLight: {
    backgroundColor: theme.colors.neutral.$13,
  },
  hoveredDark: {
    backgroundColor: theme.colors.neutral.$3,
  },
});
