import {ROUTES, UNAUTHENTICATED_ROUTES} from 'constants/routes';
import {Navigate, Outlet} from 'extensions/navigation';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {getCurrentPath} from 'utils/common/platform';
import {useCheckOrgSubscription} from './hooks/useCheckOrgSubscription';

export default function ProtectedRoute() {
  const storeToken = useSelector(user.selectors.getToken);
  const userDetails = useSelector(user.selectors.getUserDetails);

  useCheckOrgSubscription();

  const path = getCurrentPath();

  if (
    (storeToken && userDetails?.id) ||
    UNAUTHENTICATED_ROUTES.includes(path)
  ) {
    return <Outlet />;
  }

  if (userDetails?.id) {
    return <Outlet />;
  }

  return <Navigate to={ROUTES.NOT_LOGGED_IN.SIGNIN} replace />;
}
