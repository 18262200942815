import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {useHover} from 'interface/common/hooks/useHover';
import type React from 'react';
import {useState} from 'react';
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';

type TooltipPosition = {
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right' | 'center';
};

type Props = {
  children: React.ReactNode;
  tooltipText: string;
  onPress?: () => void;
  buttonStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  wrap?: boolean;
  hoverable?: boolean;
  position?: TooltipPosition;
};

export function TooltipPressable({
  children,
  tooltipText,
  onPress,
  buttonStyle,
  disabled,
  wrap,
  hoverable,
  position = {vertical: 'top', horizontal: 'right'},
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const {isHover, onHoverIn, onHoverOut} = useHover(false);

  const handleHoverIn = () => {
    onHoverIn();
    setIsOpen(true);
  };

  const handleHoverOut = () => {
    onHoverOut();
    setIsOpen(false);
  };

  return (
    <View style={[styles.container, !wrap && {flex: 1}]}>
      <Pressable
        style={[
          styles.trigger,
          buttonStyle,
          disabled && styles.disabled,
          hoverable && isHover && styles.hover,
        ]}
        onHoverIn={() => handleHoverIn()}
        onHoverOut={() => handleHoverOut()}
        onPress={disabled ? undefined : onPress}>
        {children}
      </Pressable>
      {isOpen && (
        <View
          style={[
            styles.tooltip,
            positionStyles[position.vertical],
            positionStyles[position.horizontal],
          ]}>
          <Typography
            variant="regular"
            color={theme.colors.neutral.$0}
            size="xs"
            overrides={styles.tooltipText}>
            {tooltipText}
          </Typography>
        </View>
      )}
    </View>
  );
}

const positionStyles = StyleSheet.create({
  right: {
    right: 0,
  },
  left: {
    left: 0,
  },
  center: {
    left: '50%',
    transform: [{translateX: -50}],
  },
  top: {
    bottom: '100%',
  },
  bottom: {
    top: '100%',
  },
});

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    width: 'fit-content',
    backgroundColor: theme.colors.neutral.$700,
    paddingHorizontal: 12,
    paddingVertical: 8,
    margin: 1,
    borderRadius: 8,
    marginBottom: 4,
  },
  trigger: {},
  tooltipText: {
    whiteSpace: 'nowrap',
  },
  disabled: {
    cursor: 'default',
    opacity: 0.5,
    backgroundColor: 'transparent',
  },
  hover: {
    backgroundColor: theme.colors.neutral.$100,
  },
});
