import theme from 'config/theme';
import {ItemChip} from 'interface/base/ItemChip';
import Typography from 'interface/base/Typography';
import {Fragment} from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';

// @ts-ignore
import imageAiIcon from 'assets/images/storage/ai-icon.png';
import type {FilesItem} from 'store/slices/files/types';

interface ItemAiProcessingProps {
  tip: 'top' | 'left';
  state: FilesItem['ai']['state'];
}

export function ItemAiProcessing({tip, state}: ItemAiProcessingProps) {
  let label = '';
  let tooltip = '';
  switch (state) {
    case 'pending':
      label = 'Pending';
      tooltip =
        'Processing this file to enable AI features. It will be available shortly.';
      break;
    case 'in_progress':
      label = 'Processing';
      tooltip =
        'Processing this file to enable AI features. It will be available shortly.';
      break;
    case 'ready':
      label = 'Ready';
      tooltip = 'AI features are ready to use.';
      break;
    case 'failed':
      label = 'Failed';
      tooltip = 'Failed to process this file.';
      break;
    case 'disabled':
      label = 'Disabled';
      tooltip = 'AI features are disabled for this file.';
      break;
    default:
      state satisfies never;
  }
  return (
    <Pressable style={styles.root}>
      {({hovered}) => (
        <Fragment>
          <ItemChip
            startEnhancer={
              <Image
                source={imageAiIcon}
                style={{width: 16, height: 16, margin: 2}}
              />
            }
            label={label}
            padding={{
              left: false,
              right: true,
            }}
          />
          {hovered && (
            <View
              style={[styles.tooltip, tip === 'left' && styles.tooltipOffset]}>
              <Typography size="xs" color={theme.colors.neutral.$white}>
                {tooltip}
              </Typography>
              <View
                style={[styles.arrow, tip === 'left' && styles.arrowOffset]}
                pointerEvents="none"
              />
            </View>
          )}
        </Fragment>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    cursor: 'default',
  },
  tooltip: {
    position: 'absolute',
    zIndex: 9999,
    top: -72,
    left: -110,
    width: 230,
    backgroundColor: theme.colors.neutral.$2Base,
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 10,
  },
  tooltipOffset: {
    top: -20,
    left: -236,
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    bottom: -5,
    left: 'calc(50% + 10px)',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5.5,
    borderRightWidth: 5.5,
    borderTopWidth: 6,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: theme.colors.neutral.$2Base,
    zIndex: 99999,
  },
  arrowOffset: {
    left: 'unset',
    right: -7,
    top: 'calc(50% - 10px)',
    transform: [{rotate: '-90deg'}],
  },
});
