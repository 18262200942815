import theme from 'config/theme';
import {useOrgLogo} from 'interface/base/OrgLogo';
import AppLogo from 'interface/common/AppLogo';
import {Loading} from 'interface/common/Loading';
import type React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {useDispatch} from 'react-redux';
import * as contentViewer from 'store/slices/content-viewer';

interface Props {
  kind?: 'light' | 'dark';
  orgId: string;
}

const ShareHeaderOrg: React.FC<Props> = ({orgId, kind = 'light'}) => {
  const dispatch = useDispatch();
  const {org, loading, logo} = useOrgLogo({orgId});

  const textColor =
    kind === 'light'
      ? theme.colors.neutral.$2Base
      : theme.colors.neutral.$white;

  const handleOpenPromoDialog = () => {
    dispatch(contentViewer.default.actions.setShowPromoDialog({show: true}));
  };

  const renderOrganizationContent = () => {
    if (!org) {
      return <AppLogo kind={kind} />;
    }

    return (
      <Pressable style={styles.logo} onPress={handleOpenPromoDialog}>
        {logo}
        <Text style={[styles.orgName, {color: textColor}]}>{org.name}</Text>
      </Pressable>
    );
  };

  return (
    <View style={styles.root}>
      {loading ? <Loading /> : renderOrganizationContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  orgName: {
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 24,
    paddingHorizontal: 8,
  },
  logo: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
});

export default ShareHeaderOrg;
