import {api} from 'fast-sdk';
import {useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as activity from 'store/slices/activity';
import * as aichat from 'store/slices/aichat';
import {NEW_CHAT, NEW_PRIVATE_CHAT} from '../consts';

export const useFetchSingleChatData = (
  namespace: string,
  instanceId: string,
  instanceName: string,
) => {
  const dispatch = useDispatch();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const getDetails = async (chatId: string) => {
    const response = await api.workspaceAichat.getChatDetail(
      namespace,
      instanceName,
      chatId,
    );
    if (!response.result) {
      throw new Error('Failed to fetch chat details');
    }
    return response.chat;
  };

  const fetchChatData = async (chatId: string) => {
    if (!chatId || chatId === NEW_CHAT || chatId === NEW_PRIVATE_CHAT) {
      return null;
    }

    setIsLoading(true);
    try {
      const [chat] = await Promise.all([getDetails(chatId)]);
      dispatch(
        aichat.default.actions.addChat({
          namespace,
          instanceId,
          chatId,
          isPrivate: undefined,
          chat,
        }),
      );
      dispatch(
        activity.default.actions.updateAiChatActivity({
          namespace,
          instanceId,
          chatId: chatId,
        }),
      );
    } catch (error) {
      // Ignore errors
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, fetchChatData};
};
