import type {PayloadAction} from '@reduxjs/toolkit';
import type {OrganizationMember} from 'fast-sdk/src/api/organization/consts';
import type {
  ShareItem,
  ShareMemberPermission,
} from 'fast-sdk/src/api/share/consts';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import type {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import type {Member} from '../settings/types';

export type Store = {
  isListLoading: boolean;
  workspaces: {
    [id: string]: Array<string>;
  };
  sort: ShareSort;
  sharedsByCustomName: {
    [customName: string]: {shareId: string; workspaceFolderName: string};
  };
  shares: {
    [id: string]: Share;
  };
  sharesLoaded: boolean;
};

export interface Share extends ShareItem {
  commentsList?: Array<ShareComment>;
  members?: Array<ShareMember>;
  currentLayout: FileListItemLayout;
  sort: ShareSort;
  shareViewStatus: ShareViewStatus;
  workspaceId: string;
  isMock?: boolean;
}

export type ShareMember = Member &
  Omit<OrganizationMember, 'permissions'> & {
    permissions: ShareMemberPermission;
  };

export type ShareComment = {
  id?: string;
  sender: Member;
  createdAt: Date;
  content: string;
  reply: Array<ShareComment>;
  seen?: boolean;
};

export enum MemberActionType {
  'Signed In' = 'Signed In',
  Viewed = 'Viewed',
  Downloaded = 'Downloaded',
}

export type ShareSort = {
  order: 'asc' | 'desc';
  category: 'date';
};

export enum ShareViewStatus {
  Loading = 'Loading',
  Owner = 'Owner',
  Editor = 'Editor',
  Guest = 'Guest',
  Closed = 'Closed',
  NotFound = 'NotFound',
}

export const SHARE_STATUS_WITHOUT_CONFIG = [
  ShareViewStatus.Guest,
  ShareViewStatus.Closed,
  ShareViewStatus.Loading,
];

export type Actions = {
  listShared: {
    setIsListLoading: PayloadAction<boolean>;
    setWorkspaceShareList: PayloadAction<{
      workspaceFolderName: string;
      sharedItems: Array<ShareItem>;
    }>;
    updateShareDetails: PayloadAction<{
      workspaceFolderName: string;
      shareDetails: ShareItem;
    }>;
    sorting: PayloadAction<Store['sort']>;
  };
  setShareMembers: PayloadAction<{
    share: Share;
    members: Array<ShareMember>;
  }>;
  setShareViewStatus: PayloadAction<{
    share: Share;
    status: ShareViewStatus;
  }>;
  addCommentToShare: PayloadAction<{
    share: Share;
    commentText: string;
    sender: Member;
  }>;
  addReplyForShare: PayloadAction<{
    share: Share;
    replyText: string;
    commentId: string;
    sender: Member;
  }>;
  setShareLayout: PayloadAction<{share: Share; layout: FileListItemLayout}>;
  setShareSort: PayloadAction<{share: Share; sort: ShareSort}>;
  setShares: PayloadAction<{
    shares: Array<ShareItem>;
    workspaces: Array<WorkspaceListDetail>;
  }>;
  setSharesLoaded: PayloadAction<{loaded: boolean}>;
  archiveShare: PayloadAction<Share>;
  unarchiveShare: PayloadAction<Share>;
  deleteShare: PayloadAction<Share>;
};
