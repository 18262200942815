import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {StyleSheet, View} from 'react-native';

interface Props {
  size?: number;
}

const CONTAINER_PADDING = 3;
const DEFAULT_SIZE = 24;

export function NoteIcon({size = DEFAULT_SIZE}: Props) {
  return (
    <View style={[styles.root]}>
      <Icon
        name="lucide:notebook-text"
        size={size - CONTAINER_PADDING * 2}
        color={theme.colors.neutral.$2Base}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: CONTAINER_PADDING,
    borderRadius: 4,
    backgroundColor: theme.colors.file.note,
  },
});
