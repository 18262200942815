import {createContext} from 'react';
import type {ViewStyle} from 'react-native';

export interface ModalContextInterface {
  open(content: JSX.Element, options?: ModalOptions): void;
  close(): void;
  visible: boolean;
  setAriaLabel(inp: string): void;
}

export const ModalContext = createContext<ModalContextInterface>(null);

export interface ModalOptions {
  style?: ViewStyle;
  frameless?: boolean;
  forceChoice?: boolean;
  disableClose?: boolean;
}
