import {ROUTES, WORKSPACE_STORAGE} from 'constants/routes';
import useHasAccessToWorkspace from 'extensions/session/hooks/useHasAccessToWorkspace';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import useGetShareDetails from 'interface/stacks/share/hooks/useGetShareDetails';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import * as files from 'store/slices/files';
import useContentViewerParams from './useContentViewerParams';

export const useGoBack = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {instanceId, fileId, instanceNs} = useContentViewerParams();
  const {generateShareUrl} = useGenerateShareUrl();
  const {checkWorkspaceAccess} = useHasAccessToWorkspace();

  const file = useSelector(files.selectors.getItem(fileId));

  const {share} = useGetShareDetails({
    shareId: instanceId,
    fetchDisabled: true,
  });

  useEffect(() => {
    dispatch(files.default.actions.skipClearFocus({skip: true}));
    dispatch(files.default.actions.skipResetFolderView({skip: true}));
  }, []);

  const onGoBack = () => {
    const isHistoryEmpty = location.key === 'default';
    if (isHistoryEmpty && file) {
      if (instanceNs === 'workspace') {
        return navigate(
          `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${instanceId}/${WORKSPACE_STORAGE}/${file.parent}`,
          {replace: true},
        );
      }
      if (instanceNs === 'share' && share) {
        const hasAccess = checkWorkspaceAccess(share.parent_workspace);
        window.location.pathname = generateShareUrl(share, Boolean(hasAccess));
        return;
      }
    }

    navigate(-1);
  };

  return {onGoBack};
};
