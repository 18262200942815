import {t} from '@lingui/macro';
import images from 'config/images';
import analytics from 'extensions/analytics';
import device from 'extensions/device';
import {LoginButton} from 'interface/stacks/auth/components/LoginButton';
import {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import type {AnyAction, Dispatch} from 'redux';
import type {SSOMethod} from 'store/slices/user/types';
import {
  getPreviouslySignedInMethod,
  getPreviouslySignedInResult,
  loginApple,
  loginGoogle,
  loginMicrosoft,
} from 'utils/fast/auth';

type ProviderEndpoint = (
  dispatch: Dispatch<AnyAction>,
) => Promise<string | false>;

const prevSignedInMapper = (
  method: SSOMethod,
  previouslyMethod: SSOMethod | '',
  previouslyAccepted: boolean,
) => {
  if (previouslyMethod && method === previouslyMethod) {
    if (previouslyAccepted) {
      return {
        accepted: true,
        error: false,
      };
    }
    return {
      accepted: false,
      error: true,
    };
  }
  return {
    accepted: false,
    error: false,
  };
};

interface LoginButtonsProps {
  signUpButtons: boolean;
  isModal?: boolean;
  afterOpenLoginPopup?: () => Promise<void>;
}

export function LoginButtons(props: LoginButtonsProps) {
  const [prevSignInAccepted, setPrevSignInAccepted] = useState<boolean>();
  const [prevSignInMethod, setPrevSignInMethod] = useState<SSOMethod | ''>();

  const dispatch = useDispatch();
  const toast = useToast();

  const openNewWindow = (source: string) => {
    window.location.href = source;
  };

  useEffect(() => {
    const getPreviousData = async () => {
      const preMethod = await getPreviouslySignedInMethod();
      const preResult = await getPreviouslySignedInResult();
      setPrevSignInMethod(preMethod as SSOMethod | '');
      setPrevSignInAccepted(preResult ? preResult : false);
    };
    getPreviousData();
  }, []);

  const openThirdPartyLogin = async (providerEndpoint: ProviderEndpoint) => {
    device.dismissKeyboard();
    try {
      await providerEndpoint(dispatch).then(async res => {
        if (!res) {
          toast.show(t`There is a problem with connecting to the server`, {
            type: 'danger',
          });
        } else {
          if (props.afterOpenLoginPopup) {
            await props.afterOpenLoginPopup();
          }
          openNewWindow(res);
        }
      });
    } catch (error) {
      analytics.notify(error);
      toast.show(error.message, {type: 'danger'});
    }
  };

  const openAppleLogin = () => openThirdPartyLogin(loginApple);
  const openGoogleLogin = () => openThirdPartyLogin(loginGoogle);
  const openMicrosoftLogin = () => openThirdPartyLogin(loginMicrosoft);

  const render = useCallback(() => {
    return (
      <View style={styles.root}>
        {/* <LoginButton
          key="Apple"
          title={t`Continue with Apple`}
          onPress={openAppleLogin}
          icon={{uri: images.blackAppleLogo}}
          signUpButton={props.signUpButtons}
          previouslySignedIn={
            prevSignedInMapper('Apple', prevSignInMethod, prevSignInAccepted)
              .accepted
          }
          previouslyError={
            prevSignedInMapper('Apple', prevSignInMethod, prevSignInAccepted)
              .error
          }
          width={20}
        /> */}
        <LoginButton
          key="Google"
          title={t`Continue with Google`}
          onPress={openGoogleLogin}
          icon={{uri: images.googleLogo}}
          signUpButton={props.signUpButtons}
          previouslySignedIn={
            prevSignedInMapper('Google', prevSignInMethod, prevSignInAccepted)
              .accepted
          }
          previouslyError={
            prevSignedInMapper('Google', prevSignInMethod, prevSignInAccepted)
              .error
          }
          fullWidth={props.isModal}
        />
        <LoginButton
          key="Microsoft"
          title={t`Continue with Microsoft`}
          onPress={openMicrosoftLogin}
          icon={{uri: images.microsoftLogo}}
          signUpButton={props.signUpButtons}
          previouslySignedIn={
            prevSignedInMapper(
              'Microsoft',
              prevSignInMethod,
              prevSignInAccepted,
            ).accepted
          }
          previouslyError={
            prevSignedInMapper(
              'Microsoft',
              prevSignInMethod,
              prevSignInAccepted,
            ).error
          }
          withoutMarginBottom
          fullWidth={props.isModal}
        />
      </View>
    );
  }, []);

  return render();
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
});
