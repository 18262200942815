import {createSlice} from '@reduxjs/toolkit';
import {
  COLUMN_CATEGORY,
  COLUMN_VALUES,
  getColumnManagerValue,
} from 'utils/common/ui';
import * as reducers from './reducers';
import * as selectors from './selectors';
import {
  ColumnManagerInfoKeys,
  type Overrides,
  PageType,
  type Store,
} from './types';

const COLUMN_KEYS = [
  ColumnManagerInfoKeys.WorkspaceBrowser,
  ColumnManagerInfoKeys.FoldersAndFiles,
  ColumnManagerInfoKeys.InfoPanel,
  ColumnManagerInfoKeys.ShareConfiguration,
  ColumnManagerInfoKeys.AIChat,
];

const generateColumnsInitialValue = <T>(
  initialValue: T,
  overrides?: Overrides<T>,
) => ({
  [ColumnManagerInfoKeys.WorkspaceBrowser]: initialValue,
  [ColumnManagerInfoKeys.FoldersAndFiles]: initialValue,
  [ColumnManagerInfoKeys.InfoPanel]: initialValue,
  [ColumnManagerInfoKeys.ShareConfiguration]: initialValue,
  [ColumnManagerInfoKeys.AIChat]: initialValue,
  ...overrides,
});

const getColumnMaximizedValues = () => {
  const values = generateColumnsInitialValue(null, {
    [ColumnManagerInfoKeys.AIChat]: true,
  });

  for (const key of COLUMN_KEYS) {
    values[key] =
      getColumnManagerValue(key, COLUMN_CATEGORY.maximized) ??
      COLUMN_VALUES[key].maximized;
  }

  return values;
};

const getColumnVisibilityValues = () => {
  const values = generateColumnsInitialValue(true);

  for (const key of COLUMN_KEYS) {
    const value = getColumnManagerValue(key, COLUMN_CATEGORY.visibility);
    values[key] = value ?? COLUMN_VALUES[key].visibility;
  }

  return values;
};

const getColumnWidthValues = () => {
  const values = generateColumnsInitialValue(undefined);

  for (const key of COLUMN_KEYS) {
    values[key] =
      getColumnManagerValue(key, COLUMN_CATEGORY.percentage)?.workspaces ??
      undefined;
  }

  return values;
};

export const initialState: Store = {
  loaded: false,
  active: false,
  revision: 0,
  domainOrigin: 'fast.io',
  stopRedirect: false,
  currentJoinInvitation: undefined,
  forceLogout: false,
  redirectToRenewSubscription: false,
  renewSubscriptionSubdomain: undefined,
  userPermission: undefined,
  currentPage: {
    type: PageType.Unknown,
    workspace: '',
    shareName: '',
    shareId: '',
    folderId: '',
    fileId: '',
    settings: '',
    pageName: '',
  },
  columnInfo: generateColumnsInitialValue(true, {
    [ColumnManagerInfoKeys.AIChat]: true,
  }),
  columnInfoOverride: getColumnMaximizedValues(),
  columnVisibility: getColumnVisibilityValues(),
  columnWidth: generateColumnsInitialValue(undefined),
  columnPercentages: getColumnWidthValues(),
  endpointCallsLimit: {},
  redirectTo: undefined,
  hasActiveSession: false,
  isOffline: false,
};
const sliceName = 'app';

const resetColumnManagerInfoTransform = {
  in: state => state,
  out: (state, key) => {
    if (key === sliceName) {
      return {
        ...state,
        isOffline: initialState.isOffline,
        columnInfoOverride: getColumnMaximizedValues(),
        columnVisibility: getColumnVisibilityValues(),
        columnPercentages: getColumnWidthValues(),
      };
    }
    return state;
  },
};

export default createSlice({name: sliceName, reducers, initialState});
export {resetColumnManagerInfoTransform, selectors};
