import theme from 'config/theme';
import {ROUTES, STORAGE_ROOT} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {Icon} from 'interface/base/Icon';
import Markdown from 'interface/base/Markdown';
import Typography from 'interface/base/Typography';
import {useHover} from 'interface/common/hooks/useHover';
import {useCopyLink} from 'interface/stacks/app/hooks/useCopyLink';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {Fragment, useMemo} from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {EntityChatMessage} from 'store/slices/aichat/types';
import {cleanFileName} from 'utils/fast/common';
import {TooltipPressable} from '../../../WorkspaceInfoPanel/components/TooltipPressable';
import {PERSONALITY_ICON, PERSONALITY_NAMES} from '../../consts';
import {useGenerateMessageMarkdown} from '../../hooks/useGenerateMessageMarkdown';
import {useRegenerateMessage} from '../../hooks/useRegenerateMessage';
import {AiChatMessageItemPopup} from '../AiChatMessageItemPopup';
import {AiChatCitationsSection} from './AiChatCitationsSection';
import {AiChatStreamingResponseItem} from './AiChatStreamingResponseItem';

interface Props {
  message: EntityChatMessage;
  isLastMessage: boolean;
}

export function AiChatResponseItemMarkdown({message, isLastMessage}: Props) {
  const {copyLink} = useCopyLink();
  const {regenerateMessage} = useRegenerateMessage();
  const navigate = useNavigate();

  const {instanceName, namespace, instanceId} = useGetNamespaceContext();
  const {generateMarkdown} = useGenerateMessageMarkdown();

  const chat = useSelector(
    aichat.selectors.getChat(namespace, instanceId, message.chatId),
  );

  const responseText = message.response?.text;
  const authorName = message.response?.author_name;

  const isErrored = message.state === 'errored';
  const isComplete = message.state === 'complete';

  const isProcessing = isLastMessage && !isComplete && !isErrored;

  const handleCopyPress = () => {
    copyLink(responseText, 'Response Copied');
  };

  const handleEditPress = () => {
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.MARKDOWN_EDITOR}`, {
      state: {
        text: generateMarkdown(chat, message),
        fileName: `${cleanFileName(chat?.name)}`,
        instanceId: instanceName,
        instanceNs: namespace,
        parentFolder: STORAGE_ROOT,
      },
    });
  };

  const errorText =
    '**Error:** Sorry, an error occurred while processing your request. [Regenerate](app:regenerate)';

  const responseMarkdown = useMemo(
    () => <Markdown style={markdownStyles}>{responseText}</Markdown>,
    [responseText],
  );

  const {isHover, onHoverIn, onHoverOut} = useHover();

  return (
    <View
      style={styles.container}
      //@ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}>
      <View style={styles.header}>
        <Image
          source={{uri: PERSONALITY_ICON[message.personality]}}
          style={{width: 28, height: 28}}
        />
        <Typography
          variant="medium"
          size="md"
          selectable
          color={theme.colors.neutral.$700}>
          {authorName ?? PERSONALITY_NAMES[message.personality]}
        </Typography>
        <View style={styles.spacer} />

        {isHover && (
          <View style={styles.buttons}>
            <TooltipPressable
              tooltipText="Save as Note"
              onPress={handleEditPress}
              buttonStyle={styles.iconContainer}>
              <Icon
                name="lucide:book-marked"
                color={theme.colors.neutral.$700}
              />
            </TooltipPressable>
            <TooltipPressable
              tooltipText="Copy content"
              onPress={handleCopyPress}
              buttonStyle={styles.iconContainer}>
              <Icon name="lucide:copy" color={theme.colors.neutral.$700} />
            </TooltipPressable>
            <AiChatMessageItemPopup
              chatId={message.chatId}
              messageId={message.id}
              showRegenerate={isErrored}
              onRegenerate={() => {
                regenerateMessage(message);
              }}
            />
          </View>
        )}
      </View>

      {isProcessing && <AiChatStreamingResponseItem message={message} />}

      {isComplete && responseMarkdown}
      {isComplete && (
        <AiChatCitationsSection citations={message.response?.citations} />
      )}

      {isErrored && (
        <Fragment>
          <Markdown
            style={markdownStyles}
            onLinkPress={url => {
              if (url === 'app:regenerate') {
                regenerateMessage(message);
              }
              return false;
            }}>
            {errorText}
          </Markdown>
        </Fragment>
      )}
    </View>
  );
}

const markdownStyles = StyleSheet.create({
  body: {
    color: theme.colors.neutral.$700,
    fontSize: 14,
    lineHeight: 24,
    paddingStart: 40,
  },
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 8,
    width: '100%',
  },
  regenerateButton: {
    marginStart: 40,
  },
  buttons: {
    flexDirection: 'row',
    gap: 6,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  spacer: {
    flex: 1,
  },
  iconContainer: {
    padding: 4,
  },
  popupContent: {
    width: 100,
    height: 100,
  },
});
