import type {Actions, Store} from './types';

export function setNeighbors(state: Store, action: Actions['setNeighbors']) {
  state.prev = action.payload.prev;
  state.next = action.payload.next;
}

export function setFiles(state: Store, action: Actions['setFiles']) {
  state.files = action.payload.files;
}

export function cacheUrl(state: Store, action: Actions['cacheUrl']) {
  const {id, url} = action.payload;
  state.urls[id] = url;
}

export function setIsThumbnail(
  state: Store,
  action: Actions['setIsThumbnail'],
) {
  state.isThumbnail = action.payload.isThumbnail;
}

export function setUploadedBy(state: Store, action: Actions['setUploadedBy']) {
  const {user} = action.payload;
  state.uploadedBy = user;
}

export function setShowPromoDialog(
  state: Store,
  action: Actions['setShowPromoDialog'],
) {
  state.showPromoDialog = action.payload.show;
}
