import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {Transfers} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {FileLayoutType} from 'interface/stacks/workspace/storage/FolderHeaderLayout';
import * as effects from 'interface/stacks/workspace/storage/hooks/files';
import {useModalRename} from 'interface/stacks/workspace/storage/hooks/useModalRename';
import {useCallback, useRef} from 'react';
import type {MouseEvent} from 'react-native';
import {useDispatch} from 'react-redux';
import slices from 'store/slices';
import {SharedCustomizeDialog} from '../../../shared/dialogs/SharedCustomizeDialog';
import {useContextMenu} from '../../../storage/hooks/useContextMenu';
import {useModalCreateFolder} from '../../../storage/hooks/useModalCreateFolder';
import {useWorkspaceContext} from '../../../storage/hooks/useWorkspaceContext';
import type {IFolderItem} from '../types';

interface FolderActionsOptions {
  item: IFolderItem;
  workspace: WorkspaceListDetail;
}

export function useFolderActions({item, workspace}: FolderActionsOptions) {
  const modal = useModal();
  const popup = usePopup();
  const menuRef = useRef<any>();
  const dispatch = useDispatch();

  const {uploader} = useWorkspaceContext();

  const addFilesFromLocal = async () => {
    const files = await Transfers.pickFiles();
    dispatch(
      slices.files.actions.fileLayoutType({type: FileLayoutType.Storage}),
    );
    uploader.instance.addFiles(files, item.id, workspace.id);
  };

  const openCreateShareDialog = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspace.folder_name}
        folderParentId={item.id}
        close={modal.close}
      />,
    );
  };

  const modalCreateFolder = useModalCreateFolder(
    item.id,
    workspace.folder_name,
    'workspace',
  );

  const renameModal = useModalRename(
    item ? item.id : '',
    item ? item.name : '',
    'folder',
    workspace.folder_name,
    'workspace',
  );

  const moveTo = useCallback(
    effects.pick(dispatch, {
      action: 'move',
      destination: 'root',
      ids: [item?.id],
    }),
    [item?.id],
  );

  const menuFiles = useContextMenu({
    noDividers: true,
    ignoreMultiSelect: true,
    headerText: item.name,
    uploadHereOnPress: addFilesFromLocal,
    createShareOnPress: openCreateShareDialog,
    createFolderOnPress: modalCreateFolder.open,
    renameOnPress: () => renameModal.open(),
    moveToOnPress: moveTo,
  });

  const openContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (event?.nativeEvent) {
        popup.setPlacement('bottom-start');
        popup.setPosition({
          x: event.nativeEvent.pageX,
          y: event.nativeEvent.pageY,
        });
        menuFiles.open();
      }
    },
    [popup, menuFiles],
  );

  return {openContextMenu, menuRef};
}
