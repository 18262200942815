import {ROUTES, STORAGE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {useModal} from 'extensions/viewport/useModal';
import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {useNavigateToSubdomain} from 'interface/common/hooks/navigator/useNavigateToSubdomain';
import {useOrgMembers} from 'interface/common/hooks/useOrgMembers';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import {WorkspaceCreate} from 'interface/stacks/workspace/dialogs/WorkspaceCreate';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as shared from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';
import type {Organization} from 'store/slices/user/types';
import * as workspace from 'store/slices/workspace';
import SharesTable from '../shares/SharesTable';
import WorkspaceCards from '../workspaces/WorkspaceCards';
import OrganizationHeader from './OrganizationHeader';

interface OrganizationSectionProps {
  organization: Organization;
  isSharesLoading: boolean;
  isWorkspaces?: boolean;
  searchFilter: string;
}

const OrganizationSection = ({
  organization,
  isSharesLoading,
  isWorkspaces = true,
  searchFilter,
}: OrganizationSectionProps) => {
  const modal = useModal();
  const navigate = useNavigate();

  const [isReloadingWorkspaces, setIsReloadingWorkspaces] = useState(false);

  const {generateShareUrl} = useGenerateShareUrl();
  const {
    members,
    loading: isMembersLoading,
    error,
  } = useOrgMembers(organization);
  const {navigateToSubdomain} = useNavigateToSubdomain();

  const workspaces = useSelector(
    workspace.selectors.getOrganizationWorkspaces(organization.domain),
  );
  const shares = useSelector(
    shared.selectors.getOrganizationShares(organization?.id),
  );

  const redirectToSubdomain = (redirectTo: string) => {
    navigateToSubdomain(organization.domain, redirectTo);
  };

  const onNewWorkspacePress = () => {
    modal.open(
      <WorkspaceCreate
        organization={organization}
        close={modal.close}
        setIsReloading={setIsReloadingWorkspaces}
      />,
    );
  };

  const onSettingsPress = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.organization}/${organization.domain}`,
    );
  };

  const onViewArchivedWorkspacesPress = () => {
    redirectToSubdomain(`/${ROUTES.LOGGED_IN_WITH_ORG.ARCHIVED_WORKSPACES}`);
  };

  const onWorkspacePress = (workspaceItem: WorkspaceListDetail) => {
    setTimeout(() => {
      const redirectTo = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspaceItem.folder_name}/${WORKSPACE_STORAGE}/${STORAGE_ROOT}`;
      redirectToSubdomain(redirectTo);
    }, 200);
  };

  const onSharePress = (share: Share) => {
    const internal = Boolean(
      workspaces?.find(
        workspace =>
          workspace.id === share.parent_workspace &&
          workspace.user_status === WorkspaceUserStatus.Joined,
      ),
    );
    redirectToSubdomain(generateShareUrl(share, internal));
  };

  const onRenewSubscriptionPress = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.RENEW_SUBSCRIPTION}?orgDomain=${organization.domain}`,
    );
  };

  const hasInvalidSubscription = error?.code === ApiErrors.OrgNotSubscribed;

  return (
    <View style={styles.root}>
      <OrganizationHeader
        organization={organization}
        workspacesCount={workspaces.length}
        sharesCount={shares.length}
        isWorkspaces={isWorkspaces}
        members={members}
        isMembersLoading={isMembersLoading}
        onNewWorkspacePress={onNewWorkspacePress}
        onSettingsPress={onSettingsPress}
        onViewArchivedWorkspacesPress={onViewArchivedWorkspacesPress}
        onRenewSubscriptionPress={onRenewSubscriptionPress}
        hasInvalidSubscription={hasInvalidSubscription}
      />
      {!hasInvalidSubscription &&
        (isWorkspaces ? (
          <WorkspaceCards
            workspaces={workspaces}
            isMembersLoading={isMembersLoading}
            onWorkspacePress={onWorkspacePress}
            isReloadingWorkspaces={isReloadingWorkspaces}
            searchFilter={searchFilter}
          />
        ) : (
          <SharesTable
            shares={shares}
            isSharesLoading={isSharesLoading}
            onSharePress={onSharePress}
          />
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {},
});

export default OrganizationSection;
