import theme from 'config/theme';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import Sidebar from './base/Sidebar';
import TopToolbar from './base/TopToolbar';
import MediaComponent from './components/MediaComponent';
import {EditorProvider} from './context/EditorContext';
import useContentPrefetch from './hooks/useContentPrefetch';
import useContentViewerParams from './hooks/useContentViewerParams';

type Props = {
  file: FilesItem;
  emptyState?: React.ReactNode;
};

const ContentViewer = ({file, emptyState}: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const {multiplayer} = useWorkspaceContext();
  const parent = useSelector(selectors.getItem(file?.parent));

  const toggleSidebar = () => setIsSidebarOpen(prev => !prev);
  const closeSidebar = () => setIsSidebarOpen(false);

  useContentPrefetch();

  const {instanceId, instanceNs} = useContentViewerParams();

  return (
    <EditorProvider
      initialFileName={file?.name}
      instanceId={instanceId}
      instanceNs={instanceNs}
      file={file}>
      <View style={styles.root}>
        <TopToolbar
          file={file}
          members={multiplayer?.members}
          toggleSidebar={toggleSidebar}
        />
        <View style={[styles.content, file && styles.hasContent]}>
          <MediaComponent file={file} emptyState={emptyState} />
          {isSidebarOpen && (
            <Sidebar onClose={closeSidebar} file={file} parent={parent} />
          )}
        </View>
      </View>
    </EditorProvider>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.colors.neutral.$white,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  hasContent: {
    flexGrow: 1,
  },
});

export default ContentViewer;
