import {
  MediaPlayer,
  type MediaPlayerInstance,
  MediaProvider,
  type MediaProviderInstance,
  useMediaStore,
} from '@vidstack/react';
import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {THUMBNAIL_HEIGHT} from 'interface/stacks/workspace/storage/thumbnail/PreviewFileThumbnail';
import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Pagination from '../../components/Pagination';
import TimeProgress, {
  HEIGHT_TIME_PROGRESS,
} from '../../components/TimeProgress';
import useFileUrl from '../../hooks/useFileUrl';
import type {ViewerProps as Props} from '../../types';
import {getFileExifMetadata} from '../../utils';
import ContentComments, {HEIGHT_CONTENT_COMMENTS} from '../ContentComments';
import ControlBar, {HEIGHT_CONTROL_BAR} from '../ControlBar';
import {HEIGHT_TOP_TOOLBAR} from '../TopToolbar';
import ControlsLeft from './controls/ControlsLeft';
import ControlsMiddle from './controls/ControlsMiddle';
import ControlsRight from './controls/ControlsRight';

const HEIGHT_BOTTOM_BAR =
  HEIGHT_CONTROL_BAR + HEIGHT_TIME_PROGRESS + HEIGHT_CONTENT_COMMENTS;

const HEIGHT_FULLSCREEN = {
  normal: `calc(100vh - ${HEIGHT_BOTTOM_BAR}px)`,
  thumbnail: '100vh',
};

const VideoViewer = ({file, isThumbnail}: Props) => {
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<boolean>(false);
  const providerRef = useRef<MediaProviderInstance>(null);
  const playerRef = useRef<MediaPlayerInstance>(null);
  const {url, loading, handleCheckPreviewReady} = useFileUrl(file);
  const {fullscreen} = useMediaStore(playerRef);

  const metadata = getFileExifMetadata(file);

  const rotation = metadata?.Rotation;

  const height = {
    thumbnail: `${THUMBNAIL_HEIGHT}px`,
    full: `calc(100vh - ${HEIGHT_BOTTOM_BAR}px - ${HEIGHT_TOP_TOOLBAR}px)`,
  };

  useEffect(() => {
    return () => {
      setCanPlay(false);
      setLoadedData(false);
    };
  }, []);

  useEffect(() => {
    // Reset video player loaded state when preview side panel changes without closing it
    setCanPlay(false);
    setLoadedData(false);
  }, [file?.id]);

  useEffect(() => {
    if (!loading && canPlay && providerRef.current) {
      const element = providerRef.current.el;
      const $video = element?.querySelector('video');
      if ($video) {
        $video.style.transform = 'none';
        $video.style.height = 'auto';
        $video.style.width = '100%';

        if (rotation) {
          $video.style.transform = `rotate(${rotation}deg)`;
          $video.style.width = isThumbnail ? height.thumbnail : height.full;
          return;
        }

        if (isThumbnail && fullscreen) {
          $video.style.transform = 'none';
          $video.style.height = '100%';
          $video.style.width = '100%';
          return;
        }

        if (isThumbnail && !fullscreen) {
          $video.style.height = height.thumbnail;
          return;
        }

        $video.style.height = '100%';
      }
    }
  }, [loading, canPlay, rotation, isThumbnail, fullscreen]);

  return (
    <MediaPlayer
      ref={playerRef}
      autoPlay
      muted
      crossOrigin
      title={file?.name}
      src={url}
      style={{...styles.root, ...(isThumbnail && {width: '100%'})}}>
      <View style={styles.container}>
        <View
          style={[
            styles.content,
            fullscreen && {
              maxHeight: isThumbnail
                ? HEIGHT_FULLSCREEN.thumbnail
                : HEIGHT_FULLSCREEN.normal,
            },
          ]}>
          {(loading || !canPlay || !loadedData) && (
            <View style={styles.previewContainer}>
              <FileThumbnail
                item={file}
                size={FileThumbnailSizes.Preview}
                options={{previewResizeMode: 'contain'}}
              />
            </View>
          )}
          <MediaProvider
            ref={providerRef}
            style={{opacity: canPlay ? 1 : 0}}
            onCanPlay={e => {
              if (e.isTrusted) {
                setCanPlay(true);
              }
            }}
            onLoadedData={() => setLoadedData(true)}
          />
        </View>
        <View style={styles.bottom}>
          <ControlBar
            top={
              <>
                <TimeProgress />
                {!isThumbnail && <ContentComments />}
              </>
            }
            left={
              <ControlsLeft handleCheckPreviewReady={handleCheckPreviewReady} />
            }
            middle={<ControlsMiddle />}
            right={<ControlsRight />}
          />
        </View>
        {!isThumbnail && <Pagination />}
      </View>
    </MediaPlayer>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: 'calc(100vw - 350px)',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
  },
  player: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    zIndex: -1,
    backgroundColor: '#000',
    maxHeight: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px - ${HEIGHT_BOTTOM_BAR}px)`,
  },
  bottom: {
    maxHeight: 94,
  },
  bottomLoadingContainer: {
    paddingVertical: 32,
    backgroundColor: theme.colors.neutral.$1,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$black,
  },
  previewContainer: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
});

export default VideoViewer;
