import {FilePreviewState} from 'fast-sdk/src/api/storage/consts';
import React, {useEffect} from 'react';
import {FileType, getFileTypeFromMime, isPreviewReady} from 'utils/fast/files';
import AudioViewer from '../base/audio/AudioViewer';
import ImageViewer from '../base/image/ImageViewer';
import MarkdownViewer from '../base/markdown/MarkdownViewer';
import PDFViewer from '../base/pdf/PDFViewer';
import SpreadSheetsViewer from '../base/spread-sheets/SpreadSheetsViewer';
import {TextViewer} from '../base/text/TextViewer';
import VideoViewer from '../base/video/VideoViewer';
import useContentViewerMode from '../hooks/useContentViewerMode';
import type {ViewerProps as Props} from '../types';
import NotSupported from './NotSupported';

const FILE_TYPE_TO_VIEWER_MAP = {
  [FileType.Audio]: AudioViewer,
  [FileType.PDF]: PDFViewer,
  [FileType.Video]: VideoViewer,
  [FileType.Spreadsheet]: SpreadSheetsViewer,
  [FileType.Image]: ImageViewer,
  [FileType.Text]: PDFViewer,
  [FileType.Code]: TextViewer,
  [FileType.Document]: PDFViewer,
  [FileType.Markdown]: MarkdownViewer,
};

const NOT_SUPPORTED_STATES = [
  FilePreviewState.UNKNOWN,
  FilePreviewState.NOT_POSSIBLE,
  FilePreviewState.NOT_GENERATED,
];

const SUPPORTED_PREVIEW_TYPES = Object.keys(FILE_TYPE_TO_VIEWER_MAP);

const MediaComponent = ({file, emptyState, isThumbnail = false}: Props) => {
  const {setIsThumbnail} = useContentViewerMode();

  useEffect(() => {
    setIsThumbnail(isThumbnail);
  }, [isThumbnail]);

  if (!file) {
    return <>{emptyState}</>;
  }

  const type = getFileTypeFromMime(file);
  const {state} = isPreviewReady(file);

  const Component = FILE_TYPE_TO_VIEWER_MAP[type];

  if (
    !Component ||
    (NOT_SUPPORTED_STATES.includes(state) &&
      !SUPPORTED_PREVIEW_TYPES.includes(type))
  ) {
    return <NotSupported file={file} />;
  }

  return <Component file={file} isThumbnail={isThumbnail} theme={'light'} />;
};

export default MediaComponent;
