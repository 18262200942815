import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {useHover} from 'interface/common/hooks/useHover';
import {StyleSheet, type ViewStyle} from 'react-native';

type Props = {
  text: string;
  onPress: () => void;
  isActive?: boolean;
  customButtonStyle?: ViewStyle;
};

export function TagButton({
  text,
  onPress,
  isActive = false,
  customButtonStyle,
}: Props) {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  const buttonStyle = [
    isActive
      ? styles.buttonActive
      : isHover
        ? styles.buttonHover
        : styles.button,
    customButtonStyle,
  ];

  const buttonTextStyle = isActive
    ? styles.buttonTextActive
    : isHover
      ? styles.buttonTextHover
      : styles.buttonText;

  return (
    <Button
      variant="secondary"
      onHoverInFn={onHoverIn}
      onHoverOutFn={onHoverOut}
      onPress={onPress}
      overrides={{
        Button: {
          style: buttonStyle as ViewStyle,
        },
        Text: {
          style: buttonTextStyle,
        },
      }}>
      {text}
    </Button>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: theme.colors.neutral.$white,
    borderColor: theme.colors.neutral.$9,
    borderWidth: 1,
  },
  buttonText: {
    color: theme.colors.neutral.$4,
  },
  buttonActive: {
    backgroundColor: theme.colors.brand.$9,
    borderColor: theme.colors.brand.$4Base,
    borderWidth: 1,
  },
  buttonTextActive: {
    color: theme.colors.brand.$3,
  },
  buttonHover: {
    backgroundColor: theme.colors.neutral.$13,
    borderColor: theme.colors.neutral.$9,
    borderWidth: 1,
  },
  buttonTextHover: {
    color: theme.colors.neutral.$2Base,
  },
});
