import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {StyleSheet, Text, View} from 'react-native';
import {RouteLayout} from '../_layout/RouteLayout';

interface CheckEmailProps {
  email: string;
  resendEmail: () => void;
  tryDifferentEmail: () => void;
  sendEmailLoading: boolean;
}

export const CheckEmail = (props: CheckEmailProps) => {
  return (
    <RouteLayout
      title={t`Check your email`}
      subTitle={
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text style={{textAlign: 'center', marginBottom: '1rem'}}>
            <Trans>
              If <Text style={styles.email}>{props.email}</Text> matches an
              email for an existing Fastio account, then we’ve sent you an email
              with password reset instructions.
            </Trans>
          </Text>
          <Text style={{textAlign: 'center'}}>
            <Trans>
              If you haven’t received an email in 5 minutes, check your spam
              folder or click one of the options below:
            </Trans>
          </Text>
        </View>
      }
      removeSubContentBottomBorder>
      <View style={styles.root}>
        <View style={styles.buttonsContainer}>
          <Button
            variant="primary"
            size="lg"
            onPress={props.resendEmail}
            loading={props.sendEmailLoading}
            disabled={props.sendEmailLoading}>
            {t`Resend email`}
          </Button>
          <Button
            variant="secondary"
            size="lg"
            onPress={props.tryDifferentEmail}>
            {t`Try different email`}
          </Button>
        </View>
      </View>
    </RouteLayout>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.neutral.$2Base,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  email: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 14,
    marginTop: '1rem',
  },
});
