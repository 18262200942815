import {t} from '@lingui/macro';
import theme from 'config/theme';
import events from 'extensions/events';
import {Popup} from 'extensions/viewport/Popup';
import {PopupMenu} from 'extensions/viewport/PopupMenu';
import {api} from 'fast-sdk';
import {Fragment, useCallback, useMemo, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {ProfileMemberItemUser} from './ProfileMemberItemUser';
import type {ProfileInvite, ProfileMember} from './profile.types';

export interface ProfileMemberListUserProps {
  type: 'workspace' | 'share';
  profileId: string;
  user?: ProfileMember;
  self?: ProfileMember;
  invite?: ProfileInvite;
  status?: string;
  isLast?: boolean;
  reload?: () => void;
  subdomain?: string;
  showDotsMenu?: boolean;
  canUpdatePermissions?: boolean;
}

const compareRoles = (a: string, b: string) => {
  const ROLES = ['guest', 'member', 'admin', 'owner'];
  const firstIndex = ROLES.indexOf(a);
  const lastIndex = ROLES.indexOf(b);

  if (firstIndex === lastIndex) {
    return 0;
  }

  if (firstIndex === -1 || lastIndex === -1) {
    return undefined;
  }

  return firstIndex > lastIndex ? 1 : -1;
};

const OPTIONS_MAP = {
  guest: {
    guest: (target: string) => compareRoles('guest', target) === 0,
    member: (target: string) => compareRoles('member', target) === 0,
    admin: (target: string) => compareRoles('admin', target) === 0,
    owner: (target: string) => compareRoles('owner', target) === 0,
  },
  member: {
    guest: (target: string) => compareRoles('member', target) >= 0,
    member: (target: string) => compareRoles('member', target) >= 0,
    admin: (target: string) => compareRoles('admin', target) === 0,
    owner: (target: string) => compareRoles('owner', target) === 0,
  },
  admin: {
    guest: (target: string) => compareRoles('admin', target) >= 0,
    member: (target: string) => compareRoles('admin', target) >= 0,
    admin: (target: string) => compareRoles('admin', target) >= 0,
    owner: (target: string) => compareRoles('owner', target) === 0,
  },
  owner: {
    guest: (target: string) => compareRoles('owner', target) > 0,
    member: (target: string) => compareRoles('owner', target) > 0,
    admin: (target: string) => compareRoles('owner', target) > 0,
    owner: (target: string) => compareRoles('owner', target) === 0,
  },
};

export function ProfileMemberListUser(props: ProfileMemberListUserProps) {
  const [hasMenu, setHasMenu] = useState(false);
  const [access, setAccess] = useState(props.user.permissions);
  const [email, setEmail] = useState('');

  const sourcePermission = props.self?.permissions;
  const targetPermission = props.user.permissions;

  const isTargetOwner = access === 'owner';
  const isTargetAdmin = access === 'admin';
  const isTargetMember = access === 'member';
  const isTargetGuest = access === 'guest';

  const isSourceUser = props.self?.id === props.user.id;
  const isSourceOwner = sourcePermission === 'owner';
  const isSourceAdmin = sourcePermission === 'admin';

  const hasWriteAccess = isSourceAdmin || isSourceOwner;
  const hasDeleteWorkspace = isSourceOwner && isSourceUser;
  const hasLeaveWorkspace = !isSourceOwner && isSourceUser;
  const hasRemoveWorkspace = hasWriteAccess && !isSourceUser && !isTargetOwner;
  const isWorkspace = props.type === 'workspace';

  const showGuest = OPTIONS_MAP[sourcePermission]?.guest(targetPermission);
  const showMember = OPTIONS_MAP[sourcePermission]?.member(targetPermission);
  const showAdmin = OPTIONS_MAP[sourcePermission]?.admin(targetPermission);
  const showOwner = OPTIONS_MAP[sourcePermission]?.owner(targetPermission);

  const status = access;

  const accessLabel = useMemo(() => {
    switch (access) {
      case 'owner':
        return t`Owner`;
      case 'admin':
        return t`Admin`;
      case 'member':
        return t`Member`;
      case 'guest':
        return t`Guest`;
      default:
        return access.charAt(0).toUpperCase() + access.slice(1);
    }
  }, [access]);

  const handleProfileAction = useCallback(
    async (command: string) => {
      switch (command) {
        case 'member':
        case 'admin':
        case 'guest':
          setAccess(command);
          if (props.type === 'workspace') {
            api.workspace.updateMember(props.profileId, email, command);
          } else if (props.type === 'share') {
            api.share.updateShareMember(props.profileId, email, command);
          }
          break;
        case 'leave':
          if (props.type === 'workspace')
            await api.workspace.leaveWorkspace(props.profileId);
          else if (props.type === 'share')
            await api.share.leaveShare(props.profileId);
          break;
        case 'delete':
          if (props.type === 'workspace')
            await api.workspace.deleteMember(props.profileId, email);
          else if (props.type === 'share')
            await api.share.deleteShareMember(props.profileId, email);
          props.reload?.();
          break;
        case 'close':
          if (props.type === 'workspace')
            await api.workspace.archiveWorkspace(props.profileId);
          else if (props.type === 'share')
            await api.share.closeShare(props.profileId);
          break;
      }
      setHasMenu(false);
    },
    [props.profileId, props.type, email],
  );

  return (
    <View style={[styles.root, props.isLast && styles.rootLast]}>
      <View style={styles.profile}>
        <ProfileMemberItemUser
          id={props.user.id}
          onLoad={user => {
            setEmail(user.email_address);
          }}
        />
      </View>
      <View style={styles.status}>
        {status && (
          <Text
            style={[
              styles.badge,
              status === 'external' && styles.badgeExternal,
              status === 'success' && styles.badgeSuccess,
            ]}>
            {`${status.charAt(0).toUpperCase()}${status.slice(1)}`}
          </Text>
        )}
      </View>
      <Popup
        triggerElement={
          <Pressable
            onPress={() => {
              if (!props.showDotsMenu && !props.canUpdatePermissions) {
                return;
              }

              // Hack to workaround closing other popups
              events.simulateKey('down', {
                key: 'Escape',
                code: 'Escape',
                original: null,
                flags: {},
              });
              setHasMenu(!hasMenu);
            }}
            style={e => [
              styles.menu,
              // @ts-ignore
              (e.hovered || hasMenu) && styles.menuActive,
            ]}>
            {props.showDotsMenu ? (
              <MCIcon
                name="dots-horizontal"
                size={20}
                color={theme.colors.neutral.$5}
              />
            ) : (
              <Fragment>
                <Text style={styles.menuLabel}>{accessLabel}</Text>
                {props.canUpdatePermissions && (
                  <MCIcon
                    name="chevron-down"
                    size={18}
                    color={theme.colors.neutral.$5}
                  />
                )}
              </Fragment>
            )}
          </Pressable>
        }
        placement="bottom-end"
        isOpen={hasMenu}
        close={() => setHasMenu(false)}>
        <PopupMenu
          autoClose
          close={() => setHasMenu(false)}
          onSelectItem={handleProfileAction}
          menuItems={[
            showGuest &&
              !isWorkspace && {
                command: 'guest',
                text: t`Guest`,
                disabled: isTargetGuest,
                icon: (
                  <Icon
                    name="check"
                    size={20}
                    color={
                      isTargetGuest ? theme.colors.neutral.$6 : 'transparent'
                    }
                  />
                ),
              },
            showMember && {
              command: 'member',
              text: t`Member`,
              disabled: isTargetMember,
              icon: (
                <Icon
                  name="check"
                  size={20}
                  color={
                    isTargetMember ? theme.colors.neutral.$6 : 'transparent'
                  }
                />
              ),
            },
            showAdmin && {
              command: 'admin',
              text: t`Admin`,
              disabled: isTargetAdmin,
              icon: (
                <Icon
                  name="check"
                  size={20}
                  color={
                    isTargetAdmin ? theme.colors.neutral.$6 : 'transparent'
                  }
                />
              ),
            },
            showOwner && {
              command: 'owner',
              text: t`Owner`,
              disabled: true,
              icon: (
                <Icon
                  name="check"
                  size={20}
                  color={
                    isTargetOwner ? theme.colors.neutral.$6 : 'transparent'
                  }
                />
              ),
            },
            (hasRemoveWorkspace || hasLeaveWorkspace || hasDeleteWorkspace) && {
              command: 'divider',
              text: '-',
            },
            hasRemoveWorkspace && {
              command: 'delete',
              text:
                props.type === 'workspace'
                  ? t`Remove from Workspace`
                  : t`Remove from Share`,
              icon: (
                <MCIcon
                  name="account-remove"
                  size={20}
                  color={theme.colors.danger.$35Hover}
                />
              ),
              destructive: true,
            },
            hasLeaveWorkspace && {
              command: 'leave',
              text: t`Leave Workspace`,
              icon: (
                <Icon
                  name="logout"
                  size={20}
                  color={theme.colors.danger.$35Hover}
                />
              ),
              destructive: true,
            },
            hasDeleteWorkspace && {
              command: 'close',
              text: t`Archive Workspace`,
              icon: (
                <Icon
                  name="delete"
                  size={20}
                  color={theme.colors.danger.$35Hover}
                />
              ),
              destructive: true,
            },
          ].filter(e => !!e)}
        />
      </Popup>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 14,
    height: 54,
    borderTopWidth: 1,
    borderColor: theme.colors.neutral.$12,
  },
  rootLast: {
    borderBottomWidth: 1,
  },
  profile: {
    flex: 1,
    height: 54,
    justifyContent: 'center',
  },
  status: {
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    paddingVertical: 1,
    paddingHorizontal: 8,
    borderRadius: 99,
    backgroundColor: theme.colors.neutral.$11,
    color: theme.colors.neutral.$2Base,
  },
  badgeExternal: {
    backgroundColor: theme.colors.caution.$5,
  },
  badgeSuccess: {
    backgroundColor: theme.colors.success.$6,
  },
  menuWrapper: {
    position: 'relative',
  },
  menu: {
    flexGrow: 0,
    minWidth: 36,
    borderRadius: 3,
    paddingVertical: 6,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuDropdown: {},
  menuActive: {
    backgroundColor: theme.colors.neutral.$13,
  },
  menuLabel: {
    fontSize: 14,
    color: theme.colors.neutral.$3,
    fontWeight: '400',
    lineHeight: 24,
    marginRight: 8,
  },
});
