import theme from 'config/theme';
import {useCreateNewChat} from 'interface/stacks/workspace/layout/WorkspaceAiChat/hooks/useCreateNewChat';
import {useEffect, useRef, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {EntityChat} from 'store/slices/aichat/types';
import type {FilesItem} from 'store/slices/files/types';
import type {Member} from 'store/slices/settings/types';
import {AiChatHeader} from '../components/AiChatHeader';
import {AiChatGettingStarted} from '../components/gettingStarted/AiChatGettingStarted';
import {AiChatInput} from '../components/input/AiChatInput';
import {AiChatMessageItem} from '../components/messages/AiChatMessageItem';
import {AiChatMessageSkeleton} from '../components/skeletons/AiChatMessageSkeleton';
import {
  AICHAT_TOOLBAR_HEIGHT,
  CHAT_POLL_INTERVAL,
  NEW_CHAT,
  NEW_PRIVATE_CHAT,
} from '../consts';
import {CurrentFolderProvider} from '../context/CurrentFolderContext';
import {useCreateNewMessage} from '../hooks/useCreateNewChatMessage';
import {useFetchSingleChatData} from '../hooks/useFetchSingleChatData';

interface Props {
  namespace: string;
  instanceId: string;
  instanceName: string;
  chatId: string;
  messageId: string;
  onOpenSidebar: () => void;
  onNewChatPress: (isPrivate: boolean) => void;
  workspaceMembers: Member[];
  showMenu: boolean;
}

export function AiChat({
  namespace,
  instanceId,
  instanceName,
  chatId,
  messageId,
  workspaceMembers,
  onOpenSidebar,
  onNewChatPress,
  showMenu,
}: Props) {
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);

  const chats = useSelector(aichat.selectors.getChats(namespace, instanceId));
  const hasChats = chats.length > 0;

  const {isLoading: chatLoading, fetchChatData} = useFetchSingleChatData(
    namespace,
    instanceId,
    instanceName,
  );
  const {isLoading: createMessageLoading, createChatMessage} =
    useCreateNewMessage();
  const {isLoading: createChatLoading, createChat} = useCreateNewChat();

  const chat: EntityChat = useSelector(
    aichat.selectors.getChat(namespace, instanceId, chatId),
  );
  const messages = chat?.messages ?? [];

  const isNewChat =
    chatId === null || chatId === NEW_CHAT || chatId === NEW_PRIVATE_CHAT;
  const isPrivate =
    chat?.privacy?.visibility === 'private' || chatId === NEW_PRIVATE_CHAT;

  const lastMessage = messages[messages.length - 1];
  const isEnabled =
    !lastMessage ||
    lastMessage?.state === 'complete' ||
    lastMessage?.state === 'errored';
  const scrollViewRef = useRef<ScrollView>(null);

  const scrollToMessage = (messageId: string) => {
    // TODO: AICHAT: Fix this
    const messageIndex = messages.findIndex(
      message => message.id === messageId,
    );
    if (messageIndex !== -1) {
      scrollViewRef.current?.scrollTo({y: messageIndex * 30, animated: true});
    }
  };

  const scrollToBottom = () => {
    scrollViewRef.current?.scrollToEnd({animated: true});
  };

  const submitQuestion = (question: string, attachFiles: FilesItem[]) => {
    createMessageOrNewChat(question, false, false);
  };

  const createMessageOrNewChat = async (
    message: string,
    webSearch: boolean,
    withRag: boolean,
  ) => {
    if (isNewChat) {
      setFirstLoad(false);
      return await createChat(message, 'detailed', isPrivate, [], []);
    }
    return await createChatMessage(chatId, message, 'detailed', [], []);
  };

  useEffect(() => {
    if (messageId) {
      scrollToMessage(messageId);
    }
  }, [messageId]);

  const shouldPoll =
    !isNewChat &&
    (chat?.status !== 'ready' ||
      !['complete', 'errored'].includes(lastMessage?.state));

  useEffect(() => {
    const interval = setInterval(() => {
      if (shouldPoll) {
        fetchChatData(chatId);
      }
    }, CHAT_POLL_INTERVAL);

    return () => clearInterval(interval);
  }, [shouldPoll]);

  useEffect(() => {
    if (isNewChat) {
      return;
    }
    const fetchData = async () => {
      await fetchChatData(chatId);
      setFirstLoad(false);
    };
    fetchData();
  }, [instanceId, chatId]);

  const lastMessageId = lastMessage?.id;

  useEffect(() => {
    if (lastMessage?.state === 'complete' || lastMessage?.state === 'errored') {
      dispatch(aichat.default.actions.setTriggerStreaming(null));
    }
    scrollToBottom();
  }, [chatId, lastMessageId, lastMessage?.state]);

  const hasChatCached = chat?.messages?.length > 0;
  const showSkeleton = !hasChatCached && chatLoading && firstLoad;
  const showGettingStarted = !showSkeleton && messages.length === 0;

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        {showGettingStarted ? (
          <AiChatGettingStarted submitDirectQuestion={submitQuestion} />
        ) : (
          <ScrollView
            ref={scrollViewRef}
            style={styles.messagesContainer}
            contentContainerStyle={styles.scrollContent}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}>
            <View style={styles.messages}>
              {showSkeleton ? (
                <AiChatMessageSkeleton />
              ) : (
                messages.map((message, index) => {
                  const isLastMessage = index === messages.length - 1;
                  return (
                    <AiChatMessageItem
                      key={message.id}
                      message={message}
                      isLastMessage={isLastMessage}
                    />
                  );
                })
              )}
            </View>
          </ScrollView>
        )}
        <View style={styles.headerContainer}>
          <AiChatHeader
            workspaceId={instanceId}
            chatId={chatId}
            isPrivate={isPrivate}
            showMenu={showMenu}
            title={chat?.name ?? 'New chat'}
            onOpenSidebar={onOpenSidebar}
            onNewChatPress={onNewChatPress}
            workspaceMembers={workspaceMembers}
          />
        </View>

        <CurrentFolderProvider>
          <AiChatInput
            chatId={chatId}
            onSendMessage={createMessageOrNewChat}
            isEnabled={isEnabled}
            enableSettings={true}
            style={styles.inputContainer}
          />
        </CurrentFolderProvider>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: theme.colors.neutral.$16,
  },
  container: {
    flex: 1,
    width: '100%',
  },
  headerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: AICHAT_TOOLBAR_HEIGHT,
    // overflow: 'hidden',
  },
  messagesContainer: {
    flex: 1,
    padding: 16,
    paddingTop: AICHAT_TOOLBAR_HEIGHT + 16,
    paddingBottom: 66,
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  inputContainer: {
    marginTop: -50,
  },
  scrollContent: {
    alignItems: 'center',
  },
  messages: {
    gap: 30,
    width: '100%',
  },
  drop: {
    position: 'absolute',
    top: -1,
    right: -1,
    bottom: -1,
    left: -1,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.colors.brand.$4Base,
    overflow: 'visible',
  },
});
