import {api} from 'fast-sdk';
import {useState} from 'react';

export const useFetchWorkspaces = () => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchWorkspaces = async () => {
    try {
      const response = await api.workspace.getWorkspaceListAll();
      if (response.result) {
        const workspaces = response.workspaces.filter(
          workspace => !workspace.closed,
        );
        return workspaces;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, fetchWorkspaces};
};
