import {ROUTES} from 'constants/routes';
import {
  Navigate,
  Outlet,
  useOutletContext,
  useParams,
} from 'extensions/navigation';
import LoadingProgress from 'interface/stacks/app/LoadingProgress';
import {AppPublicLayout} from 'interface/stacks/app/layout/AppPublicLayout';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ShareViewStatus} from 'store/slices/shared/types';
import useFetchShareViewStatus from '../hooks/useFetchShareViewStatus';
import useGetShareDetails from '../hooks/useGetShareDetails';
import ShareHiddenLayout from './ShareHiddenLayout';
import ShareNotAvailable from './ShareNotAvailable';

interface ShareAccessLayoutProps {
  showLayout?: boolean;
}

export default function ShareAccessLayout({
  showLayout = true,
}: ShareAccessLayoutProps) {
  const {sharedCustomName} = useParams();
  const context = useOutletContext();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const {share, isShareLoaded} = useGetShareDetails({
    sharedCustomName,
    fetchDisabled: !isLoggedIn,
  });

  const {shareViewStatus} = useFetchShareViewStatus({
    share,
    sharedCustomName,
    enabled: isLoggedIn,
    isShareLoaded,
  });

  const renderWithLayout = (content: React.ReactNode) => (
    <AppPublicLayout showHeaderAndFooter={showLayout}>
      {content}
    </AppPublicLayout>
  );

  if (!isLoggedIn) {
    return <ShareHiddenLayout sharedCustomName={sharedCustomName} />;
  }

  if (shareViewStatus === ShareViewStatus.Loading) {
    return renderWithLayout(<LoadingProgress />);
  }

  const isSharedClosed = shareViewStatus === ShareViewStatus.Closed;

  if (isSharedClosed) {
    return renderWithLayout(<ShareNotAvailable isLoggedIn />);
  }

  const redirectToHome =
    (shareViewStatus === ShareViewStatus.NotFound ||
      (share?.archived && shareViewStatus === ShareViewStatus.Guest)) &&
    isShareLoaded;

  if (redirectToHome) {
    return <Navigate to={ROUTES.LOGGED_IN_WITHOUT_ORG.HOME} />;
  }

  if (share) {
    return <Outlet context={context} />;
  }
}
