import {api} from 'fast-sdk';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as user from 'store/slices/user';
import {base64ToFile, resizeImage} from 'utils/fast/files';

type UserPhoto = {
  file: File;
  name: string;
  type: string;
  src: string;
};

const useUpdateUser = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const updateUserPhoto = async (id: string, photo: UserPhoto) => {
    setLoading(true);
    const file = Object.keys(photo.file).length
      ? photo.file
      : base64ToFile(photo.src, photo.name, photo.type);
    const resized = await resizeImage(file);
    const {result} = await api.user.addProfilePhoto(id, resized);
    if (result) {
      dispatch(user.default.actions.setUserProfilePic({img: photo.src}));
    }
    setLoading(false);
  };

  const updateUserFullname = async (
    id: string,
    firstName: string,
    lastName: string,
  ) => {
    setLoading(true);
    const isUserDataValid = firstName && lastName;

    if (isUserDataValid) {
      await api.user.updateUserDetails({
        first_name: firstName,
        last_name: lastName,
      });
    }

    setLoading(false);
  };

  return {updateUserPhoto, updateUserFullname, loading};
};

export default useUpdateUser;
