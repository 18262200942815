import {getSession} from '../utils';

export default function useHasAccessToWorkspace() {
  const checkWorkspaceAccess = (workspaceId: string) => {
    const {workspaces} = getSession() ?? {};
    const hasAccessToWorkspace = workspaces?.find(
      ({folderName, id}) => workspaceId === folderName || id === workspaceId,
    );
    return hasAccessToWorkspace;
  };

  return {checkWorkspaceAccess};
}
