import {api} from 'fast-sdk';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as organization from 'store/slices/organization';
import type {Organization} from 'store/slices/user/types';

export const useOrganizationPublicDetails = (
  domain: string,
  orgDetails?: Organization | undefined,
) => {
  const orgFromStore = useSelector(
    organization.selectors.getOrganization(domain),
  );

  const [org, setOrg] = useState<OrganizationDetails | null>(
    orgDetails ?? orgFromStore,
  );
  const [loading, setLoading] = useState(!org);

  async function fetchOrganizationDetails() {
    try {
      const {result, org} =
        await api.organization.getOrganizationPublicDetails(domain);
      if (result) {
        setOrg(org);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (domain && !org) {
      fetchOrganizationDetails();
    }
  }, [domain]);

  useEffect(() => {
    if (org) {
      setLoading(false);
    }
  }, [org]);

  return {org, loading};
};
