import {useFlag} from '@unleash/proxy-client-react';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import {useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import {
  WorkspaceInfoPanelTab as Tab,
  type WorkspaceInfoPanelTab,
} from '../types/WorkspaceInfoPanelTab';

type Props = {
  hasNodeSelected: boolean;
};

export function InfoPanelTabHeader({hasNodeSelected}: Props) {
  const dispatch = useDispatch();
  const hasComments = useFlag('storage-comments');

  const activeTab = useSelector(files.selectors.getFocusedType);
  const setActiveTab = (value: WorkspaceInfoPanelTab) => {
    dispatch(files.default.actions.focusType({type: value}));
  };

  const tabs = [
    createTabItem(Tab.FileInfo, '', 'lucide:info', activeTab),
    createTabItem(Tab.Activity, '', 'lucide:activity', activeTab),
    ...(hasNodeSelected
      ? [createTabItem(Tab.Versions, '', 'lucide:list-tree', activeTab)]
      : []),
    ...(hasComments
      ? [
          createTabItem(
            Tab.Comments,
            '',
            'lucide:message-square-text',
            activeTab,
          ),
        ]
      : []),
  ];

  useEffect(() => {
    if (activeTab === Tab.Versions && !hasNodeSelected) {
      dispatch(files.default.actions.focusType({type: Tab.Activity}));
    }
  }, [activeTab, hasNodeSelected]);

  return (
    <Tabs
      tabs={tabs}
      activeTab={activeTab}
      rootStyle={styles.root}
      tabStyle={styles.tab}
      setActiveTab={setActiveTab}
    />
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  tab: {
    minWidth: 30,
    flex: 1,
  },
});
