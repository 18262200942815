import theme from 'config/theme';
import {Panel, PanelHandle} from 'interface/base/Panel';
import useDebounce from 'interface/common/hooks/useDebounce';
import {useEffect, useMemo, useRef} from 'react';
import {type LayoutChangeEvent, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import * as files from 'store/slices/files';
import {SHARE_STATUS_WITHOUT_CONFIG} from 'store/slices/shared/types';
import {
  COLUMN_CATEGORY,
  COLUMN_VALUES,
  updateColumnManagerValue,
} from 'utils/common/ui';
import {getColumnManagerValue} from 'utils/common/ui';
import {useGetCurrentShareDetails} from '../../hooks/useGetCurrentShareDetails';
import {ColumnHeader} from './ColumnHeader';
import {ColumnMinimized} from './ColumnMinimized';

// Props interface for the ColumnPanelToggle component
interface ColumnToggleProps {
  columnKey: ColumnManagerInfoKeys;
  itemsToClose?: number;
  children: React.ReactNode;
}

// Defines the order in which columns appear from left to right
// Lower numbers appear further left
const COLUMN_ORDER = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 1,
  [ColumnManagerInfoKeys.FoldersAndFiles]: 2,
  [ColumnManagerInfoKeys.InfoPanel]: 3,
  [ColumnManagerInfoKeys.ShareConfiguration]: 4,
  [ColumnManagerInfoKeys.AIChat]: 5,
};

export const COLUMN_NAME = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 'Storage and Shares',
  [ColumnManagerInfoKeys.FoldersAndFiles]: 'Files',
  [ColumnManagerInfoKeys.InfoPanel]: 'Info',
  [ColumnManagerInfoKeys.ShareConfiguration]: 'Share Settings',
  [ColumnManagerInfoKeys.AIChat]: 'AI Chat',
};

// Defines the minimum width (in percentage) that each column can be resized to
// This prevents columns from becoming too narrow to be useful
const MIN_SIZE = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 10,
  [ColumnManagerInfoKeys.FoldersAndFiles]: 20,
  [ColumnManagerInfoKeys.InfoPanel]: 15,
  [ColumnManagerInfoKeys.ShareConfiguration]: 15,
  [ColumnManagerInfoKeys.AIChat]: 20,
};

export function ColumnPanelToggle({columnKey, children}: ColumnToggleProps) {
  const ref = useRef(null);
  // Get necessary state from Redux
  const focusedParentId = useSelector(files.selectors.getFocusedParent);
  const columnInfo = useSelector(app.selectors.columnInfo);
  const columnInfoOverride = useSelector(app.selectors.columnInfoOverride);
  const columnVisibility = useSelector(app.selectors.columnVisibility);

  // Check if we're in a share context (viewing shared content)
  const isShareContext = !!focusedParentId?.startsWith('5');
  const share = useGetCurrentShareDetails();

  // Determine if we have a valid share configuration
  const hasValidShare =
    isShareContext &&
    share &&
    !SHARE_STATUS_WITHOUT_CONFIG.includes(share.shareViewStatus);

  const dispatch = useDispatch();

  const handleLayout = useDebounce((event: LayoutChangeEvent) => {
    const width = event.nativeEvent.layout.width;
    dispatch(app.default.actions.setColumnWidth({columnKey, width}));
  }, 100);

  useEffect(() => {
    if (ref.current) {
      const size = ref.current.getSize();
      updateColumnManagerValue(columnKey, size, COLUMN_CATEGORY.percentage);
    }
  }, [columnKey]);

  const defaultSize = useMemo(() => {
    const sizeWithoutContext = getColumnManagerValue(
      columnKey,
      COLUMN_CATEGORY.percentage,
    );

    const size = isShareContext
      ? sizeWithoutContext?.shares
      : sizeWithoutContext?.workspaces;
    return size ? Number(size) : undefined;
  }, [columnKey, isShareContext]);

  const handleResize = (size: number) => {
    const currentSizes = getColumnManagerValue(
      columnKey,
      COLUMN_CATEGORY.percentage,
    );

    const workspaces =
      currentSizes?.workspaces ??
      COLUMN_VALUES[columnKey]?.percentage?.workspaces;
    const shares =
      currentSizes?.shares ?? COLUMN_VALUES[columnKey]?.percentage?.shares;

    const value = isShareContext
      ? {
          shares: size,
          workspaces,
        }
      : {
          workspaces: size,
          shares,
        };
    updateColumnManagerValue(columnKey, value, COLUMN_CATEGORY.percentage);
  };
  const debouncedResize = useDebounce(handleResize, 500);

  // Determine column visibility (checking both override and default states)
  const maximized = columnInfoOverride[columnKey] ?? columnInfo[columnKey];
  const visibility = columnVisibility[columnKey];
  const columnOrder = COLUMN_ORDER[columnKey];

  // Select appropriate size defaults based on context (share vs normal)
  const size = isShareContext
    ? COLUMN_VALUES[columnKey]?.percentage?.shares
    : COLUMN_VALUES[columnKey]?.percentage?.workspaces;

  const onClosePanel = () => {
    updateColumnManagerValue(columnKey, false, COLUMN_CATEGORY.maximized);
    dispatch(app.default.actions.setColumnInfoOverride({[columnKey]: false}));
  };

  const onExpandPanel = () => {
    dispatch(app.default.actions.expandColumn({columnKey}));
  };

  // Don't render the ShareConfiguration column if we don't have a valid share
  if (
    columnKey === ColumnManagerInfoKeys.ShareConfiguration &&
    !hasValidShare
  ) {
    return undefined;
  }

  if (!visibility) {
    return null;
  }

  // Only render the column if it should be visible
  return maximized ? (
    <Panel
      ref={ref}
      id={columnKey}
      order={columnOrder}
      minSize={MIN_SIZE[columnKey]}
      defaultSize={defaultSize ?? size}
      onResize={debouncedResize}
      flex>
      <View style={styles.root} onLayout={handleLayout}>
        <View style={styles.container}>
          <ColumnHeader
            title={COLUMN_NAME[columnKey]}
            onToggle={onClosePanel}
            onExpand={onExpandPanel}
          />
          <View style={styles.child}>{children}</View>
        </View>
      </View>
      {/* Don't show resize handle for AIChat column */}
      {columnKey !== ColumnManagerInfoKeys.AIChat && <PanelHandle />}
    </Panel>
  ) : (
    <ColumnMinimized columnKey={columnKey} />
  );
}

// Styles for the column container
const styles = StyleSheet.create({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$white,
    margin: StyleSheet.hairlineWidth,
    flexDirection: 'column',
  },
  header: {
    padding: 10,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 4,
    margin: 4,
    height: 30,
    paddingHorizontal: 12,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  child: {
    flexDirection: 'column',
    flex: 1,
  },
});
