import theme from 'config/theme';
import {ROUTES, WORKSPACE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import {Pressable, StyleSheet, Text} from 'react-native';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as workspaceStore from 'store/slices/workspace';

interface Props {
  workspaceId: string;
}

export function WorkspaceChip({workspaceId}: Props) {
  const workspace = useSelector(
    workspaceStore.selectors.getWorkspaceById(workspaceId),
  );
  const itemName = workspace?.name;
  const navigate = useNavigate();

  const handlePress = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/${WORKSPACE_STORAGE}/${WORKSPACE_ROOT}`,
    );
  };

  return (
    <Pressable style={styles.container} onPress={handlePress}>
      <WorkspaceBadge id={workspaceId} size={16} />
      {itemName && <Text style={styles.label}>{itemName}</Text>}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.neutral.$11,
    paddingHorizontal: 8,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    // @ts-ignore
    display: 'inline-flex',
    verticalAlign: 'middle',
    maxWidth: '100%',
  },
  label: {
    fontSize: 12,
    color: theme.colors.neutral.$2Base,
    lineHeight: 20,
    // @ts-ignore
    display: 'inline',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  image: {
    width: 16,
    height: 16,
  },
});
