import Skeleton from 'interface/common/Skeleton';
import {type StyleProp, StyleSheet, View, type ViewStyle} from 'react-native';
import {useSelector} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {WorkspaceLayoutTabs} from 'store/slices/workspace/types';
import Card from '../content/common/Card';

const WORKSPACE_CARD_SKELETON_ROWS = 3;

export const WorkspaceCardSkeleton = () => {
  const layoutTab = useSelector(workspace.selectors.getWorkspaceLayoutTab);

  const cardStyle =
    layoutTab === WorkspaceLayoutTabs.Grid
      ? {
          minWidth: 'auto',
          maxWidth: 'auto',
        }
      : {
          minWidth: '100%',
          maxWidth: '100%',
        };

  return (
    <Card style={[styles.card, cardStyle]}>
      <View style={styles.content}>
        <View style={styles.line}>
          <View style={styles.leftContent}>
            <Skeleton width={40} height={40} style={{borderRadius: 8}} />
            <View style={styles.nameContainer}>
              <Skeleton width={150} height={20} />
              <Skeleton width={200} height={16} style={{marginTop: 4}} />
            </View>
          </View>
          <Skeleton width={40} height={40} style={{borderRadius: 8}} />
        </View>
        <View style={styles.footer}>
          <Skeleton width={120} height={24} />
          <Skeleton width={100} height={24} />
        </View>
      </View>
    </Card>
  );
};

type Props = {
  style?: StyleProp<ViewStyle>;
};

const WorkspacesCardsSkeleton = ({style}: Props) => (
  <View style={[styles.baseGrid, style || styles.grid]}>
    {[...Array(WORKSPACE_CARD_SKELETON_ROWS)].map((_, index) => (
      <WorkspaceCardSkeleton key={`workspace-card-${index}`} />
    ))}
  </View>
);

const styles = StyleSheet.create({
  card: {
    justifyContent: 'space-between',
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.2,
    shadowRadius: 2,
    padding: 16,
  },
  cardLeftContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  baseGrid: {
    paddingTop: 24,
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
  },
  content: {
    flexDirection: 'column',
    gap: 12,
    width: '100%',
  },
  line: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  leftContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  nameContainer: {
    flexDirection: 'column',
  },
});

export default WorkspacesCardsSkeleton;
