import type {Block, BlockNoteEditor} from '@blocknote/core';
import {useCreateBlockNote} from '@blocknote/react';
import {t} from '@lingui/macro';
import {STORAGE_ROOT} from 'constants/routes';
import {api} from 'fast-sdk';
import type {
  StorageItem,
  StorageNamespace,
} from 'fast-sdk/src/api/storage/consts';
import {createContext, useContext, useEffect, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as files from 'store/slices/files';
import {buildFileDetails} from 'store/slices/files/helpers';
import type {FilesItem} from 'store/slices/files/types';
import {removeExtension} from 'utils/fast/files';

interface EditorContextType {
  editor: BlockNoteEditor;
  fileName: string;
  setFileName: (fileName: string) => void;
  file?: FilesItem;
  instanceId: string;
  instanceNs: StorageNamespace;
  saveLoading: boolean;
  setSaveLoading: (saveLoading: boolean) => void;
  parentFolder?: string;
  markdownLoaded: boolean;
  setMarkdownLoaded: (markdownLoaded: boolean) => void;
  initializeEditor: (text: string) => void;
  hasChanges: boolean;
  handleSave: (updatedFile: FilesItem) => Promise<FilesItem | null>;
  handleCancel: () => Promise<void>;
}

const EditorContext = createContext<EditorContextType | undefined>(undefined);

export function EditorProvider({
  children,
  initialFileName,
  instanceId,
  instanceNs,
  file,
  parentFolder,
}: {
  children: React.ReactNode;
  initialFileName: string;
  instanceId: string;
  instanceNs: StorageNamespace;
  file?: FilesItem;
  parentFolder?: string;
}) {
  const dispatch = useDispatch();
  const toast = useToast();

  const editor = useCreateBlockNote();

  const [fileName, setFileName] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [initialText, setInitialText] = useState('');
  const [markdownLoaded, setMarkdownLoaded] = useState(false);
  const [hasContentChanges, setHasContentChanges] = useState(false);
  const [oldFileName, setOldFileName] = useState('');

  useEffect(() => {
    if (initialFileName !== fileName) {
      const nameWithoutExtension = removeExtension(initialFileName);
      setFileName(nameWithoutExtension);
      setOldFileName(nameWithoutExtension);
    }
  }, [initialFileName]);

  const initializeEditor = (text: string) => {
    const setBlocks = (blocks: Block[]) => {
      editor.replaceBlocks(editor.document, blocks);
      setInitialText(text);
      setMarkdownLoaded(true);
    };

    editor.tryParseMarkdownToBlocks(text).then(blocks => {
      setBlocks(blocks);
    });
  };

  const handleChange = async () => {
    const markdown = await editor.blocksToMarkdownLossy();
    setHasContentChanges(markdown !== initialText);
  };

  const handleSuccessSave = async (nameWithoutExtension: string) => {
    setInitialText(await editor.blocksToMarkdownLossy());
    setOldFileName(nameWithoutExtension);
    setFileName(nameWithoutExtension);
    setHasContentChanges(false);
  };

  const handleCancel = async () => {
    setInitialText(initialText);
    initializeEditor(initialText);
    setFileName(oldFileName);
    setHasContentChanges(false);
  };

  const handleSave = async (updatedFile: FilesItem) => {
    setSaveLoading(true);
    try {
      const storage = api.storage.init(instanceNs, instanceId);
      const markdown = await editor.blocksToMarkdownLossy();
      const fileWithExtension = fileName.endsWith('.md')
        ? fileName
        : `${fileName}.md`;

      const handleStorageResult = (result: boolean, note: StorageItem) => {
        if (result) {
          dispatch(
            files.default.actions.updateFile({
              file: note,
              instanceId,
              instanceNs,
            }),
          );
          const nameWithoutExtension = removeExtension(note.name);
          handleSuccessSave(nameWithoutExtension);
          toast.show(
            t`Note ${updatedFile ? 'updated' : 'created'} successfully`,
            {
              type: 'neutral',
            },
          );
          return buildFileDetails(note, instanceId, instanceNs);
        }
      };

      const {result, note} = updatedFile
        ? await storage.updateMarkdown(
            updatedFile.id,
            fileWithExtension !== updatedFile.name
              ? fileWithExtension
              : undefined,
            markdown,
          )
        : await storage.createMarkdown(
            parentFolder ?? STORAGE_ROOT,
            fileWithExtension,
            markdown,
          );

      return handleStorageResult(result, note);
    } finally {
      setSaveLoading(false);
    }
  };

  editor.onChange(handleChange);

  const isFileNameChanged = oldFileName !== fileName;

  return (
    <EditorContext.Provider
      value={{
        editor,
        fileName,
        setFileName,
        file,
        instanceId,
        instanceNs,
        saveLoading,
        setSaveLoading,
        parentFolder,
        markdownLoaded,
        setMarkdownLoaded,
        initializeEditor,
        hasChanges: hasContentChanges || isFileNameChanged,
        handleSave,
        handleCancel,
      }}>
      {children}
    </EditorContext.Provider>
  );
}

export function useEditorContext() {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error('useEditor must be used within a EditorProvider');
  }
  return context;
}
