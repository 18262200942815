import theme from 'config/theme';
import {api} from 'fast-sdk';
import type {BillingMeterDataPoint} from 'fast-sdk/src/api/billing/consts';
import {formatNumber} from 'fast-sdk/src/utils';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import Typography from 'interface/base/Typography';
import {BarChart, type BarChartData} from 'interface/base/charts/BarChart';
import {Loading} from 'interface/common/Loading';
import MetricsFilter from 'interface/common/MetricsFilter';
import type {BillingMeters} from 'interface/stacks/onboard/lib/types';
import {useEffect, useRef, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {getHashColor} from 'utils/common/color';
import {capitalizeFirstLetter} from 'utils/common/string';
import {METER_COLORS, METER_LABELS} from '../consts';
import type {MeterInfo} from '../types';

export type Props = {
  domain: string;
  creditsMeters: Array<BillingMeterDataPoint>;
  metersPricing: BillingMeters;
  loading: boolean;
};

const DEFAULT_WIDTH = 1256;
const DEFAULT_HEIGHT = 268;

enum TrailingDaysTabs {
  Day = 'day',
  Week = 'week',
  Fortnight = 'fortnight',
  Month = 'month',
}

const TRAILING_DAYS_LABELS = {
  [TrailingDaysTabs.Day]: '24 Hours',
  [TrailingDaysTabs.Week]: '7 Days',
  [TrailingDaysTabs.Fortnight]: '14 Days',
  [TrailingDaysTabs.Month]: '30 Days',
};

enum DataTypeTabs {
  Credits = 'credits',
  Dollars = 'dollars',
}

const DAYS_OPTIONS = {
  // [TrailingDaysTabs.Day]: 1,
  [TrailingDaysTabs.Week]: 7,
  [TrailingDaysTabs.Fortnight]: 14,
  [TrailingDaysTabs.Month]: 30,
};

const DATA_TYPE_OPTIONS = {
  [DataTypeTabs.Credits]: 'Credits',
  [DataTypeTabs.Dollars]: 'Dollars',
};

const getChartData = (
  dataPoints: Array<BillingMeterDataPoint>,
  trailingDays: number,
  dataType: DataTypeTabs,
) => {
  const priceMode = dataType === DataTypeTabs.Dollars;
  const decimals = priceMode ? 2 : 0;
  const prefix = priceMode ? '$' : '';
  const data = dataPoints.map(dataPoint => {
    const value = priceMode
      ? (dataPoint.cost ?? 0)
      : (dataPoint.credits ?? dataPoint.value);

    return {
      x: new Date(dataPoint.start_time).getDate().toString(),
      y: value,
      label: `${prefix} ${formatNumber(value, {decimals})}`,
    };
  });

  return trailingDays === 30 ? data : data.slice(-trailingDays);
};

const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  }).format(date);
};

const getDateRange = (dataPoints: Array<BillingMeterDataPoint>) => {
  if (!dataPoints) {
    return '';
  }
  const startDate = new Date(dataPoints[0].start_time);
  const endDate = new Date(dataPoints[dataPoints.length - 1].start_time);
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

const formatMeterLabel = (key: string): string => {
  return key
    .split('_')
    .map(word => capitalizeFirstLetter(word))
    .join(' ');
};

const getMeterInfo = (meters: BillingMeters): MeterInfo[] => {
  return Object.entries(meters).map(([key, value]) => ({
    id: key,
    name: METER_LABELS[key] ?? formatMeterLabel(key),
    color: METER_COLORS[key] ?? getHashColor(key),
  }));
};

export function CreditUsage({
  domain,
  creditsMeters,
  metersPricing,
  loading,
}: Props) {
  const [trailingDays, setTrailingDays] = useState(TrailingDaysTabs.Month);
  const [dataType, setDataType] = useState(DataTypeTabs.Credits);
  const [chartWidth] = useState(DEFAULT_WIDTH);
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>(['credits']);
  const metersData = useRef<Map<string, Array<BillingMeterDataPoint>>>(
    new Map(creditsMeters ? [['credits', creditsMeters]] : []),
  );
  const [dataList, setDataList] = useState<BarChartData[]>([]);

  useEffect(() => {
    async function fetchMetersData() {
      for (const meter of selectedMetrics) {
        if (!metersData.current.has(meter)) {
          const metersResponse = await api.billing.getMeters(domain, meter);
          metersData.current = new Map(metersData.current).set(
            meter,
            metersResponse.usage.data_points,
          );
        }
      }

      setDataList(
        selectedMetrics.map(meter => ({
          label: METER_LABELS[meter] ?? formatMeterLabel(meter),
          color: METER_COLORS[meter] ?? getHashColor(meter),
          data: getChartData(
            metersData.current.get(meter),
            DAYS_OPTIONS[trailingDays],
            dataType,
          ),
        })),
      );
    }
    if (domain) {
      fetchMetersData();
    }
  }, [domain, selectedMetrics, trailingDays, dataType]);

  const leftTabs = Object.entries(DAYS_OPTIONS).map(([key, value]) =>
    createTabItem(key, TRAILING_DAYS_LABELS[key], '', trailingDays),
  );

  const rightTabs = Object.entries(DATA_TYPE_OPTIONS).map(([key, value]) => ({
    key,
    label: value,
  }));

  const handleChangeLeftTab = (value: string) => {
    setTrailingDays(value as TrailingDaysTabs);
  };

  const handleChangeRightTab = (value: string) => {
    setDataType(value as DataTypeTabs);
  };

  const tickFormatYAxis = (value: number) => {
    const showDollars = dataType === DataTypeTabs.Dollars;
    const prefix = showDollars ? '$' : '';
    return `${prefix} ${formatNumber(value, {decimals: showDollars ? 2 : 0})}`;
  };

  return (
    <View>
      {loading ? (
        <Loading />
      ) : (
        <View style={styles.container}>
          <View style={styles.tabsContainer}>
            <Typography
              variant="regular"
              size="xl"
              color={theme.colors.neutral.$700}>
              Credit Usage: {getDateRange(metersData.current.get('credits'))}
            </Typography>
            <View style={{flex: 1}} />
            <Tabs
              rootStyle={styles.tabs}
              tabStyle={styles.tab}
              tabs={leftTabs}
              activeTab={trailingDays}
              setActiveTab={handleChangeLeftTab}
            />
            <Tabs
              rootStyle={styles.tabs}
              tabStyle={styles.tab}
              tabs={rightTabs}
              activeTab={dataType}
              setActiveTab={handleChangeRightTab}
            />
          </View>
          <BarChart
            width={chartWidth}
            height={DEFAULT_HEIGHT}
            dataList={dataList}
            tickFormatYAxis={tickFormatYAxis}
          />
          <MetricsFilter
            meters={getMeterInfo(metersPricing)}
            selectedMetrics={selectedMetrics}
            onUpdateSelectedMetrics={setSelectedMetrics}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: theme.colors.neutral.$50,
    borderRadius: 16,
    paddingHorizontal: 24,
    paddingTop: 16,
    paddingBottom: 24,
    gap: 16,
  },
  tabs: {
    height: 36,
  },
  tab: {
    height: 32,
    minWidth: 98,
  },
  tabsContainer: {
    flexDirection: 'row',
    gap: 16,
  },
});
