export enum AppErrors {
  SubscriptionNotActivatedError = -100015,
  RetryRequestLimitError = -100014,
  ProcessingPreviewError = -100013,
  CreateOrUpdateSubError = -100012,
  StripeKeyNotFoundError = -100011,
  CreateOrgError = -100010,
  FileNetworkError = -100009,
  FilePreviewError = -100008,
  EntityWebSocketConnectionError = -100007,
  PollingError = -100006,
  GenericBoundaryError = -100005,
  JoinEmailPageLoadError = -100004,
  JoinInvitationProcessError = -100003,
  SignInPasswordError = -100002,
  LoadUserSessionDataError = -100001,
  UnknownError = -999999,
}
