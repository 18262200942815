import {BilledDurationType, PlanType} from 'store/slices/settings/types';

export const calcDowngradeOrUpgrade = (
  currentPlan: PlanType,
  selectedPlan: PlanType,
  currentDuration: BilledDurationType,
  selectedDuration: BilledDurationType,
):
  | 'Downgrade'
  | 'Upgrade'
  | 'Current Plan'
  | 'Switch to Annual'
  | 'Switch to Monthly' => {
  switch (currentPlan) {
    case PlanType.Startup:
      switch (selectedPlan) {
        case PlanType.Startup:
          return currentDuration === selectedDuration
            ? 'Current Plan'
            : currentDuration === BilledDurationType.Monthly
              ? 'Switch to Monthly'
              : 'Switch to Annual';
        default:
          return 'Downgrade';
      }
    case PlanType.Team:
      switch (selectedPlan) {
        case PlanType.Startup:
          return 'Upgrade';
        case PlanType.Team:
          return currentDuration === selectedDuration
            ? 'Current Plan'
            : currentDuration === BilledDurationType.Monthly
              ? 'Switch to Monthly'
              : 'Switch to Annual';
        default:
          return 'Downgrade';
      }
    case PlanType.Business:
      switch (selectedPlan) {
        case PlanType.Startup:
          return 'Upgrade';
        case PlanType.Team:
          return 'Upgrade';
        case PlanType.Business:
          return currentDuration === selectedDuration
            ? 'Current Plan'
            : currentDuration === BilledDurationType.Monthly
              ? 'Switch to Monthly'
              : 'Switch to Annual';
        case PlanType.Enterprise:
          return 'Downgrade';
      }
    case PlanType.Enterprise:
      switch (selectedPlan) {
        case PlanType.Enterprise:
          return currentDuration === selectedDuration
            ? 'Current Plan'
            : currentDuration === BilledDurationType.Monthly
              ? 'Switch to Monthly'
              : 'Switch to Annual';
        default:
          return 'Upgrade';
      }
  }
};
