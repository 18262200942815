import {DropIndicator} from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import {combine} from '@atlaskit/pragmatic-drag-and-drop/combine';
import {draggable} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {dropTargetForElements} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import {Toggle} from 'interface/base/Toggle';
import Typography from 'interface/base/Typography';
import {BREAKPOINTS} from 'interface/common/hooks/useBreakpoints';
import {Fragment, useEffect, useRef, useState} from 'react';
import {StyleSheet, View} from 'react-native';

interface Props {
  columns: Array<{
    name: string;
    displayName?: string;
    enabled: boolean;
  }>;
  onColumnToggle: (column: string, enabled: boolean) => void;
  containerWidth?: number;
  onColumnReorder?: (startIndex: number, endIndex: number) => void;
}

// Interface for drag-drop data
interface DragData {
  type: 'column';
  index: number;
  name: string;
  [key: string]: unknown;
}

export function ColumnsPopup({
  columns,
  onColumnToggle,
  containerWidth,
  onColumnReorder,
}: Props) {
  const [hasMenu, setHasMenu] = useState(false);
  const isMinimal = containerWidth && containerWidth < BREAKPOINTS.sm;
  const [orderedColumns, setOrderedColumns] = useState(columns);

  // Update ordered columns when the columns prop changes
  useEffect(() => {
    setOrderedColumns(columns);
  }, [columns]);

  const trigger = (
    <Button
      onPress={() => setHasMenu(true)}
      variant="secondary"
      startEnhancer={
        <Icon name="lucide:columns-3" color={theme.colors.neutral.$700} />
      }>
      {!isMinimal && (
        <Typography size="xs" color={theme.colors.neutral.$700}>
          Columns
        </Typography>
      )}
    </Button>
  );

  const update = (value: string, enabled: boolean) => {
    onColumnToggle(value, enabled);
  };

  // Handle column reordering
  const handleReorder = (sourceIndex: number, targetIndex: number) => {
    // Update local state
    setOrderedColumns(prev => {
      const newColumns = [...prev];
      const [movedItem] = newColumns.splice(sourceIndex, 1);
      newColumns.splice(targetIndex, 0, movedItem);
      return newColumns;
    });

    // Notify parent component
    if (onColumnReorder) {
      onColumnReorder(sourceIndex, targetIndex);
    }
  };

  return (
    <Popup
      isOpen={hasMenu}
      showBorder={false}
      triggerElement={trigger}
      customContainerStyle={styles.popupContainer}
      customPopupStyle={styles.customPopupStyle}
      close={() => setHasMenu(false)}>
      <Fragment>
        <Typography
          size="sm"
          variant="regular"
          color={theme.colors.neutral.$500}
          overrides={styles.title}>
          Choose Metadata Columns
        </Typography>
        {orderedColumns.map((column, index) => (
          <MenuItem
            key={column.name}
            title={
              column.displayName ||
              column.name.charAt(0).toUpperCase() + column.name.slice(1)
            }
            enabled={column.enabled}
            index={index}
            onPress={() => update(column.name, !column.enabled)}
            onReorder={handleReorder}
            isLast={index === orderedColumns.length - 1}
          />
        ))}
      </Fragment>
    </Popup>
  );
}

function MenuItem({
  title,
  enabled,
  index,
  onPress,
  onReorder,
  isLast,
}: {
  title: string;
  enabled: boolean;
  index: number;
  onPress: () => void;
  onReorder: (startIndex: number, endIndex: number) => void;
  isLast: boolean;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const dragHandleRef = useRef<View>(null);
  const itemRef = useRef<View>(null);
  const [closestEdge, setClosestEdge] = useState<'top' | 'bottom' | null>(null);

  useEffect(() => {
    // Use the ref instead of getElementById
    const element = dragHandleRef.current;
    const itemElement = itemRef.current;
    if (!element || !itemElement) return;
    // Get the native DOM elements from the refs
    const dragDomElement = element as unknown as HTMLElement;
    const itemDomElement = itemElement as unknown as HTMLElement;

    // Setup drag-and-drop
    const dragDropCleanup = combine(
      draggable({
        element: dragDomElement,
        getInitialData: () =>
          ({
            type: 'column',
            index,
            name: title,
          }) as DragData,
        onDragStart: () => {
          setIsDragging(true);
        },
        onDrop: () => {
          setIsDragging(false);
        },
      }),
      dropTargetForElements({
        element: itemDomElement,
        getIsSticky: () => true,
        canDrop: ({source}) => {
          const data = source.data as Record<string, unknown>;
          return data.type === 'column' && data.index !== index;
        },
        onDragEnter: ({source}) => {
          // Determine if we're dragging up or down
          const data = source.data as Record<string, unknown>;
          const sourceIndex = data.index as number;
          // If dragging down (from a higher position to lower), prefer bottom edge
          // Otherwise (dragging up), prefer top edge
          const preferredEdge = sourceIndex < index ? 'bottom' : 'top';
          setClosestEdge(preferredEdge);
        },
        onDragLeave: () => {
          setClosestEdge(null);
        },
        onDrop: ({source}) => {
          const data = source.data as Record<string, unknown>;
          const sourceIndex = data.index as number;
          // Determine where to drop based on edge
          onReorder(sourceIndex, index);
          setClosestEdge(null);
        },
      }),
    );

    return () => {
      dragDropCleanup();
    };
  }, [index, title, onReorder, closestEdge]);

  return (
    <View
      ref={itemRef}
      style={[
        styles.menuItemRoot,
        isDragging && styles.menuItemDragging,
        {zIndex: closestEdge ? 10 : 1},
      ]}>
      {/* Show drop indicator when this item is an active drop target */}
      {closestEdge && (
        <View style={styles.dropIndicatorContainer}>
          <DropIndicator edge={closestEdge} />
        </View>
      )}
      <HoveredView onPress={onPress} style={styles.hoverArea}>
        <View
          style={[
            styles.menuItemLayout,
            !isLast && {
              borderBottomWidth: 1,
              borderBottomColor: closestEdge
                ? 'transparent'
                : theme.colors.neutral.$75,
            },
          ]}>
          <View style={styles.menuItemGripLabel}>
            <View ref={dragHandleRef} style={styles.menuItemGrip}>
              <svg
                role="img"
                aria-label="drag"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.3">
                  <circle cx="1" cy="1" r="1" fill="#222835" />
                  <circle cx="6" cy="1" r="1" fill="#222835" />
                  <circle cx="1" cy="5" r="1" fill="#222835" />
                  <circle cx="6" cy="5" r="1" fill="#222835" />
                  <circle cx="1" cy="9" r="1" fill="#222835" />
                  <circle cx="6" cy="9" r="1" fill="#222835" />
                </g>
              </svg>
            </View>
            <View style={styles.menuItemLabel}>
              <Typography
                size="sm"
                variant="regular"
                color={theme.colors.neutral.$700}>
                {title}
              </Typography>
            </View>
          </View>
          <View style={styles.menuItemControls}>
            <View style={styles.menuItemToggle}>
              <Toggle checked={enabled} onChange={onPress} />
            </View>
          </View>
        </View>
      </HoveredView>
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 16,
  },
  popupContainer: {
    borderRadius: 16,
    padding: 16,
    width: 350,
    shadowColor: 'rgb(28, 27, 34)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
  },
  customPopupStyle: {
    backgroundColor: 'transparent',
    paddingBottom: 8,
    paddingTop: 8,
    paddingHorizontal: 8,
  },
  hoverArea: {
    flexGrow: 1,
    alignSelf: 'stretch',
  },
  dropIndicatorContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    zIndex: 20,
  },
  menuItemRoot: {
    flexDirection: 'row',
    height: 32,
    minHeight: 32,
    paddingTop: 0,
    paddingLeft: 6,
    paddingBottom: 0,
    paddingRight: 6,
    alignItems: 'center',
    gap: 8,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$0,
    cursor: 'grab',
    position: 'relative',
    marginBottom: 2,
  },
  menuItemDragging: {
    opacity: 0.5,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.12)',
  },
  menuItemLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    alignSelf: 'stretch',
  },
  menuItemGripLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    alignSelf: 'stretch',
  },
  menuItemGrip: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingLeft: 8,
    paddingBottom: 0,
    paddingRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  menuItemGripDots: {
    flexDirection: 'row',
    width: 7,
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    rowGap: 2,
    columnGap: 3,
    flexWrap: 'wrap',
  },
  menuItemLabel: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    alignSelf: 'stretch',
  },
  menuItemControls: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  menuItemToggle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
});
