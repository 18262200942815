import type {TextStyle} from 'react-native';

export const SIZE = {
  md: 'md',
} as const;

type Size = keyof typeof SIZE;

export const SIZES: Record<Size, TextStyle> = {
  md: {
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
};
