import {createSelector} from '@reduxjs/toolkit';
import {
  NEW_CHAT,
  NEW_PRIVATE_CHAT,
} from 'interface/stacks/workspace/layout/WorkspaceAiChat/consts';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getChats = (namespace: string, instanceId: string) =>
  createSelector(x, store => {
    const chats = Object.values(
      getNamespace(store, namespace, instanceId)?.chats ?? {},
    ).filter(val => val.status !== 'deleted');
    return chats;
  });

export const getChat = (
  namespace: string,
  instanceId: string,
  chatId: string,
) =>
  createSelector(x, store => {
    const chats = getNamespace(store, namespace, instanceId)?.chats;
    const chat = chats?.[chatId];

    if (chat?.messages) {
      // Create a copy of the messages array before sorting
      return {
        ...chat,
        messages: [...chat.messages].sort((a, b) =>
          a.createdAt.localeCompare(b.createdAt),
        ),
      };
    }
    return chat;
  });

export const getCurrentChat = (namespace: string, instanceId: string) =>
  createSelector(x, store => {
    const currentChatId = getNamespace(
      store,
      namespace,
      instanceId,
    )?.currentChat;
    if (!currentChatId) {
      return null;
    }
    if (currentChatId === NEW_CHAT) {
      return NEW_CHAT;
    }
    if (currentChatId === NEW_PRIVATE_CHAT) {
      return NEW_PRIVATE_CHAT;
    }
    const chat = getNamespace(store, namespace, instanceId)?.chats[
      currentChatId
    ];
    return chat?.id;
  });

export const getScopeFiles = () =>
  createSelector(x, store => store.aichat.scopeFiles ?? {});

export const getAttachFiles = () =>
  createSelector(x, store => store.aichat.attachFiles ?? {});

export const getStreamingChatId = () =>
  createSelector(x, store => store.aichat.triggerStreaming ?? null);

export const getSource = () => createSelector(x, store => store.aichat.source);

export const getPersonality = () =>
  createSelector(x, store => store.aichat.personality ?? 'detailed');

const getNamespace = (store: Store, namespace: string, instanceId: string) =>
  namespace === 'workspace'
    ? store.aichat.workspaces?.[instanceId]
    : store.aichat.shares?.[instanceId];
