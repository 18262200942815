import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Requests} from 'fast-sdk';
import {Input} from 'interface/base/Input';
import {IconButton} from 'interface/common/IconButton';
import ProfilePhotoPicker from 'interface/common/ProfilePhotoPicker';
import {useEffect, useRef, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {default as CommunityIcon} from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import {blobToBase64} from 'utils/common/image';
import Layout from './Layout';
import useUpdateUser from './hooks/useUpdateUser';

interface Props {
  next: () => void;
}

export default function AddProfilePicture(props: Props) {
  const isPhotoUpdatedRef = useRef<boolean>(false);
  const dispatch = useDispatch();

  const firstName = useSelector(user.selectors.getUserFirstName);
  const lastName = useSelector(user.selectors.getUserLastName);
  const {profile_pic: profilePicEndpoint, id: userId} = useSelector(
    user.selectors.getUserDetails,
  );
  const {imgSrc} = useSelector(onboarding.selectors.getUserData);
  const profilePicture = useSelector(user.selectors.getUserProfilePic);

  const [isEditing, setIsEditing] = useState(false);
  const [fullname, setFullname] = useState(() => `${firstName} ${lastName}`);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState(null);

  const {updateUserFullname, updateUserPhoto, loading} = useUpdateUser();

  useEffect(() => {
    async function getUserProfilePic() {
      if (profilePicEndpoint) {
        const response = await Requests.get<Blob>(
          profilePicEndpoint.replace('/current', ''),
        );
        blobToBase64(response, setUserProfilePic);
      }
    }
    getUserProfilePic();
  }, [profilePicEndpoint]);

  const onCropImage = async (file: File, src: string) => {
    if (!file || !src) {
      return;
    }

    if (isPhotoUpdatedRef.current) {
      return;
    }

    isPhotoUpdatedRef.current = true;
    dispatch(
      onboarding.default.actions.setUserData({
        user: {
          imgFile: file,
          imgSrc: src,
          imgType: file.type,
          imgName: file.name,
        },
      }),
    );
    await updateUserPhoto(userId, {
      file,
      name: file.name,
      type: file.type,
      src,
    });
  };

  const next = async () => {
    try {
      setIsNextLoading(true);
      const [newFirstName, newLastName] = fullname.split(' ');
      dispatch(
        onboarding.default.actions.setUserData({
          user: {
            firstName: newFirstName || '',
            lastName: newLastName || '',
          },
        }),
      );
      await updateUserFullname(userId, newFirstName, newLastName);
      await props.next();
    } finally {
      setIsNextLoading(false);
    }
  };

  return (
    <Layout
      title="Welcome to Fastio!"
      subtitle="This is what your profile will look like to your teammates in the Fastio app. You can change your name and profile picture now or later."
      next={next}
      loading={isNextLoading}>
      <View style={styles.root}>
        <ProfilePhotoPicker
          fullname={fullname}
          hideButtons={isEditing}
          profilePicture={userProfilePic ?? profilePicture ?? imgSrc}
          onCropImage={onCropImage}>
          {isEditing ? (
            <Input
              placeholder={t`Enter your name`}
              autoFocus
              onKeyPress={e =>
                e.nativeEvent.key === 'Escape' && setIsEditing(false)
              }
              value={fullname}
              onChangeValue={setFullname}
              aria-label="Full name"
              onBlurFn={() => setIsEditing(false)}
            />
          ) : (
            <View style={styles.nameRoot}>
              <Text style={styles.nameText}>{fullname}</Text>
              <IconButton
                icon={
                  <CommunityIcon
                    name="pencil"
                    size={20}
                    color={theme.colors.neutral.$5}
                  />
                }
                buttonStyle={styles.editIcon}
                onPress={() => setIsEditing(true)}
              />
            </View>
          )}
        </ProfilePhotoPicker>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 42,
  },
  right: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  emptyPhoto: {
    position: 'relative',
    width: 96,
    height: 96,
    borderRadius: 100,
    backgroundColor: '#6524FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadedPhoto: {
    width: 96,
    height: 96,
    borderRadius: 100,
    borderWidth: 3,
    borderColor: '#6524FF',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  initial: {
    fontSize: 24,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
    textTransform: 'uppercase',
  },
  button: {
    maxWidth: 108,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
  },
  image: {
    width: 96,
    height: 96,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addIcon: {
    borderRadius: 50,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$white,
    position: 'absolute',
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.neutral.$white,
  },
  nameRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  nameText: {
    fontSize: 21,
    lineHeight: 34,
    fontWeight: '600',
    color: theme.colors.neutral.$2Base,
  },
  editIcon: {
    minWidth: 36,
    minHeight: 36,
  },
});
