import theme from 'config/theme';
import {Svg} from 'react-native-svg';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryTooltip,
} from 'victory';
import {
  DEFAULT_PADDING,
  DEFAULT_TOOLTIP_FLYOUT_STYLE,
  DEFAULT_TOOLTIP_STYLE,
} from './consts';
import type {BarChartOverrides} from './types';

type TickFormatAxis = (value: number) => string;

interface DataPoint {
  x: string;
  y: number;
  label?: string;
}

export interface BarChartData {
  label: string;
  color: string;
  data: DataPoint[];
}

type Props = {
  width: number;
  height: number;
  overrides?: BarChartOverrides;
  dataList: BarChartData[];
  tickFormatYAxis?: TickFormatAxis;
  tickFormatXAxis?: TickFormatAxis;
};

export function BarChart({
  width,
  height,
  dataList,
  overrides,
  tickFormatYAxis,
  tickFormatXAxis,
}: Props) {
  const tickLabelStyle = {
    fontFamily: 'sans-serif',
    fontSize: 12,
    fill: theme.colors.neutral.$400,
  };

  const dependentAxisStyle = {
    tickLabels: tickLabelStyle,
    axis: {
      stroke: 'transparent',
    },
  };

  const crossAxisStyle = {
    tickLabels: tickLabelStyle,
    axis: {
      stroke: theme.colors.neutral.$100,
      marginBottom: 50,
    },
    axisLabel: {
      margin: 30,
    },
  };

  return (
    <Svg
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
      width="100%">
      <VictoryChart
        padding={DEFAULT_PADDING}
        standalone={false}
        width={width}
        height={height}
        domainPadding={{x: 30}}
        {...overrides?.Chart?.props}>
        <VictoryAxis
          dependentAxis
          style={overrides?.DependantAxis?.style ?? dependentAxisStyle}
          tickFormat={tickFormatYAxis}
        />
        <VictoryAxis
          crossAxis
          style={overrides?.CrossAxis?.style ?? crossAxisStyle}
          tickFormat={tickFormatXAxis}
        />
        <VictoryStack>
          {dataList.map((dataSet, index) => {
            const isLast = index === dataList.length - 1;
            return (
              <VictoryBar
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={5}
                    pointerLength={6}
                    flyoutHeight={30}
                    flyoutPadding={10}
                    flyoutStyle={DEFAULT_TOOLTIP_FLYOUT_STYLE}
                    constrainToVisibleArea={true}
                    renderInPortal={true}
                    style={overrides?.Tooltip?.style ?? DEFAULT_TOOLTIP_STYLE}
                    {...overrides?.Tooltip?.props}
                  />
                }
                style={{
                  data: {
                    fill: dataSet.color,
                    width: 24,
                  },
                }}
                cornerRadius={isLast ? {topLeft: 3, topRight: 3} : {}}
                {...overrides?.Bar?.props}
                data={dataSet.data}
              />
            );
          })}
        </VictoryStack>
      </VictoryChart>
    </Svg>
  );
}
