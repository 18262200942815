import {SESSION_COOKIE_KEY} from 'constants/sessionRoutes';
import {clearImagesCache} from 'extensions/images';
import {Requests} from 'fast-sdk';
import {SLIDES_KEY} from 'interface/stacks/onboard/base/OnboardSlideModal';
import {resetAppStore} from 'store';
import Auth from '../../interface/stacks/auth/consts';
import {clearCookie} from './cookies';
import {COLUMN_KEY} from './ui';

const IGNORE_COOKIES = ['ve_br_key', COLUMN_KEY, SLIDES_KEY];

const clearAllCaches = async () => {
  /*if (caches) {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(name => caches.delete(name)));
  }*/
};

const clearAllCookies = () => {
  document.cookie.split(';').forEach(cookie => {
    const [name] = cookie.split('=').map(c => c.trim());
    if (
      !IGNORE_COOKIES.includes(name) ||
      !IGNORE_COOKIES.some(cookie => name.includes(cookie))
    ) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  });
};

const clearSessionCookie = async (noClearSession: boolean) => {
  if (!noClearSession) {
    await clearCookie(SESSION_COOKIE_KEY);
  }
};

export const clearCache = async (
  stayOnPage = false,
  noClearSession = false,
) => {
  if (window) {
    await resetAppStore();
    await clearSessionCookie(noClearSession);
    clearAllCookies();
    await clearAllCaches();
    localStorage.clear();
    sessionStorage.clear();
    await Auth.clearDB();
    Requests.setAuthToken(null);
    clearImagesCache();
    if (!stayOnPage) {
      window.location.reload();
    }
  }
};
