import {WORKSPACE_TRASH} from 'constants/routes';
import {api} from 'fast-sdk';
import {
  FilePreviewState,
  FileThumbnailSizes,
  PreviewType,
} from 'fast-sdk/src/api/storage/consts';
import {useCallback, useState} from 'react';
import type {FilesItem} from 'store/slices/files/types';
import {blobToBase64} from 'utils/common/image';

export default function useFileThumbnailSource() {
  const [loading, setLoading] = useState<boolean>(false);
  const fetchImageThumbnail = useCallback(
    async (
      item: FilesItem,
      size: FileThumbnailSizes,
      isPreview: boolean,
      previewType: PreviewType,
    ): Promise<string> => {
      if (!previewType) {
        return undefined;
      }
      const thumbnailSize = isPreview ? undefined : size;
      const shareId = item.shareId;
      const quickShareId = item.quickShare?.id;
      const namespace = shareId
        ? 'share'
        : quickShareId
          ? 'quickshare'
          : 'workspace';
      const instanceId = quickShareId ?? shareId ?? item.workspaceId;
      const storage = api.storage.init(namespace, instanceId);
      const preview = storage.preview(item.id);
      const imageThumbnail = await preview.readPrimary(
        previewType,
        thumbnailSize,
      );

      return await new Promise(resolve =>
        blobToBase64(imageThumbnail, resolve),
      );
    },
    [],
  );

  const fetchFileThumbnail = useCallback(
    async (item: FilesItem, size: FileThumbnailSizes): Promise<string> => {
      setLoading(true);
      try {
        if (item.parent === WORKSPACE_TRASH) {
          return '';
        }

        const isPreview = size === FileThumbnailSizes.Preview;
        const isThumbnail =
          size === FileThumbnailSizes.Tiny || size === FileThumbnailSizes.Small;
        const PREVIEW_TYPE_PRIORITY = isPreview
          ? [PreviewType.IMAGE]
          : isThumbnail
            ? [PreviewType.THUMBNAIL]
            : [PreviewType.IMAGE, PreviewType.THUMBNAIL];

        const availablePreviews = Object.entries(item?.previews ?? {})
          .filter(([, preview]) => preview.state === FilePreviewState.READY)
          .map(([key]) => key);

        const previewType = PREVIEW_TYPE_PRIORITY.find(type =>
          availablePreviews.includes(type),
        );

        if (previewType) {
          return await fetchImageThumbnail(item, size, isPreview, previewType);
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {fetchFileThumbnail, loading};
}
