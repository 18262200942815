import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {useFetchChatList} from 'interface/stacks/workspace/layout/WorkspaceAiChat/hooks/useFetchChatList';
import {Fragment, useEffect} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {AiChatListHeader} from '../components/AiChatListHeader';

import {AiChatItem} from '../components/AiChatItem';

import {GROUP_BY_DAY, groupChatsByDay} from '../helpers';

import {useToast} from 'react-native-toast-notifications';
import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';
import {AiChatListSkeleton} from '../components/skeletons/AiChatListSkeleton';
import {AICHAT_TOOLBAR_HEIGHT} from '../consts';

interface Props {
  namespace: string;
  instanceId: string;
  onChatPress: (chat: EntityChat) => void;
  onNewChatPress: (isPrivate: boolean) => void;
  onCloseSidebar: () => void;
  workspaceMembers: Member[];
  showMenu?: boolean;
}

export function AiChatList({
  namespace,
  instanceId,
  onChatPress,
  onNewChatPress,
  onCloseSidebar,
  showMenu,
  workspaceMembers,
}: Props) {
  const toast = useToast();
  const chats = useSelector(aichat.selectors.getChats(namespace, instanceId));
  const currentChatId = useSelector(
    aichat.selectors.getCurrentChat(namespace, instanceId),
  );
  const {
    isLoading: chatsLoading,
    isCompleted: chatsCompleted,
    error,
    fetchChatList,
  } = useFetchChatList();

  useEffect(() => {
    fetchChatList();
  }, [instanceId]);

  useEffect(() => {
    if (error) {
      toast.show(error.message);
    }
  }, [error]);

  const groupedChats = groupChatsByDay(chats);
  const isEmpty = chats.length === 0;

  useEffect(() => {
    if (chatsCompleted && isEmpty) {
      onNewChatPress(false);
    }
  }, [chatsCompleted, isEmpty]);

  const showSkeleton = chatsLoading && chats.length === 0;

  return (
    <View style={styles.container}>
      {showMenu ? (
        <AiChatListHeader
          onNewChatPress={onNewChatPress}
          onCloseSidebar={onCloseSidebar}
        />
      ) : (
        <View style={{height: AICHAT_TOOLBAR_HEIGHT}} />
      )}
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.chatsContainer}>
          {showSkeleton ? (
            <Fragment>
              <AiChatListSkeleton />
              <AiChatListSkeleton />
            </Fragment>
          ) : (
            groupedChats &&
            Object.entries(groupedChats).map(([key, value]) => (
              <Fragment>
                {value.length > 0 && (
                  <Typography
                    overrides={styles.groupTitle}
                    size="sm"
                    variant="medium"
                    color={theme.colors.neutral.$700}>
                    {GROUP_BY_DAY[key]}
                  </Typography>
                )}
                <View style={styles.chatList}>
                  {value.map((chat, index) => (
                    <AiChatItem
                      key={index}
                      chat={chat}
                      isSelected={currentChatId === chat.id}
                      workspaceMembers={workspaceMembers}
                      onPress={() => onChatPress(chat)}
                    />
                  ))}
                </View>
              </Fragment>
            ))
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
    // padding: 16,
  },
  groupTitle: {
    paddingTop: 16,
    paddingBottom: 8,
    paddingHorizontal: 8,
  },
  chatsContainer: {
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
    paddingHorizontal: 16,
  },
  chatList: {
    width: '100%',
    alignSelf: 'center',
    marginBottom: 8,
  },
});
