import '@blocknote/core/fonts/inter.css';
import {BlockNoteView} from '@blocknote/mantine';
import '@blocknote/mantine/style.css';
import {Fragment, useEffect} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {useEditorContext} from '../../context/EditorContext';
import {HEIGHT_TOP_TOOLBAR} from '../EditorTopToolbar';

interface Props {
  markdownText: string;
  theme?: 'light' | 'dark';
  maxHeight?: string;
  centered?: boolean;
}

const MARGIN_TOP = 64;

export default function MarkdownDraftViewer({
  markdownText,
  theme = 'light',
}: Props) {
  const {editor, initializeEditor, markdownLoaded} = useEditorContext();

  useEffect(() => {
    initializeEditor(markdownText);
  }, [markdownText]);

  return (
    <Fragment>
      {markdownLoaded && (
        <ScrollView contentContainerStyle={styles.root}>
          <BlockNoteView editor={editor} theme={theme} />
        </ScrollView>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: MARGIN_TOP,
    maxWidth: 800,
    maxHeight: `calc(100vh - ${HEIGHT_TOP_TOOLBAR + MARGIN_TOP * 2}px)`,
    padding: 15,
    width: '100%',
    alignSelf: 'center',
  },
});
