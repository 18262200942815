import {ModalContext} from 'extensions/viewport/ModalContext';
import {useMemo, useState} from 'react';

import type {ModalOptions} from 'extensions/viewport/ModalContext';
import {BaseModal} from './BaseModal';

export const ModalProvider = ({children}) => {
  const [ariaLabel, setAriaLabel] = useState<string>();
  const [content, setContent] = useState<JSX.Element>();
  const [options, setOptions] = useState<ModalOptions>({});
  const [visible, setVisible] = useState<boolean>(false);

  const context = useMemo(
    () => ({
      visible: false,
      open: (content: JSX.Element, options?: ModalOptions) => {
        setOptions(options ?? {});
        setContent(content);
        setVisible(true);
      },
      close: () => {
        setVisible(false);
        setOptions({});
      },
      setAriaLabel: (ariaLabel: string) => {
        setAriaLabel(ariaLabel);
      },
    }),
    [],
  );

  return (
    <ModalContext.Provider value={context}>
      {children}
      <BaseModal
        options={options}
        content={content}
        visible={visible}
        ariaLabel={ariaLabel}
        onClose={context.close}
      />
    </ModalContext.Provider>
  );
};
