import {ROUTES} from 'constants/routes';
import {api} from 'fast-sdk';
import type {BillingStatus} from 'fast-sdk/src/api/billing/consts';
import type {BillingPlan} from 'interface/stacks/onboard/lib/types';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';

interface Options {
  disable?: boolean;
  generatePaymentIntent?: boolean;
  redirectWhenSubscriptionActive?: boolean;
  onError?: () => void;
  plan?: BillingPlan;
}

const defaultOptions: Options = {
  disable: false,
  generatePaymentIntent: false,
  onError: () => {},
  redirectWhenSubscriptionActive: false,
} as const;

export default function useBillingDetails(
  domain: string,
  options = defaultOptions,
) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [billingStatus, setBillingStatus] = useState<BillingStatus | null>(
    null,
  );

  async function getBillingDetails(domain: string) {
    return api.billing.getSubscriptionDetails(domain);
  }

  function shouldGenerateNewIntent(
    billingStatus: BillingStatus,
    options: Options,
  ): boolean {
    if (!options.generatePaymentIntent) return false;

    const setupIntent = billingStatus?.setup_intent;
    return (
      !setupIntent || (Array.isArray(setupIntent) && setupIntent.length === 0)
    );
  }

  async function handlePaymentIntentGeneration(
    domain: string,
    billingStatus: BillingStatus,
  ) {
    let planToGenerateIntent: BillingPlan = options.plan;
    if (!planToGenerateIntent) {
      const {result, plans} = await api.billing.getPlanList();
      if (!result) return null;

      planToGenerateIntent =
        plans.find(plan => plan.name === billingStatus.current_plan.name) ??
        plans[0];
    }

    const response = await api.billing.createOrUpdateSubscription(
      domain,
      planToGenerateIntent.name,
    );

    if (!response.result) {
      options.onError?.();
    }

    return response.result ? response : null;
  }

  const fetchBillingDetails = async () => {
    try {
      const {result, billing_status} = await getBillingDetails(domain);

      if (!result) return;

      if (options.redirectWhenSubscriptionActive && billing_status.active) {
        return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
      }

      if (shouldGenerateNewIntent(billing_status, options)) {
        const updatedResponse = await handlePaymentIntentGeneration(
          domain,
          billing_status,
        );
        if (updatedResponse) {
          setBillingStatus(updatedResponse.billing_status);
        }
      } else {
        setBillingStatus(billing_status);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!domain || options.disable) {
      return;
    }

    setLoading(true);
    fetchBillingDetails();
  }, [domain, options.disable]);

  return {billingStatus, loading} as const;
}
