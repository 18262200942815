import {api} from 'fast-sdk';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';

export function useIndustries() {
  const dispatch = useDispatch();

  const industries = useSelector(onboarding.selectors.getIndustries);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (industries.length > 0) {
      return;
    }

    async function fetchIndustries() {
      setLoading(true);
      const {result, error, ...rest} = await api.organization.getIndustries();

      if (result) {
        const industries = Object.entries(rest).map(([id, industry]) => ({
          id,
          ...industry,
        }));
        dispatch(onboarding.default.actions.setIndustries({industries}));
      }

      setLoading(false);
    }
    fetchIndustries();
  }, []);

  return {industries, loading};
}
