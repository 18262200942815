import {Fragment} from 'react';
import type {FilesItem} from 'store/slices/files/types';
import Pagination from '../../components/Pagination';
import MarkdownDraftViewer from './MarkdownDraftViewer';
import MarkdownFileViewer from './MarkdownFileViewer';

interface Props {
  markdownText: string;
  theme: 'light' | 'dark';
  file?: FilesItem;
}

export default function MarkdownViewer({markdownText, theme, file}: Props) {
  return (
    <Fragment>
      {file ? (
        <MarkdownFileViewer file={file} theme={theme} />
      ) : (
        <MarkdownDraftViewer markdownText={markdownText} theme={theme} />
      )}
      {file && <Pagination />}
    </Fragment>
  );
}
