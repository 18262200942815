import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import type {
  MetadataListResponse,
  StorageNamespace,
  TemplatesResponse,
} from 'fast-sdk/src/api/storage/consts';

import type {
  WorkspaceMetadataTemplateResponse,
  WorkspaceMetadataTemplatesResponse,
} from 'fast-sdk/src/api/workspace/consts';

/** Get templates for a workspace */
export async function getWorkspaceTemplates(
  workspaceId: string,
): Promise<WorkspaceMetadataTemplatesResponse> {
  analytics.log('Retrieved workspace templates');
  return await api.workspace.getWorkspaceMetadataTemplates(workspaceId);
}

/**
 * Get metadata template for a workspace
 */
export async function getWorkspaceTemplate(
  workspaceId: string,
  templateId: string,
): Promise<WorkspaceMetadataTemplateResponse> {
  return await api.workspace.getWorkspaceMetadataTemplate(
    workspaceId,
    templateId,
  );
}

/**
 * Get templates associated with a folder
 */
export async function getStorageTemplates(
  folderId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
): Promise<TemplatesResponse> {
  analytics.log('Retrieved templates');
  const storage = api.storage.init(instanceNs, instanceId);
  return await storage.getTemplates(folderId);
}

/**
 * Get metadata for files using a specific template
 */
export async function getStorageMetadataList(
  folderId: string,
  templateId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
  options?: {
    filters?: string;
    order_by?: string;
    order_desc?: boolean;
  },
): Promise<MetadataListResponse> {
  analytics.log('Retrieved metadata');
  const storage = api.storage.init(instanceNs, instanceId);
  return await storage.getMetadataList(folderId, templateId, options);
}
